/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-03 11:00:10
 * @ Description: All the feature of the property search search function. Containing dialogs, menus, and the no properties view allowing for search by city, saved searchs, and recent searchs
 */

import React, { useEffect, useRef, useState } from 'react'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import compact from 'lodash/compact'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import remove from 'lodash/remove'
import head from 'lodash/head'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import join from 'lodash/join'
import slice from 'lodash/slice'
import chunk from 'lodash/chunk'
import isNil from 'lodash/isNil'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputBase,
  List,
  ListItem,
  Menu,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import SearchIcon from '@mui/icons-material/Search'

import Script from 'react-load-script'
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import {
  CALL_REAPI,
  CALL_REAPI_COUNT,
  GET_POPULAR_SEARCHES,
  GET_ZIP_BY_CITY_STATE,
  GET_ZIP_BY_COUNTY_STATE,
} from '../PropertySearch/queries'
import Autocomplete from '@mui/material/Autocomplete'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_STATETABLE } from '../PropertySearch/queries'
import { UPDATE_TABLE } from '../PropertySearch/mutations'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import withSession from '@/housefolios-components/Session/withSession'
import { MoreVertOutlined } from '@mui/icons-material'
import { useStopwatch } from 'react-timer-hook'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'
import { GENERIC_SEND_EMAIL } from '@/housefolios-components/Session/mutations'
import AddSearch from '@/housefolios-components/Dialogs/AddSearch'
import { DatePicker } from '@mui/x-date-pickers'
import AddPropertyButton from '@/housefolios-components/TitleButtons/AddPropertyButton'
import { get } from 'lodash'

import { compressAndStore } from '@/functions/lzma.js'
// import { setSearchParam } from '@/utils/url'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

function SearchArea(props) {
  const { title, topEnterCity, bottomMenus, reapiInputRef } = props
  const [openNoPropertiesAvailable, setOpenNoPropertiesAvailable] =
    useState(false)
  const [openNearbyCities, setOpenNearbyCities] = useState(false)
  const [open1, setOpen1] = useState(false)

  const [placeResult, setPlaceResult] = useState(null)
  const [state, setState] = useState('')
  const [county, setCounty] = useState('')
  const [altCities, setAltCities] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const [zips, setZips] = useState([])

  const [addPropertyPlaceResult, setAddPropertyPlaceResult] =
    useState(null)

  const { current } = reapiInputRef

  const [price, setPrice] = useState(current.price)
  const [beds, setBeds] = useState(current.beds)
  const [baths, setBaths] = useState(current.baths)
  const [sqft, setSqft] = useState(current.sqft)
  const [year, setYear] = useState(current.year)

  const [mlsActive, setMLSActive] = useState(
    isNil(current.mls_active) ? 'both' : current.mls_active,
  )
  const [vacant, setVacant] = useState(
    isNil(current.vacant) ? 'both' : current.vacant,
  )
  const [lien, setLien] = useState(
    isNil(current.lien) ? 'both' : current.lien,
  )
  const [cashBuyer, setCashBuyer] = useState(
    isNil(current.cashBuyer) ? 'both' : current.cashBuyer,
  )
  const [preForeclosure, setPreForeclosure] = useState(
    isNil(current.preForeclosure) ? 'both' : current.preForeclosure,
  )
  const [auction, setAuction] = useState(
    isNil(current.auction) ? 'both' : current.auction,
  )
  const [reo, setReo] = useState(
    isNil(current.reo) ? 'both' : current.reo,
  )
  const [highEquity, setHighEquity] = useState(
    isNil(current.highEquity) ? 'both' : current.highEquity,
  )
  const [freeClear, setFreeClear] = useState(
    isNil(current.freeClear) ? 'both' : current.freeClear,
  )
  const [mlsFailed, setMLSFailed] = useState(
    isNil(current.mlsFailed) ? 'both' : current.mlsFailed,
  )
  const [taxDelinquentYear, setTaxDelinquentYear] = useState(
    current.taxDelinquentYear,
  )
  const [propertyType, setPropertyType] = useState(
    current.propertyType,
  )

  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const [searchCount, setSearchCount] = useState(-1)
  const [openThreshold, setOpenThreshold] = useState(false)

  const [name, setName] = useState('')

  const [loader, setLoader] = useState(false)

  const { loading, error, data } = useQuery(GET_STATETABLE)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const [genericSendEmail] = useMutation(GENERIC_SEND_EMAIL)

  const sendNoCityFound = (cities, state) => {
    genericSendEmail({
      variables: {
        msg: {
          to: [
            { addr: 'daniel.clark@housefolios.com' },
            { addr: 'jared.rossean@housefolios.com' },
          ],
          subject: `${
            hostname === 'localhost' ||
            hostname === 'dev-admin.housefolios.com'
              ? 'DEV/TEST '
              : ''
          }Missing City`,
          text: `A user: ${
            props.session.me._id
          } did a property search for ${join(
            cities,
            ',',
          )}, ${state} - which is not in our system`,
          html: `A user: ${
            props.session.me._id
          } did a property search for ${join(
            cities,
            ',',
          )}, ${state} - which is not in our system`,
        },
      },
    })
  }

  const sendNoCountyFound = (county, state) => {
    genericSendEmail({
      variables: {
        msg: {
          to: [
            { addr: 'daniel.clark@housefolios.com' },
            { addr: 'jared.rossean@housefolios.com' },
          ],
          subject: `${
            hostname === 'localhost' ||
            hostname === 'dev-admin.housefolios.com'
              ? 'DEV/TEST '
              : ''
          }Missing County`,
          text: `A user: ${props.session.me._id} did a property search for ${county}, ${state} - which is not in our system`,
          html: `A user: ${props.session.me._id} did a property search for ${county}, ${state} - which is not in our system`,
        },
      },
    })
  }

  const handleOpen1 = () => {
    setOpen1(true)
  }
  const handleClose1 = () => {
    setOpen1(false)
  }
  const handleOpenNoPropertiesAvailable = () => {
    setOpenNoPropertiesAvailable(true)
  }
  const handleCloseNoPropertiesAvailable = () => {
    setOpenNoPropertiesAvailable(false)
  }
  const handleOpenNearbyCities = () => {
    setOpenNearbyCities(true)
  }
  const handleCloseNearbyCities = () => {
    setOpenNearbyCities(false)
    setLoader(false)
    pause()
  }

  const resetParams = () => {
    setPrice({})
    setBeds({})
    setBaths({})
    setSqft({})
    setYear({})
    setMLSActive(true)
    setVacant('both')
    setLien('both')
    setCashBuyer('both')
    setPreForeclosure('both')
    setAuction('both')
    setReo('both')
    setHighEquity('both')
    setFreeClear('both')
    setMLSFailed('both')
    setTaxDelinquentYear({})
    setPropertyType('ANY')
    if (reapiInputRef)
      reapiInputRef.current = {
        mls_active: true,
        beds: {},
        baths: {},
        sqft: {},
        year: {},
        price: {},
        vacant: undefined,
        lien: undefined,
        cashBuyer: undefined,
        preForeclosure: undefined,
        auction: undefined,
        reo: undefined,
        highEquity: undefined,
        freeClear: undefined,
        mlsFailed: undefined,
        taxDelinquentYear: {},
        propertyType: undefined,
      }
  }

  const clearAll = () => {
    props.setResults([])
    resetParams()
    handleClearButtonClick()
  }

  //  const reapiInputRef = useRef({
  //   mls_active: mlsActive === 'both' ? undefined : mlsActive,
  //   beds,
  //   baths,
  //   sqft,
  //   year,
  //   price,
  //   vacant: vacant === 'both' ? undefined : vacant,
  //   lien: lien === 'both' ? undefined : lien,
  //   preForeclosure: preForeclosure === 'both' ? undefined : preForeclosure,
  //   auction: auction === 'both' ? undefined : auction,
  //   reo: reo === 'both' ? undefined : reo,
  //   highEquity: highEquity === 'both' ? undefined : highEquity,
  //   freeClear: freeClear === 'both' ? undefined : freeClear,
  //   mlsFailed: mlsFailed === 'both' ? undefined : mlsFailed,
  //   taxDelinquentYear,
  //  })

  useEffect(() => {
    reapiInputRef.current = {
      mls_active: mlsActive === 'both' ? undefined : mlsActive,
      beds: {
        min: beds.min ? beds.min : undefined,
        max: beds.max ? beds.max : undefined,
      },
      baths: {
        min: baths.min ? baths.min : undefined,
        max: baths.max ? baths.max : undefined,
      },
      sqft: {
        min: sqft.min ? sqft.min : undefined,
        max: sqft.max ? sqft.max : undefined,
      },
      year: {
        min: year.min ? year.min : undefined,
        max: year.max ? year.max : undefined,
      },
      price: {
        min: price.min ? price.min : undefined,
        max: price.max ? price.max : undefined,
      },
      vacant: vacant === 'both' ? undefined : vacant,
      lien: lien === 'both' ? undefined : lien,
      cashBuyer: cashBuyer === 'both' ? undefined : cashBuyer,
      preForeclosure:
        preForeclosure === 'both' ? undefined : preForeclosure,
      auction: auction === 'both' ? undefined : auction,
      reo: reo === 'both' ? undefined : reo,
      highEquity: highEquity === 'both' ? undefined : highEquity,
      freeClear: freeClear === 'both' ? undefined : freeClear,
      mlsFailed: mlsFailed === 'both' ? undefined : mlsFailed,
      taxDelinquentYear: {
        min: taxDelinquentYear.min
          ? taxDelinquentYear.min
          : undefined,
        max: taxDelinquentYear.max
          ? taxDelinquentYear.max
          : undefined,
      },
      propertyType: propertyType === 'ANY' ? undefined : propertyType,
    }
  }, [
    mlsActive,
    beds.min,
    beds.max,
    baths.min,
    baths.max,
    sqft.min,
    sqft.max,
    year.min,
    year.max,
    price.min,
    price.max,
    vacant,
    lien,
    cashBuyer,
    preForeclosure,
    auction,
    reo,
    highEquity,
    freeClear,
    mlsFailed,
    taxDelinquentYear.min,
    taxDelinquentYear.max,
    propertyType,
  ])

  let autocomplete

  const handleScriptLoad = (client) => () => {
    // Declare Options For Autocomplete
    //  var options = {
    //    types: ['(cities)'],
    //  } // To disable any eslint 'google not defined' error

    // Initialize Google Autocomplete
    /*global google*/ autocomplete =
      new google.maps.places.Autocomplete(
        document.getElementById('propertySearch-autocomplete'),
        // options,
      )
    autocomplete.setFields([
      'address_component',
      'geometry',
      'formatted_address',
    ])
    autocomplete.addListener('place_changed', () => {
      setPlaceResult(
        autocomplete.getPlace().address_components
          ? autocomplete.getPlace()
          : null,
      )
      if (autocomplete.getPlace().address_components) {
        const place = autocomplete.getPlace()
        if (
          get(place, 'address_components.0.types.0') == 'locality' ||
          get(place, 'address_components.0.types.0') == 'postal_code'
        ) {
          countSearch(client, place, reapiInputRef)
        } else {
          setAddPropertyPlaceResult(place)
        }
      }
    })
  }

  function handleClearButtonClick() {
    const autocompleteInput = document.getElementById(
      'propertySearch-autocomplete',
    )

    // Clear the value of the autocomplete input field
    if (autocompleteInput) autocompleteInput.value = ''

    // If using Google Maps autocomplete, also clear its internal value
    if (autocomplete) {
      autocomplete.set('place', null)
    }
  }

  const [updateTable] = useMutation(UPDATE_TABLE)

  const countSearch = async (client, placeResult, reapiInputRef) => {
    if (placeResult) {
      setLoader(true)
      reset()
      if (placeResult.address_components) {
        let location = {
          administrative_area_level_1: 'short_name',
          locality: 'long_name',
          postal_code: 'short_name',
        }
        for (
          let i = 0;
          i < placeResult.address_components.length;
          i++
        ) {
          const addressType =
            placeResult.address_components[i].types[0]
          if (location[addressType])
            location[addressType] =
              placeResult.address_components[i][location[addressType]]
        }
        for (var prop in location) {
          if (Object.prototype.hasOwnProperty.call(location, prop)) {
            if (
              location[prop] === 'long_name' ||
              location[prop] === 'short_name'
            )
              location[prop] = ''
          }
        }
        const city = location.locality
        const state = location.administrative_area_level_1
        const zip = location.postal_code

        if ((city || zip) && state) {
          const {
            data: { callREAPICount },
          } = await client.query({
            query: CALL_REAPI_COUNT,
            variables: {
              REAPIInput: {
                cities: zip ? [] : compact([city]),
                state,
                zips: compact([zip]),
                ...reapiInputRef.current,
              },
            },
          })
          let result = callREAPICount
          if (result > 0) {
            setOpenSearchDialog(true)
            setSearchCount(result)
          } else handleCloseNoPropertiesAvailable()
          setLoader(false)
        }
      }
    }
  }

  const handleSearch = async (client, placeResult, reapiInputRef) => {
    if (placeResult) {
      setLoader(true)
      reset()
      if (placeResult.address_components) {
        let location = {
          administrative_area_level_1: 'short_name',
          locality: 'long_name',
          postal_code: 'short_name',
        }
        for (
          let i = 0;
          i < placeResult.address_components.length;
          i++
        ) {
          const addressType =
            placeResult.address_components[i].types[0]
          if (location[addressType])
            location[addressType] =
              placeResult.address_components[i][location[addressType]]
        }
        for (var prop in location) {
          if (Object.prototype.hasOwnProperty.call(location, prop)) {
            if (
              location[prop] === 'long_name' ||
              location[prop] === 'short_name'
            )
              location[prop] = ''
          }
        }
        const city = location.locality
        const state = location.administrative_area_level_1
        const zip = location.postal_code

        if ((city || zip) && state) {
          const {
            data: { callREAPI },
          } = await client.query({
            query: CALL_REAPI,
            variables: {
              REAPIInput: {
                cities: zip ? [] : compact([city]),
                state,
                zips: compact([zip]),
                ...reapiInputRef.current,
              },
            },
          })
          let result = callREAPI || []
          //  const {
          //    data: { zipTableByCityState },
          //  } = await client.query({
          //    query: GET_ZIP_BY_CITY_STATE,
          //    variables: {
          //      city: [city],
          //      state: state,
          //    },
          //  })
          //  let zips = map(zipTableByCityState, (zip) => zip.zipcode)
          //  if (isEmpty(zips)) sendNoCityFound([city], state)
          //  if (isEmpty(zips) && zip) {
          //    await updateTable({
          //      variables: {
          //        tableInputs: [
          //          {
          //            zipcode: zip,
          //            city: city,
          //          }
          //        ]
          //      }
          //    })
          //    zips = [zip]
          //  }
          //  let result = []
          //  let county = []
          //  let isAltCity = false
          //  if (!isEmpty(zips)) {
          //    county = uniq(flatten(map(zipTableByCityState, (zip) => zip.counties)))
          //    const countyZips = uniq(flatten(await Promise.all(map(county, async county => {
          //      const { data: { zipTableByCountyState } } = await client.query({
          //        query: GET_ZIP_BY_COUNTY_STATE,
          //        variables: {
          //          state,
          //          county,
          //        },
          //        errorPolicy: 'ignore',
          //      })
          //      return zipTableByCountyState ? map(zipTableByCountyState, zip => zip.zipcode) || [] : []
          //    }))))
          //    if (isEmpty(countyZips)) sendNoCountyFound(county, state)
          //    const chunkZips = chunk(zips, 50)
          //    result = compact(uniq(flatten(await Promise.all(map(chunkZips, async zips => {
          //      let data = {
          //        apiKey: apiKey,
          //        action: 'property_search',
          //        type: 'Single Family',
          //        env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
          //        orgID: apiID,
          //        state: state,
          //        city: city,
          //        zips: zips,
          //        county: county,
          //        countyZips: countyZips,
          //      }
          //      const str_json = JSON.stringify(data)
          //      let response = { ok: false }
          //      try {
          //        response = await fetch(
          //          hostname === 'localhost'
          //            ? 'http://data.housefolios.com/housefoliosdev-api.php'
          //            //? 'http://housefolios-data.test/housefoliosdev-api.php'
          //            : hostname === 'dev-admin.housefolios.com'
          //              ? 'https://data.housefolios.com/housefoliosdev-api.php'
          //              : 'https://data.housefolios.com/housefolios-api.php',
          //          {
          //            method: 'POST',
          //            headers: {
          //              'Content-Type': 'application/json',
          //            },
          //            body: str_json,
          //          },
          //        )
          //      } catch (error) {
          //        console.log(error)
          //      }
          //      if (!response.ok) {
          //        // setAltCities([])
          //        // setSelectedCities([])
          //        // props.setResults([], null)
          //        // localStorage.setItem('propertySearch', null)
          //        // localStorage.setItem('propertySearch-name', '')
          //        enqueueSnackbar(
          //          `Something has gone wrong. Please contact support`,
          //          {
          //            variant: 'error',
          //            autoHideDuration: 3000,
          //          },
          //        )
          //        // setLoader(false)
          //        // pause()
          //        return []
          //      }
          //      return await response.json()
          //    })))))
          //    if (every(result, object => Object.keys(object)[0] === 'altCity')) isAltCity = true
          //    else {
          //      result = filter(result, object => Object.keys(object)[0] !== 'altCity')
          //    }
          //  }
          const searchResultCount = result.length
          // const {
          //   data: { marketplaceProperties },
          // } = await client.query({
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // })
          // const filteredMarketplaceProperties = filter(
          //   marketplaceProperties,
          //   (property) =>
          //     (property.zip === zip ||
          //       (property.city === city &&
          //         property.state === state)) &&
          //     new Date(
          //       property.marketplaces.expirationDate,
          //     ).getTime() > Date.now(),
          // )

          //  //OVERVIEW COMMENT: IF WE DON'T RECEIVE CITIES FROM PROPERTY SEARCH AND THERE ARE MARKETPLACE PROPERTIES IN THIS CITY, SHOW BOTH.
          //  if (!isAltCity) result = concat(filteredMarketplaceProperties, result)
          //  else
          //  {
          //    //OVERVIEW COMMENT: IF WE RECEIVE ALT CITIES FROM PROPERTY SEARCH, BUT THERE ARE MARKETPLACE PROPERTIES IN THIS CITY, JUST SHOW THE MARKETPLACE PROPERTIES. ELSE, JUST SHOW PROPERTY SEARCH PROPERTIES
          //    if (filteredMarketplaceProperties.length > 0) result = filteredMarketplaceProperties
          //    //else result = result
          //  }
          //SPECIFIC COMMENT: Alt cities not compatiable with REAPI
          // result = concat(filteredMarketplaceProperties, result)

          //setCity(city)
          setState(state)
          setLoader(false)
          handleClose1()
          setOpenSearchDialog(false)
          pause()
          //  setPlaceResult(null)
          const newSearch = {
            // name: `${zip ? zip : city}, ${state}`,
            name:
              placeResult.formatted_address ??
              `${zip ? zip : city}, ${state}`,
            state,
            county: head(county),
            cities: zip ? [] : [city],
            zips: compact([zip]),
            searchType: 'property search',
            searchCount: searchResultCount,
            searchParameters: {
              cities: zip ? [] : compact([city]),
              state,
              zips: compact([zip]),
              ...reapiInputRef.current,
            },
          }
          if (
            !props.session.me.activeOrg.recentSearches?.find(
              (search) => search?.name === newSearch?.name,
            )
          ) {
            updateOrganization({
              variables: {
                id: props.session.me.activeOrg._id,
                organizationInput: {
                  newRecentSearch: newSearch,
                },
              },
              refetchQueries: [{ query: GET_ME }],
            })
          }
          if (result.length === 0) {
            //  setAltCities([])
            //  setSelectedCities([])
            props.setResults([], null)
            localStorage.setItem('propertySearch', null)
            localStorage.setItem('propertySearch-name', '')
            handleOpenNoPropertiesAvailable()
            setLoader(false)
            handleClose1()
            setOpenSearchDialog(false)
            pause()
          }
          //  else if (Object.keys(result[0])[0] === 'altCity') {
          //    setAltCities(map(result, (city) => city.altCity))
          //    setSelectedCities([])
          //    props.setResults([], null)
          //    localStorage.setItem('propertySearch', null)
          //    localStorage.setItem('propertySearch-name', '')
          //    handleOpenNoPropertiesAvailable()
          //    setLoader(false)
          //    pause()
          //  }
          else {
            props.setResults(result, `${city}, ${state}`)
            // setSearchParam(placeResult.formatted_address)
            compressAndStore(
              result,
              city,
              state,
              reapiInputRef.current,
            )
          }
        } else {
          //setCity('')
          setState('')
          setLoader(false)
          handleClose1()
          setOpenSearchDialog(false)
          pause()
          //  setPlaceResult(null)
        }
      }
    }
  }

  //  const searchMultipleCities = (client) => async () => {
  //    let zips = []
  //    let county = []
  //    if (selectedCities.length !== 0) {
  //      setLoader(true)
  //      reset()
  //      for (let i = 0; i < selectedCities.length; i++) {
  //        const {
  //          data: { zipTableByCityState },
  //        } = await client.query({
  //          query: GET_ZIP_BY_CITY_STATE,
  //          variables: {
  //            city: selectedCities,
  //            state: state,
  //          },
  //        })
  //        const newZips = map(zipTableByCityState, (zip) => zip.zipcode)
  //        if (isEmpty(newZips)) sendNoCityFound(selectedCities, state)
  //        for (let k = 0; k < newZips.length; k++) {
  //          zips.push(newZips[k])
  //        }
  //        const newCounties = map(zipTableByCityState, (zip) => zip.counties)
  //        for (let k = 0; k < newCounties.length; k++) {
  //          county.push(newCounties[k])
  //        }
  //      }
  //      county = uniq(flatten(county))
  //      const countyZips = uniq(flatten(await Promise.all(map(county, async county => {
  //        const { data: { zipTableByCountyState } } = await client.query({
  //          query: GET_ZIP_BY_COUNTY_STATE,
  //          variables: {
  //            state,
  //            county,
  //          },
  //          errorPolicy: 'ignore',
  //        })
  //        return zipTableByCountyState ? map(zipTableByCountyState, zip => zip.zipcode) || [] : []
  //      }))))
  //      if (isEmpty(countyZips)) sendNoCountyFound(county, state)
  //      let data = {
  //        apiKey: apiKey,
  //        orgID: apiID,
  //        action: 'saved_property_search',
  //        type: 'Single Family',
  //        env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
  //        cities: selectedCities,
  //        state: state,
  //        zips: zips,
  //        county: county,
  //        countyZips: countyZips,
  //      }
  //      if (zips.length !== 0) {
  //        const str_json = JSON.stringify(data)
  //        let response = { ok: false }
  //        response = await fetch(
  //          hostname === 'localhost'
  //          ? 'http://data.housefolios.com/housefoliosdev-api.php'
  //          //? 'http://housefolios-data.test/housefoliosdev-api.php'
  //            : hostname === 'dev-admin.housefolios.com'
  //              ? 'https://data.housefolios.com/housefoliosdev-api.php'
  //              : 'https://data.housefolios.com/housefolios-api.php',
  //          {
  //            method: 'POST',
  //            headers: {
  //              'Content-Type': 'application/json',
  //            },
  //            body: str_json,
  //          },
  //        )
  //        if (!response.ok) {
  //          handleCloseNearbyCities()
  //          props.setResults([], null)
  //          localStorage.setItem('propertySearch', null)
  //          localStorage.setItem('propertySearch-name', '')
  //          enqueueSnackbar(
  //            `Something has gone wrong. Please contact support`,
  //            {
  //              variant: 'error',
  //              autoHideDuration: 3000,
  //            },
  //          )
  //          return null
  //        }
  //        let result = await response.json()
  //        const {
  //          data: { marketplaceProperties },
  //        } = await client.query({
  //          query: GET_MARKETPLACES,
  //          variables: {
  //            marketplaces: ['housefolios', 'myGrayLabel'],
  //            approved: true,
  //          },
  //        })
  //        const newSearch = {
  //          name: selectedCities.length > 3 ? `${selectedCities.length} ${state} Cities` : `${join(slice(selectedCities, 0, 3), ', ')}, ${state}`,
  //          state,
  //          county: head(county),
  //          cities: selectedCities,
  //          zips: zips,
  //        }
  //        updateOrganization({
  //          variables: {
  //            id: props.session.me.activeOrg._id,
  //            organizationInput: {
  //              newRecentSearch: newSearch,
  //            }
  //          },
  //          refetchQueries: [
  //            { query: GET_ME }
  //          ],
  //        })
  //        const filteredMarketplaceProperties = filter(marketplaceProperties, property => includes(zips, property.zip) && new Date(property.marketplaces.expirationDate).getTime() > Date.now())
  //        result = concat(filteredMarketplaceProperties, result)
  //        handleCloseNearbyCities()
  //        //setCity('')
  //        props.setResults(result, `Alternative Cities`)
  //        my_my_lzma.compress(JSON.stringify(result), 1, (result, error) => {
  //          const size = new TextEncoder().encode(JSON.stringify(result)).length
  //          if (size < 4 * 1024 * 1024) {
  //            localStorage.setItem('propertySearch', result)
  //            localStorage.setItem('propertySearch-name', `Alternative Cities`)
  //          }
  //          else {
  //            localStorage.setItem('propertySearch', null)
  //            localStorage.setItem('propertySearch-name', '')
  //          }
  //        }, percent => { return })
  //      } else {
  //        handleCloseNearbyCities()
  //        enqueueSnackbar(
  //          `These properties could not be found`,
  //          {
  //            variant: 'warning',
  //            autoHideDuration: 3000,
  //          },
  //        )
  //        //setCity('')
  //      }
  //    } else {
  //      handleCloseNearbyCities()
  //    }
  //  }

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  if (loading) return 'Loading...'
  if (error) return error.message

  return (
    <Grid item xs={12}>
      <ApolloConsumer>
        {(client) => (
          <>
            <Script
              url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
              onLoad={handleScriptLoad(client)}
            />
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              style={{ marginBottom: 25 }}
            >
              <Grid
                item
                xs={
                  props.session.me.activeOrg.isRealeflow ? 9 : 12 //THIS SEEMS LIKE UNUSED CODE SINCE WE USE REALEFLOW
                }
              >
                <TextField
                  id="propertySearch-autocomplete"
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Enter an address or city"
                  // autoFocus
                  style={{ backgroundColor: 'white' }}
                  // InputProps={{
                  //     endAdornment: (
                  //         <InputAdornment position="end" margin='dense'>
                  //             <IconButton>
                  //                 <FontAwesomeIcon
                  //                     icon={['far', 'magnifying-glass']}
                  //                     className="font-size-lg"
                  //                 />
                  //             </IconButton>
                  //         </InputAdornment>
                  //     )
                  // }}
                />
              </Grid>
              {props.session.me.activeOrg.isRealeflow && ( //THIS SEEMS LIKE UNUSED CODE SINCE WE USE REALEFLOW
                <>
                  <Grid item>
                    <Button
                      onClick={handleOpen1}
                      variant="outlined"
                      color="primary"
                    >
                      Search Criteria
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={clearAll}
                      variant="outlined"
                      color="primary"
                    >
                      Clear All
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            <Backdrop
              open={loader}
              style={{
                color: '#fff',
                zIndex: 2000,
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
              open={open1}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              maxWidth="sm"
              onKeyDown={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
            >
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">
                    <p>Searching for properties</p>
                  </div>
                }
              >
                <DialogTitle id="form-dialog-title">
                  Customize your property search
                </DialogTitle>
                <DialogContent className="p-3">
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: -15 }}>
                      <Typography>Property Details</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Min Asking Price"
                        fullWidth={true}
                        value={price.min || ''}
                        onChange={(event) =>
                          setPrice({
                            ...price,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Max Asking Price"
                        fullWidth={true}
                        value={price.max || ''}
                        onChange={(event) =>
                          setPrice({
                            ...price,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Beds Min"
                        fullWidth={true}
                        value={beds.min || ''}
                        onChange={(event) =>
                          setBeds({
                            ...beds,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Beds Max"
                        fullWidth={true}
                        value={beds.max || ''}
                        onChange={(event) =>
                          setBeds({
                            ...beds,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Baths Min"
                        fullWidth={true}
                        value={baths.min || ''}
                        onChange={(event) =>
                          setBaths({
                            ...baths,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Baths Max"
                        fullWidth={true}
                        value={baths.max || ''}
                        onChange={(event) =>
                          setBaths({
                            ...baths,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Sqft Min"
                        fullWidth={true}
                        value={sqft.min || ''}
                        onChange={(event) =>
                          setSqft({
                            ...sqft,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Sqft Max"
                        fullWidth={true}
                        value={sqft.max || ''}
                        onChange={(event) =>
                          setSqft({
                            ...sqft,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Year Min"
                        fullWidth={true}
                        value={year.min || ''}
                        onChange={(event) =>
                          setYear({
                            ...year,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Year Max"
                        fullWidth={true}
                        value={year.max || ''}
                        onChange={(event) =>
                          setYear({
                            ...year,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: 25, marginBottom: 25 }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: -15 }}>
                      <Typography>More Details</Typography>
                    </Grid>
                    {/* <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="On Market"
                        fullWidth={true}
                        value={mlsActive}
                        onChange={event => setMLSActive(event.target.value)}
                      >
                        <MenuItem key="yes" value={true}>Yes</MenuItem>
                        <MenuItem key="no" value={false}>No</MenuItem>
                        <MenuItem key="both" value={'both'}>Both</MenuItem>
                      </TextField>
                    </Grid> */}
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Vacant"
                        fullWidth={true}
                        value={vacant}
                        onChange={(event) =>
                          setVacant(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Liens"
                        fullWidth={true}
                        value={lien}
                        onChange={(event) =>
                          setLien(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Cash Buyer"
                        fullWidth={true}
                        value={cashBuyer}
                        onChange={(event) =>
                          setCashBuyer(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Pre-Foreclosures"
                        fullWidth={true}
                        value={preForeclosure}
                        onChange={(event) =>
                          setPreForeclosure(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Auctions"
                        fullWidth={true}
                        value={auction}
                        onChange={(event) =>
                          setAuction(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Bank Owned"
                        fullWidth={true}
                        value={reo}
                        onChange={(event) =>
                          setReo(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="High Equity"
                        fullWidth={true}
                        value={highEquity}
                        onChange={(event) =>
                          setHighEquity(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Free and Clear"
                        fullWidth={true}
                        value={freeClear}
                        onChange={(event) =>
                          setFreeClear(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Failed Listings"
                        fullWidth={true}
                        value={mlsFailed}
                        onChange={(event) =>
                          setMLSFailed(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <DatePicker
                        name="taxDelinquentYear.min"
                        label="Tax Delinquent Year Min"
                        value={taxDelinquentYear.min || null}
                        maxDate={taxDelinquentYear.max}
                        onChange={(newValue) => {
                          setTaxDelinquentYear({
                            ...taxDelinquentYear,
                            min: newValue,
                          })
                        }}
                        fullWidth={true}
                        views={['year']}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                        disableFuture
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <DatePicker
                        name="taxDelinquentYear.max"
                        label="Tax Delinquent Year Max"
                        value={taxDelinquentYear.max || null}
                        minDate={taxDelinquentYear.min}
                        onChange={(newValue) => {
                          setTaxDelinquentYear({
                            ...taxDelinquentYear,
                            max: newValue,
                          })
                        }}
                        fullWidth={true}
                        views={['year']}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                        disableFuture
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        name="propertyType"
                        label="Property Type"
                        options={[
                          'ANY',
                          'SFR',
                          'MFR',
                          'LAND',
                          'CONDO',
                          'MOBILE',
                          'OTHER',
                        ]}
                        value={propertyType}
                        onChange={(event, newValue) => {
                          setPropertyType(newValue)
                        }}
                        disableClearable
                        renderOption={(
                          props,
                          option,
                          { selected },
                        ) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={
                                <CheckBoxIcon fontSize="small" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Property Type"
                            margin="dense"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className=" pr-4">
                  <Grid container direction="row">
                    <Typography style={{ flex: 1 }} />
                    <Grid item>
                      <Button onClick={resetParams} variant="text">
                        <Typography style={{ fontWeight: 600 }}>
                          Clear
                        </Typography>
                      </Button>
                      <Button
                        onClick={handleClose1}
                        variant="outlined"
                        style={{ marginLeft: 5 }}
                      >
                        <Typography style={{ fontWeight: 600 }}>
                          Close
                        </Typography>
                      </Button>
                      {!isEmpty(placeResult) && (
                        <Button
                          onClick={() =>
                            countSearch(
                              client,
                              placeResult,
                              reapiInputRef,
                            )
                          }
                          disabled={isEmpty(placeResult)}
                          className="btn-primary shadow-none"
                          style={{ marginLeft: 5 }}
                        >
                          <Typography style={{ fontWeight: 600 }}>
                            Search
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </DialogActions>
              </BlockUi>
            </Dialog>

            {/* Search Count */}
            <Dialog
              open={openSearchDialog}
              onClose={() => setOpenSearchDialog(false)}
              onClick={(event) => event.stopPropagation()}
              classes={{ paper: 'shadow-lg rounded' }}
            >
              <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div
                    className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                    style={{ backgroundColor: '#ffddca' }}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'location-exclamation']}
                      className="d-flex align-self-center display-3"
                      style={{ fontSize: 56, color: '#ff8132' }}
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  This property search will return {searchCount}{' '}
                  number of properties, do you want to proceed
                </h4>
                <div className="pt-4">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        fullWidth={true}
                        onClick={() => {
                          setOpenSearchDialog(false)
                        }}
                        style={{
                          backgroundColor: '#008aff',
                          color: 'white',
                        }}
                      >
                        <Typography>No</Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth={true}
                        variant="contained"
                        onClick={() => {
                          if (searchCount > 6000)
                            setOpenThreshold(true)
                          else
                            handleSearch(
                              client,
                              placeResult,
                              reapiInputRef,
                            )
                        }}
                        style={{
                          backgroundColor: '#008aff',
                          color: 'white',
                        }}
                      >
                        <Typography>Yes</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Dialog>

            {/* Threshold Overflow */}
            <Dialog
              open={openThreshold}
              onClose={() => setOpenThreshold(false)}
              onClick={(event) => event.stopPropagation()}
              classes={{ paper: 'shadow-lg rounded' }}
            >
              <div className="text-center p-5">
                <p className="font-weight-bold mt-4">
                  Before we can do a search of that many properties,
                  we would like to chat with them to understand who
                  they are and their business goals. Please select the
                  live chat option in the Support menu of the left
                  navigation bar.
                </p>
                <p>
                  You can also make use of the search criteria to
                  filter the property result set to a smaller ammount.
                </p>
                <div className="pt-4">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        fullWidth={true}
                        onClick={() => {
                          setOpenThreshold(false)
                          setOpenSearchDialog(false)
                        }}
                        style={{
                          backgroundColor: '#008aff',
                          color: 'white',
                        }}
                      >
                        <Typography>Close</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Dialog>

            {/* No properties in searched area */}
            <Dialog
              open={openNoPropertiesAvailable}
              onClose={handleCloseNoPropertiesAvailable}
              onClick={(event) => event.stopPropagation()}
              classes={{ paper: 'shadow-lg rounded' }}
            >
              <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div
                    className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                    style={{ backgroundColor: '#ffddca' }}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'location-exclamation']}
                      className="d-flex align-self-center display-3"
                      style={{ fontSize: 56, color: '#ff8132' }}
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  No Data yet. Try again tomorrow!
                </h4>
                <p className="mb-0 font-size-lg text-muted">
                  We work with national data providers to see
                  available property listings. We will check if any of
                  them cover your desired city. It may take up to 24
                  hours.
                </p>
                <div className="pt-4">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    {/* {!isEmpty(altCities) && (
                    <Grid item>
                      <Button
                        fullWidth={true}
                        variant="text"
                        onClick={() => {
                          handleCloseNoPropertiesAvailable()
                          handleOpenNearbyCities()
                        }}
                        style={{ backgroundColor: '#ff8132', color: 'white' }}
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'map-marker-check']}
                          className="d-flex align-self-center display-3 font-size-lg mr-2"
                        />
                        <Typography>See Nearby Cities</Typography>
                      </Button>
                    </Grid>
                  )} */}
                    {/* <Grid item>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      onClick={() => {
                        handleCloseNoPropertiesAvailable()
                        handleOpenCitySearch()
                      }}
                      style={{ backgroundColor: '#008aff', color: 'white' }}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'magnifying-glass']}
                        className="d-flex align-self-center display-3 font-size-lg mr-2"
                      />
                      <Typography>New Search</Typography>
                    </Button>
                  </Grid> */}
                  </Grid>
                </div>
              </div>
            </Dialog>
            {/* Nearby Cities */}
            {/* SPECIFIC COMMENT: REAPI feature doesn't work with alt cities */}
            {/* <Dialog
            open={openNearbyCities}
            onClose={handleCloseNearbyCities}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth='sm'
          >
            <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />} message={<div className="text-primary" ><p>Connecting</p><p>{((minutes * 60 + seconds) / 120 * 100).toFixed(1)}% Complete</p></div>}>
              <DialogTitle id="form-dialog-title">Nearby Cities</DialogTitle>
              <DialogContent className="p-4">
                <DialogContentText className="mb-4">
                  Here are some nearby cities that we have data for now!
                </DialogContentText>
                <Grid container>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={altCities}
                      disableCloseOnSelect
                      value={selectedCities}
                      onChange={(event, value) =>
                        setSelectedCities(value)
                      }
                      renderOption={(
                                      props,
                                      option,
                                      { selected },
                                    ) => (
                                      <li {...props}>
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon fontSize="small" />
                            }
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                style={{ color: '#2296F3' }}
                              />
                            }
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          fullWidth={true}
                          placeholder='Cities with data'
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className=" pr-4">
                <Grid container direction='row' alignItems='center' >
                  <Typography style={{ flex: 1 }} />
                  <Grid item>
                    <Button
                      onClick={handleCloseNearbyCities}
                      variant="text"
                    >
                      <Typography style={{ fontWeight: 600, }}>Cancel</Typography>
                    </Button>
                    <Button onClick={searchMultipleCities(client)} className="btn-primary shadow-none" disabled={isEmpty(selectedCities)}>
                      <Typography style={{ fontWeight: 600 }}>Search</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </BlockUi>
          </Dialog> */}
          </>
        )}
      </ApolloConsumer>
      <AddPropertyButton placeResult={addPropertyPlaceResult} />
    </Grid>
  )
}

export default withSession(SearchArea)
