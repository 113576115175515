import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'

export const GET_LOTS = gql`
  query lots($portfolioId: [ID]) {
    lots(portfolioId: $portfolioId) {
      _id
      askingPrice
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      lotSqft
      strategy
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      description
      proformaDescription
      proformaImages
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      deletedAt
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions {
        ...assumptions
      }
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      owned
      appraisedValue
      lender
      lenderStatus
      sewer
      water
      exchange
      market
      type
      plan
      zone
      parcelID
      subdivision
      daysOnMarket
      neighborhood
      dueDiligenceFee
      percTest
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const GET_LOT = gql`
  query lot($id: ID!) {
    lot(id: $id) {
      _id
      askingPrice
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      lotSqft
      strategy
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      description
      proformaDescription
      proformaImages
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      deletedAt
      propertyId
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions {
        ...assumptions
      }
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      owned
      appraisedValue
      lender
      lenderStatus
      sewer
      water
      exchange
      market
      type
      plan
      zone
      parcelID
      subdivision
      daysOnMarket
      neighborhood
      dueDiligenceFee
      percTest
      lotChecklist
      buildChecklist
      saleChecklist
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const GET_COMMERCIAL_PROPERTIES = gql`
  query commercialProperties($portfolioId: [ID]) {
    commercialProperties(portfolioId: $portfolioId) {
      _id
      askingPrice
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      lotSqft
      strategy
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      description
      proformaDescription
      proformaImages
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      deletedAt
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions {
        ...assumptions
      }
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      owned
      appraisedValue
      lender
      lenderStatus
      sewer
      water
      exchange
      market
      type
      plan
      zone
      parcelID
      subdivision
      daysOnMarket
      neighborhood
      dueDiligenceFee
      percTest
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const GET_LOT_PORTFOLIOS = gql`
  query lotPortfolios {
    lotPortfolios {
      _id
      name
    }
  }
`

export const GET_COMMERCIAL_PORTFOLIOS = gql`
  query commercialPortfolios {
    commercialPortfolios {
      _id
      name
    }
  }
`

export const GET_LOT_PROCESSES = gql`
  query lotProcesses($lotId: ID!) {
    lotProcesses(lotId: $lotId) {
      _id
      lot
      build
      sale
    }
  }
`
