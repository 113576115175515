/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-29 10:22:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-01-15 10:51:40
 * @ Description: Determines which page to show based off of url
 */

import React, { Suspense, lazy, useState, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
} from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import includes from 'lodash/includes'

import {
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import MuiTheme from './theme'
import MuiTheme2 from './theme/theme2'

import isNil from 'lodash/isNil'
import filter from 'lodash/filter'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import head from 'lodash/head'

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout,
  PublicSidebar,
} from './layout-blueprints'

const AssumptionsSettingsPage = lazy(
  () => import('./housefolios-pages/AssumptionsSettings'),
)
import BillingSettingsPage from './housefolios-pages/BillingSettings'
import CreateAccountPage from './housefolios-pages/CreateAccount'
import ForgotPasswordPage from './housefolios-pages/ForgotPassword'
import EnterpriseAccountPage from './housefolios-pages/EnterpriseAccount'
import FreeAccount from './housefolios-pages/FreeAccount'
const LaunchpadPage = lazy(
  () => import('./housefolios-pages/Launchpad'),
)
const ToolsPage = lazy(() => import('./housefolios-pages/Tools'))
const MarketplacePage = lazy(
  () => import('./housefolios-pages/Marketplace'),
)
const MarketplaceSettingsPage = lazy(
  () => import('./housefolios-pages/MarketplaceSettings'),
)
const ReportsPage = lazy(() => import('./housefolios-pages/Reports'))
const ReportsPage2 = lazy(
  () => import('./housefolios-pages/Reports2'),
)
import ResetPasswordPage from './housefolios-pages/ResetPassword'
const OrganizationSettingsPage = lazy(
  () => import('./housefolios-pages/OrganizationSettings'),
)
const PersonalSettingsPage = lazy(
  () => import('./housefolios-pages/PersonalSettings'),
)
// import PortfolioPage from './housefolios-pages/Portfolio/'
const PropertiesPage = lazy(
  () => import('./housefolios-pages/Properties'),
)
const PropertyPage = lazy(
  () => import('./housefolios-pages/Property'),
)
import PropertySearchPage from './housefolios-pages/PropertySearch'
const TrashPage = lazy(() => import('./housefolios-pages/Trash'))
import SignInPage from './housefolios-pages/SignIn'
const TeamSettingsPage = lazy(
  () => import('./housefolios-pages/TeamSettings'),
)
import PublicLanding from '@/housefolios-pages/PublicLanding'
import PublicProperty from '@/housefolios-pages/PublicProperty'
const SharedPortfolio = lazy(
  () => import('./housefolios-pages/SharedPortfolio'),
)
const SharedMarketplace = lazy(
  () => import('./housefolios-pages/SharedMarketplace'),
)
const SharedProperty = lazy(
  () => import('./housefolios-pages/SharedProperty'),
)
const SharedProperties = lazy(
  () => import('./housefolios-pages/SharedProperties'),
)
const PDFContainer = lazy(
  () =>
    import(
      './housefolios-components/PropertyContainer/Proforma/pdfContainer'
    ),
)
const TrainingPage = lazy(
  () => import('./housefolios-pages/TrainingPage'),
)
const LotsPage = lazy(() => import('./housefolios-pages/Lots'))
import Typography from '@mui/material/Typography'

import withSession from '@/housefolios-components/Session/withSession'
import ExternalSignIn from '@/housefolios-components/SignIn/ExternalSignIn'
import DelinquentPage from '@/housefolios-components/zComponents/DelinquentPage'
import DeactivatedPage from '@/housefolios-components/zComponents/DeactivatedPage'
import ReactGA from 'react-ga'
const Notifications = lazy(
  () => import('./housefolios-pages/Notifications'),
)

const LotPage = lazy(() => import('./housefolios-pages/Lot'))
const CommercialPage = lazy(
  () => import('./housefolios-pages/Commercial'),
)
import addCompImage from '../src/assets/images/addCompImage.png'
import strategyTourImg from '../src/assets/images/strategyTourImage.png'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
const PartnerPortal = lazy(
  () => import('./housefolios-pages/PartnerPortal'),
)
import CoachingOffer from '@/housefolios-pages/coachingOffer'
import PublicMarketplacePage from '@/housefolios-pages/PublicMarketplace'
import PublicPropertyDashboardPage from '@/housefolios-pages/PublicPropertyDashboard'
import { result } from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_ORG_FROM_MARKETPLACE } from '@/housefolios-components/PublicProperty/queries'
import { GET_REALEFLOW_LOGIN_TOKEN } from '@/housefolios-components/PropertySearch/queries'
const ClientPage = lazy(() => import('./housefolios-pages/Client'))
const FeatureRequest = lazy(
  () => import('./housefolios-pages/FeatureRequest'),
)
const SupportChatPage = lazy(
  () => import('./housefolios-pages/SupportChatPage'),
)
import SuspenseLoading from '@/housefolios-components/Routes/SuspenseLoading'
import RealeflowRedirect from '@/housefolios-components/Routes/realeflowRedirect'
import { pageTransition, pageVariants } from '@/utils/animation'
import {
  Tour,
  AnalyzeAreaTour,
  AnalyzeCompsTour,
  ChooseStrategyTour,
  FeedbackTour,
  TakeActionTour,
  VerifyFinancialsTour,
  useTour,
} from '@/housefolios-components/Tours'
import SubDomain from '@/housefolios-components/Routes/SubDomain'
import Board from './housefolios-pages/Board'
import CssBaseline from '@mui/material/CssBaseline'
import useAffiliate from './hooks/useAffiliate'
import ProformaRedirect from './housefolios-components/Routes/ProformaRedirect'
import Legal from '@/housefolios-pages/Legal'
import Terms from '@/housefolios-pages/Terms'
import Privacy from '@/housefolios-pages/Privacy'

import { Helmet } from 'react-helmet-async'
import DealAnalysisPage from './housefolios-pages/DealAnalysis'

var GA_MARKETING_ID = import.meta.env.VITE_GA_MARKETING_ID

ReactGA.initialize(GA_MARKETING_ID)

const RoutesComponent = (props) => {
  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      ['internal'],
    )
  })

  const {
    openAnalyzeAreaTour,
    openAnalyzeCompsTour,
    openChooseStrategyTour,
    openFeedbackTour,
    openTakeActionTour,
    openTour,
    openVerifyFinancialsTour,
    setOpenAnalyzeAreaTour,
    setOpenAnalyzeCompsTour,
    setOpenChooseStrategyTour,
    setOpenFeedbackTour,
    setOpenTakeActionTour,
    setOpenTour,
    setOpenVerifyFinancialsTour,
  } = useTour()

  const location = useLocation()

  const { session, refetch } = props
  const { me } = session || {}
  const isLoggedIn = !isNil(me) ? true : false

  const hostname = import.meta.env.VITE_HOSTNAME

  var subdomain = window.location.host.split('.')[1]
    ? window.location.host.split('.')[0]
    : null
  if (
    hostname === 'localhost' ||
    hostname === 'dev-admin.housefolios.com'
  ) {
    const urlParams = new URLSearchParams(window.location.search)
    subdomain = urlParams.get('subdomain')
  } else if (window.location.host === 'roitk.com') {
    subdomain = 'roitk'
  } else if (window.location.host === 'nlinvesting.com') {
    subdomain = 'nlinvesting'
  } else if (subdomain === 'app' || subdomain === 'app2') {
    subdomain = null
  }

  const isNotPropertyDashboard =
    !includes(window.location.href, 'property-details') &&
    !includes(window.location.href, 'editLot')

  // var superAdmin = false
  // if (isLoggedIn)
  //   superAdmin =
  //     filter(me.roles, (role) => role.role[0] === 'superAdmin')
  //       .length > 0
  //       ? true
  //       : false

  // var deactiveChild = false
  // if (isLoggedIn)
  //   if (me.activeOrg)
  //     if (me.activeOrg.parentCorpDetails)
  //       if (!me.activeOrg.parentCorpDetails.active)
  //         deactiveChild = true

  var delinquent = false
  if (isLoggedIn)
    if (me.activeOrg) if (me.activeOrg.delinquent) delinquent = true

  var noOrg = false
  if (isLoggedIn)
    if (
      filter(me.roles, (role) => {
        return role.role[0] !== 'deactive'
      }).length === 0
    )
      noOrg = true

  var defaultPage = '/launchpad'
  if (isLoggedIn) {
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
      props.session.me.preferences,
    )
    const { general = {} } = preferences
    if (general.defaultPage) defaultPage = general.defaultPage.value
  }

  const [
    { affiliate, affiliateLoading, urlAffiliate, urlHelmetData },
  ] = useAffiliate({
    memberValue: isLoggedIn
      ? props?.session?.me?.activeOrg?.member
      : null,
  })

  // const favicon = document.getElementById('favicon')
  // const title = document.getElementById('titleId')
  let sidebarMemberSmallImage = '/images/LeftNavLogo.png'
  let titleText = 'Housefolios - Analyze, Manage and Sell Real Estate'
  if (urlAffiliate) {
    if (!isEmpty(urlHelmetData)) {
      sidebarMemberSmallImage =
        urlHelmetData.icon || '/images/LeftNavLogo.png'
      titleText = urlHelmetData.titleText || titleText
    }
    // actually affiliate takes priority
    if (!isEmpty(affiliate)) {
      const { sidebar = {} } = affiliate
      sidebarMemberSmallImage =
        get(sidebar, 'sidebarMemberSmallImage.0.url') ||
        '/images/LeftNavLogo.png'
      titleText = affiliate?.companyName || titleText
    }
  } else {
    if (isLoggedIn && affiliate) {
      if (!isEmpty(affiliate)) {
        const { sidebar = {} } = affiliate
        sidebarMemberSmallImage =
          get(sidebar, 'sidebarMemberSmallImage.0.url') ||
          '/images/LeftNavLogo.png'
        titleText = affiliate?.companyName || titleText
      }
    }
  }

  // favicon.href = sidebarMemberSmallImage
  // title.innerText = titleText

  // var salesperson = false
  // if (isLoggedIn) {
  //   const includesOrg = find(me.roles, [
  //     'organization._id',
  //     me.activeOrg._id,
  //   ])

  //   salesperson = includesOrg ? includesOrg.role[0] === 'salesperson' : false
  // }

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth >= 950 && window.innerWidth < 1200,
  )

  const updateScreenSize = () => {
    setIsCollapsed(
      window.innerWidth >= 950 && window.innerWidth < 1200,
    )
  }

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize)
    return () =>
      window.removeEventListener('resize', updateScreenSize)
  })

  const navigate = useNavigate()

  //SPECIFIC COMMENT: render prop should be used for when routes are using functions instead of just components
  //Ex: component={Component} render={(props) => <Component />}

  // if (isLoggedIn) {
  //   if (noOrg || !me.activeOrg) {
  //     navigate('/deactivated')
  //   } else if (delinquent) {
  //     navigate('/delinquent')
  //     // } else if (includes(
  //     //   window.location.search,
  //     //   'sharedPortfolioToken=',
  //     // )) {
  //     //   navigate('/sharedPortfolio' + window.location.search)
  //     // } else if (includes(
  //     //   window.location.search,
  //     //   'sharedMarketplaceToken=',
  //     // )) {
  //     //   navigate('/sharedMarketplace' + window.location.search)
  //     // } else if (includes(
  //     //   window.location.search,
  //     //   'sharedPropertyToken=',
  //     // )) {
  //     //   navigate('/sharedProperty' + window.location.search)
  //     // } else if (includes(
  //     //   window.location.search,
  //     //   'sharedPropertiesToken=',
  //     // )) {
  //     //   navigate('/sharedProperties' + window.location.search)
  //   }
  // }

  return (
    <div>
      {subdomain ? (
        <SubDomain subdomain={subdomain} />
      ) : (
        <StyledEngineProvider injectFirst>
          <Helmet>
            <link
              id="favicon"
              rel="shortcut icon"
              href={sidebarMemberSmallImage}
              async
            />
            <title id="titleId">{titleText}</title>
          </Helmet>
          <ThemeProvider
            theme={isNotPropertyDashboard ? MuiTheme : MuiTheme2}
          >
            <CssBaseline />
            <AnimatePresence>
              <Suspense fallback={<SuspenseLoading />}>
                {isLoggedIn ? (
                  noOrg ? (
                    <Routes>
                      <Route element={<MinimalLayout />}>
                        <Route
                          path="/deactivated"
                          element={<DeactivatedPage />}
                        />
                      </Route>
                      <Route
                        path="/*"
                        element={<Navigate to="/deactivated" />}
                      />
                    </Routes>
                  ) : delinquent ? (
                    <Routes>
                      <Route element={<MinimalLayout />}>
                        <Route
                          path="/delinquent"
                          element={<DelinquentPage />}
                        />
                      </Route>
                      <Route
                        path="/legal"
                        element={
                          <Legal affiliate={affiliate?.memberValue} />
                        }
                      />
                      <Route
                        path="/terms"
                        element={
                          <Terms affiliate={affiliate?.memberValue} />
                        }
                      />
                      <Route
                        path="/privacy"
                        element={
                          <Privacy
                            affiliate={affiliate?.memberValue}
                          />
                        }
                      />
                      <Route
                        path="/*"
                        element={<Navigate to="/delinquent" />}
                      />
                    </Routes>
                  ) : (
                    <Routes>
                      <Route
                        element={
                          <LeftSidebar
                            setOpenFeedbackTour={setOpenFeedbackTour}
                            hideBackButton={
                              me.activeOrg.isRealeflow &&
                              location.pathname === '/property-search'
                            }
                            collapsed={
                              me.activeOrg.isRealeflow &&
                              location.pathname === '/property-search'
                            }
                          />
                        }
                      >
                        <Route
                          path="/launchpad"
                          element={<LaunchpadPage />}
                        />
                        <Route
                          path="/tools"
                          element={<ToolsPage />}
                        />
                        <Route
                          path="/marketplace"
                          element={<MarketplacePage />}
                        />
                        <Route
                          path="/submitted-marketplace"
                          element={<MarketplacePage submitted />}
                        />
                        <Route
                          path="/settings/marketplace"
                          element={<MarketplaceSettingsPage />}
                        />
                        <Route
                          path="/reports"
                          element={
                            props.session.me.housefoliosAdmin ||
                            props.session.me.activeOrg.isAffiliate ? (
                              <ReportsPage />
                            ) : (
                              <Navigate to={defaultPage} />
                            )
                          }
                        />
                        <Route
                          path="/reports2"
                          element={
                            props.session.me.housefoliosAdmin ||
                            props.session.me.activeOrg.isAffiliate ? (
                              <ReportsPage2 />
                            ) : (
                              <Navigate to={defaultPage} />
                            )
                          }
                        />
                        <Route
                          path="/clients"
                          element={
                            props.session.me.housefoliosAdmin ||
                            props.session.me.activeOrg.isAffiliate ? (
                              <ClientPage />
                            ) : (
                              <Navigate to={defaultPage} />
                            )
                          }
                        />
                        <Route
                          path="/partnerportal"
                          element={
                            props.session.me.activeOrg.isAffiliate ? (
                              <PartnerPortal />
                            ) : (
                              <Navigate to={defaultPage} />
                            )
                          }
                        />
                        <Route
                          path="/featureRequests"
                          element={
                            props.session.me.activeOrg.isAffiliate &&
                            props.session.me.activeOrg.isAffiliate
                              .isRealeflow ? (
                              <FeatureRequest />
                            ) : (
                              <Navigate to={defaultPage} />
                            )
                          }
                        />

                        {/* Nested settings routes */}
                        <Route path="/settings" element={<Outlet />}>
                          <Route
                            path="organization"
                            element={<OrganizationSettingsPage />}
                          />
                          <Route
                            path="personal"
                            element={<PersonalSettingsPage />}
                          />
                          <Route
                            path="assumptions"
                            element={<AssumptionsSettingsPage />}
                          />
                          <Route
                            path="billing"
                            element={<BillingSettingsPage />}
                          />
                          <Route
                            path="team"
                            element={<TeamSettingsPage />}
                          />
                        </Route>

                        <Route
                          path="/my-properties"
                          element={<PropertiesPage />}
                        />
                        <Route
                          path="/submitted-lender-properties"
                          element={<PropertiesPage lender />}
                        />
                        <Route
                          path="/submitted-jv-properties"
                          element={<PropertiesPage jv />}
                        />
                        <Route
                          path="/shared-properties"
                          element={<PropertiesPage shared />}
                        />
                        <Route
                          path="/my-lots"
                          element={<LotsPage />}
                        />
                        {/* <Route path="/board" element={<Board />} /> */}
                        <Route path="/lot" element={<LotPage />} />
                        <Route
                          path="/commercial"
                          element={<CommercialPage />}
                        />
                        <Route
                          path="/property-search"
                          element={
                            me.activeOrg.isRealeflow ? (
                              <RealeflowRedirect />
                            ) : (
                              <PropertySearchPage />
                            )
                          }
                        />
                        <Route
                          path="/trash"
                          element={<TrashPage />}
                        />
                        <Route
                          path="/notifications"
                          element={<Notifications />}
                        />
                        <Route
                          path="/coaching-offer"
                          element={<CoachingOffer />}
                        />
                        <Route
                          path="/training"
                          element={<TrainingPage />}
                        />
                        <Route
                          path="/support-chat"
                          element={<SupportChatPage />}
                        />

                        <Route
                          path="/sharedPortfolio"
                          element={<SharedPortfolio />}
                        />
                        <Route
                          path="/sharedMarketplace"
                          element={<SharedMarketplace />}
                        />
                        <Route
                          path="/sharedProperty"
                          element={<SharedProperty />}
                        />
                        <Route
                          path="/sharedProperties"
                          element={<SharedProperties />}
                        />

                        <Route
                          path="/property-details/:id/proforma/:type"
                          element={<PDFContainer />}
                        />
                        <Route
                          path="/deal-analysis"
                          element={
                            <Suspense
                              fallback={<div>Loading...</div>}
                            >
                              <DealAnalysisPage />
                            </Suspense>
                          }
                        />
                      </Route>

                      <Route
                        element={
                          <LeftSidebar
                            collapsed
                            setOpenFeedbackTour={setOpenFeedbackTour}
                          />
                        }
                      >
                        <Route
                          path="/property-details/:id/:view"
                          element={
                            <PropertyPage
                              setIsOpen={setOpenTour}
                              setIsOpenVerifyFinancialsTour={
                                setOpenVerifyFinancialsTour
                              }
                              setIsOpenAnalyzeCompsTour={
                                setOpenAnalyzeCompsTour
                              }
                              setIsOpenChooseStrategyTour={
                                setOpenChooseStrategyTour
                              }
                              setIsOpenAnalyzeAreaTour={
                                setOpenAnalyzeAreaTour
                              }
                              setIsOpenTakeActionTour={
                                setOpenTakeActionTour
                              }
                            />
                          }
                        />
                        <Route
                          path="/:id/editLot"
                          element={<LotPage />}
                        />
                      </Route>

                      {/* Redirects */}
                      <Route
                        path="/property/:id/proforma/:type"
                        element={
                          <ProformaRedirect isLoggedIn={isLoggedIn} />
                        }
                      />
                      <Route
                        path="/*"
                        element={
                          <Navigate
                            to={
                              me.activeOrg.isRealeflow
                                ? '/property-search'
                                : defaultPage
                            }
                          />
                        }
                      />
                      <Route
                        path="/legal"
                        element={
                          <Legal affiliate={affiliate?.memberValue} />
                        }
                      />
                      <Route
                        path="/terms"
                        element={
                          <Terms affiliate={affiliate?.memberValue} />
                        }
                      />
                      <Route
                        path="/privacy"
                        element={
                          <Privacy
                            affiliate={affiliate?.memberValue}
                          />
                        }
                      />
                    </Routes>
                  )
                ) : (
                  <Routes>
                    <Route element={<PresentationLayout />}>
                      <Route
                        path="/createAccount"
                        element={<Outlet />}
                      >
                        <Route
                          index
                          element={
                            <CreateAccountPage refetch={refetch} />
                          }
                        />
                        <Route
                          path=":planName"
                          element={
                            <CreateAccountPage refetch={refetch} />
                          }
                        />
                      </Route>
                    </Route>
                    <Route element={<MinimalLayout />}>
                      <Route
                        path="/signin"
                        element={<SignInPage refetch={refetch} />}
                      />
                      <Route
                        path="/enterpriseAccount"
                        element={<EnterpriseAccountPage />}
                      />
                      <Route
                        path="/free-account"
                        element={<FreeAccount />}
                      />
                      <Route
                        path="/ForgotPassword"
                        element={<ForgotPasswordPage />}
                      />

                      <Route
                        path="/ResetPassword"
                        element={<Outlet />}
                      >
                        <Route
                          path=":token/:orgName"
                          element={<ResetPasswordPage />}
                        />
                        <Route
                          path=":token"
                          element={<ResetPasswordPage />}
                        />
                      </Route>

                      <Route
                        path="/externalVisit/"
                        element={
                          <ExternalSignIn
                            refetch={refetch}
                            navigate={navigate}
                          />
                        }
                      />
                      <Route
                        path="/publiclanding"
                        element={<PublicLanding />}
                      />
                      <Route
                        path="/publicproperty/:view"
                        element={<PublicProperty />}
                      />
                      <Route
                        path="/marketplace/:url"
                        element={<PublicMarketplacePage />}
                      />
                      <Route
                        path="/property/:id/proforma/:type"
                        element={<PDFContainer />}
                      />
                    </Route>

                    <Route
                      element={
                        <LeftSidebar
                          collapsed
                          setOpenFeedbackTour={setOpenFeedbackTour}
                        />
                      }
                    >
                      <Route
                        path="/public-property-details/:id/:view"
                        element={
                          <PublicPropertyDashboardPage
                            setIsOpen={setOpenTour}
                            setIsOpenVerifyFinancialsTour={
                              setOpenVerifyFinancialsTour
                            }
                            setIsOpenAnalyzeCompsTour={
                              setOpenAnalyzeCompsTour
                            }
                            setIsOpenChooseStrategyTour={
                              setOpenChooseStrategyTour
                            }
                            setIsOpenAnalyzeAreaTour={
                              setOpenAnalyzeAreaTour
                            }
                            setIsOpenTakeActionTour={
                              setOpenTakeActionTour
                            }
                          />
                        }
                      />
                    </Route>

                    {/* Redirects */}
                    <Route
                      path="/sharedPortfolio"
                      element={
                        <ProformaRedirect isLoggedIn={isLoggedIn} />
                      }
                    />
                    <Route
                      path="/property-details/:id/proforma/:type"
                      element={
                        <ProformaRedirect isLoggedIn={isLoggedIn} />
                      }
                    />
                    <Route
                      path="/*"
                      element={
                        <Navigate
                          to={'/signin' + window.location.search}
                        />
                      }
                    />
                    <Route
                      path="/legal"
                      element={
                        <Legal affiliate={affiliate?.memberValue} />
                      }
                    />
                    <Route
                      path="/terms"
                      element={
                        <Terms affiliate={affiliate?.memberValue} />
                      }
                    />
                    <Route
                      path="/privacy"
                      element={
                        <Privacy affiliate={affiliate?.memberValue} />
                      }
                    />
                  </Routes>
                )}
              </Suspense>
            </AnimatePresence>
            <Tour
              isOpen={openTour}
              closeTour={() => setOpenTour(false)}
            />
            <VerifyFinancialsTour
              isOpenVerifyFinancialsTour={openVerifyFinancialsTour}
              closeVerifyFinancialsTour={() =>
                setOpenVerifyFinancialsTour(false)
              }
            />
            <AnalyzeCompsTour
              isOpenAnalyzeCompsTour={openAnalyzeCompsTour}
              closeAnalyzeCompsTour={() =>
                setOpenAnalyzeCompsTour(false)
              }
            />
            <ChooseStrategyTour
              isOpenChooseStrategyTour={openChooseStrategyTour}
              closeChooseStrategyTour={() =>
                setOpenChooseStrategyTour(false)
              }
            />
            <AnalyzeAreaTour
              isOpenAnalyzeAreaTour={openAnalyzeAreaTour}
              closeAnalyzeAreaTour={() =>
                setOpenAnalyzeAreaTour(false)
              }
            />
            <TakeActionTour
              isOpenTakeActionTour={openTakeActionTour}
              closeTakeActionTour={() => {
                setOpenTakeActionTour(false)
              }}
            />
            <FeedbackTour
              isOpenFeedbackTour={openFeedbackTour}
              closeFeedbackTour={() => setOpenFeedbackTour(false)}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </div>
  )
}

export default withSession(RoutesComponent)
