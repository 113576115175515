/**
 * @ Author: Housefolios
 * @ Create Time: 2023-01-23 11:22:51
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-02 10:45:32
 * @ Description: Sortable table of properties for list view of the public marketplace page.
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
} from 'react-contextmenu'
import moment from 'moment'
import Pagination from '@mui/material/Pagination'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'

import map from 'lodash/map'
import isEqualWith from 'lodash/isEqualWith'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import intersection from 'lodash/intersection'
import includes from 'lodash/includes'
import ceil from 'lodash/ceil'
import isUndefined from 'lodash/isUndefined'
import slice from 'lodash/slice'
import get from 'lodash/get'
import pullAt from 'lodash/pullAt'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import toUpper from 'lodash/toUpper'
import pull from 'lodash/pull'
import compact from 'lodash/compact'
import set from 'lodash/set'
import merge from 'lodash/merge'
import meanBy from 'lodash/meanBy'
import remove from 'lodash/remove'
import sum from 'lodash/sum'
import concat from 'lodash/concat'
import head from 'lodash/head'
import replace from 'lodash/replace'
import kebabCase from 'lodash/kebabCase'
import flatMap from 'lodash/flatMap'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  Table,
  TableCell,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'

import MaterialUIMenuItem from '@mui/material/MenuItem'

import Autocomplete from '@mui/material/Autocomplete'

import withSession from '@/housefolios-components/Session/withSession'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import {
  Compare,
  ExpandLess,
  ExpandMore,
  SquareFoot,
  Tune,
} from '@mui/icons-material'
import Slider from 'react-slick'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { STATES } from '@/constants/states'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { NumericFormat } from 'react-number-format'
import { makeStyles } from '@mui/styles'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { DataGrid } from '@mui/x-data-grid'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { DEFAULT_ESTIMATES } from '@/constants/defaultValues'
import { getStrategyName } from '@/utils/stratagies'
import { statesShort } from '@/utils/states'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(3),
    color: 'white',
    flex: 1,
  },
}))

function checkColumns(custom, column) {
  if (!custom || !column) return false
  if (custom.length !== column.length) return false
  for (let i = 0; i < custom.length; i++) {
    if (custom[i].Section !== column[i].Section) return false
    if (custom[i].data.length !== column[i].data.length) return false
    for (let k = 0; k < custom[i].data.length; k++) {
      if (custom[i].data[k].title !== column[i].data[k].title)
        return false
    }
  }
  return true
}

function desc(a, b, orderBy, marketOrderBy, propertySearch) {
  let orderByA =
    a.strategy === 'Turnkey End Buyer' ? marketOrderBy : orderBy
  let orderByB =
    b.strategy === 'Turnkey End Buyer' ? marketOrderBy : orderBy
  if (propertySearch) {
    orderByA = a.marketplaces ? marketOrderBy : orderBy
    orderByB = b.marketplaces ? marketOrderBy : orderBy
  }
  if (isString(get(a, orderByA)) && isString(get(b, orderByB))) {
    if (!get(b, orderByB) && !get(a, orderByA)) {
      return 0
    }
    if (!get(b, orderByB)) {
      return 1
    }
    if (!get(a, orderByA)) {
      return -1
    }
    if (
      every(get(a, orderByA), (c) => c <= '9' && c >= '0') &&
      every(get(b, orderByB), (c) => c <= '9' && c >= '0')
    ) {
      if (Number(get(b, orderByB)) < Number(get(a, orderByA))) {
        return -1
      }
      if (Number(get(b, orderByB)) > Number(get(a, orderByA))) {
        return 1
      }
      return 0
    } else {
      if (
        get(b, orderByB).toLowerCase() <
        get(a, orderByA).toLowerCase()
      ) {
        return -1
      }
      if (
        get(b, orderByB).toLowerCase() >
        get(a, orderByA).toLowerCase()
      ) {
        return 1
      }
      return 0
    }
  } else {
    if (isNil(get(b, orderByB)) && isNil(get(a, orderByA))) {
      return 0
    }
    if (isNil(get(b, orderByB))) {
      return 1
    }
    if (isNil(get(a, orderByA))) {
      return -1
    }
    if (get(b, orderByB) < get(a, orderByA)) {
      return -1
    }
    if (get(b, orderByB) > get(a, orderByA)) {
      return 1
    }
    return 0
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy, marketOrderBy, propertySearch) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy, marketOrderBy, propertySearch)
    : (a, b) => -desc(a, b, orderBy, marketOrderBy, propertySearch)
}

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

function PublicPropertyListComponent(props) {
  const classes = useStyles()

  const {
    properties,
    selected,
    selectAll,
    marketplace,
    myMarketplace,
    propertySearch,
    selectedPortfolio,
    defaultStrategy = 'Buy & Hold',
    page,
    setPage,
    count,
    handlePageChange,
    handleRequestSort,
    currentPageProperties,
    ordering,
    setOrdering,
  } = props

  //   const [
  //   propertySearchOrderBy,
  //   marketOrderBy,
  //   defaultOrderBy,
  //   order,
  // ] = split(ordering, '-')
  const [updateUser] = useMutation(UPDATE_USER)

  const [defaultColumns, setDefaultColumns] = useState([
    {
      Section: 'Property Details',
      data: [
        { title: 'Asking Price', show: true },
        { title: 'Beds', show: true },
        { title: 'Baths', show: true },
        { title: 'SqFt', show: true },
        { title: 'Year', show: true },
        { title: 'D.O.M.', show: true },
        { title: 'Property Type', show: false },
        { title: 'Plan', show: false },
        { title: 'Zone', show: false },
      ],
    },
    {
      Section: 'Location',
      data: [
        { title: 'Property Address', show: true },
        { title: 'County', show: true },
        { title: 'City', show: true },
        { title: 'State', show: true },
        { title: 'Zip', show: true },
        { title: 'Market', show: true },
      ],
    },
    {
      Section: 'Buy & Hold',
      data: [
        //{ title: 'Gross Yield', show: true },
        { title: 'IRR', show: true },
        {
          title: 'Yield-on-Cost',
          show:
            defaultStrategy === 'Buy & Hold' || Boolean(marketplace),
        },
        {
          title: 'Cash on Cash Return',
          show:
            defaultStrategy === 'Buy & Hold' || Boolean(marketplace),
        },
        {
          title: 'Cashflow/mo',
          show:
            defaultStrategy === 'Buy & Hold' || Boolean(marketplace),
        },
        {
          title: 'Rent',
          show:
            defaultStrategy === 'Buy & Hold' || Boolean(marketplace),
        },
        {
          title: 'Equity',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Fix & Flip',
      data: [
        {
          title: 'Net ROI',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
        {
          title: '% of ARV',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
        {
          title: 'All in Cost',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
        {
          title: 'ARV',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
        {
          title: 'Net Profit',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
        {
          title: 'All-in Cost to ARV',
          show:
            defaultStrategy === 'Fix & Flip' || Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Turnkey',
      data: [
        // { title: 'Net ROI', show: defaultStrategy === 'Turnkey End Buyer' || Boolean(marketplace) },
        // { title: 'All-in Cost', show: defaultStrategy === 'Turnkey End Buyer' || Boolean(marketplace) },
        {
          title: 'Monthly Rent',
          show:
            defaultStrategy === 'Turnkey End Buyer' ||
            Boolean(marketplace),
        },
        {
          title: 'Net Monthly Cashflow',
          show:
            defaultStrategy === 'Turnkey End Buyer' ||
            Boolean(marketplace),
        },
        {
          title: 'Cap Rate',
          show:
            defaultStrategy === 'Turnkey End Buyer' ||
            Boolean(marketplace),
        },
        {
          title: 'Turnkey Cash on Cash Return',
          show:
            defaultStrategy === 'Turnkey End Buyer' ||
            Boolean(marketplace),
        },
        {
          title: 'Turnkey IRR',
          show:
            defaultStrategy === 'Turnkey End Buyer' ||
            Boolean(marketplace),
        },
        {
          title: 'Turnkey Equity',
          show:
            defaultStrategy === 'Turnkey End Buyer' ||
            Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'BRRRR',
      data: [
        //{ title: 'Gross Yield', show: true },
        { title: 'BRRRR IRR', show: true },
        {
          title: 'BRRRR Yield-on-Cost',
          show: defaultStrategy === 'BRRRR' || Boolean(marketplace),
        },
        {
          title: 'BRRRR Cash on Cash Return',
          show: defaultStrategy === 'BRRRR' || Boolean(marketplace),
        },
        {
          title: 'BRRRR Cashflow/mo',
          show: defaultStrategy === 'BRRRR' || Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Short Term Rental',
      data: [
        //{ title: 'Gross Yield', show: true },
        { title: 'STR IRR', show: true },
        {
          title: 'STR Yield-on-Cost',
          show:
            defaultStrategy === 'Short Term Rental' ||
            Boolean(marketplace),
        },
        {
          title: 'STR Cash on Cash Return',
          show:
            defaultStrategy === 'Short Term Rental' ||
            Boolean(marketplace),
        },
        {
          title: 'STR Cashflow/mo',
          show:
            defaultStrategy === 'Short Term Rental' ||
            Boolean(marketplace),
        },
        {
          title: 'STR Rent',
          show:
            defaultStrategy === 'Short Term Rental' ||
            Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Wholesale',
      data: [
        {
          title: 'Wholesale Net Profit',
          show:
            defaultStrategy === 'Wholesale' || Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Build to Rent',
      data: [
        {
          title: 'B2R Net Monthly Cashflow',
          show:
            defaultStrategy === 'Build to Rent' ||
            Boolean(marketplace),
        },
        {
          title: 'B2R Cap Rate',
          show:
            defaultStrategy === 'Build to Rent' ||
            Boolean(marketplace),
        },
        {
          title: 'B2R Cash on Cash Return',
          show:
            defaultStrategy === 'Build to Rent' ||
            Boolean(marketplace),
        },
        {
          title: 'B2R IRR',
          show:
            defaultStrategy === 'Build to Rent' ||
            Boolean(marketplace),
        },
        {
          title: 'B2R Equity',
          show:
            defaultStrategy === 'Build to Rent' ||
            Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'BOR',
      data: [
        { title: 'BOR IRR', show: true },
        {
          title: 'Average Annual Return',
          show: defaultStrategy === 'BOR' || Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Astroflip',
      // hide: props?.session?.me?.activeOrg?.member !== 'astroflip',
      data: [
        {
          title: 'Astroflip Net Profit',
          show:
            defaultStrategy === 'Astroflip' || Boolean(marketplace),
        },
      ],
    },
    {
      Section: 'Other',
      data: [
        { title: 'Status', show: true },
        { title: 'Strategy', show: true },
        { title: 'Date Added', show: true },
        { title: 'Added By', show: true },
        { title: 'Comp Count', show: true },
        { title: 'Trashed Date', show: true },
        { title: 'Days Until Deleted', show: true },
        { title: 'Submitted By', show: true },
        { title: 'Lender Submitted By', show: true },
        { title: 'JV Submitted By', show: true },
      ],
    },
  ])

  useEffect(() => {
    setDefaultColumns([
      {
        Section: 'Property Details',
        data: [
          { title: 'Asking Price', show: true },
          { title: 'Beds', show: true },
          { title: 'Baths', show: true },
          { title: 'SqFt', show: true },
          { title: 'Year', show: true },
          { title: 'D.O.M.', show: true },
          { title: 'Property Type', show: false },
          { title: 'Plan', show: false },
          { title: 'Zone', show: false },
        ],
      },
      {
        Section: 'Location',
        data: [
          { title: 'Property Address', show: true },
          { title: 'County', show: true },
          { title: 'City', show: true },
          { title: 'State', show: true },
          { title: 'Zip', show: true },
          { title: 'Market', show: true },
        ],
      },
      {
        Section: 'Buy & Hold',
        data: [
          //{ title: 'Gross Yield', show: true },
          { title: 'IRR', show: true },
          {
            title: 'Yield-on-Cost',
            show:
              defaultStrategy === 'Buy & Hold' ||
              Boolean(marketplace),
          },
          {
            title: 'Cash on Cash Return',
            show:
              defaultStrategy === 'Buy & Hold' ||
              Boolean(marketplace),
          },
          {
            title: 'Cashflow/mo',
            show:
              defaultStrategy === 'Buy & Hold' ||
              Boolean(marketplace),
          },
          {
            title: 'Rent',
            show:
              defaultStrategy === 'Buy & Hold' ||
              Boolean(marketplace),
          },
          {
            title: 'Equity',
            show:
              defaultStrategy === 'Buy & Hold' ||
              Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Fix & Flip',
        data: [
          {
            title: 'Net ROI',
            show:
              defaultStrategy === 'Fix & Flip' ||
              Boolean(marketplace),
          },
          {
            title: '% of ARV',
            show:
              defaultStrategy === 'Fix & Flip' ||
              Boolean(marketplace),
          },
          {
            title: 'All in Cost',
            show:
              defaultStrategy === 'Fix & Flip' ||
              Boolean(marketplace),
          },
          {
            title: 'Fix & Flip ARV',
            show:
              defaultStrategy === 'Fix & Flip' ||
              Boolean(marketplace),
          },
          {
            title: 'Net Profit',
            show:
              defaultStrategy === 'Fix & Flip' ||
              Boolean(marketplace),
          },
          {
            title: 'All-in Cost to ARV',
            show:
              defaultStrategy === 'Fix & Flip' ||
              Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Turnkey',
        data: [
          // { title: 'Net ROI', show: defaultStrategy === 'Turnkey End Buyer' || Boolean(marketplace) },
          // { title: 'All-in Cost', show: defaultStrategy === 'Turnkey End Buyer' || Boolean(marketplace) },
          {
            title: 'Monthly Rent',
            show:
              defaultStrategy === 'Turnkey End Buyer' ||
              Boolean(marketplace),
          },
          {
            title: 'Net Monthly Cashflow',
            show:
              defaultStrategy === 'Turnkey End Buyer' ||
              Boolean(marketplace),
          },
          {
            title: 'Cap Rate',
            show:
              defaultStrategy === 'Turnkey End Buyer' ||
              Boolean(marketplace),
          },
          {
            title: 'Turnkey Cash on Cash Return',
            show:
              defaultStrategy === 'Turnkey End Buyer' ||
              Boolean(marketplace),
          },
          {
            title: 'Turnkey IRR',
            show:
              defaultStrategy === 'Turnkey End Buyer' ||
              Boolean(marketplace),
          },
          {
            title: 'Turnkey Equity',
            show:
              defaultStrategy === 'Turnkey End Buyer' ||
              Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'BRRRR',
        data: [
          //{ title: 'Gross Yield', show: true },
          { title: 'BRRRR IRR', show: true },
          {
            title: 'BRRRR Yield-on-Cost',
            show: defaultStrategy === 'BRRRR' || Boolean(marketplace),
          },
          {
            title: 'BRRRR Cash on Cash Return',
            show: defaultStrategy === 'BRRRR' || Boolean(marketplace),
          },
          {
            title: 'BRRRR Cashflow/mo',
            show: defaultStrategy === 'BRRRR' || Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Short Term Rental',
        data: [
          //{ title: 'Gross Yield', show: true },
          { title: 'STR IRR', show: true },
          {
            title: 'STR Yield-on-Cost',
            show:
              defaultStrategy === 'Short Term Rental' ||
              Boolean(marketplace),
          },
          {
            title: 'STR Cash on Cash Return',
            show:
              defaultStrategy === 'Short Term Rental' ||
              Boolean(marketplace),
          },
          {
            title: 'STR Cashflow/mo',
            show:
              defaultStrategy === 'Short Term Rental' ||
              Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Wholesale',
        data: [
          {
            title: 'Wholesale Net Profit',
            show:
              defaultStrategy === 'Wholesale' || Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Build to Rent',
        data: [
          {
            title: 'B2R Net Monthly Cashflow',
            show:
              defaultStrategy === 'Build to Rent' ||
              Boolean(marketplace),
          },
          {
            title: 'B2R Cap Rate',
            show:
              defaultStrategy === 'Build to Rent' ||
              Boolean(marketplace),
          },
          {
            title: 'B2R Cash on Cash Return',
            show:
              defaultStrategy === 'Build to Rent' ||
              Boolean(marketplace),
          },
          {
            title: 'B2R IRR',
            show:
              defaultStrategy === 'Build to Rent' ||
              Boolean(marketplace),
          },
          {
            title: 'B2R Equity',
            show:
              defaultStrategy === 'Build to Rent' ||
              Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'BOR',
        data: [
          { title: 'BOR IRR', show: true },
          {
            title: 'Average Annual Return',
            show: defaultStrategy === 'BOR' || Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Astroflip',
        // hide: props?.session?.me?.activeOrg?.member !== 'astroflip',
        data: [
          {
            title: 'Astroflip Net Profit',
            show:
              defaultStrategy === 'Astroflip' || Boolean(marketplace),
          },
        ],
      },
      {
        Section: 'Other',
        data: [
          { title: 'Status', show: true },
          { title: 'Strategy', show: true },
          { title: 'Date Added', show: true },
          { title: 'Added By', show: true },
          { title: 'Comp Count', show: true },
          { title: 'Trashed Date', show: true },
          { title: 'Days Until Deleted', show: true },
          { title: 'Submitted By', show: true },
          { title: 'Lender Submitted By', show: true },
          { title: 'JV Submitted By', show: true },
        ],
      },
    ])
  }, [defaultStrategy, marketplace])

  const [showImages, setShowImages] = useState(false)
  const [images, setImages] = useState([])
  const [propertyManyImages, setPropertyManyImages] = useState('')

  const toggleImages =
    (images = [], propertyId) =>
    (event) => {
      event.stopPropagation()
      setShowImages(!showImages)
      setImages(images)
      if (!showImages === false) setPropertyManyImages('')
      else setPropertyManyImages(propertyId)
    }

  const slider = useRef()
  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [openManageColumns, setManageColumns] = useState(false)
  const [showColumns, setShowColumns] = useState([])

  const getColumns = (columns, customColumns) => {
    let col = []
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].title === 'Select') col.push(columns[i])
      for (let j = 0; j < customColumns.length; j++) {
        for (let k = 0; k < customColumns[j].data.length; k++) {
          if (
            columns[i].title === customColumns[j].data[k].title &&
            customColumns[j].data[k].show
          )
            col.push(columns[i])
        }
      }
    }
    return col
  }

  const getColumnVisibility = (columns, customColumns) => {
    let columnVisibilityModel = {}
    for (let i = 0; i < columns.length; i++) {
      const flattenedCustomColumns = flatMap(
        customColumns,
        (section) => section.data,
      )
      const customColumn = find(
        flattenedCustomColumns,
        (customColumn) =>
          columns[i].headerName === customColumn.title,
      )
      columnVisibilityModel[columns[i].field] = customColumn
        ? customColumn.show
        : false
    }
    return columnVisibilityModel
  }

  const [openNeedsInfo, setOpenNeedsInfo] = useState(null)
  const [needsInfoInput, setNeedsInfoInput] = useState({})
  const [needsInfoAddedAt, setNeedsInfoAddedAt] = useState(null)
  const [needsInfoEstimates, setNeedsInfoEstimates] = useState({})
  const [needsInfoStrategySpecificFields, setStrategySpecificFields] =
    useState([])
  const [loader, setLoader] = useState(false)

  const stateOptions = statesShort()

  const handleNeedsInfo = (property) => {
    if (property) {
      const { acquisition = {}, assumptions = {} } = property
      const { analysis = {} } = acquisition
      setOpenNeedsInfo(property._id)
      setNeedsInfoInput({
        address: property.address,
        city: property.city,
        state: property.state,
        beds: property.beds,
        baths: property.baths,
        sqft: property.sqft,
        year: property.year,
        analysisInput: {
          listPrice: analysis.listPrice,
          afterRepairValue: analysis.afterRepairValue,
          afterRepairValueBuyHold: analysis.afterRepairValueBuyHold,
          afterRepairValueFixFlip: analysis.afterRepairValueFixFlip,
          afterRepairValueTurnkey: analysis.afterRepairValueTurnkey,
          afterRepairValueSTR: analysis.afterRepairValueSTR,
          afterRepairValueB2R: analysis.afterRepairValueB2R,
          afterRepairValueBRRRR: analysis.afterRepairValueBRRRR,
          afterRepairValueBOR: analysis.afterRepairValueBOR,
          afterRepairValueWholesale:
            analysis.afterRepairValueWholesale,
          rent: analysis.rent,
          resellPrice: analysis.resellPrice,
          offerPrice: analysis.offerPrice,
        },
      })
      setNeedsInfoAddedAt(property.createdAt)
      setStrategySpecificFields(assumptions.strategySpecificFields)
      setNeedsInfoEstimates(
        merge(DEFAULT_ESTIMATES, property.estimates),
      )
    } else {
      setOpenNeedsInfo(null)
      setNeedsInfoInput({})
      setNeedsInfoAddedAt(null)
      setNeedsInfoEstimates({})
      setStrategySpecificFields([])
    }
  }

  const onChangeNeedsInfo = (event) => {
    const { name, value, type } = event.target
    let newInput = cloneDeep(needsInfoInput)
    let newValue = value
    if (type === 'number') newValue = Number(value)
    set(newInput, name, newValue)
    setNeedsInfoInput(newInput)
  }

  const changeMenu = (name, value) => {
    onChangeNeedsInfo({
      target: {
        name: 'analysisInput.' + name,
        value,
        type: 'number',
      },
    })
    setMenu('')
    setMenuTarget('')
    setAnchorElDefinition(null)
  }

  const toggleCollapse = (name) => {
    let newEstimatesCollapse = { ...estimatesCollapse }
    newEstimatesCollapse[name] = !estimatesCollapse[name]
    setEstimatesCollapse(newEstimatesCollapse)
  }

  const saveNeedsInfo = () => {
    setLoader(true)
    updateProperty({
      variables: {
        id: openNeedsInfo,
        propertyInput: needsInfoInput,
      },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [props.selectedPortfolio],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTY,
          variables: { id: openNeedsInfo },
        },
      ],
    }).then(() => {
      handleNeedsInfo()
      setLoader(false)
    })
  }

  const addedDate = new Date(needsInfoAddedAt).toDateString()
  const [menu, setMenu] = useState('')
  const [menuTarget, setMenuTarget] = useState('')
  const [anchorElDefinition, setAnchorElDefinition] = useState(null)

  const menuTitle = {
    rent: 'Rent',
    afterRepairValue: 'After Repair Value',
    listPrice: 'Estimated Asking Price',
    propertyTaxes: 'Estimated Annual Taxes',
    rehabCost: 'Estimated Rehab Cost',
    offerPrice: 'Estimated Purchase Price',
  }
  const description = {
    rent: `Estimated monthly rental income from property. These numbers were gathered when you analyzed the property on ${addedDate}.`,
    afterRepairValue: `After Repair Value (ARV) is the estimated worth of a property after all the repair have been completed. These numbers were gathered when you analyzed the property on ${addedDate}.`,
    listPrice: `These figures do not represent the actual asking price. Instead, they are estimates based on comparable home sales in the area or derived from your assumptions. While the estimate may be accurate for properties currently on the market, it might not be as reliable for off-market properties.`,
    //'We were unable to find a asking price for this property. Here is our Asking Price Estimate These numbers were gathered when the property was first added. To see the most current data, add the property again.',
    propertyTaxes: `Estimated annual taxes for the property. These numbers were gathered when you analyzed the property on ${addedDate}`,
    rehabCost: `Estimated cost to rehab the property`,
    offerPrice: `Estimated purchase price`,
  }

  const [estimatesCollapse, setEstimatesCollapse] = useState({})
  const [openNoData, setOpenNoData] = useState(false)

  const handleDefinition = (name, anchorEl, target = '') => {
    setMenu(name)
    setMenuTarget(target)
    setAnchorElDefinition(anchorEl)
  }

  let results = useQuery(GET_COMPPROPERTIES, {
    variables: { propertyId: openNeedsInfo },
  })
  let data = results.data
  let loading = results.loading
  //let error = results.error
  let { compProperties = [] } = data || {}

  const determinedComps = filter(
    compProperties,
    (comp) => !comp.potential,
  )

  const compValues = map(determinedComps, (comp) => {
    return {
      _id: comp._id,
      propertyPrice: Number(
        comp.estval * (1 + comp.priceAdjustment / 100),
      ),
      propertyPerSqft: comp.sqft
        ? Number(comp.estval * (1 + comp.priceAdjustment / 100)) /
            comp.sqft || 0
        : null,
      sqft: comp.sqft,
    }
  })

  //const useDefault = !hasEstimates && !isUndefined(defaults[menu])

  const average = {
    afterRepairValue: meanBy(
      filter(compValues, 'propertyPrice'),
      'propertyPrice',
    ),
    secondary: 'Adjusted comp average',
  }
  let menuArray = [
    // {
    //   field: needsInfoEstimates.listPriceEstimate,
    //   icon: <Avatar className="rounded"><LocalOffer /></Avatar>,
    // },
    {
      field: needsInfoEstimates.house,
      icon: (
        <Avatar
          className="rounded"
          imgProps={{ className: 'app-sidebar-logo--icon' }}
          src="/images/LeftNavLogo.png"
        />
      ),
    },
    // {
    //   field: needsInfoEstimates.housefolios,
    //   icon: <Avatar className="rounded"><CenterFocusStrong /></Avatar>,
    // },
    // {
    //   field: needsInfoEstimates.housefoliosCounty,
    //   icon: <Avatar className="rounded"><NotListedLocation /></Avatar>,
    // },
    // {
    //   field: needsInfoEstimates.housefoliosSqft,
    //   icon: <Avatar className="rounded"><SquareFootIcon /></Avatar>,
    // },
    // {
    //   field: needsInfoEstimates.housefoliosAssumptionSqft,
    //   icon: <Avatar className="rounded"><SquareFoot /></Avatar>,
    // },
    // {
    //   field: needsInfoEstimates.housefoliosBed,
    //   icon: <Avatar className="rounded"><HotelIcon /></Avatar>,
    // },
    // {
    //   field: needsInfoEstimates.housefoliosBedAndSqft,
    //   icon: <Avatar className="rounded"><HomeIcon /></Avatar>,
    // },
    {
      field: needsInfoEstimates.smartAsset,
      icon: (
        <Avatar className="rounded" src="/images/smart_asset.png" />
      ),
    },
    // {
    //   field: needsInfoEstimates.zillow,
    //   icon: <Avatar className="rounded" src="/images/ZillowLogo.png" />,
    // },
    {
      field: 'rentometer',
      icon: (
        <Avatar
          className="rounded"
          src="/images/Rentometer_Logo.png"
        />
      ),
      secondary: 'Range provided by Rentometer',
      min: needsInfoEstimates.rentometerLow,
      mid: needsInfoEstimates.rentometer,
      max: needsInfoEstimates.rentometerHigh,
      minLabel: 'Min',
      midLabel: 'Mean',
      maxLabel: 'Max',
    },
    {
      field: needsInfoEstimates.housefoliosAssumption,
      icon: (
        <Avatar className="rounded">
          <Tune />
        </Avatar>
      ),
    },
  ]
  menuArray = filter(
    menuArray,
    (item) =>
      (item.field && !isUndefined(item.field[menu])) ||
      (item.mid && !isUndefined(item.mid[menu])),
  )
  if (menu === 'afterRepairValue')
    menuArray.push({
      field: average,
      icon: (
        <Avatar>
          <Compare />
        </Avatar>
      ),
    })
  let dropdowns = remove(menuArray, (item) => item.mid)
  let noDataFound = remove(menuArray, (item) => !item.field[menu])
  menuArray = stableSort(
    menuArray,
    (item, oth) => Number(item.field[menu]) < Number(oth.field[menu]),
  )
  let menuSearch = [
    {
      field: needsInfoEstimates.zillowSearch,
      icon: <Avatar src="/images/ZillowLogo.png" />,
      onClick: () =>
        window.open(
          `https://www.zillow.com/homes/${kebabCase(
            needsInfoInput.address,
          )},-${needsInfoInput.state},-${needsInfoInput.zip}_rb/`,
          '_blank',
          // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
        ),
    },
    {
      field: needsInfoEstimates.smartAssetSearch,
      icon: <Avatar src="/images/smart_asset.png" />,
      onClick: () =>
        window.open(
          `https://smartasset.com/taxes/property-taxes`,
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
        ),
    },
    {
      field: needsInfoEstimates.googleSearch,
      icon: <Avatar src="/images/googleLogo.svg" />,
      onClick: () =>
        window.open(
          `https://www.google.com/search?q=${needsInfoInput.address}, ${needsInfoInput.city} ${needsInfoInput.state} ${needsInfoInput.zip}`,
          '_blank',
          // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
        ),
    },
  ]
  menuSearch = filter(
    menuSearch,
    (item) => item.field && !isUndefined(item.field[menu]),
  )

  const renderInput = (field) => {
    if (field.display) {
      return (
        <Grid
          item
          container
          xs={field.xs}
          sm={field.sm}
          md={field.md}
          lg={field.lg}
          xl={field.xl}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography>{field.label}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {field.type === 'dollar'
                ? currencyFormat.format(field.value) || '-'
                : field.value || '-'}
              {field.type === 'percentage' ? '%' : ''}
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (field.type === 'select') {
      return (
        <Grid item xs={field.xs}>
          <Autocomplete
            value={field.value}
            options={field.options}
            onChange={(event, newValue) =>
              onChangeNeedsInfo({
                target: {
                  name: field.name,
                  value: newValue,
                  type: 'text',
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                variant="outlined"
                error={!field.value}
              />
            )}
          />
        </Grid>
      )
    } else if (field.type === 'currency') {
      return (
        <Grid item xs={field.xs}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={true}
            prefix={'$'}
            fullWidth={true}
            //isNumericString={true}
            decimalScale={0}
            key={field.name}
            name={field.name}
            value={field.value}
            onValueChange={(values) => {
              const { value } = values
              const event = {
                target: {
                  name: field.name,
                  value: value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              onChangeNeedsInfo(event)
            }}
            error={!field.value}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            margin="dense"
            label={field.label}
            InputProps={{
              endAdornment: field.definition ? (
                <InputAdornment
                  position="end"
                  onClick={(event) =>
                    handleDefinition(
                      field.definition,
                      event.target,
                      field.definitionTarget,
                    )
                  }
                >
                  <IconButton
                    size="small"
                    style={{ padding: 5 }}
                    className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'database']}
                      className="font-size-xl"
                    />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
      )
    } else if (field.type === 'number') {
      return (
        <Grid item xs={field.xs}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={!field.noSeperator}
            fullWidth={true}
            //isNumericString={true}
            decimalScale={field.decimal || 0}
            key={field.name}
            name={field.name}
            value={field.value}
            allowNegative={false}
            onValueChange={(values) => {
              const { value } = values
              const event = {
                target: {
                  name: field.name,
                  value: value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              onChangeNeedsInfo(event)
            }}
            error={!field.value}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            margin="dense"
            label={field.label}
            InputProps={{
              endAdornment: field.definition ? (
                <InputAdornment
                  position="end"
                  onClick={(event) =>
                    handleDefinition(
                      field.definition,
                      event.target,
                      field.definitionTarget,
                    )
                  }
                >
                  <IconButton
                    size="small"
                    style={{ padding: 5 }}
                    className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'database']}
                      className="font-size-xl"
                    />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
      )
    } else {
      return (
        <Grid item xs={field.xs}>
          <TextField
            name={field.name}
            fullWidth={true}
            variant="outlined"
            margin="dense"
            label={field.label}
            value={field.value}
            onChange={onChangeNeedsInfo}
            InputProps={{
              endAdornment: field.definition ? (
                <InputAdornment
                  position="end"
                  onClick={(event) =>
                    handleDefinition(
                      field.definition,
                      event.target,
                      field.definitionTarget,
                    )
                  }
                >
                  <IconButton
                    size="small"
                    style={{ padding: 5 }}
                    className="d-30 rounded-sm border-0 mx-auto card-icon-wrapper bg-first text-white btn-icon text-center"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'database']}
                      className="font-size-xl"
                    />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
      )
    }
  }

  const needsInfoDetailsFields = [
    {
      name: 'city',
      label: 'City',
      type: 'text',
      value: get(needsInfoInput, 'city'),
      xs: 6,
    },
    {
      name: 'state',
      label: 'State',
      type: 'select',
      value:
        includes(stateOptions, get(needsInfoInput, 'state')) ||
        find(stateOptions, { value: get(needsInfoInput, 'state') })
          ? get(needsInfoInput, 'state')
          : null,
      options: stateOptions,
      xs: 6,
    },
    {
      name: 'beds.0',
      label: 'Beds',
      type: 'number',
      value: get(needsInfoInput, 'beds.0'),
      xs: 6,
    },
    {
      name: 'baths.0',
      label: 'Baths',
      type: 'number',
      value: get(needsInfoInput, 'baths.0') || '',
      xs: 6,
    },
    {
      name: 'sqft.0',
      label: 'Sqft',
      type: 'number',
      value: get(needsInfoInput, 'sqft.0') || '',
      xs: 6,
    },
    {
      name: 'year',
      label: 'Year',
      type: 'number',
      value: get(needsInfoInput, 'year') || '',
      noSeperator: true,
      xs: 6,
    },
  ]

  const needsInfoFinancialFields = [
    {
      name: 'analysisInput.listPrice',
      label: 'Asking Price',
      type: 'currency',
      value: get(needsInfoInput, 'analysisInput.listPrice') || '',
      definition: 'listPrice',
      xs: 6,
    },
    {
      name: 'analysisInput.afterRepairValue',
      label: 'ARV',
      type: 'currency',
      value:
        get(needsInfoInput, 'analysisInput.afterRepairValue') || '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValue',
      xs: 6,
      hide: includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueBuyHold',
      label: 'Buy & Hold ARV',
      type: 'currency',
      value:
        get(
          needsInfoInput,
          'analysisInput.afterRepairValueBuyHold',
        ) || '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueBuyHold',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueFixFlip',
      label: 'Fix & Flip ARV',
      type: 'currency',
      value:
        get(
          needsInfoInput,
          'analysisInput.afterRepairValueFixFlip',
        ) || '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueFixFlip',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueTurnkey',
      label: 'Turnkey ARV',
      type: 'currency',
      value:
        get(
          needsInfoInput,
          'analysisInput.afterRepairValueTurnkey',
        ) || '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueTurnkey',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueSTR',
      label: 'STR ARV',
      type: 'currency',
      value:
        get(needsInfoInput, 'analysisInput.afterRepairValueSTR') ||
        '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueSTR',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueB2R',
      label: 'Build to Rent ARV',
      type: 'currency',
      value:
        get(needsInfoInput, 'analysisInput.afterRepairValueB2R') ||
        '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueB2R',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueBRRRR',
      label: 'BRRRR ARV',
      type: 'currency',
      value:
        get(needsInfoInput, 'analysisInput.afterRepairValueBRRRR') ||
        '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueBRRRR',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueBOR',
      label: 'BOR ARV',
      type: 'currency',
      value:
        get(needsInfoInput, 'analysisInput.afterRepairValueBOR') ||
        '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueBOR',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.afterRepairValueWholesale',
      label: 'Wholesale ARV',
      type: 'currency',
      value:
        get(
          needsInfoInput,
          'analysisInput.afterRepairValueWholesale',
        ) || '',
      definition: 'afterRepairValue',
      definitionTarget: 'afterRepairValueWholesale',
      xs: 6,
      hide: !includes(
        needsInfoStrategySpecificFields,
        'afterRepairValue',
      ),
    },
    {
      name: 'analysisInput.rent.0',
      label: 'Rent',
      type: 'currency',
      value: get(needsInfoInput, 'analysisInput.rent.0') || '',
      definition: 'rent',
      xs: 6,
    },
    {
      name: 'analysisInput.resellPrice',
      label: 'Resale Price',
      type: 'currency',
      value: get(needsInfoInput, 'analysisInput.resellPrice') || '',
      xs: 6,
    },
    {
      name: 'analysisInput.offerPrice',
      label: 'Purchase Price',
      type: 'currency',
      value: get(needsInfoInput, 'analysisInput.offerPrice') || '',
      definition: 'offerPrice',
      xs: 6,
    },
  ]

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
  })

  const plainFormat = new Intl.NumberFormat()

  const [hearts, setHearts] = useState(
    compact(
      map(properties, (property) => {
        if (property.favorite) return property._id
      }),
    ),
  )

  const changeHeart = (id) => {
    let newHearts = [...hearts]
    if (includes(newHearts, id)) pull(newHearts, id)
    else newHearts.push(id)
    setHearts(newHearts)
  }

  useEffect(() => {
    setHearts(
      compact(
        map(properties, (property) => {
          if (property.favorite) return property._id
        }),
      ),
    )
  }, [properties])

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const [openCriticalFields, setOpenCriticalFields] = useState()

  const handleOpenCriticalFields = (propertyId) => {
    setOpenCriticalFields(propertyId)
  }

  const handleCloseCriticalFields = () => {
    setOpenCriticalFields(null)
  }

  // let columns = [
  //   {
  //     title: 'Property Address',
  //     align: 'center',
  //     name: 'address',
  //     getCellValue: row => {
  //       let image = '/images/default_image.png'
  //       let images = row.images || []
  //       images = compact(images)
  //       // if (row.mainImage) image = row.mainImage
  //       if (propertySearch && !row.marketplaces && row.images) {
  //         image = JSON.parse(row.images)[0]
  //         images = map(JSON.parse(row.images), image => { return { url: image } })
  //       }
  //       else if (propertySearch && !row.marketplaces && row.image) {
  //         image = row.image
  //         images = [row.image]
  //       }

  //       if (image === '/images/default_image.png' && !isEmpty(images))
  //         image = head(images).url

  //       if (marketplace || props.trash)
  //         return (
  //           <div className='align-box-row'>
  //             <Button /*onClick={toggleImages(images, row._id)}*/ onClick={event => event.stopPropagation()} className="d-40 btn-icon btn-animated-icon p-0 mr-3 badge-wrapper m-3 ">
  //               {/* <div className="d-block p-0 avatar-icon-wrapper"> */}
  //                 {propertySearch && !props.shared && row.marketplaces && !isEmpty(row.marketplaces.approvedMarketplace) && (
  //                   <div className="d-block p-0 avatar-icon-wrapper badge badge-success badge-position badge-position--top-right badge-circle" style={{ marginTop: 5, marginRight: 5 }}>New notifications</div>
  //                 )}
  //                 <div className="avatar-icon rounded">
  //                   <img src={image} alt="..." />
  //                 </div>
  //               {/* </div> */}
  //             </Button>
  //             <a href={`/public-property-details/${row._id}/Summary${props.subdomainParam}`} onClick={event => {
  //               event.stopPropagation()
  //             }}>
  //               {!row.hideAddress && (<b>{row.address}</b>)}
  //               <span className="text-black-50 d-block">{row.city} {row.state}, {row.zip}</span>
  //             </a>
  //           </div>
  //         )
  //       return (
  //         <div className='align-box-row'>
  //           <Button /*onClick={toggleImages(images, row._id)}*/ onClick={event => event.stopPropagation()} className="d-40 btn-icon btn-animated-icon p-0 mr-3 badge-wrapper m-3 ">
  //             {/* <div className="d-block p-0 avatar-icon-wrapper"> */}
  //             {row.marketplaces && !isEmpty(row.marketplaces.approvedMarketplace) && (
  //               <Tooltip title={
  //                 <Grid container direction='column'>
  //                   {map(row.marketplaces.approvedMarketplace, marketplace => (
  //                     <Grid item key={marketplace}>
  //                       <Typography style={marketplace === 'housefolios' && new Date(row.marketplaces.expirationDate).getTime() < Date.now() ? { color: 'red' } : null}>{marketplace}</Typography>
  //                     </Grid>
  //                   ))}
  //                 </Grid>}
  //               >
  //                 <div className="d-block p-0 avatar-icon-wrapper badge badge-success badge-position badge-position--top-right badge-circle" style={{ marginTop: 5, marginRight: 5 }}>New notifications</div>
  //               </Tooltip>
  //             )}
  //             <div className="avatar-icon rounded">
  //                 <img src={image} alt="..." />
  //               </div>
  //           </Button>
  //           <a href={`/public-property-details/${row._id}/Summary${props.subdomainParam}`} onClick={event => {
  //             event.stopPropagation()
  //           }}>
  //             {!row.hideAddress && (<b>{row.address}</b>)}
  //             <span className="text-black-50 d-block">{row.city} {row.state}, {row.zip}</span>
  //           </a>
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     title: 'Status',
  //     name: props.jv ? 'jvStatus' : props.lender ? 'lenderStatus' : 'acquisition.activeStage',
  //     getCellValue: row => {
  //       if (props.jv)
  //         return (
  //           <Grid item>
  //             <Tooltip title="Status">
  //               <div className="badge badge-pill rounded" style={{ backgroundColor: row.jvStatus === 'Rejected' ? 'red' : row.jvStatus === 'Accepted' ? '#00bf00' : 'orange', color: 'white' }}>{toUpper(row.jvStatus)}</div>
  //             </Tooltip>
  //           </Grid>
  //         )
  //       if (props.lender)
  //         return (
  //           <Grid item>
  //             <Tooltip title="Status">
  //               <div className="badge badge-pill rounded" style={{ backgroundColor: row.lenderStatus === 'Rejected' ? 'red' : row.lenderStatus === 'Accepted' ? '#00bf00' : 'orange', color: 'white' }}>{toUpper(row.lenderStatus)}</div>
  //             </Tooltip>
  //           </Grid>
  //         )
  //       const { acquisition = {} } = row || {}
  //       const activeStage =
  //         acquisition && acquisition.activeStage
  //           ? acquisition.activeStage
  //           : ''

  //       const { analysis = {} } = acquisition

  //       let missingData = false
  //       if (
  //         !analysis.listPrice ||
  //         !analysis.offerPrice ||
  //         !analysis.rent ||
  //         !analysis.afterRepairValue ||
  //         !analysis.resellPrice ||
  //         !row.city ||
  //         !row.state ||
  //         isNil(row.beds) ||
  //         isNil(row.baths) ||
  //         isNil(row.sqft) ||
  //         isNil(row.year)
  //       ) {
  //         missingData = true
  //       }

  //       if (activeStage)
  //         {if (!missingData)
  //           return (
  //             <Grid item>
  //               <Tooltip title="Selected Status">
  //                 <div className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>{toUpper(activeStage)}</div>
  //               </Tooltip>
  //             </Grid>
  //           )
  //         else
  //           {if (props.trash)
  //             {return (
  //               <Grid item>
  //               <Tooltip title="Selected Status">
  //                 <div className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>Needs Info</div>
  //               </Tooltip>
  //             </Grid>
  //             )
  //             } else
  //           {return (
  //             <Grid item>
  //               <Tooltip title="Selected Status">
  //                 <div onClick={() => handleNeedsInfo(row)} className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>Needs Info</div>
  //               </Tooltip>
  //             </Grid>
  //           )}}
  //           } else {
  //               return missingData && props.trash ? (
  //                 <Grid item>
  //                   <Tooltip title="Selected Status">
  //                     <div className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>Needs Info</div>
  //                   </Tooltip>
  //                 </Grid>
  //               ) : missingData && !props.trash ? (
  //                   <Grid item>
  //                     <Tooltip title="Selected Status">
  //                       <div onClick={() => handleNeedsInfo(row)} className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>Needs Info</div>
  //                     </Tooltip>
  //                   </Grid>
  //               ) : (
  //                 <Grid item>
  //                   <Tooltip title="Selected Status">
  //                     <div className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>New</div>
  //                   </Tooltip>
  //                 </Grid>
  //             )}
  //     },
  //   },
  //   {
  //     title: 'Strategy',
  //     name: 'strategy',
  //   },
  //   {
  //     title: 'County',
  //     name: 'county',
  //   },
  //   {
  //     title: 'City',
  //     name: 'city',
  //   },
  //   {
  //     title: 'State',
  //     name: 'state',
  //   },
  //   {
  //     title: 'Zip',
  //     name: 'zip',
  //   },
  //   {
  //     title: 'Beds',
  //     name: 'beds',
  //     getCellValue: row => sum(row.beds),
  //   },
  //   {
  //     title: 'Baths',
  //     name: 'baths',
  //     getCellValue: row => sum(row.baths),
  //   },
  //   {
  //     title: 'SqFt',
  //     name: 'sqft',
  //     formatter: plainFormat,
  //     getCellValue: row => sum(row.sqft),
  //   },
  //   {
  //     title: 'Year',
  //     name: 'year',
  //     propertySearchName: 'yearBuilt',
  //     getCellValue: row => {
  //       if (propertySearch && !row.marketplaces && row.yearBuilt)
  //         return row.yearBuilt
  //       else
  //         return row.year
  //     }
  //   },
  //   {
  //     title: 'Market',
  //     name: 'market',
  //   },
  //   {
  //     title: 'Property Type',
  //     name: 'type',
  //   },
  //   {
  //     title: 'Plan',
  //     name: 'plan',
  //   },
  //   {
  //     title: 'Zone',
  //     name: 'zone',
  //   },
  //   {
  //     title: 'Asking Price',
  //     name: 'acquisition.analysis.listPrice',
  //     marketName: 'acquisition.analysis.resellPrice',
  //     propertySearchName: 'price',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analysis = {} } = row.acquisition || {}
  //       const listPrice = propertySearch && !row.marketplaces
  //         ? row.price
  //         : marketplace && !props.trash
  //         ? analysis.resellPrice
  //         : analysis.listPrice
  //       return (
  //         listPrice
  //       )
  //     },
  //   },
  //   {
  //     title: 'Rent',
  //     name: 'acquisition.analysis.rent',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analysis = {} } = row.acquisition || {}
  //       return (
  //         sum(analysis.rent)
  //       )
  //     },
  //   },
  //   {
  //     title: 'Cashflow/mo',
  //     name: 'acquisition.analytics.buyHold.cashFlow',
  //     marketName: 'acquisition.analytics.turnkey.cashFlowTurnkey',
  //     arrow: 'acquisition.analytics.buyHold.cashFlowArrow',
  //     marketArrow: 'acquisition.analytics.turnkey.cashFlowTurnkeyArrow',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashFlow = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashFlow = analytics.turnkey.cashFlowTurnkey
  //       } else {
  //         cashFlow = analytics.buyHold.cashFlow
  //       }
  //       return (
  //         cashFlow / 12
  //       )
  //     },
  //   },
  //   {
  //     title: 'BRRRR Cashflow/mo',
  //     name: 'acquisition.analytics.BRRRR.cashFlowBRRRR',
  //     arrow: 'acquisition.analytics.BRRRR.cashFlowBRRRRArrow',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashFlow = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashFlow = analytics.BRRRR.cashFlowBRRRR
  //       } else {
  //         cashFlow = analytics.BRRRR.cashFlowBRRRR
  //       }
  //       return (
  //         cashFlow / 12
  //       )
  //     },
  //   },
  //   {
  //     title: 'STR Cashflow/mo',
  //     name: 'acquisition.analytics.STR.cashFlowSTR',
  //     arrow: 'acquisition.analytics.STR.cashFlowSTRArrow',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashFlow = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashFlow = analytics.STR.cashFlowSTR
  //       } else {
  //         cashFlow = analytics.STR.cashFlowSTR
  //       }
  //       return (
  //         cashFlow / 12
  //       )
  //     },
  //   },
  //   {
  //     title: 'Yield-on-Cost',
  //     name: 'acquisition.analytics.buyHold.capRate',
  //     arrow: 'acquisition.analytics.buyHold.capRateArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let capRate = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         capRate = analytics.buyHold.capRate
  //         //capRate = analytics.capRateTurnkey
  //       } else {
  //         capRate = analytics.buyHold.capRate
  //       }
  //       return (
  //         capRate
  //       )
  //     },
  //   },
  //   {
  //     title: 'BRRRR Yield-on-Cost',
  //     name: 'acquisition.analytics.BRRRR.capRateBRRRR',
  //     arrow: 'acquisition.analytics.BRRRR.capRateBRRRRArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let capRate = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         capRate = analytics.BRRRR.capRateBRRRR
  //         //capRate = analytics.capRateTurnkey
  //       } else {
  //         capRate = analytics.BRRRR.capRateBRRRR
  //       }
  //       return (
  //         capRate
  //       )
  //     },
  //   },
  //   {
  //     title: 'STR Yield-on-Cost',
  //     name: 'acquisition.analytics.STR.capRateSTR',
  //     arrow: 'acquisition.analytics.STR.capRateSTRArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let capRate = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         capRate = analytics.STR.capRateSTR
  //         //capRate = analytics.capRateTurnkey
  //       } else {
  //         capRate = analytics.STR.capRateSTR
  //       }
  //       return (
  //         capRate
  //       )
  //     },
  //   },
  //   {
  //     title: 'Cap Rate',
  //     name: 'acquisition.analytics.turnkey.capRateTurnkey',
  //     arrow: 'acquisition.analytics.turnkey.capRateTurnkeyArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let capRate = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         capRate = analytics.turnkey.capRateTurnkey
  //       } else {
  //         capRate = analytics.turnkey.capRateTurnkey
  //         //capRate = analytics.capRate
  //       }
  //       return (
  //         capRate
  //       )
  //     },
  //   },
  //   {
  //     title: 'B2R Cap Rate',
  //     name: 'acquisition.analytics.B2R.capRateB2R',
  //     arrow: 'acquisition.analytics.B2R.capRateB2RArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let capRate = ''
  //       capRate = analytics.B2R.capRateB2R
  //       return (
  //         capRate
  //       )
  //     },
  //   },
  //   {
  //     title: 'Cash on Cash Return',
  //     name: 'acquisition.analytics.buyHold.cashOnCashReturn',
  //     //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
  //     arrow: 'acquisition.analytics.buyHold.cashOnCashReturnArrow',
  //     //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashOnCashReturn = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashOnCashReturn = analytics.buyHold.cashOnCashReturn
  //       } else {
  //         cashOnCashReturn = analytics.buyHold.cashOnCashReturn
  //       }
  //       return (
  //         cashOnCashReturn
  //       )
  //     },
  //   },{
  //     title: 'Turnkey Cash on Cash Return',
  //     name: 'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
  //     //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
  //     arrow: 'acquisition.analytics.turnkey.cashOnCashReturnTurnkeyArrow',
  //     //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashOnCashReturn = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashOnCashReturn = analytics.turnkey.cashOnCashReturnTurnkey
  //       } else {
  //         cashOnCashReturn = analytics.turnkey.cashOnCashReturnTurnkey
  //       }
  //       return (
  //         cashOnCashReturn
  //       )
  //     },
  //   },
  //   {
  //     title: 'BRRRR Cash on Cash Return',
  //     name: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRR',
  //     //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
  //     arrow: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRRArrow',
  //     //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashOnCashReturn = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashOnCashReturn = analytics.BRRRR.cashOnCashReturnBRRRR
  //       } else {
  //         cashOnCashReturn = analytics.BRRRR.cashOnCashReturnBRRRR
  //       }
  //       return (
  //         cashOnCashReturn
  //       )
  //     },
  //   },
  //   {
  //     title: 'STR Cash on Cash Return',
  //     name: 'acquisition.analytics.STR.cashOnCashReturnSTR',
  //     //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
  //     arrow: 'acquisition.analytics.STR.cashOnCashReturnSTRArrow',
  //     //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashOnCashReturn = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashOnCashReturn = analytics.STR.cashOnCashReturnSTR
  //       } else {
  //         cashOnCashReturn = analytics.STR.cashOnCashReturnSTR
  //       }
  //       return (
  //         cashOnCashReturn
  //       )
  //     },
  //   },
  //   {
  //     title: 'B2R Cash on Cash Return',
  //     name: 'acquisition.analytics.B2R.cashOnCashReturnB2R',
  //     arrow: 'acquisition.analytics.B2R.cashOnCashReturnB2RArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashOnCashReturn = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         cashOnCashReturn = analytics.B2R.cashOnCashReturnB2R
  //       } else {
  //         cashOnCashReturn = analytics.B2R.cashOnCashReturnB2R
  //       }
  //       return (
  //         cashOnCashReturn
  //       )
  //     },
  //   },
  //   {
  //     title: 'Equity',
  //     name: 'acquisition.analytics.buyHold.equity',
  //     //marketName: 'acquisition.analytics.equityTurnkey',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let equity = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         equity = analytics.buyHold.equity
  //       } else {
  //         equity = analytics.buyHold.equity
  //       }
  //       return (
  //         equity
  //       )
  //     },
  //   },
  //   {
  //     title: 'Turnkey Equity',
  //     name: 'acquisition.analytics.turnkey.equityTurnkey',
  //     //marketName: 'acquisition.analytics.equityTurnkey',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let equity = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         equity = analytics.turnkey.equityTurnkey
  //       } else {
  //         equity = analytics.turnkey.equityTurnkey
  //       }
  //       return (
  //         equity
  //       )
  //     },
  //   },
  //   {
  //     title: 'B2R Equity',
  //     name: 'acquisition.analytics.B2R.equityB2R',
  //     //marketName: 'acquisition.analytics.equityB2R',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let equity = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         equity = analytics.B2R.equityB2R
  //       } else {
  //         equity = analytics.B2R.equityB2R
  //       }
  //       return (
  //         equity
  //       )
  //     },
  //   },
  //   {
  //     title: 'IRR',
  //     name: 'acquisition.analytics.buyHold.IRR',
  //     //marketName: 'acquisition.analytics.IRRTurnkey',
  //     //arrow: 'acquisition.analytics.IRRArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let IRR = ''
  //        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //          IRR = analytics.buyHold.IRR
  //        } else {
  //          IRR = analytics.buyHold.IRR
  //        }
  //       return (
  //         IRR
  //       )
  //     },
  //   },
  //   {
  //     title: 'Turnkey IRR',
  //     name: 'acquisition.analytics.turnkey.IRRTurnkey',
  //     //marketName: 'acquisition.analytics.IRRTurnkey',
  //     //arrow: 'acquisition.analytics.IRRArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let IRR = ''
  //        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //          IRR = analytics.turnkey.IRRTurnkey
  //        } else {
  //          IRR = analytics.turnkey.IRRTurnkey
  //        }
  //       return (
  //         IRR
  //       )
  //     },
  //   },
  //   {
  //     title: 'BRRRR IRR',
  //     name: 'acquisition.analytics.BRRRR.IRRBRRRR',
  //     //marketName: 'acquisition.analytics.IRRTurnkey',
  //     //arrow: 'acquisition.analytics.IRRArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let IRR = ''
  //        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //          IRR = analytics.BRRRR.IRRBRRRR
  //        } else {
  //          IRR = analytics.BRRRR.IRRBRRRR
  //        }
  //       return (
  //         IRR
  //       )
  //     },
  //   },
  //   {
  //     title: 'STR IRR',
  //     name: 'acquisition.analytics.STR.IRRSTR',
  //     //marketName: 'acquisition.analytics.IRRTurnkey',
  //     //arrow: 'acquisition.analytics.IRRArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let IRR = ''
  //         if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //           IRR = analytics.STR.IRRSTR
  //         } else {
  //           IRR = analytics.STR.IRRSTR
  //         }
  //       return (
  //         IRR
  //       )
  //     },
  //   },
  //   {
  //     title: 'B2R IRR',
  //     name: 'acquisition.analytics.B2R.IRRB2R',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let IRR = ''
  //        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //          IRR = analytics.B2R.IRRB2R
  //        } else {
  //          IRR = analytics.B2R.IRRB2R
  //        }
  //       return (
  //         IRR
  //       )
  //     },
  //   },
  //   {
  //     title: 'BOR IRR',
  //     name: 'acquisition.analytics.BOR.IRRBOR',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let IRR = ''
  //        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //          IRR = analytics.BOR.IRRBOR
  //        } else {
  //          IRR = analytics.BOR.IRRBOR
  //        }
  //       return (
  //         IRR
  //       )
  //     },
  //   },
  //   {
  //     title: 'Net ROI',
  //     name: 'acquisition.analytics.fixFlip.netROIFixFlip',
  //     arrow: 'acquisition.analytics.fixFlip.netROIArrow',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let netROI = analytics.fixFlip.netROIFixFlip
  //       return (
  //         netROI
  //       )
  //     },
  //   },
  //   {
  //     title: '% of ARV',
  //     name: 'acquisition.analytics.fixFlip.percentageOfARV',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let percentageOfARV = analytics.fixFlip.percentageOfARV
  //       return (
  //         percentageOfARV
  //       )
  //     },
  //   },
  //   {
  //     title: 'All in Cost',
  //     name: 'acquisition.analytics.fixFlip.allInCostFixFlip',
  //     marketName: 'acquisition.analytics.fixFlip.allInCostFixFlip',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let allInCost = ''
  //       if (row.strategy === 'Turnkey End Buyer' || marketplace) {
  //         allInCost = analytics.fixFlip.allInCostFixFlip
  //       } else {
  //         allInCost = analytics.fixFlip.allInCostFixFlip
  //       }
  //       return (
  //         allInCost
  //       )
  //     },
  //   },
  //   {
  //     title: 'ARV',
  //     name: 'acquisition.analysis.afterRepairValue',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analysis = {} } = row.acquisition || {}
  //       let afterRepairValue = analysis.afterRepairValue
  //       return (
  //         afterRepairValue
  //       )
  //     },
  //   },
  //   {
  //     title: 'Net Profit',
  //     name: 'acquisition.analytics.fixFlip.saleProceeds',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let saleProceeds = analytics.fixFlip.saleProceeds
  //       return (
  //         saleProceeds
  //       )
  //     },
  //   },
  //   {
  //     title: 'Wholesale Net Profit',
  //     name: 'acquisition.analytics.wholesale.saleProceedsWholesale',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let saleProceeds = analytics.wholesale.saleProceedsWholesale
  //       return (
  //         saleProceeds
  //       )
  //     },
  //   },
  //   {
  //     title: 'Average Annual Return',
  //     name: 'acquisition.analytics.BOR.averageAnnualReturnBOR',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let averageAnnualReturn = analytics.BOR.averageAnnualReturnBOR
  //       return (
  //         averageAnnualReturn
  //       )
  //     },
  //   },
  //   {
  //     title: 'All-in Cost to ARV',
  //     name: 'acquisition.analytics.fixFlip.percentageOfARV',
  //     formatter: percentageFormat,
  //     max: 1000,
  //     maxString: "+1000%",
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let percentageOfARV = analytics.fixFlip.percentageOfARV
  //       return (
  //         percentageOfARV
  //       )
  //     },
  //   },
  //   {
  //     title: 'Monthly Rent',
  //     name: 'acquisition.analysis.rent',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analysis = {} } = row.acquisition || {}
  //       let rent = sum(analysis.rent)
  //       return (
  //         rent
  //       )
  //     },
  //   },
  //   {
  //     title: 'Net Monthly Cashflow',
  //     name: 'acquisition.analytics.turnkey.cashFlowTurnkey',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashflow = analytics.turnkey.cashFlowTurnkey / 12
  //       return (
  //         cashflow
  //       )
  //     },
  //   },
  //   {
  //     title: 'B2R Net Monthly Cashflow',
  //     name: 'acquisition.analytics.B2R.cashFlowB2R',
  //     formatter: currencyFormat,
  //     getCellValue: row => {
  //       const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
  //       let cashflow = analytics.B2R.cashFlowB2R / 12
  //       return (
  //         cashflow
  //       )
  //     },
  //   },
  //   {
  //     title: 'D.O.M.',
  //     name: 'acquisition.analysis.daysOnMarket',
  //     formatter: plainFormat,
  //     getCellValue: row => {
  //       const { analysis = {} } = row.acquisition || {}
  //       let daysOnMarket = analysis.daysOnMarket
  //       return (
  //         daysOnMarket
  //       )
  //     },
  //   },
  //   {
  //     title: 'Comp Count',
  //     name: 'compCount',
  //     formatter: plainFormat,
  //     getCellValue: row => {
  //       const { compCount } = row || {}
  //       return (
  //         compCount
  //       )
  //     },
  //   },
  //   {
  //     title: 'Date Added',

  //     name: 'createdAt',
  //     getCellValue: row => (
  //       moment(row.createdAt).format('MMM Do YYYY')
  //     ),
  //   },
  //   {
  //     title: 'Added By',

  //     name: 'createdBy.profile.firstname',
  //     getCellValue: row => {
  //       const { createdBy } = row
  //       const name =
  //         createdBy && createdBy.profile
  //           ? `${createdBy.profile.firstname} ${createdBy.profile.lastname} `
  //           : ''
  //       return name
  //     },
  //   },
  //   {
  //     title: 'Trashed Date',
  //     name: 'deletedAt',
  //     getCellValue: row => (
  //       moment(row.deletedAt).format('MMM Do YYYY')
  //     ),
  //   },
  //   {
  //     title: 'Days Until Deleted',
  //     name: 'deletedAt',
  //     getCellValue: row => {
  //       const today = new Date()
  //       let endDate = new Date(row.deletedAt)
  //       endDate.setDate(endDate.getDate() + 7)
  //       const days = moment.duration(moment(endDate).diff(today)).asDays().toFixed(0)
  //       //Returns a string. Since its not expected to be greater than 7 it should still sort
  //       return `${days} days left`
  //     },
  //   },
  //   {
  //     title: 'Submitted By',
  //     name: 'marketplaceSubmittedBy.profile.firstname',
  //     getCellValue: row => {
  //       const { marketplaceSubmittedBy } = row
  //       const name =
  //         marketplaceSubmittedBy && marketplaceSubmittedBy.profile
  //           ? `${marketplaceSubmittedBy.profile.firstname} ${marketplaceSubmittedBy.profile.lastname} `
  //           : ''
  //       return name
  //     },
  //   },
  //   {
  //     title: 'Lender Submitted By',
  //     name: 'lenderSubmittedBy.profile.firstname',
  //     getCellValue: row => {
  //       const { lenderSubmittedBy } = row
  //       const name =
  //         lenderSubmittedBy && lenderSubmittedBy.profile
  //           ? `${lenderSubmittedBy.profile.firstname} ${lenderSubmittedBy.profile.lastname} `
  //           : ''
  //       return name
  //     },
  //   },
  //   {
  //     title: 'JV Submitted By',
  //     name: 'jvSubmittedBy.profile.firstname',
  //     getCellValue: row => {
  //       const { jvSubmittedBy } = row
  //       const name =
  //         jvSubmittedBy && jvSubmittedBy.profile
  //           ? `${jvSubmittedBy.profile.firstname} ${jvSubmittedBy.profile.lastname} `
  //           : ''
  //       return name
  //     },
  //   },
  // ]

  const formatCell = (value, formatter, max, maxString) => {
    return !(value < 0 || !value)
      ? value > max
        ? maxString
        : formatter
          ? formatter.format(value)
          : value
      : '-'
  }

  const renderArrowCell = (params, arrow) => {
    const arrowValue = get(params?.row, arrow)
    return (
      <Grid container justifyContent="flex-end">
        <Grid item>{params?.formattedValue}</Grid>
        <Grid item style={{ marginLeft: 5 }}>
          {!isUndefined(arrow) ? (
            arrowValue ? (
              arrowValue === 2 ? (
                <Avatar
                  style={{
                    backgroundColor: '#2296F3',
                    height: 15,
                    width: 15,
                  }}
                >
                  <ArrowUpwardIcon
                    style={{
                      color: 'white',
                      fontSize: 'small',
                    }}
                  />
                </Avatar>
              ) : (
                <Avatar
                  style={{
                    backgroundColor: '#46de64',
                    height: 15,
                    width: 15,
                  }}
                >
                  <ArrowUpwardIcon
                    style={{
                      color: 'white',
                      fontSize: 'small',
                    }}
                  />
                </Avatar>
              )
            ) : (
              <Avatar
                style={{
                  backgroundColor: '#F05248',
                  height: 15,
                  width: 15,
                }}
              >
                <ArrowDownwardIcon
                  style={{
                    color: 'white',
                    fontSize: 'small',
                  }}
                />
              </Avatar>
            )
          ) : null}
        </Grid>
      </Grid>
    )
  }

  const arraySumComparator = (v1, v2) => sum(v1) - sum(v2)

  function calculateMinWidth(headerText) {
    const headerWidth = headerText.length * 8 + 20 // change 8 to adjust the character width
    return headerWidth < 150 ? 150 : headerWidth // set minimum width to 150
  }

  let underwritingColumns = concat([
    {
      headerName: 'Property Address',
      field: 'address',
      width: 350,
      renderCell: (params) => {
        const { row = {} } = params || {}
        let image = '/images/default_image.png'
        let images = row.images || []
        let image_small = '/images/default_image.png'
        images = compact(images)
        // if (row.mainImage) image = row.mainImage
        if (propertySearch && !row.marketplaces && row.images) {
          image = JSON.parse(row.images)[0]
          images = map(JSON.parse(row.images), (image) => {
            return { url: image }
          })
        } else if (propertySearch && !row.marketplaces && row.image) {
          image = row.image
          images = [row.image]
        }

        if (
          image === '/images/default_image.png' &&
          !isEmpty(images)
        ) {
          image = head(images).url
          image_small = head(images).url_small
        }

        if (marketplace || props.trash)
          return (
            <div className="align-box-row">
              <Button
                /*onClick={toggleImages(images, row._id)}*/ onClick={(
                  event,
                ) => event.stopPropagation()}
                className="d-40 btn-icon btn-animated-icon p-0 mr-3 badge-wrapper m-3 "
              >
                {/* <div className="d-block p-0 avatar-icon-wrapper"> */}
                {propertySearch &&
                  !props.shared &&
                  row.marketplaces &&
                  !isEmpty(row.marketplaces.approvedMarketplace) && (
                    <div
                      className="d-block p-0 avatar-icon-wrapper badge badge-success badge-position badge-position--top-right badge-circle"
                      style={{ marginTop: 5, marginRight: 5 }}
                    >
                      New notifications
                    </div>
                  )}
                <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
                  <div
                    className="blur-load"
                    style={{
                      backgroundImage: 'url(' + image_small + ')',
                    }}
                  >
                    <img src={image} alt="..." loading="lazy" />
                  </div>
                </div>
                {/* </div> */}
              </Button>
              <a
                href={`/public-property-details/${row._id}/Summary${props.subdomainParam}`}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <div>
                  {!row.hideAddress && (
                    <Typography style={{ fontWeight: '600' }}>
                      {row.address}
                    </Typography>
                  )}
                  <Typography className="text-black-50 d-block">
                    {row.city} {row.state}, {row.zip}
                  </Typography>
                </div>
              </a>
            </div>
          )
        return (
          <div className="align-box-row">
            <Button
              /*onClick={toggleImages(images, row._id)}*/ onClick={(
                event,
              ) => event.stopPropagation()}
              className="d-40 btn-icon btn-animated-icon p-0 mr-3 badge-wrapper m-3 "
            >
              {/* <div className="d-block p-0 avatar-icon-wrapper"> */}
              {row.marketplaces &&
                !isEmpty(row.marketplaces.approvedMarketplace) && (
                  <Tooltip
                    title={
                      <Grid container direction="column">
                        {map(
                          row.marketplaces.approvedMarketplace,
                          (marketplace) => (
                            <Grid item key={marketplace}>
                              <Typography
                                style={
                                  marketplace === 'housefolios' &&
                                  new Date(
                                    row.marketplaces.expirationDate,
                                  ).getTime() < Date.now()
                                    ? { color: 'red' }
                                    : null
                                }
                              >
                                {marketplace}
                              </Typography>
                            </Grid>
                          ),
                        )}
                      </Grid>
                    }
                  >
                    <div
                      className="d-block p-0 avatar-icon-wrapper badge badge-success badge-position badge-position--top-right badge-circle"
                      style={{ marginTop: 5, marginRight: 5 }}
                    >
                      New notifications
                    </div>
                  </Tooltip>
                )}
              <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
                <div
                  className="blur-load"
                  style={{
                    backgroundImage: 'url(' + image_small + ')',
                  }}
                >
                  <img src={image} alt="..." loading="lazy" />
                </div>
              </div>
            </Button>
            <a
              href={`/public-property-details/${row._id}/Summary${props.subdomainParam}`}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <div>
                {!row.hideAddress && (
                  <Typography style={{ fontWeight: '600' }}>
                    {row.address}
                  </Typography>
                )}
                <Typography className="text-black-50 d-block">
                  {row.city} {row.state}, {row.zip}
                </Typography>
              </div>
            </a>
          </div>
        )
      },
    },
    {
      headerName: 'Status',
      field: props.jv
        ? 'jvStatus'
        : props.lender
          ? 'lenderStatus'
          : 'acquisition.activeStage',
      // valueSetter: (params) => {
      //   const newRow = cloneDeep(params?.row)
      //   set(newRow, props.jv ? 'jvStatus' : props.lender ? 'lenderStatus' : 'acquisition.activeStage', value)
      //   return newRow
      // },
      renderCell: (params) => {
        const { row = {} } = params || {}
        if (props.jv)
          return (
            <Grid item>
              <Tooltip title="Status">
                <div
                  className="badge badge-pill rounded"
                  style={{
                    backgroundColor:
                      row.jvStatus === 'Rejected'
                        ? 'red'
                        : row.jvStatus === 'Accepted'
                          ? '#00bf00'
                          : 'orange',
                    color: 'white',
                  }}
                >
                  {toUpper(row.jvStatus)}
                </div>
              </Tooltip>
            </Grid>
          )
        if (props.lender)
          return (
            <Grid item>
              <Tooltip title="Status">
                <div
                  className="badge badge-pill rounded"
                  style={{
                    backgroundColor:
                      row.lenderStatus === 'Rejected'
                        ? 'red'
                        : row.lenderStatus === 'Accepted'
                          ? '#00bf00'
                          : 'orange',
                    color: 'white',
                  }}
                >
                  {toUpper(row.lenderStatus)}
                </div>
              </Tooltip>
            </Grid>
          )
        const { acquisition = {} } = row || {}
        const activeStage =
          acquisition && acquisition.activeStage
            ? acquisition.activeStage
            : ''

        const { analysis = {} } = acquisition

        let missingData = false
        if (
          !analysis.listPrice ||
          !analysis.offerPrice ||
          !analysis.rent ||
          (!analysis.afterRepairValue &&
            !analysis.afterRepairValueBuyHold &&
            !analysis.afterRepairValueFixFlip &&
            !analysis.afterRepairValueTurnkey &&
            !analysis.afterRepairValueSTR &&
            !analysis.afterRepairValueB2R &&
            !analysis.afterRepairValueBRRRR &&
            !analysis.afterRepairValueBOR &&
            !analysis.afterRepairValueWholesale) ||
          !analysis.resellPrice ||
          !row.city ||
          !row.state ||
          isNil(row.beds) ||
          isNil(row.baths) ||
          isNil(row.sqft) ||
          isNil(row.year)
        ) {
          missingData = true
        }

        if (activeStage) {
          if (!missingData)
            return (
              <Grid item>
                <Tooltip title="Selected Status">
                  <div
                    className="badge badge-pill rounded"
                    style={{
                      backgroundColor: '#00bf00',
                      color: 'white',
                    }}
                  >
                    {toUpper(activeStage)}
                  </div>
                </Tooltip>
              </Grid>
            )
          else {
            if (props.trash) {
              return (
                <Grid item>
                  <Tooltip title="Selected Status">
                    <div
                      className="badge badge-pill rounded"
                      style={{
                        backgroundColor: '#00bf00',
                        color: 'white',
                      }}
                    >
                      Needs Info
                    </div>
                  </Tooltip>
                </Grid>
              )
            } else {
              return (
                <Grid item>
                  <Tooltip title="Selected Status">
                    <div
                      onClick={() => handleNeedsInfo(row)}
                      className="badge badge-pill rounded"
                      style={{
                        backgroundColor: '#00bf00',
                        color: 'white',
                      }}
                    >
                      Needs Info
                    </div>
                  </Tooltip>
                </Grid>
              )
            }
          }
        } else {
          return missingData && props.trash ? (
            <Grid item>
              <Tooltip title="Selected Status">
                <div
                  className="badge badge-pill rounded"
                  style={{
                    backgroundColor: '#00bf00',
                    color: 'white',
                  }}
                >
                  Needs Info
                </div>
              </Tooltip>
            </Grid>
          ) : missingData && !props.trash ? (
            <Grid item>
              <Tooltip title="Selected Status">
                <div
                  onClick={() => handleNeedsInfo(row)}
                  className="badge badge-pill rounded"
                  style={{
                    backgroundColor: '#00bf00',
                    color: 'white',
                  }}
                >
                  Needs Info
                </div>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="Selected Status">
                <div
                  className="badge badge-pill rounded"
                  style={{
                    backgroundColor: '#00bf00',
                    color: 'white',
                  }}
                >
                  New
                </div>
              </Tooltip>
            </Grid>
          )
        }
      },
    },
    {
      headerName: 'Strategy',
      field: 'strategy',
      width: 150,
      type: 'singleSelect',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'County',
      field: 'county',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'City',
      field: 'city',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'State',
      field: 'state',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Zip',
      field: 'zip',
      type: 'text',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Beds',
      field: 'beds',
      align: 'right',
      valueFormatter: (value) => formatCell(sum(value), plainFormat),
      sortComparator: arraySumComparator,
    },
    {
      headerName: 'Baths',
      field: 'baths',
      align: 'right',
      valueFormatter: (value) => formatCell(sum(value), plainFormat),
      sortComparator: arraySumComparator,
    },
    {
      headerName: 'SqFt',
      field: 'sqft',
      align: 'right',
      valueFormatter: (value) => formatCell(sum(value), plainFormat),
      sortComparator: arraySumComparator,
    },
    {
      headerName: 'Year',
      field: 'year',
      type: 'number',
      propertySearchName: 'yearBuilt',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) => {
        if (propertySearch && !row.marketplaces && row.yearBuilt)
          return row.yearBuilt
        else return row.year
      },
    },
    {
      headerName: 'Market',
      field: 'market',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Property Type',
      field: 'type',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Plan',
      field: 'plan',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Zone',
      field: 'zone',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Asking Price',
      field: 'acquisition.analysis.listPrice',
      marketName: 'acquisition.analysis.resellPrice',
      propertySearchName: 'price',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analysis = {} } = row.acquisition || {}
        const listPrice =
          propertySearch && !row.marketplaces
            ? row.price
            : marketplace && !props.trash
              ? analysis.resellPrice
              : analysis.listPrice
        return listPrice
      },
      valueSetter: (params) => {
        const newRow = cloneDeep(params?.row)
        set(
          newRow,
          marketplace && !props.trash
            ? 'acquisition.analysis.resellPrice'
            : 'acquisition.analysis.listPrice',
          value,
        )
        return newRow
      },
    },
    {
      headerName: 'Monthly Rent',
      field: 'acquisition.analysis.rent',
      align: 'right',
      valueFormatter: (value) =>
        formatCell(sum(value), currencyFormat),
      valueGetter: (value, row) => {
        const { analysis = {} } = row.acquisition || {}
        return analysis.rent
      },
      sortComparator: arraySumComparator,
    },
    {
      headerName: 'Cashflow/mo',
      field: 'acquisition.analytics.buyHold.cashFlow',
      //marketName: 'acquisition.analytics.turnkey.cashFlowTurnkey',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.buyHold.cashFlowArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashFlow = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashFlow = analytics.buyHold.cashFlow
          //cashFlow = analytics.turnkey.cashFlowTurnkey
        } else {
          cashFlow = analytics.buyHold.cashFlow
        }
        return cashFlow / 12
      },
    },
    {
      headerName: 'BRRRR Cashflow/mo',
      field: 'acquisition.analytics.BRRRR.cashFlowBRRRR',
      arrow: 'acquisition.analytics.BRRRR.cashFlowBRRRRArrow',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.BRRRR.cashFlowBRRRRArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashFlow = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashFlow = analytics.BRRRR.cashFlowBRRRR
        } else {
          cashFlow = analytics.BRRRR.cashFlowBRRRR
        }
        return cashFlow / 12
      },
    },
    {
      headerName: 'STR Cashflow/mo',
      field: 'acquisition.analytics.STR.cashFlowSTR',
      arrow: 'acquisition.analytics.STR.cashFlowSTRArrow',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.STR.cashFlowSTRArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashFlow = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashFlow = analytics.STR.cashFlowSTR
        } else {
          cashFlow = analytics.STR.cashFlowSTR
        }
        return cashFlow / 12
      },
    },
    {
      headerName: 'Yield-on-Cost',
      field: 'acquisition.analytics.buyHold.capRate',
      arrow: 'acquisition.analytics.buyHold.capRateArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.buyHold.capRateArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let capRate = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          capRate = analytics.buyHold.capRate
          //capRate = analytics.capRateTurnkey
        } else {
          capRate = analytics.buyHold.capRate
        }
        return capRate
      },
    },
    {
      headerName: 'BRRRR Yield-on-Cost',
      field: 'acquisition.analytics.BRRRR.capRateBRRRR',
      arrow: 'acquisition.analytics.BRRRR.capRateBRRRRArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.BRRRR.capRateBRRRRArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let capRate = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          capRate = analytics.BRRRR.capRateBRRRR
          //capRate = analytics.capRateTurnkey
        } else {
          capRate = analytics.BRRRR.capRateBRRRR
        }
        return capRate
      },
    },
    {
      headerName: 'STR Yield-on-Cost',
      field: 'acquisition.analytics.STR.capRateSTR',
      arrow: 'acquisition.analytics.STR.capRateSTRArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.STR.capRateSTRArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let capRate = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          capRate = analytics.STR.capRateSTR
          //capRate = analytics.capRateTurnkey
        } else {
          capRate = analytics.STR.capRateSTR
        }
        return capRate
      },
    },
    {
      headerName: 'Cap Rate',
      field: 'acquisition.analytics.turnkey.capRateTurnkey',
      arrow: 'acquisition.analytics.turnkey.capRateTurnkeyArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.turnkey.capRateTurnkeyArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let capRate = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          capRate = analytics.turnkey.capRateTurnkey
        } else {
          capRate = analytics.turnkey.capRateTurnkey
          //capRate = analytics.capRate
        }
        return capRate
      },
    },
    {
      headerName: 'B2R Cap Rate',
      field: 'acquisition.analytics.B2R.capRateB2R',
      arrow: 'acquisition.analytics.B2R.capRateB2RArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.B2R.capRateB2RArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let capRate = ''
        capRate = analytics.B2R.capRateB2R
        return capRate
      },
    },
    {
      headerName: 'Cash on Cash Return',
      field: 'acquisition.analytics.buyHold.cashOnCashReturn',
      //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
      arrow: 'acquisition.analytics.buyHold.cashOnCashReturnArrow',
      //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.buyHold.cashOnCashReturnArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashOnCashReturn = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashOnCashReturn = analytics.buyHold.cashOnCashReturn
        } else {
          cashOnCashReturn = analytics.buyHold.cashOnCashReturn
        }
        return cashOnCashReturn
      },
    },
    {
      headerName: 'Turnkey Cash on Cash Return',
      field: 'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
      //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
      arrow:
        'acquisition.analytics.turnkey.cashOnCashReturnTurnkeyArrow',
      //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.turnkey.cashOnCashReturnTurnkeyArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashOnCashReturn = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashOnCashReturn = analytics.turnkey.cashOnCashReturnTurnkey
        } else {
          cashOnCashReturn = analytics.turnkey.cashOnCashReturnTurnkey
        }
        return cashOnCashReturn
      },
    },
    {
      headerName: 'BRRRR Cash on Cash Return',
      field: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRR',
      //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
      arrow: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRRArrow',
      //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.BRRRR.cashOnCashReturnBRRRRArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashOnCashReturn = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashOnCashReturn = analytics.BRRRR.cashOnCashReturnBRRRR
        } else {
          cashOnCashReturn = analytics.BRRRR.cashOnCashReturnBRRRR
        }
        return cashOnCashReturn
      },
    },
    {
      headerName: 'STR Cash on Cash Return',
      field: 'acquisition.analytics.STR.cashOnCashReturnSTR',
      //marketName: 'acquisition.analytics.cashOnCashReturnTurnkey',
      arrow: 'acquisition.analytics.STR.cashOnCashReturnSTRArrow',
      //marketArrow: 'acquisition.analytics.cashOnCashReturnTurnkeyArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.STR.cashOnCashReturnSTRArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashOnCashReturn = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashOnCashReturn = analytics.STR.cashOnCashReturnSTR
        } else {
          cashOnCashReturn = analytics.STR.cashOnCashReturnSTR
        }
        return cashOnCashReturn
      },
    },
    {
      headerName: 'B2R Cash on Cash Return',
      field: 'acquisition.analytics.B2R.cashOnCashReturnB2R',
      arrow: 'acquisition.analytics.B2R.cashOnCashReturnB2RArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.B2R.cashOnCashReturnB2RArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashOnCashReturn = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          cashOnCashReturn = analytics.B2R.cashOnCashReturnB2R
        } else {
          cashOnCashReturn = analytics.B2R.cashOnCashReturnB2R
        }
        return cashOnCashReturn
      },
    },
    {
      headerName: 'Equity',
      field: 'acquisition.analytics.buyHold.equity',
      //marketName: 'acquisition.analytics.equityTurnkey',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let equity = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          equity = analytics.buyHold.equity
        } else {
          equity = analytics.buyHold.equity
        }
        return equity
      },
    },
    {
      headerName: 'Turnkey Equity',
      field: 'acquisition.analytics.turnkey.equityTurnkey',
      //marketName: 'acquisition.analytics.equityTurnkey',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let equity = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          equity = analytics.turnkey.equityTurnkey
        } else {
          equity = analytics.turnkey.equityTurnkey
        }
        return equity
      },
    },
    {
      headerName: 'B2R Equity',
      field: 'acquisition.analytics.B2R.equityB2R',
      //marketName: 'acquisition.analytics.equityB2R',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let equity = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          equity = analytics.B2R.equityB2R
        } else {
          equity = analytics.B2R.equityB2R
        }
        return equity
      },
    },
    {
      headerName: 'IRR',
      field: 'acquisition.analytics.buyHold.IRR',
      //marketName: 'acquisition.analytics.IRRTurnkey',
      //arrow: 'acquisition.analytics.IRRArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let IRR = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          IRR = analytics.buyHold.IRR
        } else {
          IRR = analytics.buyHold.IRR
        }
        return IRR
      },
    },
    {
      headerName: 'Turnkey IRR',
      field: 'acquisition.analytics.turnkey.IRRTurnkey',
      //marketName: 'acquisition.analytics.IRRTurnkey',
      //arrow: 'acquisition.analytics.IRRArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let IRR = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          IRR = analytics.turnkey.IRRTurnkey
        } else {
          IRR = analytics.turnkey.IRRTurnkey
        }
        return IRR
      },
    },
    {
      headerName: 'BRRRR IRR',
      field: 'acquisition.analytics.BRRRR.IRRBRRRR',
      //marketName: 'acquisition.analytics.IRRTurnkey',
      //arrow: 'acquisition.analytics.IRRArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let IRR = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          IRR = analytics.BRRRR.IRRBRRRR
        } else {
          IRR = analytics.BRRRR.IRRBRRRR
        }
        return IRR
      },
    },
    {
      headerName: 'STR IRR',
      field: 'acquisition.analytics.STR.IRRSTR',
      //marketName: 'acquisition.analytics.IRRTurnkey',
      //arrow: 'acquisition.analytics.IRRArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let IRR = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          IRR = analytics.STR.IRRSTR
        } else {
          IRR = analytics.STR.IRRSTR
        }
        return IRR
      },
    },
    {
      headerName: 'B2R IRR',
      field: 'acquisition.analytics.B2R.IRRB2R',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      type: 'number',
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let IRR = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          IRR = analytics.B2R.IRRB2R
        } else {
          IRR = analytics.B2R.IRRB2R
        }
        return IRR
      },
    },
    {
      headerName: 'BOR IRR',
      field: 'acquisition.analytics.BOR.IRRBOR',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      type: 'number',
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let IRR = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          IRR = analytics.BOR.IRRBOR
        } else {
          IRR = analytics.BOR.IRRBOR
        }
        return IRR
      },
    },
    {
      headerName: 'Net ROI',
      field: 'acquisition.analytics.fixFlip.netROIFixFlip',
      arrow: 'acquisition.analytics.fixFlip.netROIArrow',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      renderCell: (params) =>
        renderArrowCell(
          params,
          'acquisition.analytics.fixFlip.netROIArrow',
        ),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let netROI = analytics.fixFlip.netROIFixFlip
        return netROI
      },
    },
    // {
    //   headerName: '% of ARV',
    //   field: 'acquisition.analytics.fixFlip.percentageOfARV',
    //   type: 'number',
    //   valueFormatter: params => formatCell(value, percentageFormat, 10, "+1000%"),
    //   valueGetter: params => {
    //     const { row = {} } = params || {}
    //     const { analytics = DEFAULT_ANALYTICS } = row.acquisition || {}
    //     let percentageOfARV = analytics.fixFlip.percentageOfARV
    //     return (
    //       percentageOfARV
    //     )
    //   },
    // },
    {
      headerName: 'All in Cost',
      field: 'acquisition.analytics.fixFlip.allInCostFixFlip',
      marketName: 'acquisition.analytics.fixFlip.allInCostFixFlip',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let allInCost = ''
        if (row.strategy === 'Turnkey End Buyer' || marketplace) {
          allInCost = analytics.fixFlip.allInCostFixFlip
        } else {
          allInCost = analytics.fixFlip.allInCostFixFlip
        }
        return allInCost
      },
    },
    {
      headerName: 'Buy & Hold ARV',
      field: 'acquisition.analytics.afterRepairValueBuyHold',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { buyHold = {} } = analytics || {}
        let afterRepairValue = buyHold.arvTotalBuyHold
        return afterRepairValue
      },
    },
    {
      headerName: 'Fix & Flip ARV',
      field: 'acquisition.analytics.afterRepairValueFixFlip',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { fixFlip = {} } = analytics || {}
        let afterRepairValue = fixFlip.arvTotalFixFlip
        return afterRepairValue
      },
    },
    {
      headerName: 'Turnkey ARV',
      field: 'acquisition.analytics.afterRepairValueTurnkey',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { turnkey = {} } = analytics || {}
        let afterRepairValue = turnkey.arvTotalTurnkey
        return afterRepairValue
      },
    },
    {
      headerName: 'STR ARV',
      field: 'acquisition.analytics.afterRepairValueSTR',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { STR = {} } = analytics || {}
        let afterRepairValue = STR.arvTotalSTR
        return afterRepairValue
      },
    },
    {
      headerName: 'B2R ARV',
      field: 'acquisition.analytics.afterRepairValueB2R',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { B2R = {} } = analytics || {}
        let afterRepairValue = B2R.arvTotalB2R
        return afterRepairValue
      },
    },
    {
      headerName: 'BRRRR ARV',
      field: 'acquisition.analytics.afterRepairValueBRRRR',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { BRRRR = {} } = analytics || {}
        let afterRepairValue = BRRRR.arvTotalBRRRR
        return afterRepairValue
      },
    },
    {
      headerName: 'BOR ARV',
      field: 'acquisition.analytics.afterRepairValueBOR',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { BOR = {} } = analytics || {}
        let afterRepairValue = BOR.arvTotalBOR
        return afterRepairValue
      },
    },
    {
      headerName: 'Wholesale ARV',
      field: 'acquisition.analytics.afterRepairValueWholesale',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = {} } = row.acquisition || {}
        const { wholesale = {} } = analytics || {}
        let afterRepairValue = wholesale.arvTotalWholesale
        return afterRepairValue
      },
    },
    {
      headerName: 'Net Profit',
      field: 'acquisition.analytics.fixFlip.saleProceeds',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let saleProceeds = analytics.fixFlip.saleProceeds
        return saleProceeds
      },
    },
    {
      headerName: 'Wholesale Net Profit',
      field: 'acquisition.analytics.wholesale.saleProceedsWholesale',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let saleProceeds = analytics.wholesale.saleProceedsWholesale
        return saleProceeds
      },
    },
    {
      headerName: 'Astroflip Net Profit',
      field: 'acquisition.analytics.astroflip.saleProceedsAstroFlip',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let saleProceeds = analytics.astroflip?.saleProceedsAstroFlip
        return saleProceeds
      },
    },
    {
      headerName: 'Average Annual Return',
      field: 'acquisition.analytics.BOR.averageAnnualReturnBOR',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let averageAnnualReturn = analytics.BOR.averageAnnualReturnBOR
        return averageAnnualReturn
      },
    },
    {
      headerName: 'All-in Cost to ARV',
      field: 'acquisition.analytics.fixFlip.percentageOfARV',
      type: 'number',
      valueFormatter: (value) =>
        formatCell(value, percentageFormat, 10, '+1000%'),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let percentageOfARV = analytics.fixFlip.percentageOfARV
        return percentageOfARV
      },
    },
    {
      headerName: 'Net Monthly Cashflow',
      field: 'acquisition.analytics.turnkey.cashFlowTurnkey',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashflow = analytics.turnkey.cashFlowTurnkey / 12
        return cashflow
      },
    },
    {
      headerName: 'B2R Net Monthly Cashflow',
      field: 'acquisition.analytics.B2R.cashFlowB2R',
      type: 'number',
      valueFormatter: (value) => formatCell(value, currencyFormat),
      valueGetter: (value, row) => {
        const { analytics = DEFAULT_ANALYTICS } =
          row.acquisition || {}
        let cashflow = analytics.B2R.cashFlowB2R / 12
        return cashflow
      },
    },
    {
      headerName: 'D.O.M.',
      field: 'acquisition.analysis.daysOnMarket',
      type: 'number',
      valueFormatter: (value) => formatCell(value, plainFormat),
      valueGetter: (value, row) => {
        const { analysis = {} } = row.acquisition || {}
        let daysOnMarket = analysis.daysOnMarket
        return daysOnMarket
      },
    },
    {
      headerName: 'Comp Count',
      field: 'compCount',
      type: 'number',
      valueFormatter: (value) => formatCell(value, plainFormat),
      valueGetter: (value, row) => {
        const { compCount } = row || {}
        return compCount
      },
    },
    {
      headerName: 'Date Added',
      type: 'date',
      width: 200,
      field: 'createdAt',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        moment(row.createdAt).format('MMM Do YYYY'),
    },
    {
      headerName: 'Added By',
      field: 'createdBy.profile.firstname',
      width: 200,
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) => {
        const { createdBy } = row
        const name =
          createdBy && createdBy.profile
            ? `${createdBy.profile.firstname} ${createdBy.profile.lastname} `
            : ''
        return name
      },
    },
    {
      headerName: 'Trashed Date',
      field: 'deletedAt',
      type: 'date',
      width: 200,
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        moment(row.deletedAt).format('MMM Do YYYY'),
    },
    {
      headerName: 'Days Until Deleted',
      field: 'daysleft',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) => {
        const today = new Date()
        let endDate = new Date(row.deletedAt)
        endDate.setDate(endDate.getDate() + 7)
        const days = moment
          .duration(moment(endDate).diff(today))
          .asDays()
          .toFixed(0)
        //Returns a string. Since its not expected to be greater than 7 it should still sort
        return `${days} days left`
      },
    },
    {
      headerName: 'Submitted By',
      field: 'marketplaceSubmittedBy.profile.firstname',
      width: 200,
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) => {
        const { marketplaceSubmittedBy } = row
        const name =
          marketplaceSubmittedBy && marketplaceSubmittedBy.profile
            ? `${marketplaceSubmittedBy.profile.firstname} ${marketplaceSubmittedBy.profile.lastname} `
            : ''
        return name
      },
    },
    {
      headerName: 'Lender Submitted By',
      field: 'lenderSubmittedBy.profile.firstname',
      width: 200,
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) => {
        const { lenderSubmittedBy } = row
        const name =
          lenderSubmittedBy && lenderSubmittedBy.profile
            ? `${lenderSubmittedBy.profile.firstname} ${lenderSubmittedBy.profile.lastname} `
            : ''
        return name
      },
    },
    {
      headerName: 'JV Submitted By',
      field: 'jvSubmittedBy.profile.firstname',
      width: 200,
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) => {
        const { jvSubmittedBy } = row
        const name =
          jvSubmittedBy && jvSubmittedBy.profile
            ? `${jvSubmittedBy.profile.firstname} ${jvSubmittedBy.profile.lastname} `
            : ''
        return name
      },
    },
  ])

  const offerToCloseColumns = concat([
    {
      headerName: 'Property Address',
      field: 'address',
      width: 350,
      renderCell: (params) => {
        const { row = {} } = params || {}
        let image = '/images/default_image.png'
        let images = row.images || []
        let image_small = '/images/default_image.png'
        const plan =
          find(props?.session?.me?.activeOrg?.plans || [], {
            value: row.plan,
          }) || {}
        const planImages = plan.images || []
        images = compact(concat(images, planImages))
        if (row.mainImage) image = row.mainImage
        if (propertySearch && !row.marketplaces && row.images) {
          image = JSON.parse(row.images)[0]
          images = map(JSON.parse(row.images), (image) => {
            return { url: image }
          })
        } else if (propertySearch && !row.marketplaces && row.image) {
          image = row.image
          images = [row.image]
        }

        if (
          image === '/images/default_image.png' &&
          !isEmpty(images)
        ) {
          image = head(images).url
          image_small = head(images).url_small
        }

        if (
          (marketplace &&
            ((!(
              isNil(get(row, 'portfolio.organization._id')) &&
              !props.propertySearch
            ) && get(row, 'portfolio.organization._id')) !==
              props?.session?.me?.activeOrg?._id ||
              propertySearch)) ||
          props.trash
        )
          return (
            <div className="align-box-row">
              <Button
                /*onClick={toggleImages(images, row._id)}*/ onClick={(
                  event,
                ) => event.stopPropagation()}
                className="d-40 btn-icon btn-animated-icon p-0 mr-3 badge-wrapper m-3 "
              >
                {/* <div className="d-block p-0 avatar-icon-wrapper"> */}
                {propertySearch &&
                  !props.shared &&
                  row.marketplaces &&
                  !isEmpty(row.marketplaces.approvedMarketplace) && (
                    <div
                      className="d-block p-0 avatar-icon-wrapper badge badge-success badge-position badge-position--top-right badge-circle"
                      style={{ marginTop: 5, marginRight: 5 }}
                    >
                      New notifications
                    </div>
                  )}
                {/* <div className="avatar-icon rounded">
                    <img src={image} alt="..." />
                  </div> */}
                <a
                  href={
                    props?.session?.me?.activeOrg?.isRealeflow
                      ? ''
                      : `https://www.comehome.com/property-details/${replace(
                          property.address,
                          /\s/g,
                          '-',
                        )}-${replace(property.city, /\s/g, '-')}-${
                          property.state
                        }-${property.zip}?modal=photo-list`
                  }
                  target="_blank"
                >
                  <div
                    onClick={(event) => {
                      event.preventDefault()
                      props?.session?.me?.activeOrg?.isRealeflow
                        ? ''
                        : window.open(
                            `https://www.comehome.com/property-details/${replace(
                              property.address,
                              /\s/g,
                              '-',
                            )}-${replace(
                              property.city,
                              /\s/g,
                              '-',
                            )}-${property.state}-${
                              property.zip
                            }?modal=photo-list`,
                            '_blank',
                          )
                    }}
                    className="avatar-icon rounded"
                  >
                    <img
                      src={
                        props?.session?.me?.activeOrg?.member ===
                          'pacemorby' ||
                        props?.session?.me?.activeOrg?.member ===
                          'astroflip'
                          ? '/images/DealSauce-Logo-Tan.png'
                          : props?.session?.me?.activeOrg?.member ===
                              'pep'
                            ? '/images/pep-icon-only.png'
                            : '/images/comehome_small.png'
                      }
                      alt="..."
                    />
                  </div>
                </a>
                {/* </div> */}
              </Button>
              <div>
                <b>{row.address}</b>
                <span className="text-black-50 d-block">
                  {row.city} {row.state}, {row.zip}
                </span>
              </div>
            </div>
          )
        return (
          <div className="align-box-row">
            {!props.shared && !marketplace && (
              <Avatar
                style={{ backgroundColor: 'white' }}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  changeHeart(row._id)
                  updateProperty({
                    variables: {
                      id: row._id,
                      propertyInput: {
                        favorite: !includes(hearts, row._id),
                      },
                    },
                    refetchQueries: [
                      {
                        query: GET_PROPERTIES,
                        variables: {
                          portfolioId: [props.selectedPortfolio],
                        },
                      },
                      {
                        query: GET_PROPERTY,
                        variables: { id: row._id },
                      },
                    ],
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={[
                    includes(hearts, row._id) ? 'fas' : 'fal',
                    'heart',
                  ]}
                  className="d-block font-size-xl"
                  style={{ color: '#368cff' }}
                />
              </Avatar>
            )}
            <Button
              /*onClick={toggleImages(images, row._id)}*/ onClick={(
                event,
              ) => event.stopPropagation()}
              className="d-40 btn-icon btn-animated-icon p-0 mr-3 badge-wrapper m-3 "
            >
              {/* <div className="d-block p-0 avatar-icon-wrapper"> */}
              {row.marketplaces &&
                !isEmpty(row.marketplaces.approvedMarketplace) && (
                  <Tooltip
                    title={
                      <Grid container direction="column">
                        {map(
                          row.marketplaces.approvedMarketplace,
                          (marketplace) => (
                            <Grid item key={marketplace}>
                              <Typography
                                style={
                                  marketplace === 'housefolios' &&
                                  new Date(
                                    row.marketplaces.expirationDate,
                                  ).getTime() < Date.now()
                                    ? { color: 'red' }
                                    : null
                                }
                              >
                                {marketplace}
                              </Typography>
                            </Grid>
                          ),
                        )}
                      </Grid>
                    }
                  >
                    <div
                      className="d-block p-0 avatar-icon-wrapper badge badge-success badge-position badge-position--top-right badge-circle"
                      style={{ marginTop: 5, marginRight: 5 }}
                    >
                      New notifications
                    </div>
                  </Tooltip>
                )}
              {/* <div className="avatar-icon rounded">
                  <img src={image} alt="..." />
                </div> */}
              {/* </div> */}
              <a
                href={
                  props?.session?.me?.activeOrg?.isRealeflow
                    ? ''
                    : `https://www.comehome.com/property-details/${replace(
                        property.address,
                        /\s/g,
                        '-',
                      )}-${replace(property.city, /\s/g, '-')}-${
                        property.state
                      }-${property.zip}?modal=photo-list`
                }
                target="_blank"
              >
                <div
                  onClick={(event) => {
                    event.preventDefault()
                    props?.session?.me?.activeOrg?.isRealeflow
                      ? ''
                      : window.open(
                          `https://www.comehome.com/property-details/${replace(
                            property.address,
                            /\s/g,
                            '-',
                          )}-${replace(property.city, /\s/g, '-')}-${
                            property.state
                          }-${property.zip}?modal=photo-list`,
                          '_blank',
                        )
                  }}
                  className="avatar-icon rounded"
                >
                  <img
                    src={
                      props?.session?.me?.activeOrg?.member ===
                        'pacemorby' ||
                      props?.session?.me?.activeOrg?.member ===
                        'astroflip'
                        ? '/images/DealSauce-Logo-Tan.png'
                        : props?.session?.me?.activeOrg?.member ===
                            'pep'
                          ? '/images/pep-icon-only.png'
                          : '/images/comehome_small.png'
                    }
                    alt="..."
                  />
                </div>
              </a>
            </Button>
            <a
              href={`/property-details/${row._id}/Summary`}
              onClick={(event) => {
                event.stopPropagation()
                if (props.jv && row.jvStatus === 'New') {
                  updateProperty({
                    variables: {
                      id: row._id,
                      propertyInput: {
                        jvStatus: 'In Process',
                        jvTimeline: {
                          ...row.jvTimeline,
                          viewed: new Date(),
                        },
                      },
                    },
                    refetchQueries: [
                      {
                        query: GET_PROPERTIES,
                        variables: {
                          portfolioId: [props.selectedPortfolio],
                        },
                      },
                      {
                        query: GET_PROPERTY,
                        variables: { id: row._id },
                      },
                    ],
                  })
                }
                if (props.lender && row.lenderStatus === 'New') {
                  updateProperty({
                    variables: {
                      id: row._id,
                      propertyInput: {
                        lenderStatus: 'In Process',
                        lenderTimeline: {
                          ...row.lenderTimeline,
                          viewed: new Date(),
                        },
                      },
                    },
                    refetchQueries: [
                      {
                        query: GET_PROPERTIES,
                        variables: {
                          portfolioId: [props.selectedPortfolio],
                        },
                      },
                      {
                        query: GET_PROPERTY,
                        variables: { id: row._id },
                      },
                    ],
                  })
                }
              }}
            >
              <b>{row.address}</b>
              <span className="text-black-50 d-block">
                {row.city} {row.state}, {row.zip}
              </span>
            </a>
          </div>
        )
      },
    },
    {
      headerName: 'County',
      field: 'county',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'City',
      field: 'city',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'State',
      field: 'state',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Zip',
      field: 'zip',
      type: 'text',
      valueFormatter: (value) => formatCell(value),
    },
    {
      headerName: 'Earnest Money Sent',
      field: 'offerToClose.earnestMoney.earnestMoneyReceived',
      type: 'date',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        get(row, 'offerToClose.earnestMoney.earnestMoneyReceived')
          ? moment(
              get(
                row,
                'offerToClose.earnestMoney.earnestMoneyReceived',
              ),
            ).format('MMM Do YYYY')
          : null,
    },
    {
      headerName: 'Appraisal Paid',
      field: 'offerToClose.appraisal.dateAppraisalOrdered',
      type: 'date',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        get(row, 'offerToClose.appraisal.dateAppraisalOrdered')
          ? moment(
              get(row, 'offerToClose.appraisal.dateAppraisalOrdered'),
            ).format('MMM Do YYYY')
          : null,
    },
    {
      headerName: 'Appraisal Paid',
      field: 'offerToClose.appraisal.dateAppraisalReceived',
      type: 'date',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        get(row, 'offerToClose.appraisal.dateAppraisalReceived')
          ? moment(
              get(
                row,
                'offerToClose.appraisal.dateAppraisalReceived',
              ),
            ).format('MMM Do YYYY')
          : null,
    },
    {
      headerName: 'Home Inspection Ordered',
      field: 'offerToClose.homeInspection.dateInspectionOrdered',
      type: 'date',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        get(row, 'offerToClose.homeInspection.dateInspectionOrdered')
          ? moment(
              get(
                row,
                'offerToClose.homeInspection.dateInspectionOrdered',
              ),
            ).format('MMM Do YYYY')
          : null,
    },
    {
      headerName: 'Due Diligence Deadline',
      field: 'offerToClose.homeInspection.dateDueDiligence',
      type: 'date',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        get(row, 'offerToClose.homeInspection.dateDueDiligence')
          ? moment(
              get(
                row,
                'offerToClose.homeInspection.dateDueDiligence',
              ),
            ).format('MMM Do YYYY')
          : null,
    },
    {
      headerName: 'Purchase Closing Date',
      field: 'offerToClose.closing.closingDate',
      type: 'date',
      valueFormatter: (value) => formatCell(value),
      valueGetter: (value, row) =>
        get(row, 'offerToClose.closing.closingDate')
          ? moment(
              get(row, 'offerToClose.closing.closingDate'),
            ).format('MMM Do YYYY')
          : null,
    },
  ])

  const selectColumn = [
    {
      field: 'selected',
      headerName: 'Select',
      width: 80,
      renderCell: (params) => {
        const { row = {} } = params || {}
        return (
          <td className="text-center">
            <Checkbox
              color="primary"
              id="CustomCheckbox4"
              className="align-self-start"
              checked={includes(props.selected, params?.id)}
              onClick={
                !props.shared || props.sharedProperties
                  ? (props.propertySearch &&
                      row.marketplaces &&
                      !isEmpty(
                        row.marketplaces.approvedMarketplace,
                      )) ||
                    (!(
                      isNil(get(row, 'portfolio.organization._id')) &&
                      !props.propertySearch
                    ) &&
                      get(row, 'portfolio.organization._id') !==
                        props?.session?.me?.activeOrg?._id &&
                      !props.propertySearch &&
                      !props.sharedProperties &&
                      !props.trash)
                    ? props.selectMarketplaceProperty(row._id)
                    : props.selectProperty(row._id)
                  : null
              }
            />
          </td>
        )
      },
    },
  ]

  const actionColumn = [
    {
      headerName: 'Actions',
      type: 'actions',
      field: 'Actions',
      width: 100,
      renderCell: (params) => {
        const { row = {} } = params || {}
        return (
          <Grid
            container
            spacing={1}
            className="align-box-row"
            onClick={(event) => event.stopPropagation()}
            style={{ margin: 5 }}
          >
            <Grid item xs={12}>
              <Button
                component="a"
                href={`/property-details/${row._id}`}
                variant="contained"
                color="primary"
              >
                View
              </Button>
            </Grid>
          </Grid>
        )
      },
    },
  ]

  const [selectedColumns, setSelectedColumns] = useState([])

  const handleOpenManageColumns = () => {
    setSelectedColumns(cloneDeep(showColumns))
    setManageColumns(true)
  }
  const handleCloseManageColumns = () => {
    setManageColumns(false)
  }

  const pullColumns = (customColumns) => {
    if (!isEmpty(customColumns)) {
      if (propertySearch) {
        pullAt(customColumns, [2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
        pullAt(customColumns[0].data, [5])
      } else if (marketplace && !props.trash) {
        let numbers = []
        if (!props.jv) {
          numbers.push(9)
        }
        if (!props.lender) {
          numbers.push(8)
        }
        if (!props.submitted) {
          numbers.push(7)
        }
        pullAt(
          customColumns[11].data,
          concat([0, 2, 3, 4, 5, 6], numbers),
        )
        //days on market
        pullAt(customColumns[0].data, [5])

        if (props?.session?.me?.activeOrg?.member !== 'astroflip')
          pullAt(customColumns, [10])
      } else {
        pullAt(customColumns[3].data, [1])
        pullAt(customColumns[8].data, [2])
        let numbers = []
        if (!props.jv) {
          numbers.push(9)
        }
        if (!props.lender) {
          numbers.push(8)
        }
        if (!props.submitted) {
          numbers.push(7)
        }
        if (!props.trash) {
          numbers.push(6)
          numbers.push(5)
        }
        pullAt(customColumns[11].data, numbers)

        if (props?.session?.me?.activeOrg?.member !== 'astroflip')
          pullAt(customColumns, [10])
      }
    }
  }

  //change columns and page on page change
  useEffect(() => {
    const columnKey = myMarketplace
      ? 'myMarketplace'
      : marketplace
        ? marketplace
        : selectedPortfolio !== 'null'
          ? selectedPortfolio
          : 'all'
    const customColumns = []
    setShowColumns(
      isEqualWith(customColumns, defaultColumns, checkColumns)
        ? cloneDeep(customColumns)
        : cloneDeep(defaultColumns),
    )
    setSelectedColumns(
      isEqualWith(customColumns, defaultColumns, checkColumns)
        ? cloneDeep(customColumns)
        : cloneDeep(defaultColumns),
    )
  }, [selectedPortfolio, defaultColumns, marketplace, myMarketplace])

  //reset page to 1 for propertySearch on doing a new search
  // useEffect(() => {
  //   setPage(1)
  // }, [props.propertySearchResetPage])

  let customShowColumns = cloneDeep(showColumns)
  pullColumns(customShowColumns)
  let customSelectedColumns = cloneDeep(selectedColumns)
  pullColumns(customSelectedColumns)

  // const tableColumns = getColumns(columns, customShowColumns)

  const onSubmit = () => {
    const columnKey = myMarketplace
      ? 'myMarketplace'
      : marketplace
        ? marketplace
        : selectedPortfolio !== 'null'
          ? selectedPortfolio
          : 'all'
    setShowColumns(cloneDeep(selectedColumns))
    handleCloseManageColumns()
  }

  const compare = intersection(
    map(properties, (property) => property._id),
    selected,
  )

  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }

  if (loading) return 'loading'
  //if (error) return error

  return (
    <>
      <Card className="card-box">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>List View</small>
            <b>
              {props.trash
                ? 'Trash'
                : propertySearch
                  ? 'Property Search'
                  : marketplace
                    ? marketplace !== 'null'
                      ? marketplace
                      : 'Marketplace'
                    : selectedPortfolio !== 'null'
                      ? props.portfolio.name
                      : 'All Properties'}
            </b>
          </div>
          <div className="card-header--actions">
            <Tooltip title="Manage Columns">
              <Button
                href={null}
                onClick={handleOpenManageColumns}
                variant="contained"
                color="primary"
                size="small"
                id="RefreshTooltip1"
                style={{ margin: 5, padding: '6px 15px' }}
              >
                <ViewColumnIcon />
                {/* <FontAwesomeIcon icon={['fas', 'cog']} spin /> */}
              </Button>
            </Tooltip>
          </div>
        </div>
        <CardContent
          className="p-0"
          style={{
            height:
              properties.length * 52 + 130 < 850
                ? properties.length * 52 + 130
                : 550,
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            columns={map(
              concat(underwritingColumns, actionColumn),
              (column) => ({
                ...column,
                flex: column.width ? 0 : 1,
                minWidth:
                  column.width ||
                  calculateMinWidth(column.headerName),
              }),
            )}
            columnVisibilityModel={getColumnVisibility(
              underwritingColumns,
              customShowColumns,
            )}
            disableColumnMenu={true}
            //onColumnVisibilityModelChange={null}
            rows={properties}
            // getRowHeight={() => 90}
            page={page - 1}
            onPageChange={(newPage) =>
              handlePageChange(null, newPage + 1)
            }
            rowsPerPageOptions={[24]}
            pageSize={24}
            columnBuffer={
              concat(underwritingColumns, actionColumn).length
            }
            onProcessRowUpdateError={(error) => {
              console.log(error)
            }}
            // checkboxSelection={true}
            // onSelectionModelChange={selectProperties}
            // selectionModel={props.selected}
            disableSelectionOnClick
            sx={{
              fontSize: 16,
              '& .ColumnEditable': {
                border: 6,
                borderColor: '#DCDCDC',
                borderRight: 'none',
                borderLeft: 'none',
              },
              '& .MuiInputBase-root:not(.MuiInput-underline).Mui-focused':
                {
                  boxShadow: 'none',
                },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                width: '0.4em',
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track':
                {
                  background: '#f1f1f1',
                },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb':
                {
                  backgroundColor: '#888',
                },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover':
                {
                  background: '#555',
                },
              '& .MuiDataGrid-editInputCell': {
                fontSize: 16,
              },
            }}
          />
        </CardContent>
        {/* <CardContent className="p-0">
          <div className="table-responsive">
            <Table className="table text-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {map(tableColumns, column => (
                    <TableCell
                      key={column.title}
                      //style={{ whiteSpace: 'nowrap' }}
                      align={column.align}
                      sortDirection={orderBy === column.name ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.name}
                        direction={order}
                        onClick={(event) => handleRequestSort(event, propertySearch && column.propertySearchName ? column.propertySearchName : column.name, column.marketName)}
                      >
                        {column.title}
                        {orderBy === column.name ? (
                          <span style={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {marketplace && (
                    <TableCell>Actions</TableCell>
                  )}
                </tr>
              </thead>
              <tbody>
                {map(slice(stableSort(properties, getSorting(order, !propertySearch && marketplace ? marketOrderBy : orderBy, marketOrderBy, propertySearch)), (page -1 ) * 25, page * 25), property => (
                  <tr>
                    {map(tableColumns, column => {
                      let arrow = get(property, column.arrow)
                      if ((property.strategy === 'Turnkey End Buyer' || marketplace) && column.marketArrow) {
                        arrow = get(property, column.marketArrow)
                      }
                      return (
                        <td className="text-center">
                          <Grid container >
                            <Grid item>
                              {column.getCellValue ? !(column.getCellValue(property) < 0 || !column.getCellValue(property)) ? column.getCellValue(property) > column.max ? column.maxString : column.formatter ? column.formatter.format(column.getCellValue(property)) : column.getCellValue(property) : '-' : !(property[column.name] < 0 || !property[column.name]) ? property[column.name] > column.max ? column.maxString : column.formatter ? column.formatter.format(property[column.name]) : property[column.name] : '-'}
                            </Grid>
                            <Grid item style={{ marginLeft: 5 }}>
                            {!isUndefined(arrow) ? (
                              arrow ? (
                                arrow === 2 ? (
                                  <Avatar
                                    style={{
                                      backgroundColor: '#2296F3',
                                      height: 15,
                                      width: 15,
                                    }}
                                  >
                                    <ArrowUpwardIcon
                                      style={{
                                        color: 'white',
                                        fontSize: 'small',
                                      }}
                                    />
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    style={{
                                      backgroundColor: '#46de64',
                                      height: 15,
                                      width: 15,
                                    }}
                                  >
                                    <ArrowUpwardIcon
                                      style={{
                                        color: 'white',
                                        fontSize: 'small',
                                      }}
                                    />
                                  </Avatar>
                                )
                              ) : (
                                  <Avatar
                                    style={{
                                      backgroundColor: '#F05248',
                                      height: 15,
                                      width: 15,
                                    }}
                                  >
                                    <ArrowDownwardIcon
                                      style={{
                                        color: 'white',
                                        fontSize: 'small',
                                      }}
                                    />
                                  </Avatar>
                              )
                            ) : null}
                            </Grid>
                          </Grid>
                        </td>
                      )
                    })}
                    <td className="text-center">
                      <Grid item xs={12}>
                        <Button component="a" href={`/public-property-details/${property._id}/Summary${props.subdomainParam}`}
                          variant="contained" color="primary"
                        >View</Button>
                      </Grid>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="divider" />
          <div className="divider" />
          <div className="p-3 d-flex justify-content-center">
            <Pagination className="pagination-first" size="small" count={count} page={page} onChange={handlePageChange} />
          </div>
        </CardContent> */}
      </Card>
      <Dialog
        open={openManageColumns}
        onClose={handleCloseManageColumns}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        scroll="body"
      >
        <Card>
          <CardHeader title="Manage Columns" />
          <Divider />
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={1}
            >
              {map(customSelectedColumns, (section, index) => (
                <Grid item xs={12} fullWidth={true}>
                  <Container>
                    <Autocomplete
                      multiple
                      //id="fixed-tags-demo"
                      disableCloseOnSelect
                      options={section.data}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, value) => {
                        map(value, (obj) => {
                          obj.show = true
                        })
                        let newColumns = cloneDeep(selectedColumns)
                        map(newColumns[index].data, (obj) => {
                          const check = find(value, {
                            title: obj.title,
                          })
                          if (check) obj.show = true
                          else obj.show = false
                        })
                        setSelectedColumns(newColumns)
                      }}
                      value={filter(
                        customSelectedColumns[index].data,
                        'show',
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          return (
                            <Chip
                              color="primary"
                              label={option.title}
                              {...getTagProps({ index })}
                            />
                          )
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getStrategyName(
                            section.Section,
                            props?.session?.me?.activeOrg?.member,
                          )}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      )}
                    />
                  </Container>
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Typography style={{ flex: 1 }} />
            <Button variant="text" onClick={handleCloseManageColumns}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              type="submit"
              style={{ marginRight: 10 }}
            >
              Save
            </Button>
          </CardActions>
          {/* </ValidatorForm> */}
        </Card>
      </Dialog>
      <Dialog
        fullScreen
        open={showImages}
        onClose={toggleImages()}
        // TransitionComponent={Transition}
      >
        <AppBar enableColorOnDark className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Property Images
            </Typography>
            <Button
              variant="outlined"
              margin="dense"
              style={{ color: 'white', borderColor: 'white' }}
              onClick={toggleImages()}
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="min-vh-100"
        >
          <Grid item style={{ width: '100%' }}>
            <Slider
              ref={slider}
              className="card-img-top slider-dots"
              {...widgetsCarousels1B}
            >
              {!isEmpty(images) ? (
                map(slice(images, 0, 5), (image) => (
                  <div>
                    <Card
                      className="shadow-none"
                      style={{
                        backgroundColor: 'white',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        margin: 1,
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: '100%' }}
                      >
                        <Grid item>
                          <a
                            href="/#"
                            onClick={(event) => {
                              event.preventDefault()
                            }}
                            className="card-img-wrapper"
                            style={{
                              textAlign: 'center',
                              backgroundColor: 'transparent',
                              borderBottomRightRadius: '0px',
                            }}
                          >
                            <div
                              className="blur-load"
                              style={{
                                backgroundImage:
                                  'url(' + image.url_small + ')',
                                height: 500,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={image.url}
                                className="card-img-top"
                                style={{
                                  height: '500px',
                                  width: '100%',
                                  objectFit: 'contain',
                                  maxHeight: 500,
                                }}
                                alt="..."
                                loading="lazy"
                              />
                            </div>
                          </a>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                ))
              ) : (
                <div>
                  <Card
                    className="shadow-none"
                    style={{
                      height: 'auto',
                      maxHeight: 500,
                      backgroundColor: 'white',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      margin: 1,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{ height: '100%' }}
                    >
                      <Grid item>
                        <a
                          href="/#"
                          onClick={(event) => {
                            event.preventDefault()
                          }}
                          className="card-img-wrapper"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <img
                            src="/images/default_image.png"
                            className="card-img-top"
                            style={{
                              maxHeight: 500,
                              height: 'auto',
                              width: 'auto',
                              objectFit: 'contain',
                            }}
                            alt="..."
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              )}
            </Slider>
          </Grid>
          {images.length > 5 && (
            <Grid item>
              <Link
                href={`/public-property-details/${propertyManyImages}/Images`}
              >
                <Typography>
                  This property has more than 5 images. View them
                  here.
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </>
  )
}

export default withSession(PublicPropertyListComponent)
