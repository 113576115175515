import React, { useEffect, useState } from 'react'

import { PageTitle } from '@/layout-components'
import { ExampleWrapperSeamless } from '@/layout-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import clsx from 'clsx'

import find from 'lodash/find'
import head from 'lodash/head'
import toLower from 'lodash/toLower'
import map from 'lodash/map'
import filter from 'lodash/filter'
import intersectionBy from 'lodash/intersectionBy'
import intersection from 'lodash/intersection'
import ceil from 'lodash/ceil'
import pull from 'lodash/pull'
import includes from 'lodash/includes'
import slice from 'lodash/slice'
import isString from 'lodash/isString'
import get from 'lodash/get'
import split from 'lodash/split'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import remove from 'lodash/remove'

import EmptyMarketplace from '@/housefolios-components/Marketplace/EmptyMarketplace'
import Notification from '@/housefolios-components/Marketplace/BulkActionsNotification'
import {
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Grid,
  Switch,
  ListItem,
  List,
  Typography,
  IconButton,
} from '@mui/material'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'
import withSession from '@/housefolios-components/Session/withSession'
import { Query } from '@apollo/client/react/components'
import PropertiesNav from '@/housefolios-components/Properties/PropertiesNav'
import Pagination from '@mui/material/Pagination'
import AllPropertiesFiltered from '@/housefolios-components/Properties/AllPropertiesFiltered'
import PublicPropertyCard from '@/housefolios-components/PublicProperty/PublicPropertyCard'
import PublicPropertyList from '@/housefolios-components/PublicProperty/PublicPropertyList'
import { ArrowBackIos } from '@mui/icons-material'
import useProperties, { KindOfPage } from '@/hooks/useProperties'
import FetchMore from '@/housefolios-components/Properties/MyProperties/FetchMore'

function desc(a, b, orderBy, marketOrderBy) {
  let orderByA = a.marketplaces ? marketOrderBy : orderBy
  let orderByB = b.marketplaces ? marketOrderBy : orderBy
  if (isString(get(a, orderByA)) && isString(get(b, orderByB))) {
    if (!get(b, orderByB) && !get(a, orderByA)) {
      return 0
    }
    if (!get(b, orderByB)) {
      return 1
    }
    if (!get(a, orderByA)) {
      return -1
    }
    if (
      every(get(a, orderByA), (c) => c <= '9' && c >= '0') &&
      every(get(b, orderByB), (c) => c <= '9' && c >= '0')
    ) {
      if (Number(get(b, orderByB)) < Number(get(a, orderByA))) {
        return -1
      }
      if (Number(get(b, orderByB)) > Number(get(a, orderByA))) {
        return 1
      }
      return 0
    } else {
      if (
        get(b, orderByB).toLowerCase() <
        get(a, orderByA).toLowerCase()
      ) {
        return -1
      }
      if (
        get(b, orderByB).toLowerCase() >
        get(a, orderByA).toLowerCase()
      ) {
        return 1
      }
      return 0
    }
  } else {
    if (isNil(get(b, orderByB)) && isNil(get(a, orderByA))) {
      return 0
    }
    if (isNil(get(b, orderByB))) {
      return 1
    }
    if (isNil(get(a, orderByA))) {
      return -1
    }
    if (get(b, orderByB) < get(a, orderByA)) {
      return -1
    }
    if (get(b, orderByB) > get(a, orderByA)) {
      return 1
    }
    return 0
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy, marketOrderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy, marketOrderBy)
    : (a, b) => -desc(a, b, orderBy, marketOrderBy)
}

function PublicMarketplacePage(props) {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('view') ? localStorage.getItem('view') : '1',
  )
  // const [search, setSearch] = useState('')
  // const [searchedProperties, setSearchedProperties] = useState([])
  // const [filtered, setFiltered] = useState(false)
  // const [filteredProperties, setFilteredProperties] = useState([])
  // const [selected, setSelected] = useState([])
  // const [marketplaceSelected, setMarketplaceSelected] = useState([])
  const [showMenu, setShowMenu] = useState(true)

  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  const handleMenu = () => {
    setShowMenu(false)
  }

  const isSmallWindow = window.innerWidth < 950
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false)

  const toggleSidebarMenu = () =>
    setIsSidebarMenuOpen(!isSidebarMenuOpen)

  let marketplace = props.marketplace ? props.marketplace.url : null

  const urlParams = new URLSearchParams(window.location.search)
  const subdomain = urlParams.get('subdomain') || null
  const subdomainParam = subdomain ? '?subdomain=' + subdomain : ''

  // const [page, setPage] = useState(
  //   parseInt(
  //     localStorage.getItem(
  //       !marketplace
  //         ? 'housefolios-marketplace-card-page'
  //         : `${marketplace}-marketplace-card-page`,
  //     ),
  //   ) || 1,
  // )
  // const handleChange = (event, value) => {
  //   if (!marketplace)
  //     localStorage.setItem('housefolios-marketplace-card-page', value)
  //   else
  //     localStorage.setItem(
  //       `${marketplace}-marketplace-card-page`,
  //       value,
  //     )
  //   setPage(value)
  // }

  // const [ordering, setOrdering] = useState('')

  // const [, marketOrderBy, , order] = split(ordering, '-')
  // useEffect(() => {
  //   setSelected([])
  //   setMarketplaceSelected([])
  //   setPage(
  //     parseInt(
  //       localStorage.getItem(
  //         !marketplace
  //           ? 'housefolios-marketplace-card-page'
  //           : `${marketplace}-marketplace-card-page`,
  //       ),
  //     ) || 1,
  //   )
  //   setSearch('')
  // }, [marketplace])

  const [
    {
      properties,
      count,
      currentPageProperties,
      marketPlaceData,
      loadingMarketPlaceData,
      marketPlaceError,
      ordering,
      page,
      selected,
      search,
      marketplaceSelected,
    },
    {
      selectAll,
      selectProperty,
      getSearchResults,
      getFilteredProperties,
      fetchMoreProperties,
      setOrdering,
      selectMarketplaceProperty,
      setMarketplaceSelected,
      handlePageChange,
      handleRequestSort,
      setSelected,
      setPage,
    },
  ] = useProperties({
    ...props,
    // selectedPortfolio,
    kind: KindOfPage.PUBLIC_MARKETPLACE,
    marketplace,
  })

  const cursor =
    marketPlaceData?.marketplaceProperties?.cursor || null

  let unfilteredProperties = !marketplace
    ? filter(
        marketPlaceData?.marketplaceProperties?.properties,
        (property) =>
          new Date(property?.marketplaces?.expirationDate).getTime() >
          Date.now(),
      )
    : marketPlaceData?.marketplaceProperties?.properties
  unfilteredProperties = props.sold
    ? filter(
        unfilteredProperties,
        (property) => toLower(property.status) === 'sold',
      )
    : filter(
        unfilteredProperties,
        (property) => toLower(property.status) !== 'sold',
      )

  if (loadingMarketPlaceData)
    return (
      <Backdrop
        open={loadingMarketPlaceData}
        style={{
          color: '#fff',
          zIndex: 2000,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  if (marketPlaceError) return `Error! ${marketPlaceError?.message}`
  return (
    <>
      <PageTitle
        titleHeading={
          !props.marketplace
            ? 'Marketplace'
            : `${props.marketplace.name}`
        }
        titleDescription="Purchase your next property"
        icon={
          <FontAwesomeIcon
            icon={['fad', 'sign-hanging']}
            className="text-primary"
            style={{ fontSize: 30 }}
          />
        }
        style={{ margin: 0 }}
      />
      <ExampleWrapperSeamless>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item className="gridItem2" xs={12}>
            {!showMenu && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      style={{ marginRight: 100 }}
                      onClick={() => {
                        setShowMenu(!showMenu)
                      }}
                      size="small"
                      defaultChecked
                    />
                  }
                  label="Show Menu"
                />
              </FormGroup>
            )}
          </Grid>
          <PropertiesNav
            handleMenu={handleMenu}
            showMenu={showMenu}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            search={search}
            getSearchResults={getSearchResults}
            getFilteredProperties={getFilteredProperties}
            selectAll={selectAll}
            selectProperty={selectProperty}
            properties={
              marketPlaceData?.marketplaceProperties?.properties
            }
            filteredProperties={properties}
            currentPageProperties={currentPageProperties}
            marketplace={marketplace || 'null'}
            ordering={ordering}
            setOrdering={setOrdering}
            shared
            public
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={4}
          style={{ marginTop: 10 }}
        >
          {compact(properties).length > 0 ? (
            <>
              {activeTab === '2' && (
                <Grid item xs={12}>
                  <PublicPropertyList
                    properties={properties}
                    marketplace={marketplace || 'null'}
                    shared
                    subdomainParam={subdomainParam}
                    handleRequestSort={handleRequestSort}
                    page={page}
                    setPage={setPage}
                    count={count}
                    handlePageChange={handlePageChange}
                    currentPageProperties={currentPageProperties}
                    ordering={ordering}
                    setOrdering={setOrdering}
                  />
                  <FetchMore
                    fetchMoreProperties={fetchMoreProperties}
                    loadMore={
                      marketPlaceData?.marketplaceProperties
                        .hasNextPage
                    }
                    cursor={cursor}
                  />
                </Grid>
              )}
              {activeTab === '1' && (
                <>
                  <Grid container item xs={12} spacing={4}>
                    {map(currentPageProperties, (property) => (
                      <PublicPropertyCard
                        property={property}
                        marketplace={marketplace || 'null'}
                        shared
                        subdomainParam={subdomainParam}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <FetchMore
                      fetchMoreProperties={fetchMoreProperties}
                      loadMore={
                        marketPlaceData?.marketplaceProperties
                          .hasNextPage
                      }
                      cursor={cursor}
                    />
                    <div className="p-3 d-flex justify-content-center">
                      <Pagination
                        className="pagination-first"
                        size="small"
                        count={count}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </>
          ) : unfilteredProperties?.length > 0 ? (
            <AllPropertiesFiltered />
          ) : (
            <EmptyMarketplace sold={props.sold} />
          )}
        </Grid>
        {/* This needs to be hooked up once we merge everhtying.  Also, I need to figure out how to anchor it to the bottom of the screen*/}
        {/* <Notification marketplace={marketplace || 'null'} /> */}
      </ExampleWrapperSeamless>
    </>
  )
}

export default PublicMarketplacePage
