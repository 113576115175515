import { gql } from '@apollo/client'

export const GET_ACTIVE_ORGS = gql`
  query activeOrgs {
    activeOrgs {
      _id
      meetingUrl
      phone
      email
      website
      legalDescription
      CMALegalDescription
      disabled
      delinquent
      name
      address
      city
      state
      zip
      subscriptions
      createdAt
      setup
      affiliateActive
      affiliateTaC
      isRealeflow
      realeflowURL
      markets
      propertyTypes
      plans
      zones
      affiliateMarketplace
      marketplaceDisclaimerRead
      marketPlaceFields
      canListOnHousefoliosMarketplace
      savedSearches
      recentSearches
      watchedDemos
      manageLots
      manageCommercial
      preferences
      referredByDetails
      buyBox
      skipTraceCredits
      allowCoachLogin
      lastLogin
      member
      createdBy {
        _id
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const GET_ALL_ORGS = gql`
  query allOrgs {
    allOrgs {
      _id
      meetingUrl
      phone
      email
      website
      legalDescription
      CMALegalDescription
      disabled
      delinquent
      name
      address
      city
      state
      zip
      subscriptions
      createdAt
      setup
      affiliateActive
      affiliateTaC
      isRealeflow
      realeflowURL
      markets
      propertyTypes
      plans
      zones
      affiliateMarketplace
      marketplaceDisclaimerRead
      marketPlaceFields
      canListOnHousefoliosMarketplace
      savedSearches
      recentSearches
      watchedDemos
      manageLots
      preferences
      referredByDetails
      buyBox
      skipTraceCredits
      allowCoachLogin
      lastLogin
      member
      createdBy {
        _id
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const GET_TRANSACTIONS_MONTH = gql`
  query transactionsByMonthAndAffiliate($monthYear: String) {
    transactionsByMonthAndAffiliate(monthYear: $monthYear) {
      _id
      customer
      amount
      amount_refunded
      description
      invoice
      payment_intent
      created
      organizationId
      orgName
    }
  }
`
