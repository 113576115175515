import React, { useState, useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Button,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import TimelineIcon from '@mui/icons-material/Timeline'
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from '@/utils/number'

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiCardContent-root': {
    padding: theme.spacing(3),
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}))

const emptyUnitRow = {
  length: 0,
  width: 0,
  units: 0,
  monthlyRate: 0,
  sqftPerUnit: 0,
}

interface DealStructure {
  purchasePrice: number
  capitalExpenditures: number
  closingCosts: number
  downPayment: number
  interestRate: number
  mortgageLength: number
  loanAmount: number
}

const DealAnalysis = () => {
  const [mode, setMode] = useState('current') // 'current' or 'stabilized'
  const [projectionDialogOpen, setProjectionDialogOpen] =
    useState(false)
  const [annualRateIncrease, setAnnualRateIncrease] = useState(2.0) // 2% default

  const [unitMix, setUnitMix] = useState({
    current: [{ ...emptyUnitRow }],
    stabilized: [{ ...emptyUnitRow }],
  })

  const [operationalMetrics, setOperationalMetrics] = useState({
    current: {
      currentOccupancy: 90,
      expenseRatio: 40,
    },
    stabilized: {
      currentOccupancy: 85,
      expenseRatio: 40,
    },
  })

  const [dealStructure, setDealStructure] = useState<DealStructure>({
    purchasePrice: 750000,
    capitalExpenditures: 30000,
    closingCosts: 12500,
    downPayment: 157500,
    interestRate: 7,
    mortgageLength: 25,
    loanAmount: 0,
  })

  // Calculate total costs and loan amount
  const totalCosts =
    dealStructure.purchasePrice +
    dealStructure.capitalExpenditures +
    dealStructure.closingCosts
  const loanAmount = totalCosts - dealStructure.downPayment

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode)
    }
  }

  const handleAddRow = () => {
    setUnitMix((prev) => ({
      ...prev,
      [mode]: [...prev[mode], { ...emptyUnitRow }],
    }))
  }

  const handleRemoveRow = (indexToRemove) => {
    if (unitMix[mode].length > 1) {
      setUnitMix((prev) => ({
        ...prev,
        [mode]: prev[mode].filter(
          (_, index) => index !== indexToRemove,
        ),
      }))
    }
  }

  // Calculate derived values
  const calculateUnitMetrics = (unit) => {
    const totalRentableSqft = unit.length * unit.width * unit.units
    const monthlyIncomePerUnit = unit.monthlyRate * unit.units
    const annualIncomePerUnit = monthlyIncomePerUnit * 12
    return {
      ...unit,
      totalRentableSqft,
      monthlyIncomePerUnit,
      annualIncomePerUnit,
    }
  }

  const calculateFinancialMetrics = (projectionMode = mode) => {
    const currentUnits = unitMix[projectionMode]
    const currentMetrics = operationalMetrics[projectionMode]

    // Calculate total units and net rentable sqft
    const totalUnits = currentUnits.reduce(
      (sum, unit) => sum + (unit.units || 0),
      0,
    )
    const netRentableSqft = currentUnits.reduce(
      (sum, unit) =>
        sum +
        (unit.length || 0) * (unit.width || 0) * (unit.units || 0),
      0,
    )

    // Calculate gross annual income
    const grossAnnualIncome = currentUnits.reduce(
      (sum, unit) =>
        sum + (unit.monthlyRate || 0) * (unit.units || 0) * 12,
      0,
    )

    // Calculate occupancy loss
    const occupancyLoss =
      grossAnnualIncome *
      (1 - (currentMetrics.currentOccupancy || 0) / 100)

    // Calculate operating expenses
    const operatingExpenses =
      (grossAnnualIncome - occupancyLoss) *
      ((currentMetrics.expenseRatio || 0) / 100)

    // Calculate NOI
    const netOperatingIncome =
      grossAnnualIncome - occupancyLoss - operatingExpenses

    // Calculate yearly debt payments (principal + interest)
    const yearlyInterest =
      loanAmount * (dealStructure.interestRate / 100)
    const monthlyInterestRate =
      (dealStructure.interestRate || 0) / 1200
    const numberOfPayments = (dealStructure.mortgageLength || 0) * 12
    const monthlyPayment =
      numberOfPayments === 0
        ? 0
        : (loanAmount *
            (monthlyInterestRate *
              Math.pow(1 + monthlyInterestRate, numberOfPayments))) /
          (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)

    // Calculate first year principal payments
    let remainingBalance = loanAmount
    let yearlyPrincipal = 0
    for (let month = 1; month <= 12; month++) {
      const monthlyInterest = remainingBalance * monthlyInterestRate
      const monthlyPrincipal = monthlyPayment - monthlyInterest
      yearlyPrincipal += monthlyPrincipal
      remainingBalance -= monthlyPrincipal
    }

    const yearlyDebtPayments = yearlyPrincipal + yearlyInterest

    // Calculate cash flows
    const annualCashFlow = netOperatingIncome - yearlyDebtPayments
    const monthlyCashFlow = annualCashFlow / 12

    // Calculate returns
    const cashOnCashReturn =
      dealStructure.downPayment === 0
        ? 0
        : (annualCashFlow / dealStructure.downPayment) * 100
    const debtServiceCoverageRatio =
      yearlyDebtPayments === 0
        ? 0
        : netOperatingIncome / yearlyDebtPayments

    return {
      totalUnits,
      netRentableSqft,
      grossAnnualIncome,
      occupancyLoss,
      operatingExpenses,
      netOperatingIncome,
      monthlyCashFlow: isNaN(monthlyCashFlow) ? 0 : monthlyCashFlow,
      annualCashFlow: isNaN(annualCashFlow) ? 0 : annualCashFlow,
      cashOnCashReturn: isNaN(cashOnCashReturn)
        ? 0
        : cashOnCashReturn,
      debtServiceCoverageRatio: isNaN(debtServiceCoverageRatio)
        ? 0
        : debtServiceCoverageRatio,
    }
  }

  const handleUnitMixChange = (index, field, value) => {
    const newUnitMix = { ...unitMix }
    newUnitMix[mode][index] = {
      ...newUnitMix[mode][index],
      [field]: Number(value),
    }
    setUnitMix(newUnitMix)
  }

  const handleOperationalMetricsChange = (field, value) => {
    setOperationalMetrics((prev) => ({
      ...prev,
      [mode]: {
        ...prev[mode],
        [field]: Number(value),
      },
    }))
  }

  const handleDealStructureChange = (field, value) => {
    setDealStructure((prev) => ({ ...prev, [field]: Number(value) }))
  }

  const metrics = calculateFinancialMetrics()

  const calculate10YearProjection = (projectionMode = mode) => {
    const projections = []
    const currentMetrics = calculateFinancialMetrics(projectionMode)

    // Calculate yearly debt payments (constant for all years except year 0)
    const yearlyInterest =
      loanAmount * (dealStructure.interestRate / 100)
    const monthlyInterestRate =
      (dealStructure.interestRate || 0) / 1200
    const numberOfPayments = (dealStructure.mortgageLength || 0) * 12
    const monthlyPayment =
      numberOfPayments === 0
        ? 0
        : (loanAmount *
            (monthlyInterestRate *
              Math.pow(1 + monthlyInterestRate, numberOfPayments))) /
          (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)

    // Calculate first year principal payments
    let remainingBalance = loanAmount
    let yearlyPrincipal = 0
    for (let month = 1; month <= 12; month++) {
      const monthlyInterest = remainingBalance * monthlyInterestRate
      const monthlyPrincipal = monthlyPayment - monthlyInterest
      yearlyPrincipal += monthlyPrincipal
      remainingBalance -= monthlyPrincipal
    }

    const yearlyDebtPayments = yearlyPrincipal + yearlyInterest

    // Initial year values - use the selected mode's metrics
    let currentRevenue =
      currentMetrics.grossAnnualIncome - currentMetrics.occupancyLoss
    let currentExpenses = currentMetrics.operatingExpenses

    for (let year = 0; year <= 10; year++) {
      if (year === 0) {
        projections.push({
          year: 'Investment',
          totalDebtService: 0,
          revenue: 0,
          expenses: 0,
          netCashFlow: -dealStructure.downPayment,
          cashOnCash: 0,
          cumulativeCashOnCash: 0,
        })
        continue
      }

      // For all years after year 0, debt service is constant
      const totalDebtService = yearlyDebtPayments

      // Calculate values with annual increases
      if (year > 1) {
        currentRevenue *= 1 + annualRateIncrease / 100
        currentExpenses *= 1 + annualRateIncrease / 100
      }

      const netCashFlow =
        currentRevenue - currentExpenses - totalDebtService
      const cashOnCash =
        (netCashFlow / dealStructure.downPayment) * 100
      const previousCumulative =
        projections[year - 1].cumulativeCashOnCash

      projections.push({
        year: `Year ${year}`,
        totalDebtService,
        revenue: currentRevenue,
        expenses: currentExpenses,
        netCashFlow,
        cashOnCash,
        cumulativeCashOnCash: previousCumulative + cashOnCash,
      })
    }

    return projections
  }

  const ProjectionDialog = () => {
    const [projectionMode, setProjectionMode] = useState(mode)
    const projections = calculate10YearProjection(projectionMode)

    return (
      <Dialog
        open={projectionDialogOpen}
        onClose={() => setProjectionDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              10 Year Projection
              <ToggleButtonGroup
                value={projectionMode}
                exclusive
                onChange={(e, newMode) => {
                  if (newMode !== null) {
                    setProjectionMode(newMode)
                  }
                }}
                size="small"
              >
                <ToggleButton value="current">Current</ToggleButton>
                <ToggleButton value="stabilized">
                  Stabilized
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <TextField
              label="Annual Rate Increase (%)"
              type="number"
              value={annualRateIncrease}
              onChange={(e) =>
                setAnnualRateIncrease(Number(e.target.value))
              }
              size="small"
              sx={{ width: 200 }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Total Debt Service</TableCell>
                  <TableCell>Revenue (less vacancy)</TableCell>
                  <TableCell>Expenses</TableCell>
                  <TableCell>Net Cash Flow</TableCell>
                  <TableCell>Cash on Cash Return</TableCell>
                  <TableCell>Cumulative Cash on Cash</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projections.map((row) => (
                  <TableRow key={row.year}>
                    <TableCell>{row.year}</TableCell>
                    <TableCell>
                      {currencyFormat.format(row.totalDebtService)}
                    </TableCell>
                    <TableCell>
                      {currencyFormat.format(row.revenue)}
                    </TableCell>
                    <TableCell>
                      {currencyFormat.format(row.expenses)}
                    </TableCell>
                    <TableCell>
                      {currencyFormat.format(row.netCashFlow)}
                    </TableCell>
                    <TableCell>
                      {percentageFormat.format(row.cashOnCash / 100)}
                    </TableCell>
                    <TableCell>
                      {percentageFormat.format(
                        row.cumulativeCashOnCash / 100,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant="h4">
          Self Storage Deal Analysis
        </Typography>
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="analysis mode"
        >
          <ToggleButton
            value="current"
            aria-label="current operations"
          >
            Current
          </ToggleButton>
          <ToggleButton
            value="stabilized"
            aria-label="stabilized operations"
          >
            Stabilized
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Business Operations Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h5">
            {mode === 'current'
              ? 'Current Operations'
              : 'Stabilized Operations'}
          </Typography>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddRow}
            variant="contained"
            color="primary"
          >
            Add Unit Type
          </Button>
        </Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Length</StyledTableCell>
                <StyledTableCell>Width</StyledTableCell>
                <StyledTableCell># of units</StyledTableCell>
                <StyledTableCell>Monthly Rate</StyledTableCell>
                <StyledTableCell>Sqft per unit</StyledTableCell>
                <StyledTableCell>Total Rentable Sqft</StyledTableCell>
                <StyledTableCell>
                  Monthly Income Per Unit
                </StyledTableCell>
                <StyledTableCell>
                  Annual Income Per Unit
                </StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unitMix[mode].map((unit, index) => {
                const calculatedUnit = calculateUnitMetrics(unit)
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        value={unit.length}
                        onChange={(e) =>
                          handleUnitMixChange(
                            index,
                            'length',
                            e.target.value,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        value={unit.width}
                        onChange={(e) =>
                          handleUnitMixChange(
                            index,
                            'width',
                            e.target.value,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        value={unit.units}
                        onChange={(e) =>
                          handleUnitMixChange(
                            index,
                            'units',
                            e.target.value,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        value={unit.monthlyRate}
                        onChange={(e) =>
                          handleUnitMixChange(
                            index,
                            'monthlyRate',
                            e.target.value,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {numberFormat.format(
                        calculatedUnit.sqftPerUnit,
                      )}
                    </TableCell>
                    <TableCell>
                      {numberFormat.format(
                        calculatedUnit.totalRentableSqft,
                      )}
                    </TableCell>
                    <TableCell>
                      {currencyFormat.format(
                        calculatedUnit.monthlyIncomePerUnit,
                      )}
                    </TableCell>
                    <TableCell>
                      {currencyFormat.format(
                        calculatedUnit.annualIncomePerUnit,
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveRow(index)}
                        disabled={unitMix[mode].length === 1}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography>
                Total Storage Units:{' '}
                {numberFormat.format(metrics.totalUnits)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Net Rentable Sq Ft:{' '}
                {numberFormat.format(metrics.netRentableSqft)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Gross Annual Potential Income:{' '}
                {currencyFormat.format(metrics.grossAnnualIncome)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Current Occupancy (%)"
                type="number"
                value={operationalMetrics[mode].currentOccupancy}
                onChange={(e) =>
                  handleOperationalMetricsChange(
                    'currentOccupancy',
                    e.target.value,
                  )
                }
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Occupancy Loss (Yearly):{' '}
                {currencyFormat.format(metrics.occupancyLoss)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Expense Ratio (%)"
                type="number"
                value={operationalMetrics[mode].expenseRatio}
                onChange={(e) =>
                  handleOperationalMetricsChange(
                    'expenseRatio',
                    e.target.value,
                  )
                }
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Operating Expenses (Yearly):{' '}
                {currencyFormat.format(metrics.operatingExpenses)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="primary"
                sx={{ mt: 1 }}
              >
                Annual Net Operating Income:{' '}
                {currencyFormat.format(metrics.netOperatingIncome)}
              </Typography>
            </Grid>
            {mode === 'stabilized' && (
              <Grid item xs={12}>
                <Typography color="primary">
                  Annual Rate Increase: {annualRateIncrease}% (Used in
                  10 year projection)
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      {/* Deal Structure Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h5">Deal Structure</Typography>
          <Button
            startIcon={<TimelineIcon />}
            onClick={() => setProjectionDialogOpen(true)}
            variant="outlined"
            color="primary"
          >
            View 10 Year Projection
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Purchase Price"
              type="number"
              value={dealStructure.purchasePrice}
              onChange={(e) =>
                handleDealStructureChange(
                  'purchasePrice',
                  e.target.value,
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Capital Expenditures"
              type="number"
              value={dealStructure.capitalExpenditures}
              onChange={(e) =>
                handleDealStructureChange(
                  'capitalExpenditures',
                  e.target.value,
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Closing Costs"
              type="number"
              value={dealStructure.closingCosts}
              onChange={(e) =>
                handleDealStructureChange(
                  'closingCosts',
                  e.target.value,
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              Total Costs: {currencyFormat.format(totalCosts)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Down Payment"
              type="number"
              value={dealStructure.downPayment}
              onChange={(e) =>
                handleDealStructureChange(
                  'downPayment',
                  e.target.value,
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              Loan Amount: {currencyFormat.format(loanAmount)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Interest Rate (%)"
              type="number"
              value={dealStructure.interestRate}
              onChange={(e) =>
                handleDealStructureChange(
                  'interestRate',
                  e.target.value,
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Length of mortgage (years)"
              type="number"
              value={dealStructure.mortgageLength}
              onChange={(e) =>
                handleDealStructureChange(
                  'mortgageLength',
                  e.target.value,
                )
              }
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Return Metrics Section */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Return Metrics (
          {mode === 'current' ? 'Current' : 'Stabilized'})
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography>
              Monthly Cash Flow:{' '}
              {currencyFormat.format(metrics.monthlyCashFlow)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              Annual Cash Flow:{' '}
              {currencyFormat.format(metrics.annualCashFlow)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              Cash on Cash Return:{' '}
              {percentageFormat.format(
                metrics.cashOnCashReturn / 100,
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              Debt Service Coverage Ratio:{' '}
              {numberFormat.format(metrics.debtServiceCoverageRatio)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <ProjectionDialog />
    </Box>
  )
}

export default DealAnalysis
