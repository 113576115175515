/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-09 15:38:52
 * @ Description: Dialog to list a property on one or more marketplaces
 */

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@mui/material/Autocomplete'

import map from 'lodash/map'
import find from 'lodash/find'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import assign from 'lodash/assign'
import filter from 'lodash/filter'
import get from 'lodash/get'
import set from 'lodash/set'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'
import sum from 'lodash/sum'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import join from 'lodash/join'
import split from 'lodash/split'
import startsWith from 'lodash/startsWith'

import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  MenuItem,
  Step,
  StepConnector,
  Stepper,
  StepLabel,
  TextField,
  Typography,
  Chip,
  List,
  ListItem,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@mui/material'

import {
  ArrowForward,
  ContactSupportOutlined,
} from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { NumericFormat } from 'react-number-format'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { UPDATE_PROPERTIES } from '@/housefolios-components/Portfolio/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
// import { removeListener } from 'process'
import ImageUploader from '@/housefolios-components/PropertyContainer/CenterPage/ManageImages/ImageUploader'
import { STATES } from '@/constants/states'
import { groupBy, includes, uniq, unset } from 'lodash'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import RemoveOption from '@/housefolios-components/Dialogs/RemoveOption'
import AddOption from '@/housefolios-components/Dialogs/AddOption'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { getProformaLink } from '@/utils/proforma'
import {
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'
import { statesShort } from '@/utils/states'

// const primaryFields = [
//   { label: 'IRR', value: '', target: 'acquisition.analytics.IRR' },
//   { label: 'Yield-on-Cost', value: '', target: 'acquisition.analytics.capRate' },
//   { label: 'Cash on Cash', value: '', target: 'acquisition.analytics.cashOnCashReturn' },
//   { label: 'Net Monthly Cashflow', value: '', target: 'acquisition.analytics.cashFlow' },
//   { label: 'Monthly Rent', value: '', target: 'acquisition.analytics.rent' },
//   { label: 'Taxes', value: '', target: 'acquisition.analysis.propertyTaxes' },
//   { label: 'Rehab Est.', value: '', target: 'acquisition.analysis.rehabCost' },
//   { label: 'Loan amnt', value: '', target: 'acquisition.analytics.loanAmount' },
//   { label: 'Debt coverage ratio', value: '', target: 'acquisition.analytics.debtCoverageRatio' },
//   { label: 'Monthly Mortgage', value: '', target: 'acquisition.analytics.mp_monthly' },
//   { label: 'All-in cost', value: '', target: 'acquisition.analytics.allInCost' },
//   { label: 'Total out of pocket', value: '', target: 'acquisition.analytics.totalOutOfPocket' },
//   { label: 'Prop. Insurance', value: '', target: 'assumptions.propertyInsurance' },
//   { label: 'Prop mgmt fee', value: '', target: 'assumptions.propertyManagement' },
//   { label: 'Maintenance', value: '', target: 'assumptions.maintenance' },
//   { label: 'Vacancy', value: '', target: 'assumptions.vacancy' },
//   { label: 'Utilities/mo', value: '', target: 'assumptions.utilities' },
//   { label: 'Capex', value: '', target: 'assumptions.monthlyReserves' },
//   { label: 'Cash purchase', value: '', target: 'assumptions.allCashPurchase' },
//   { label: 'Down payment', value: '', target: 'acquisition.analytics.downPaymentTotal' },
//   { label: 'Interest rate', value: '', target: 'assumptions.rate' },
//   { label: 'Loan term', value: '', target: 'assumptions.term' },
//   { label: 'Points', value: '', target: 'assumptions.points' },
//   { label: 'Interst only loan', value: '', target: 'assumptions.interestOnlyLoan' },
//   { label: 'Closing cost', value: '', target: 'assumptions.closingCost' },
//   { label: 'Rental holding period (years)', value: '', target: 'assumptions.rentalHoldingPeriod' },
// ]
const buyAndHoldAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.buyHold.arvTotalBuyHold',
  },
  {
    label: 'IRR',
    value: '',
    target: 'acquisition.analytics.buyHold.IRR',
    divisor: 0.01,
  },
  {
    label: 'Yield-on-Cost',
    value: '',
    target: 'acquisition.analytics.buyHold.capRate',
    divisor: 0.01,
  },
  {
    label: 'Cash on Cash',
    value: '',
    target: 'acquisition.analytics.buyHold.cashOnCashReturn',
    divisor: 0.01,
  },
  {
    label: 'Net Monthly Cashflow',
    value: '',
    target: 'acquisition.analytics.buyHold.cashFlow',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    value: '',
    target: 'acquisition.analytics.annualRent',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    value: '',
    target: 'acquisition.analysis.propertyTaxes',
  },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.buyHold.corTotal',
  },
]
const fixAndFlipAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.fixFlip.arvTotalFixFlip',
  },
  {
    label: 'Net Profit',
    value: '',
    target: 'acquisition.analytics.fixFlip.saleProceeds',
  },
  {
    label: 'Net ROI',
    value: '',
    target: 'acquisition.analytics.fixFlip.netROIFixFlip',
    divisor: 0.01,
  },
  {
    label: 'All-in Cost to ARV',
    value: '',
    target: 'acquisition.analytics.fixFlip.percentageOfARV',
    divisor: 0.01,
  },
  {
    label: 'Taxes',
    value: '',
    target: 'acquisition.analysis.propertyTaxes',
  },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.fixFlip.corTotalFixFlip',
  },
]
const turnkeyAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.turnkey.arvTotalTurnkey',
  },
  {
    label: 'IRR',
    value: '',
    target: 'acquisition.analytics.turnkey.IRRTurnkey',
    divisor: 0.01,
  },
  {
    label: 'Cap Rate',
    value: '',
    target: 'acquisition.analytics.turnkey.capRateTurnkey',
    divisor: 0.01,
  },
  {
    label: 'Cash on Cash',
    value: '',
    target: 'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
    divisor: 0.01,
  },
  {
    label: 'Net Monthly Cashflow',
    value: '',
    target: 'acquisition.analytics.turnkey.cashFlowTurnkey',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    value: '',
    target: 'acquisition.analytics.annualRent',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    value: '',
    target: 'acquisition.analysis.propertyTaxes',
  },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.turnkey.corTotalTurnkey',
  },
]
const brrrrAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.BRRRR.arvTotalBRRRR',
  },
  {
    label: 'IRR',
    value: '',
    target: 'acquisition.analytics.BRRRR.IRRBRRRR',
    divisor: 0.01,
  },
  {
    label: 'Yield-on-Cost',
    value: '',
    target: 'acquisition.analytics.BRRRR.capRateBRRRR',
    divisor: 0.01,
  },
  {
    label: 'Cash on Cash',
    value: '',
    target: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRR',
    divisor: 0.01,
  },
  {
    label: 'Net Monthly Cashflow',
    value: '',
    target: 'acquisition.analytics.BRRRR.cashFlowBRRRR',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    value: '',
    target: 'acquisition.analytics.annualRent',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    value: '',
    target: 'acquisition.analysis.propertyTaxes',
  },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.BRRRR.corTotalBRRRR',
  },
]
const shortTermRentalAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.STR.arvTotalSTR',
  },
  {
    label: 'IRR',
    value: '',
    target: 'acquisition.analytics.STR.IRRSTR',
    divisor: 0.01,
  },
  {
    label: 'Yield-on-Cost',
    value: '',
    target: 'acquisition.analytics.STR.capRateSTR',
    divisor: 0.01,
  },
  {
    label: 'Cash on Cash',
    value: '',
    target: 'acquisition.analytics.STR.cashOnCashReturnSTR',
    divisor: 0.01,
  },
  {
    label: 'Net Monthly Cashflow',
    value: '',
    target: 'acquisition.analytics.STR.cashFlowSTR',
    divisor: 12,
  },
  {
    label: 'Daily Rent',
    value: '',
    target: 'acquisition.analytics.STR.annualRentSTR',
    divisor: 365,
    sum: true,
  },
  {
    label: 'Taxes',
    value: '',
    target: 'acquisition.analysis.propertyTaxes',
  },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.STR.corTotalSTR',
  },
]
const buildToRentAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.B2R.arvTotalB2R',
  },
  {
    label: 'IRR',
    value: '',
    target: 'acquisition.analytics.B2R.IRRB2R',
    divisor: 0.01,
  },
  {
    label: 'Yield-on-Cost',
    value: '',
    target: 'acquisition.analytics.B2R.capRateB2R',
    divisor: 0.01,
  },
  {
    label: 'Cash on Cash',
    value: '',
    target: 'acquisition.analytics.B2R.cashOnCashReturnB2R',
    divisor: 0.01,
  },
  {
    label: 'Net Monthly Cashflow',
    value: '',
    target: 'acquisition.analytics.B2R.cashFlowB2R',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    value: '',
    target: 'acquisition.analytics.annualRent',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    value: '',
    target: 'acquisition.analysis.propertyTaxes',
  },
]
const wholesaleAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.wholesale.arvTotalWholesale',
  },
  // { label: 'IRR', value: '', target: 'acquisition.analytics.wholesale.IRR', divisor: 0.01 },
  {
    label: 'Net Profit',
    value: '',
    target: 'acquisition.analytics.wholesale.saleProceedsWholesale',
  },
  {
    label: 'Buyer Profit',
    value: '',
    target: 'acquisition.analytics.wholesale.buyerProfitTotal',
  },
  {
    label: 'Closing Cost',
    value: '',
    target:
      'acquisition.analytics.wholesale.closingCostWholesaleDollar',
  },
  {
    label: 'Assignment Fee',
    value: '',
    target: 'acquisition.analysis.assignmentFeeDollar',
  },
  // { label: 'Taxes', value: '', target: 'acquisition.analysis.propertyTaxes' },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.wholesale.rehabDollar',
  },
]
const borAnalysis = [
  {
    label: 'ARV',
    value: '',
    target: 'acquisition.analytics.BOR.arvTotalBOR',
  },
  {
    label: 'IRR',
    value: '',
    target: 'acquisition.analytics.BOR.IRRBOR',
    divisor: 0.01,
  },
  {
    label: 'Average Annual Return',
    value: '',
    target: 'acquisition.analytics.BOR.averageAnnualReturnBOR',
    divisor: 0.01,
  },
  // { label: 'Cash on Cash', value: '', target: 'acquisition.analytics.cashOnCashReturn', divisor: 0.01 },
  // { label: 'Net Monthly Cashflow', value: '', target: 'acquisition.analytics.cashFlow', divisor: 12 },
  // { label: 'Monthly Rent', value: '', target: 'acquisition.analytics.annualRent', divisor: 12, sum: true},
  // { label: 'Taxes', value: '', target: 'acquisition.analysis.propertyTaxes' },
  {
    label: 'Rehab Est.',
    value: '',
    target: 'acquisition.analytics.BOR.corTotalBOR',
  },
]
const expenseAssumptions = [
  {
    label: 'Prop. Insurance',
    value: '',
    target: 'assumptions.propertyInsurance',
  },
  {
    label: 'Prop mgmt fee',
    value: '',
    target: 'assumptions.propertyManagement',
  },
  {
    label: 'Maintenance',
    value: '',
    target: 'assumptions.maintenance',
  },
  { label: 'Vacancy', value: '', target: 'assumptions.vacancy[0]' },
  {
    label: 'Utilities/mo',
    value: '',
    target: 'assumptions.utilities',
  },
  {
    label: 'Capex',
    value: '',
    target: 'assumptions.monthlyReserves',
  },
]
const financingAssumptions = [
  //{ label: 'Cash purchase', value: false, target: 'assumptions.allCashPurchase' },
  {
    label: 'Down payment',
    value: '',
    target: 'acquisition.analytics.buyHold.downPaymentTotal',
  },
  { label: 'Interest rate', value: '', target: 'assumptions.rate' },
  { label: 'Loan term', value: '', target: 'assumptions.term' },
  { label: 'Points', value: '', target: 'assumptions.points' },
  //{ label: 'Interest only loan', value: false, target: 'assumptions.interestOnlyLoan' },
]
const otherAssumptions = [
  {
    label: 'Closing cost',
    value: '',
    target: 'assumptions.closingCost',
  },
  {
    label: 'Rental holding period (years)',
    value: '',
    target: 'assumptions.rentalHoldingPeriod',
  },
]

// function StepIcon(props) {
//   const { active, completed } = props;

//   const icons = {
//     1: <CloudUploadIcon />,
//     2: <ViewColumnIcon />,
//     3: <FolderIcon />,
//   };

//   return (
//     <div
//       className={clsx("d-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded", {
//         "d-80 bg-primary text-white shadow-primary-sm": active,
//         "d-50 bg-success text-white shadow-success-sm": completed,
//       })}
//     >
//       {completed ? <Check className="completed" /> : icons[String(props.icon)]}

//     </div>
//   );
// }

// StepIcon.propTypes = {
//   active: PropTypes.bool,
//   completed: PropTypes.bool,
//   icon: PropTypes.node,
// };

function getSteps(bulk) {
  return bulk
    ? ['Details', 'Fields']
    : ['Details', 'Fields', 'Values']
}

function OldListOnMarketplaceComponent(props) {
  const { actionsButton, property = {}, bulk } = props

  const [activeStep, setActiveStep] = useState(0)

  const [activeUnit, setActiveUnit] = useState(0)

  const steps = getSteps(bulk)

  const [propertyInput, setPropertyInput] = useState({
    city: property.city,
    state: property.state,
    zip: property.zip,
    beds: !isNil(property.beds) ? [...property.beds] : [0],
    baths: !isNil(property.baths) ? [...property.baths] : [0],
    sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
    year: property.year,
    analysisInput: {
      resellPrice: get(property, 'acquisition.analysis.resellPrice'),
      resellPriceB2R: get(
        property,
        'acquisition.analysis.resellPriceB2R',
      ),
      resellPriceBRRRR: get(
        property,
        'acquisition.analysis.resellPriceBRRRR',
      ),
      resellPriceFixFlip: get(
        property,
        'acquisition.analysis.resellPriceFixFlip',
      ),
      resellPriceTurnkey: get(
        property,
        'acquisition.analysis.resellPriceTurnkey',
      ),
      resellPriceSTR: get(
        property,
        'acquisition.analysis.resellPriceSTR',
      ),
      resellPriceWholesale: get(
        property,
        'acquisition.analysis.resellPriceWholesale',
      ),
    },
  })

  //Made options to not include Housefolios based on conditional
  // const options = props.session.me.activeOrg
  //   .canListOnHousefoliosMarketplace
  //   ? concat(
  //       [{ name: 'Housefolios', url: 'housefolios' }],
  //       props.session.me.activeOrg.marketPlaces,
  //       filter(
  //         props.session.me.activeOrg.sharedMarketplaces,
  //         (marketplace) =>
  //           startsWith(marketplace.sharePermission, 'canListDelist'),
  //       ),
  //     )
  //   : concat(
  //       props.session.me.activeOrg.marketPlaces,
  //       filter(
  //         props.session.me.activeOrg.sharedMarketplaces,
  //         (marketplace) =>
  //           startsWith(marketplace.sharePermission, 'canListDelist'),
  //       ),
  //     )
  const options =
    props.session.me.activeOrg?.isAffiliate?.memberValue === 'pep'
      ? concat(
          [{ name: 'PEP', url: 'pep' }],
          props.session.me.activeOrg.marketPlaces,
          filter(
            props.session.me.activeOrg.sharedMarketplaces,
            (marketplace) =>
              startsWith(
                marketplace.sharePermission,
                'canListDelist',
              ),
          ),
        )
      : concat(
          props.session.me.activeOrg.marketPlaces,
          filter(
            props.session.me.activeOrg.sharedMarketplaces,
            (marketplace) =>
              startsWith(
                marketplace.sharePermission,
                'canListDelist',
              ),
          ),
        )

  const extraOptions = props.session.me.activeOrg.marketingExtraTags
  const [status, setStatus] = useState(bulk ? null : property.status)
  const [strategy, setStrategy] = useState(
    bulk ? null : property.strategy,
  )
  const [proformaType, setProformaType] = useState(
    bulk
      ? null
      : property.strategy === 'Fix & Flip' ||
          property.strategy === 'Turnkey End Buyer' ||
          property.strategy === 'Short Term Rental' ||
          property.strategy === 'Wholesale' ||
          property.strategy === 'AstroFlip'
        ? property.strategy
        : 'Buy & Hold',
  )
  const [marketplaces, setMarketplaces] = useState(
    bulk
      ? []
      : !property.marketplaces ||
          isEmpty(property.marketplaces.marketplaceName)
        ? []
        : filter(property.marketplaces.marketplaceName, (url) =>
            find(options, (option) => option.url === url),
          ),
  )
  const [extras, setExtras] = useState(
    bulk
      ? []
      : !property.marketplaces ||
          isEmpty(property.marketplaces.extraTags)
        ? []
        : property.marketplaces.extraTags,
  )
  const [hide, setHide] = useState(
    bulk ? false : property.hideAddress,
  )
  const [hideDays, setHideDays] = useState(
    bulk ? true : property.hideDaysOnMarketplace,
  )

  const [selectedPrimaryFields, setSelectedPrimaryFields] = useState(
    [],
  )
  const [selectedAnalysis, setSelectedAnalysis] = useState([])
  const [selectedExpenseAssumptions, setSelectedExpenseAssumptions] =
    useState([])
  const [
    selectedFinancingAssumptions,
    setSelectedFinancingAssumptions,
  ] = useState([])
  const [selectedOtherAssumptions, setSelectedOtherAssumptions] =
    useState([])

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    {
      general: {
        strategies:
          property && property.portfolio
            ? property.portfolio.strategies
            : [],
      },
    },
  )
  const strategies =
    property && !isEmpty(property.strategies)
      ? property.strategies
      : filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        )

  const analysisFields =
    strategy === 'BOR'
      ? borAnalysis
      : strategy === 'Build to Rent'
        ? buildToRentAnalysis
        : strategy === 'Wholesale'
          ? wholesaleAnalysis
          : strategy === 'Short Term Rental'
            ? shortTermRentalAnalysis
            : strategy === 'BRRRR'
              ? brrrrAnalysis
              : strategy === 'Turnkey End Buyer'
                ? turnkeyAnalysis
                : strategy === 'Fix & Flip'
                  ? fixAndFlipAnalysis
                  : buyAndHoldAnalysis

  const expenseAssumptionFields = map(expenseAssumptions, (field) => {
    const strategyValue =
      strategy === 'BOR'
        ? 'BOR'
        : strategy === 'Build to Rent'
          ? 'B2R'
          : strategy === 'Wholesale'
            ? 'Wholesale'
            : strategy === 'Short Term Rental'
              ? 'STR'
              : strategy === 'BRRRR'
                ? 'BRRRR'
                : strategy === 'Turnkey End Buyer'
                  ? 'Turnkey'
                  : strategy === 'Fix & Flip'
                    ? 'FixFlip'
                    : ''
    const newTarget =
      field.target === 'assumptions.vacancy[0]'
        ? 'assumptions.vacancy' + strategyValue + '[0]'
        : field.target + strategyValue
    return {
      ...field,
      target: !isNil(get(property, newTarget))
        ? newTarget
        : field.target,
    }
  })

  const financingAssumptionFields = map(
    financingAssumptions,
    (field) => {
      const strategyValue =
        strategy === 'BOR'
          ? 'BOR'
          : strategy === 'Build to Rent'
            ? 'B2R'
            : strategy === 'Wholesale'
              ? 'Wholesale'
              : strategy === 'Short Term Rental'
                ? 'STR'
                : strategy === 'BRRRR'
                  ? 'BRRRR'
                  : strategy === 'Turnkey End Buyer'
                    ? 'Turnkey'
                    : strategy === 'Fix & Flip'
                      ? 'FixFlip'
                      : ''
      const newTarget = field.target + strategyValue
      return {
        ...field,
        target: !isNil(get(property, newTarget))
          ? newTarget
          : field.target,
      }
    },
  )

  const otherAssumptionFields = map(otherAssumptions, (field) => {
    const strategyValue =
      strategy === 'BOR'
        ? 'BOR'
        : strategy === 'Build to Rent'
          ? 'B2R'
          : strategy === 'Wholesale'
            ? 'Wholesale'
            : strategy === 'Short Term Rental'
              ? 'STR'
              : strategy === 'BRRRR'
                ? 'BRRRR'
                : strategy === 'Turnkey End Buyer'
                  ? 'Turnkey'
                  : strategy === 'Fix & Flip'
                    ? 'FixFlip'
                    : ''
    const newTarget = field.target + strategyValue
    return {
      ...field,
      target: !isNil(get(property, newTarget))
        ? newTarget
        : field.target,
    }
  })

  const primaryFields = concat(
    analysisFields,
    expenseAssumptionFields,
    financingAssumptionFields,
    otherAssumptionFields,
  )

  useEffect(() => {
    const options =
      props.session.me.activeOrg?.isAffiliate?.memberValue === 'pep'
        ? concat(
            [{ name: 'PEP', url: 'pep' }],
            props.session.me.activeOrg.marketPlaces,
            filter(
              props.session.me.activeOrg.sharedMarketplaces,
              (marketplace) =>
                startsWith(
                  marketplace.sharePermission,
                  'canListDelist',
                ),
            ),
          )
        : concat(
            props.session.me.activeOrg.marketPlaces,
            filter(
              props.session.me.activeOrg.sharedMarketplaces,
              (marketplace) =>
                startsWith(
                  marketplace.sharePermission,
                  'canListDelist',
                ),
            ),
          )
    setStatus(bulk ? null : property.status)
    setStrategy(bulk ? null : property.strategy)
    setMarketplaces(
      bulk
        ? []
        : !property.marketplaces ||
            isEmpty(property.marketplaces.marketplaceName)
          ? []
          : filter(property.marketplaces.marketplaceName, (url) =>
              find(options, (option) => option.url === url),
            ),
    )
    setExtras(
      bulk
        ? []
        : !property.marketplaces ||
            isEmpty(property.marketplaces.extraTags)
          ? []
          : property.marketplaces.extraTags,
    )
  }, [
    bulk,
    props.session.me.activeOrg.canListOnHousefoliosMarketplace,
    props.session.me.activeOrg.marketPlaces,
    props.session.me.activeOrg.sharedMarketplaces,
  ]) //BUG COMMENT: Function used to be: [property, bulk, options]). We removed options as it caused the page to keep rerendering for canListOnHousefoliosMarketplace. Also removed property as it would constantly retrigger the useEffect May cause warning in Console.

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      const { marketPlaceFields } = props.session.me.activeOrg
      if (marketPlaceFields[strategy]) {
        if (
          !isEmpty(property) &&
          !findResellPrice(property, strategy)
        ) {
          handleCloseListOnMarketplace()
          handleOpenDisabled()
          return
        } else {
          setSelectedPrimaryFields(
            compact(
              map(
                marketPlaceFields[strategy].selectedPrimaryFields,
                (field) =>
                  find(
                    primaryFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
          )
          setSelectedAnalysis(
            compact(
              map(
                marketPlaceFields[strategy].selectedAnalysis,
                (field) =>
                  find(
                    analysisFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
          )
          setSelectedExpenseAssumptions(
            compact(
              map(
                marketPlaceFields[strategy]
                  .selectedExpenseAssumptions,
                (field) =>
                  find(
                    expenseAssumptionFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
          )
          setSelectedFinancingAssumptions(
            compact(
              map(
                marketPlaceFields[strategy]
                  .selectedFinancingAssumptions,
                (field) =>
                  find(
                    financingAssumptionFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
          )
          setSelectedOtherAssumptions(
            compact(
              map(
                marketPlaceFields[strategy].selectedOtherAssumptions,
                (field) =>
                  find(
                    otherAssumptionFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
          )
        }
      } else {
        setSelectedPrimaryFields([])
        setSelectedAnalysis([])
        setSelectedExpenseAssumptions([])
        setSelectedFinancingAssumptions([])
        setSelectedOtherAssumptions([])
      }
    }
    if (activeStep === 1) {
      setSelectedPrimaryFields(
        map(selectedPrimaryFields, (field) => {
          field.sum
            ? (field.value =
                sum(get(property, field.target)) /
                (field.divisor || 1))
            : (field.value =
                get(property, field.target) / (field.divisor || 1))
          return field
        }),
      )
      setSelectedAnalysis(
        map(selectedAnalysis, (field) => {
          field.sum
            ? (field.value =
                sum(get(property, field.target)) /
                (field.divisor || 1))
            : (field.value =
                get(property, field.target) / (field.divisor || 1))
          return field
        }),
      )
      setSelectedExpenseAssumptions(
        map(selectedExpenseAssumptions, (field) => {
          field.sum
            ? (field.value =
                sum(get(property, field.target)) /
                (field.divisor || 1))
            : (field.value =
                get(property, field.target) / (field.divisor || 1))
          return field
        }),
      )
      setSelectedFinancingAssumptions(
        map(selectedFinancingAssumptions, (field) => {
          field.sum
            ? (field.value =
                sum(get(property, field.target)) /
                (field.divisor || 1))
            : (field.value =
                get(property, field.target) / (field.divisor || 1))
          return field
        }),
      )
      setSelectedOtherAssumptions(
        map(selectedOtherAssumptions, (field) => {
          field.sum
            ? (field.value =
                sum(get(property, field.target)) /
                (field.divisor || 1))
            : (field.value =
                get(property, field.target) / (field.divisor || 1))
          return field
        }),
      )
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const [openListOnMarketplace, setOpenListOnMarketplace] =
    useState(false)
  const handleOpenListOnMarketplace = () => {
    setOpenListOnMarketplace(true)
  }
  const handleCloseListOnMarketplace = () => {
    setLoading(false)
    setOpenListOnMarketplace(false)
    setActiveStep(0)
  }

  const onChangeProperty = (event, source = null, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newPropertyInput = cloneDeep(propertyInput)
    let newValue = value
    // let newSource = null
    if (type === 'checkbox' && name !== 'owned')
      newValue = !get(newPropertyInput, name)
    if (type === 'number' && name !== 'zip') newValue = Number(value)
    if (includes(name, 'bed')) newValue = parseInt(newValue)
    if ((type === 'number' || type === 'dollar') && newValue < 0)
      newValue = 0
    set(newPropertyInput, name, newValue)
    setPropertyInput(newPropertyInput)
  }

  const [openDisabled, setOpenDisabled] = useState(false)
  const handleOpenDisabled = () => {
    setOpenDisabled(true)
    setPropertyInput({
      city: property.city,
      state: property.state,
      zip: property.zip,
      beds: !isNil(property.beds) ? [...property.beds] : [0],
      baths: !isNil(property.baths) ? [...property.baths] : [0],
      sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
      year: property.year,
      analysisInput: {
        resellPrice: get(
          property,
          'acquisition.analysis.resellPrice',
        ),
        resellPriceB2R: get(
          property,
          'acquisition.analysis.resellPriceB2R',
        ),
        resellPriceBRRRR: get(
          property,
          'acquisition.analysis.resellPriceBRRRR',
        ),
        resellPriceFixFlip: get(
          property,
          'acquisition.analysis.resellPriceFixFlip',
        ),
        resellPriceTurnkey: get(
          property,
          'acquisition.analysis.resellPriceTurnkey',
        ),
        resellPriceSTR: get(
          property,
          'acquisition.analysis.resellPriceSTR',
        ),
        resellPriceWholesale: get(
          property,
          'acquisition.analysis.resellPriceWholesale',
        ),
      },
    })
  }
  const handleCloseDisabled = () => {
    setOpenDisabled(false)
  }
  const hostname = import.meta.env.VITE_HOSTNAME

  //const [name, setName] = useState(portfolio.name)
  // const [description, setDescription] = useState(portfolio.description)

  const [loading, setLoading] = useState(false)

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateProperties] = useMutation(UPDATE_PROPERTIES)

  const accessableMarketplaces = concat(
    props.session.me.activeOrg.marketPlaces,
    props.session.me.activeOrg?.isAffiliate?.memberValue === 'pep'
      ? [{ name: 'PEP', url: 'pep' }]
      : [],
  )

  const onSubmit = () => {
    setLoading(true)
    updateProperty({
      variables: {
        id: property._id,
        propertyInput,
      },
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          {
            query: GET_PROPERTY,
            variables: { id: props.property._id },
          },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     orgIds: [props.session.me.activeOrg._id],
          //     approved: true,
          //   },
          // },
        ],
        map(accessableMarketplaces, (marketplace) => {
          return {
            query: GET_MARKETPLACES,
            variables: {
              marketplaces: [marketplace.url],
              approved: true,
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          }
        }),
        map(
          props.session.me.activeOrg.sharedMarketplaces,
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
      ),
    })
      .then((data) => {
        setLoading(false)
        handleCloseDisabled()
        handleOpenListOnMarketplace()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const findResellPrice = (property, marketplaceStrategy = null) => {
    const strategy = marketplaceStrategy
      ? marketplaceStrategy
      : property.marketplaceStrategy
    if (!strategy) return false
    if (strategy == 'Buy & Hold') {
      return property.acquisition.analysis.resellPrice
    } else if (strategy == 'Fix & Flip') {
      return property.acquisition.analysis.resellPriceFixFlip
    } else if (strategy == 'Turnkey End Buyer') {
      return property.acquisition.analysis.resellPriceTurnkey
    } else if (strategy == 'BRRRR') {
      return property.acquisition.analysis.resellPriceBRRRR
    } else if (strategy == 'Short Term Rental') {
      return property.acquisition.analysis.resellPriceSTR
    } else if (strategy == 'Build to Rent') {
      return property.acquisition.analysis.resellPriceB2R
    } else if (strategy == 'Wholesale') {
      return property.acquisition.analysis.resellPriceWholesale
    } else if (strategy == 'BOR') {
      return property.acquisition.analysis.resellPrice
    } else {
      return property.acquisition.analysis.resellPrice
    }
  }

  const findResellName = (property, marketplaceStrategy = null) => {
    const strategy = marketplaceStrategy
      ? marketplaceStrategy
      : property.marketplaceStrategy
    if (!strategy) return false
    if (strategy == 'Buy & Hold') {
      return 'resellPrice'
    } else if (strategy == 'Fix & Flip') {
      return 'resellPriceFixFlip'
    } else if (strategy == 'Turnkey End Buyer') {
      return 'resellPriceTurnkey'
    } else if (strategy == 'BRRRR') {
      return 'resellPriceBRRRR'
    } else if (strategy == 'Short Term Rental') {
      return 'resellPriceSTR'
    } else if (strategy == 'Build to Rent') {
      return 'resellPriceB2R'
    } else if (strategy == 'Wholesale') {
      return 'resellPriceWholesale'
    } else if (strategy == 'BOR') {
      return 'resellPrice'
    } else {
      return 'resellPrice'
    }
  }

  const bulkUpdateMarketplacesGrouping = (remove = false) => {
    setLoading(true)
    const validProperties = filter(
      props.properties,
      (property) =>
        property.images.length >= 2 &&
        findResellPrice(property, strategy) &&
        sum(property.beds) > 0 &&
        sum(property.baths) > 0 &&
        sum(property.sqft) > 0 &&
        property.year &&
        property.state &&
        property.city,
    )
    const groupedProperties = groupBy(validProperties, (property) =>
      property.marketplaces
        ? join(
            filter(
              property.marketplaces.approvedMarketplace,
              (url) => {
                //SPECIFIC COMMENT: The following conditonal means that you can aways delist from a marketplace if one of three conditons is met. 1: you are a housefolios admin delisting from housefolios. 2. You are on your on portfolio. 3. The property belongs to you.
                if (
                  (url === 'housefolios' &&
                    props.session.me.housefoliosAdmin) ||
                  (isNil(
                    get(property, 'portfolio.organization._id'),
                  ) &&
                    !props.propertySearch) ||
                  get(property, 'portfolio.organization._id') ===
                    props.session.me.activeOrg._id
                )
                  return false
                return !find(
                  accessableMarketplaces,
                  (marketplace) => marketplace.url === url,
                )
              },
            ),
            ',',
          )
        : '',
    )
    map(
      groupedProperties,
      (validProperties, noAccessApprovedMarketplace) => {
        const groupedProperties = groupBy(
          validProperties,
          (property) =>
            property.marketplaces
              ? join(
                  filter(
                    property.marketplaces.marketplaceName,
                    (url) => {
                      if (
                        (url === 'housefolios' &&
                          props.session.me.housefoliosAdmin) ||
                        (isNil(
                          get(property, 'portfolio.organization._id'),
                        ) &&
                          !props.propertySearch) ||
                        get(
                          property,
                          'portfolio.organization._id',
                        ) === props.session.me.activeOrg._id
                      )
                        return false
                      return !find(
                        accessableMarketplaces,
                        (marketplace) => marketplace.url === url,
                      )
                    },
                  ),
                  ',',
                )
              : '',
        )
        map(
          groupedProperties,
          (validProperties, noAccessMarketplaceName) => {
            if (remove) {
              const groupedProperties = groupBy(
                validProperties,
                (property) =>
                  property.marketplaces
                    ? join(property.marketplaces.extraTags, ',')
                    : '',
              )
              map(
                groupedProperties,
                (validProperties, formerExtraTags) => {
                  bulkUpdateMarketplaces(
                    validProperties,
                    remove,
                    compact(split(noAccessMarketplaceName, ',')),
                    compact(split(noAccessApprovedMarketplace, ',')),
                    compact(split(formerExtraTags, ',')),
                  )
                },
              )
            } else {
              bulkUpdateMarketplaces(
                validProperties,
                remove,
                compact(split(noAccessMarketplaceName, ',')),
                compact(split(noAccessApprovedMarketplace, ',')),
              )
            }
          },
        )
      },
    )

    if (hostname === 'app.housefolios.com')
      enqueueSnackbar(
        remove
          ? 'Properties Removed from all Marketplaces'
          : `${validProperties.length} Properties listed successfully`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        },
      )
    else
      enqueueSnackbar(
        remove
          ? 'Property Removed from all Marketplaces... if you were on live'
          : `${validProperties.length} Property listed successfully... if you were on live`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        },
      )

    if (validProperties.length < props.selected.length && !remove)
      enqueueSnackbar(
        `${
          props.selected.length - validProperties.length
        } Properties didn't get listed because more information is needed or it wasn't your property`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      )
    if (props.setSelected) props.setSelected([])
    handleCloseListOnMarketplace()
  }

  //TODO COMMENT: Bulk delist delists from all marketplaces. Fix it so that it can be selective
  const bulkUpdateMarketplaces = (
    validProperties,
    remove = false,
    noAccessMarketplaceName,
    noAccessApprovedMarketplace,
    formerExtraTags,
  ) => {
    setLoading(true)
    const marketplacesInput = {
      marketplaceName: remove
        ? concat(noAccessMarketplaceName, [])
        : uniq(concat(noAccessMarketplaceName, marketplaces)),
      approvedMarketplace: remove
        ? concat(noAccessApprovedMarketplace, [])
        : uniq(
            concat(
              noAccessApprovedMarketplace,
              props.session.me.housefoliosAdmin
                ? ['housefolios']
                : [],
              filter(marketplaces, (url) =>
                find(
                  accessableMarketplaces,
                  (marketplace) => marketplace.url === url,
                ),
              ),
              filter(marketplaces, (url) =>
                find(
                  accessableMarketplaces,
                  (marketplace) => marketplace.url === url,
                ),
              ),
              filter(marketplaces, (url) =>
                find(
                  props.session.me.activeOrg.sharedMarketplaces,
                  (marketplace) =>
                    marketplace.url === url &&
                    marketplace.sharePermission ===
                      'canListDelistAutoApprove',
                ),
              ),
            ),
          ),
      expirationDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      extraTags: remove ? formerExtraTags : extras,
      proformaType: proformaType,
    }
    if (isEmpty(marketplaces) && !remove) {
      unset(marketplacesInput, 'marketplaceName')
      unset(marketplacesInput, 'approvedMarketplace')
    }
    if (isEmpty(proformaType)) {
      unset(marketplacesInput, 'proformaType')
    }
    updateProperties({
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     orgIds: [props.session.me.activeOrg._id],
          //     approved: true,
          //   },
          // },
          {
            query: GET_PORTFOLIOS,
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: [props.portfolioId],
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: map(
                props.portfolios,
                (portfolio) => portfolio._id,
              ),
            },
          },
        ],
        map(accessableMarketplaces, (marketplace) => {
          return {
            query: GET_MARKETPLACES,
            variables: {
              marketplaces: [marketplace.url],
              approved: true,
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          }
        }),
        map(
          props.session.me.activeOrg.sharedMarketplaces,
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
      ),
      variables: {
        ids: remove
          ? props.selected
          : map(validProperties, (property) => property._id),
        propertyInput: {
          status: status || undefined,
          marketplaceStrategy: strategy || undefined,
          hideAddress: hide,
          hideDaysOnMarketplace: hideDays,
          marketplaces: marketplacesInput,
          marketplaceSubmittedBy: props.session.me._id,
        },
      },
    })
      .then((data) => {
        // bulkPhpSend(
        //   validProperties,
        //   remove,
        //   noAccessMarketplaceName,
        //   noAccessApprovedMarketplace,
        //   formerExtraTags,
        // )
        if (!remove)
          updateOrganization({
            refetchQueries: [{ query: GET_ME }],
            variables: {
              id: props.session.me.activeOrg._id,
              organizationInput: {
                marketPlaceFields: {
                  ...props.session.me.activeOrg.marketPlaceFields,
                  [strategy]: {
                    selectedPrimaryFields: map(
                      selectedPrimaryFields,
                      (field) => field.label,
                    ),
                    selectedAnalysis: map(
                      selectedAnalysis,
                      (field) => field.label,
                    ),
                    selectedFinancingAssumptions: map(
                      selectedFinancingAssumptions,
                      (field) => field.label,
                    ),
                    selectedExpenseAssumptions: map(
                      selectedExpenseAssumptions,
                      (field) => field.label,
                    ),
                    selectedOtherAssumptions: map(
                      selectedOtherAssumptions,
                      (field) => field.label,
                    ),
                  },
                },
              },
            },
          })
      })
      .catch((error) => {
        console.log(error.message)
        enqueueSnackbar(`Failed to save`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        setLoading(false)
        handleCloseListOnMarketplace()
      })
  }

  const updateMarketplaces = (remove = false) => {
    setLoading(true)
    let noAccessMarketplaceName = []
    let noAccessApprovedMarketplace = []
    if (
      !(
        isNil(get(property, 'portfolio.organization._id')) &&
        !props.propertySearch
      ) &&
      get(property, 'portfolio.organization._id') !==
        props.session.me.activeOrg._id
    ) {
      noAccessMarketplaceName = property.marketplaces
        ? filter(
            property.marketplaces.marketplaces,
            (url) =>
              !find(
                accessableMarketplaces,
                (marketplace) => marketplace.url === url,
              ),
          )
        : []
      noAccessApprovedMarketplace = property.marketplaces
        ? filter(
            property.marketplaces.approvedMarketplace,
            (url) =>
              !find(
                accessableMarketplaces,
                (marketplace) => marketplace.url === url,
              ),
          )
        : []
    }
    updateProperty({
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          {
            query: GET_PROPERTY,
            variables: { id: props.property._id },
          },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     orgIds: [props.session.me.activeOrg._id],
          //     approved: true,
          //   },
          // },
        ],
        map(
          concat(
            props.session.me.activeOrg.marketPlaces,
            props.session.me.activeOrg?.isAffiliate?.memberValue ===
              'pep'
              ? [{ name: 'PEP', url: 'pep' }]
              : [],
          ),
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
        map(
          props.session.me.activeOrg.sharedMarketplaces,
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
      ),
      variables: {
        id: property._id,
        propertyInput: {
          status: status,
          marketplaceStrategy: strategy,
          hideAddress: hide,
          hideDaysOnMarketplace: hideDays,
          marketplaces: {
            marketplaceName: remove
              ? concat(noAccessMarketplaceName, [])
              : uniq(concat(noAccessMarketplaceName, marketplaces)),
            approvedMarketplace: remove
              ? concat(noAccessApprovedMarketplace, [])
              : uniq(
                  concat(
                    noAccessApprovedMarketplace,
                    filter(marketplaces, (url) =>
                      find(
                        accessableMarketplaces,
                        (marketplace) => marketplace.url === url,
                      ),
                    ),
                    filter(marketplaces, (url) =>
                      find(
                        props.session.me.activeOrg.sharedMarketplaces,
                        (marketplace) =>
                          marketplace.url === url &&
                          marketplace.sharePermission ===
                            'canListDelistAutoApprove',
                      ),
                    ),
                  ),
                ),
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: filter(extras, (extra) =>
              includes(extraOptions, extra),
            ),
            proformaType: proformaType,
          },
          marketplaceSubmittedBy: props.session.me._id,
        },
      },
    })
      .then((data) => {
        // phpSend(
        //   remove,
        //   noAccessMarketplaceName,
        //   noAccessApprovedMarketplace,
        // )
        if (!remove)
          updateOrganization({
            refetchQueries: [{ query: GET_ME }],
            variables: {
              id: props.session.me.activeOrg._id,
              organizationInput: {
                marketPlaceFields: {
                  ...props.session.me.activeOrg.marketPlaceFields,
                  [strategy]: {
                    selectedPrimaryFields: map(
                      selectedPrimaryFields,
                      (field) => field.label,
                    ),
                    selectedAnalysis: map(
                      selectedAnalysis,
                      (field) => field.label,
                    ),
                    selectedFinancingAssumptions: map(
                      selectedFinancingAssumptions,
                      (field) => field.label,
                    ),
                    selectedExpenseAssumptions: map(
                      selectedExpenseAssumptions,
                      (field) => field.label,
                    ),
                    selectedOtherAssumptions: map(
                      selectedOtherAssumptions,
                      (field) => field.label,
                    ),
                  },
                },
              },
            },
          })
        if (hostname === 'app.housefolios.com')
          enqueueSnackbar(
            remove
              ? 'Property Removed from all Marketplaces'
              : `Property listed successfully`,
            {
              variant: 'success',
              autoHideDuration: 3000,
            },
          )
        else
          enqueueSnackbar(
            remove
              ? 'Property Removed from all Marketplaces... if you were on live'
              : `Property listed successfully... if you were on live`,
            {
              variant: 'success',
              autoHideDuration: 3000,
            },
          )
        handleCloseListOnMarketplace()
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        setLoading(false)
        handleCloseListOnMarketplace()
      })
  }

  const bulkPhpSend = (
    validProperties,
    remove = false,
    noAccessMarketplaceName,
    noAccessApprovedMarketplace,
    formerExtraTags,
  ) => {
    if (remove && isEmpty(noAccessMarketplaceName)) {
      let data = {
        _id: props.selected,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(validProperties)
      data = map(data, (data) => {
        if (status) data.status = status
        if (strategy) data.marketplaceStrategy = strategy
        data.hideAddress = hide
        data.hideDaysOnMarketplace = hideDays
        let activeMP = []
        data.beds = sum(data.beds)
        data.baths = sum(data.baths)
        data.sqft = sum(data.sqft)
        if (get(data, 'acquisition.analysis')) {
          map(data.acquisition.analysis, (value, key) => {
            if (isArray(value)) {
              set(
                data,
                `acquisition.analysis.${key}`,
                sum(get(data, `acquisition.analysis.${key}`)),
              )
            }
          })
        }
        if (get(data, 'assumptions')) {
          map(data.assumptions, (value, key) => {
            if (isArray(value)) {
              set(
                data,
                `assumptions.${key}`,
                sum(get(data, `assumptions.${key}`)),
              )
            }
          })
        }
        if (get(data, 'acquisition.analytics')) {
          map(data.acquisition.analytics, (value, key) => {
            if (isArray(value)) {
              set(
                data,
                `acquisition.analytics.${key}`,
                sum(get(data, `acquisition.analytics.${key}`)),
              )
            } else if (isObject(value)) {
              map(value, (secondKey, value) => {
                if (isArray(value)) {
                  set(
                    data,
                    `acquisition.analytics.${key}.${secondKey}`,
                    sum(
                      get(
                        data,
                        `acquisition.analytics.${key}.${secondKey}`,
                      ),
                    ),
                  )
                }
              })
            }
          })
        }
        if (!get(data, 'portfolio.organization._id')) {
          set(
            data,
            'portfolio.organization._id',
            props.session.me.activeOrg._id,
          )
        }

        let dataProformaType =
          proformaType || get(data, 'marketplaces.proformaType')
        const proformaLink = getProformaLink(data._id, [
          dataProformaType,
        ])

        data.proformaURL = proformaLink

        activeMP.push({
          marketplaces: {
            marketplaceName: isEmpty(marketplaces)
              ? data.marketplaces
                ? data.marketplaces.marketplaceName
                : []
              : uniq(concat(noAccessMarketplaceName, marketplaces)),
            approvedMarketplace: isEmpty(marketplaces)
              ? data.marketplaces
                ? data.marketplaces.approvedMarketplace
                : []
              : uniq(
                  concat(
                    noAccessApprovedMarketplace,
                    filter(marketplaces, (url) =>
                      find(
                        accessableMarketplaces,
                        (marketplace) => marketplace.url === url,
                      ),
                    ),
                    filter(marketplaces, (url) =>
                      find(
                        props.session.me.activeOrg.sharedMarketplaces,
                        (marketplace) =>
                          marketplace.url === url &&
                          marketplace.sharePermission ===
                            'canListDelistAutoApprove',
                      ),
                    ),
                  ),
                ),
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: remove
              ? formerExtraTags
              : filter(extras, (extra) =>
                  includes(extraOptions, extra),
                ),
            proformaType: proformaType,
          },
        })

        data = assign(data, activeMP[0])

        if (remove || !strategy) {
          let changeRelist = { changeRelist: true }
          data = assign(data, changeRelist)
        } else {
          // map(selectedAnalysis, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedFinancingAssumptions, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedExpenseAssumptions, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedOtherAssumptions, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedPrimaryFields, field => {
          //   set(data, field.target, get(property, field.target))
          // })

          let marketPlaceFields = {
            selectedPrimaryFields: map(
              selectedPrimaryFields,
              (field) => field.label,
            ),
            selectedAnalysis: map(
              selectedAnalysis,
              (field) => field.label,
            ),
            selectedFinancingAssumptions: map(
              selectedFinancingAssumptions,
              (field) => field.label,
            ),
            selectedExpenseAssumptions: map(
              selectedExpenseAssumptions,
              (field) => field.label,
            ),
            selectedOtherAssumptions: map(
              selectedOtherAssumptions,
              (field) => field.label,
            ),
          }
          data = assign(data, marketPlaceFields)
        }

        return data
      })

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const phpSend = (
    remove = false,
    noAccessMarketplaceName,
    noAccessApprovedMarketplace,
  ) => {
    if (remove && isEmpty(noAccessMarketplaceName)) {
      let data = {
        _id: props.property._id,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(props.property)
      data.status = status
      data.marketplaceStrategy = strategy
      data.hideAddress = hide
      data.hideDaysOnMarketplace = hideDays
      data.beds = sum(data.beds)
      data.baths = sum(data.baths)
      data.sqft = sum(data.sqft)
      if (get(data, 'acquisition.analysis')) {
        map(data.acquisition.analysis, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analysis.${key}`,
              sum(get(data, `acquisition.analysis.${key}`)),
            )
          }
        })
      }
      if (get(data, 'assumptions')) {
        map(data.assumptions, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `assumptions.${key}`,
              sum(get(data, `assumptions.${key}`)),
            )
          }
        })
      }
      if (get(data, 'acquisition.analytics')) {
        map(data.acquisition.analytics, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analytics.${key}`,
              sum(get(data, `acquisition.analytics.${key}`)),
            )
          } else if (isObject(value)) {
            map(value, (secondKey, value) => {
              if (isArray(value)) {
                set(
                  data,
                  `acquisition.analytics.${key}.${secondKey}`,
                  sum(
                    get(
                      data,
                      `acquisition.analytics.${key}.${secondKey}`,
                    ),
                  ),
                )
              }
            })
          }
        })
      }
      if (!get(data, 'portfolio.organization._id')) {
        set(
          data,
          'portfolio.organization._id',
          props.session.me.activeOrg._id,
        )
      }

      const proformaLink = getProformaLink(data._id, [proformaType])

      data.proformaURL = proformaLink

      let activeMP = []
      if (marketplaces) {
        activeMP.push({
          marketplaces: {
            marketplaceName: uniq(
              concat(noAccessMarketplaceName, marketplaces),
            ),
            approvedMarketplace: uniq(
              concat(
                noAccessApprovedMarketplace,
                filter(marketplaces, (url) =>
                  find(
                    accessableMarketplaces,
                    (marketplace) => marketplace.url === url,
                  ),
                ),
                filter(marketplaces, (url) =>
                  find(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) =>
                      marketplace.url === url &&
                      marketplace.sharePermission ===
                        'canListDelistAutoApprove',
                  ),
                ),
              ),
            ),
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: filter(extras, (extra) =>
              includes(extraOptions, extra),
            ),
            proformaType: proformaType,
          },
        })

        data = assign(data, activeMP[0])
      }

      if (remove || !strategy) {
        let changeRelist = { changeRelist: true }
        data = assign(data, changeRelist)
      } else {
        map(selectedAnalysis, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedFinancingAssumptions, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedExpenseAssumptions, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedOtherAssumptions, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedPrimaryFields, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })

        let marketPlaceFields = {
          selectedPrimaryFields: map(
            selectedPrimaryFields,
            (field) => field.label,
          ),
          selectedAnalysis: map(
            selectedAnalysis,
            (field) => field.label,
          ),
          selectedFinancingAssumptions: map(
            selectedFinancingAssumptions,
            (field) => field.label,
          ),
          selectedExpenseAssumptions: map(
            selectedExpenseAssumptions,
            (field) => field.label,
          ),
          selectedOtherAssumptions: map(
            selectedOtherAssumptions,
            (field) => field.label,
          ),
        }
        data = assign(data, marketPlaceFields)
      }

      const str_json = JSON.stringify(data)

      //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const validProperties = filter(
    props.properties,
    (property) =>
      property.images.length >= 2 &&
      findResellPrice(property, strategy) &&
      sum(property.beds) > 0 &&
      sum(property.baths) > 0 &&
      sum(property.sqft) > 0 &&
      property.year &&
      property.state &&
      property.city,
  )
  const allowNullFields =
    bulk &&
    every(validProperties, (property) => {
      return (
        property.marketplaces &&
        !isEmpty(property.marketplaces.marketplaceName)
      )
    })

  const disabled = allowNullFields
    ? activeStep === 1
      ? selectedPrimaryFields.length < 1 && strategy
      : false
    : activeStep === 0
      ? !status || !strategy || isEmpty(marketplaces)
      : activeStep === 1
        ? selectedPrimaryFields.length < 1
        : !every(
            concat(
              selectedPrimaryFields,
              selectedAnalysis,
              selectedExpenseAssumptions,
              selectedFinancingAssumptions,
              selectedOtherAssumptions,
            ),
            (field) => !isNil(field.value),
          )

  const propertyFields = [
    {
      type: 'text',
      name: 'city',
      label: 'City',
      value: propertyInput.city,
      xs: 12,
      md: 6,
      details: true,
      show: !property.city,
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      value: propertyInput.state,
      options: statesShort(),
      xs: 12,
      md: 6,
      details: true,
      show: !property.state,
    },
    {
      type: 'number',
      name: `beds.${activeUnit}`,
      label: 'Beds',
      value: propertyInput.beds[activeUnit],
      xs: 12,
      md: 6,
      details: true,
      show: sum(property.beds) === 0,
    },
    {
      type: 'number',
      name: `baths.${activeUnit}`,
      label: 'Baths',
      value: propertyInput.baths[activeUnit],
      xs: 12,
      md: 6,
      details: true,
      show: sum(property.baths) === 0,
    },
    {
      type: 'number',
      name: `sqft.${activeUnit}`,
      label: 'Sqft',
      value: propertyInput.sqft[activeUnit],
      xs: 12,
      md: 6,
      details: true,
      show: sum(property.sqft) === 0,
    },
    {
      type: 'number',
      name: 'year',
      label: 'Year',
      value: propertyInput.year,
      xs: 12,
      md: 6,
      details: true,
      show: !property.year,
    },
  ]

  const analysisInputFields = [
    {
      type: 'dollar',
      name: `analysisInput.${findResellName(
        propertyInput,
        strategy,
      )}`,
      label: 'Resale Price',
      value:
        get(
          propertyInput.analysisInput,
          findResellName(propertyInput, strategy),
        ) === null
          ? ''
          : get(
              propertyInput.analysisInput,
              findResellName(propertyInput, strategy),
            ),
      xs: 12,
      lg: 6,
      details: true,
      show: !get(
        property,
        `acquisition.analysis.${findResellName(
          propertyInput,
          strategy,
        )}`,
      ),
      tooltipField:
        'The price you will resell the property for once the rehab is completed.',
    },
  ]

  return (
    <>
      {actionsButton && !props.edit && (
        <Button
          href={isEmpty(options) ? '/settings/marketplace' : null}
          target="_blank"
          fullWidth={true}
          size="small"
          className="btn-success"
          onClick={
            isEmpty(options)
              ? null
              : props.disabled ||
                  (!isEmpty(property) &&
                    !findResellPrice(property, strategy))
                ? handleOpenDisabled
                : handleOpenListOnMarketplace
          }
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'sign-hanging']} />
          </span>
          <span className="btn-wrapper--label">
            List on marketplace
          </span>
        </Button>
      )}
      {actionsButton && props.edit && (
        <>
          <Grid item xs={12}>
            <Button
              href={isEmpty(options) ? '/settings/marketplace' : null}
              target="_blank"
              fullWidth={true}
              size="small"
              className="btn-success"
              onClick={
                isEmpty(options)
                  ? null
                  : props.disabled ||
                      (!isEmpty(property) &&
                        !findResellPrice(property, strategy))
                    ? handleOpenDisabled
                    : handleOpenListOnMarketplace
              }
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'upload']} />
              </span>
              <span className="btn-wrapper--label">
                Update Listing
              </span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => updateMarketplaces(true)}
              disabled={loading}
              fullWidth={true}
              size="small"
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['far', 'circle-xmark']} />
              </span>
              <span className="btn-wrapper--label">
                Remove Listing
              </span>
            </Button>
          </Grid>
        </>
      )}
      {bulk && (
        <>
          {!isEmpty(props.properties) && (
            <MenuItem
              component={isEmpty(options) ? 'a' : null}
              href="/settings/marketplace"
              target="_blank"
              button
              onClick={
                isEmpty(options) ? null : handleOpenListOnMarketplace
              }
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'upload']} />
              </span>
              <span className="btn-wrapper--label">
                List Properties
              </span>
            </MenuItem>
          )}
          {!isEmpty(props.selected) && (
            <MenuItem
              button
              onClick={() => bulkUpdateMarketplacesGrouping(true)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['far', 'circle-xmark']} />
              </span>
              <span className="btn-wrapper--label">
                Delist Properties
              </span>
            </MenuItem>
          )}
        </>
      )}
      <Dialog
        open={openListOnMarketplace}
        onClose={handleCloseListOnMarketplace}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>List on Marketplace</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          {/* <form onSubmit={() => updateMarketplaces()}> */}
          <BlockUi
            blocking={loading}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">
                Updating Listing Status
              </div>
            }
          >
            {/* <Card className="card-box"> */}
            {/* <div className="card-header">
                <div className="card-header--title">
                  <Typography variant='h6'>List on Marketplace</Typography>
                </div>
              </div> */}
            {/* <div> */}
            <div className="bg-secondary mb-3">
              <Stepper
                style={{ padding: 24 }}
                className="stepper-horizontal-1"
                alternativeLabel
                activeStep={activeStep}
                connector={<StepConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel /*StepIconComponent={StepIcon}*/>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {activeStep === steps.length ? (
              <div className="text-center p-2 pb-3">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                    <FontAwesomeIcon
                      icon={['far', 'check']}
                      className="d-flex align-self-center display-3"
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  Listed on Marketplace
                </h4>
                <div className="pt-4">
                  <Button
                    onClick={handleCloseListOnMarketplace}
                    className="btn-success font-weight-bold rounded hover-scale-lg mx-1"
                    size="large"
                    endIcon={<ArrowForward />}
                  >
                    <span className="btn-wrapper--label">Close</span>
                  </Button>
                </div>
              </div>
            ) : (
              // <ValidatorForm onSubmit={activeStep === steps.length - 1 ? handleAdd : handleNext}>
              <span>
                <div
                  style={{
                    maxHeight: window.innerHeight - 300,
                    overflow: 'scroll',
                  }}
                >
                  {activeStep === 0 && (
                    <Container>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Grid item xs={12}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            label="Status"
                            fullWidth={true}
                            value={status}
                            onChange={(event) =>
                              setStatus(event.target.value)
                            }
                            required={true}
                          >
                            <MenuItem
                              key={'Coming Soon'}
                              value={'Coming Soon'}
                            >
                              {'Coming Soon'}
                            </MenuItem>
                            <MenuItem
                              key={'Available'}
                              value={'Available'}
                            >
                              {'Available'}
                            </MenuItem>
                            <MenuItem
                              key={'New Construction'}
                              value={'New Construction'}
                            >
                              {'New Construction'}
                            </MenuItem>
                            <MenuItem key={'Active'} value={'Active'}>
                              {'Active'}
                            </MenuItem>
                            <MenuItem
                              key={'Pending'}
                              value={'Pending'}
                            >
                              {'Pending'}
                            </MenuItem>
                            <MenuItem
                              key={'Contingent'}
                              value={'Contingent'}
                            >
                              {'Contingent'}
                            </MenuItem>
                            <MenuItem key={'Sold'} value={'Sold'}>
                              {'Sold'}
                            </MenuItem>
                            <MenuItem
                              key={'Under Contract'}
                              value={'Under Contract'}
                            >
                              {'Under Contract'}
                            </MenuItem>
                            <MenuItem
                              key={'Due Diligence'}
                              value={'Due Diligence'}
                            >
                              {'Due Diligence'}
                            </MenuItem>
                            <MenuItem key={'Closed'} value={'Closed'}>
                              {'Closed'}
                            </MenuItem>
                            <MenuItem key={'Rehab'} value={'Rehab'}>
                              {'Rehab'}
                            </MenuItem>
                            <MenuItem
                              key={'Under Mgmt'}
                              value={'Under Mgmt'}
                            >
                              {'Under Mgmt'}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            label="Display Analysis"
                            fullWidth={true}
                            value={strategy}
                            onChange={(event) => {
                              const { value } = event.target
                              setStrategy(value)
                              if (
                                !isEmpty(props.property) &&
                                !findResellPrice(
                                  props.property,
                                  value,
                                )
                              ) {
                                handleCloseListOnMarketplace()
                                handleOpenDisabled()
                              }
                            }}
                            required={true}
                          >
                            {map(strategies, (option) => (
                              <MenuItem key={option} value={option}>
                                {getStrategyName(
                                  option,
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            label="Proforma Type"
                            fullWidth={true}
                            value={proformaType}
                            onChange={(event) =>
                              setProformaType(event.target.value)
                            }
                            required={true}
                          >
                            <MenuItem
                              key={'Fix & Flip'}
                              value={'Fix & Flip'}
                            >
                              {'Fix & Flip'}
                            </MenuItem>
                            <MenuItem
                              key={'Buy & Hold'}
                              value={'Buy & Hold'}
                            >
                              {'Buy & Hold'}
                            </MenuItem>
                            <MenuItem
                              key={'Turnkey End Buyer'}
                              value={'Turnkey End Buyer'}
                            >
                              {'Turnkey End Buyer'}
                            </MenuItem>
                            <MenuItem
                              key={'Short Term Rental'}
                              value={'Short Term Rental'}
                            >
                              {'Short Term Rental'}
                            </MenuItem>
                            <MenuItem
                              key={'Wholesale'}
                              value={'Wholesale'}
                            >
                              {'Wholesale'}
                            </MenuItem>
                            {props?.session?.me?.activeOrg?.member ===
                              'astroflip' && (
                              <MenuItem
                                key={'Astroflip'}
                                value={'Astroflip'}
                              >
                                {'Astroflip'}
                              </MenuItem>
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            margin="dense"
                            style={{ width: '100%' }}
                          >
                            <InputLabel id="extraTags-multiple-chip-label">
                              Extra Tags
                            </InputLabel>
                            <Select
                              labelId="extraTags-multiple-chip-label"
                              name="marketingExtraTags"
                              multiple
                              fullWidth={true}
                              variant="outlined"
                              margin="dense"
                              label="Extra Tags"
                              value={filter(extras, (extra) =>
                                includes(extraOptions, extra),
                              )}
                              onChange={(event) => {
                                const { value } = event.target
                                if (value.length <= 2)
                                  setExtras(value)
                              }}
                              renderValue={(value) => (
                                <>
                                  {map(value, (v, index) => (
                                    <Chip
                                      color="primary"
                                      label={v}
                                      style={{ marginRight: 5 }}
                                    />
                                  ))}
                                </>
                              )}
                            >
                              {map(extraOptions, (option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox
                                    checked={includes(extras, option)}
                                  />
                                  <Grid container alignItems="center">
                                    <Grid item>{option}</Grid>
                                    <Typography style={{ flex: 1 }} />
                                    <RemoveOption
                                      removeOption="marketingExtraTags"
                                      removeOptionLabel="Tag"
                                      removeOptionValue={option}
                                    />
                                  </Grid>
                                </MenuItem>
                              ))}
                              <AddOption
                                currentValue={filter(
                                  extras,
                                  (extra) =>
                                    includes(extraOptions, extra),
                                )}
                                addOption="marketingExtraTags"
                                addOptionLabel="Tag"
                                addOptionTarget="marketingExtraTags"
                                onChangeProperty={(event) => {
                                  const { value } = event.target
                                  if (value.length <= 2)
                                    setExtras(value)
                                }}
                              />
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            variant="outlined"
                            margin="dense"
                            label="List on Marketplace(s)"
                            options={options}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              value === option.url
                            }
                            filterSelectedOptions
                            fullWidth={true}
                            value={marketplaces}
                            onChange={(event, newValue) => {
                              setMarketplaces(
                                map(newValue, (value) => {
                                  if (value.url) return value.url
                                  return value
                                }),
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="List on Marketplace(s)*"
                              />
                            )}
                            renderTags={(value, getTagProps) => (
                              <>
                                {map(value, (v, index) => (
                                  <Chip
                                    color="primary"
                                    label={
                                      find(
                                        options,
                                        (option) => option.url === v,
                                      ).name
                                    }
                                    {...getTagProps({ index })}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={hide}
                                  onClick={() => setHide(!hide)}
                                  id="Hide Address on external marketplace listing"
                                />
                              }
                              label="Hide Address on external marketplace listing"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={hideDays}
                                  onClick={() =>
                                    setHideDays(!hideDays)
                                  }
                                  id="Hide Days on Marketplace on external marketplace listing"
                                />
                              }
                              label="Hide Days On Marketplace on external marketplace listing"
                            />
                          </FormGroup>
                        </Grid>
                        {allowNullFields && (
                          <Grid item xs={12}>
                            <Typography>
                              Since all selected properties are
                              already listed on a marketplace,
                              required fields can be left blank if you
                              do not wish for its value to change
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Container>
                  )}
                  {activeStep === 1 && (
                    <Container>
                      <Grid container direction="column">
                        <Grid item>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            //id="fixed-tags-demo"
                            options={primaryFields}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            value={selectedPrimaryFields}
                            disabled={!strategy}
                            onChange={(event, newValue) => {
                              if (newValue.length <= 4)
                                setSelectedPrimaryFields(newValue)
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  color="primary"
                                  label={option.label}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Primary Fields"
                                variant="outlined"
                                margin="dense"
                                placeholder="Primary Fields"
                                fullWidth={true}
                                required
                                helperText="These are the fields that are displayed on the card view and at the top of the property details page for each property. Please select at least 1"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            //id="fixed-tags-demo"
                            options={analysisFields}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            value={selectedAnalysis}
                            disabled={!strategy}
                            onChange={(event, newValue) => {
                              setSelectedAnalysis(newValue)
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  color="primary"
                                  label={option.label}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  strategy
                                    ? `${strategy} Analysis`
                                    : 'Analysis'
                                }
                                variant="outlined"
                                margin="dense"
                                placeholder={
                                  strategy
                                    ? `${strategy} Analysis`
                                    : 'Analysis'
                                }
                                fullWidth={true}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            //id="fixed-tags-demo"
                            options={expenseAssumptionFields}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={isEqual}
                            filterSelectedOptions
                            value={selectedExpenseAssumptions}
                            disabled={!strategy}
                            onChange={(event, newValue) => {
                              setSelectedExpenseAssumptions(newValue)
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  color="primary"
                                  label={option.label}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Expense Assumptions"
                                variant="outlined"
                                margin="dense"
                                placeholder="Expense Assumptions"
                                fullWidth={true}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            //id="fixed-tags-demo"
                            options={financingAssumptionFields}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={isEqual}
                            filterSelectedOptions
                            value={selectedFinancingAssumptions}
                            disabled={!strategy}
                            onChange={(event, newValue) => {
                              setSelectedFinancingAssumptions(
                                newValue,
                              )
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  color="primary"
                                  label={option.label}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Financing Assumptions"
                                variant="outlined"
                                margin="dense"
                                placeholder="Financing Assumptions"
                                fullWidth={true}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            //id="fixed-tags-demo"
                            options={otherAssumptionFields}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={isEqual}
                            filterSelectedOptions
                            value={selectedOtherAssumptions}
                            disabled={!strategy}
                            onChange={(event, newValue) => {
                              setSelectedOtherAssumptions(newValue)
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  color="primary"
                                  label={option.label}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Other Assumptions"
                                variant="outlined"
                                margin="dense"
                                placeholder="Other Assumptions"
                                fullWidth={true}
                              />
                            )}
                          />
                        </Grid>
                        {!strategy && (
                          <Grid item xs={12}>
                            <Typography>
                              Fields are disabled due to not having a
                              strategy selected.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Container>
                  )}
                  {activeStep === 2 && (
                    <Container>
                      {!isEmpty(selectedPrimaryFields) && (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={12}>
                              <Typography>Primary Fields</Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            {map(
                              selectedPrimaryFields,
                              (field, index) => (
                                <Grid item xs={12}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    label={field.label}
                                    variant="outlined"
                                    margin="dense"
                                    placeholder={field.label}
                                    required
                                    value={field.value}
                                    onValueChange={(values) => {
                                      const { value } = values
                                      if (
                                        Number(value) !== field.value
                                      ) {
                                        let newField = [
                                          ...selectedPrimaryFields,
                                        ]
                                        newField[index].value =
                                          Number(value)
                                        setSelectedPrimaryFields(
                                          newField,
                                        )
                                      }
                                    }}
                                    decimalScale={2}
                                    fullWidth
                                  />
                                </Grid>
                              ),
                            )}
                          </Grid>
                          <Divider
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          />
                        </>
                      )}
                      {!isEmpty(selectedAnalysis) && (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={12}>
                              <Typography>
                                {strategy} Analysis
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            {map(selectedAnalysis, (field, index) => (
                              <Grid item xs={12}>
                                <NumericFormat
                                  customInput={TextField}
                                  thousandSeparator={true}
                                  label={field.label}
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={field.label}
                                  required
                                  value={field.value}
                                  onValueChange={(values) => {
                                    const { value } = values
                                    if (
                                      Number(value) !== field.value
                                    ) {
                                      let newField = [
                                        ...selectedAnalysis,
                                      ]
                                      newField[index].value =
                                        Number(value)
                                      setSelectedAnalysis(newField)
                                    }
                                  }}
                                  decimalScale={2}
                                  fullWidth
                                />
                              </Grid>
                            ))}
                          </Grid>
                          <Divider
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          />
                        </>
                      )}
                      {!isEmpty(selectedExpenseAssumptions) && (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={12}>
                              <Typography>
                                Expense Assumptions
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            {map(
                              selectedExpenseAssumptions,
                              (field, index) => (
                                <Grid item xs={12}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    label={field.label}
                                    variant="outlined"
                                    margin="dense"
                                    placeholder={field.label}
                                    required
                                    value={field.value}
                                    onValueChange={(values) => {
                                      const { value } = values
                                      if (
                                        Number(value) !== field.value
                                      ) {
                                        let newField = [
                                          ...selectedExpenseAssumptions,
                                        ]
                                        newField[index].value =
                                          Number(value)
                                        setSelectedExpenseAssumptions(
                                          newField,
                                        )
                                      }
                                    }}
                                    decimalScale={2}
                                    fullWidth
                                  />
                                </Grid>
                              ),
                            )}
                          </Grid>
                          <Divider
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          />
                        </>
                      )}
                      {!isEmpty(selectedFinancingAssumptions) && (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={12}>
                              <Typography>
                                Financing Assumptions
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            {map(
                              selectedFinancingAssumptions,
                              (field, index) => (
                                <Grid item xs={12}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    label={field.label}
                                    variant="outlined"
                                    margin="dense"
                                    placeholder={field.label}
                                    required
                                    value={field.value}
                                    onValueChange={(values) => {
                                      const { value } = values
                                      if (
                                        Number(value) !== field.value
                                      ) {
                                        let newField = [
                                          ...selectedFinancingAssumptions,
                                        ]
                                        newField[index].value =
                                          Number(value)
                                        setSelectedFinancingAssumptions(
                                          newField,
                                        )
                                      }
                                    }}
                                    decimalScale={2}
                                    fullWidth
                                  />
                                </Grid>
                              ),
                            )}
                          </Grid>
                          <Divider
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          />
                        </>
                      )}
                      {!isEmpty(selectedOtherAssumptions) && (
                        <>
                          <Grid container direction="row">
                            <Grid item xs={12}>
                              <Typography>
                                Other Assumptions
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            {map(
                              selectedOtherAssumptions,
                              (field, index) => (
                                <Grid item xs={12}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    label={field.label}
                                    variant="outlined"
                                    margin="dense"
                                    placeholder={field.label}
                                    required
                                    value={field.value}
                                    onValueChange={(values) => {
                                      const { value } = values
                                      if (
                                        Number(value) !== field.value
                                      ) {
                                        let newField = [
                                          ...selectedOtherAssumptions,
                                        ]
                                        newField[index].value =
                                          Number(value)
                                        setSelectedOtherAssumptions(
                                          newField,
                                        )
                                      }
                                    }}
                                    decimalScale={2}
                                    fullWidth
                                  />
                                </Grid>
                              ),
                            )}
                          </Grid>
                          <Divider
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          />
                        </>
                      )}
                    </Container>
                  )}
                </div>
              </span>
              // </ValidatorForm>
            )}
            {/* </div>
           </Card> */}
            {/* <Card>
            <CardHeader title="Marketplace Listing" />
            <Divider />
            <CardContent>
              <Grid container direction='row' alignItems='center' justifyContent='center' spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    select
                    variant='outlined'
                    margin='dense'
                    label="Status"
                    fullWidth={true}
                    value={status}
                    onChange={event => setStatus(event.target.value)}
                    required={true}
                  >
                    <MenuItem key={'Coming Soon'} value={'Coming Soon'}>
                      {'Coming Soon'}
                    </MenuItem>
                    <MenuItem key={'New Construction'} value={'New Construction'}>
                       {'New Construction'}
                     </MenuItem>
                     <MenuItem key={'Active'} value={'Active'}>
                       {'Active'}
                    </MenuItem>
                    <MenuItem key={'Pending'} value={'Pending'}>
                      {'Pending'}
                    </MenuItem>
                    <MenuItem key={'Contingent'} value={'Contingent'}>
                       {'Contingent'}
                     </MenuItem>
                    <MenuItem key={'Sold'} value={'Sold'}>
                      {'Sold'}
                    </MenuItem>
                    <MenuItem key={'Under Contract'} value={'Under Contract'}>
                      {'Under Contract'}
                    </MenuItem>
                    <MenuItem key={'Due Diligence'} value={'Due Diligence'}>
                      {'Due Diligence'}
                    </MenuItem>
                    <MenuItem key={'Closed'} value={'Closed'}>
                      {'Closed'}
                    </MenuItem>
                    <MenuItem key={'Rehab'} value={'Rehab'}>
                      {'Rehab'}
                    </MenuItem>
                    <MenuItem key={'Under Mgmt'} value={'Under Mgmt'}>
                      {'Under Mgmt'}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    variant='outlined'
                    margin='dense'
                    label="Display Analysis"
                    fullWidth={true}
                    value={strategy}
                    onChange={event => setStrategy(event.target.value)}
                    required={true}
                  >
                    <MenuItem key={'Fix & Flip'} value={'Fix & Flip'}>
                      {'Fix & Flip'}
                    </MenuItem>
                    <MenuItem key={'Buy & Hold'} value={'Buy & Hold'}>
                      {'Buy & Hold'}
                    </MenuItem>
                    <MenuItem key={'Turnkey End Buyer'} value={'Turnkey End Buyer'}>
                      {'Turnkey End Buyer'}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    variant='outlined'
                    margin='dense'
                    label="List on Marketplace(s)"
                    options={options}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => value === option.url}
                    fullWidth={true}
                    value={marketplaces}
                    onChange={(event, newValue) => {
                      setMarketplaces(map(newValue, value => {
                        if (value.url)
                          return value.url
                        return value
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="List on Marketplace(s)"
                      />
                    )}
                    renderTags={value => (
                      <>
                      {map(value, v => (
                        <Chip color="primary" label={find(options, option => option.url === v).name} />
                      ))}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={hide} onClick={() => setHide(!hide)} id='Hide Address on external marketplace listing' />}
                      label='Hide Address on external marketplace listing'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Typography style={{ flex: 1 }} />
              <Button variant='text' onClick={handleCloseListOnMarketplace}>Cancel</Button>
              <Button variant='contained' color="primary" onClick={() => updateMarketplaces()} style={{ marginRight: 10 }}>Save</Button>
            </CardActions>
                      </Card>*/}
          </BlockUi>
          {/* </form> */}
        </DialogContent>
        <DialogActions>
          {activeStep !== 0 && (
            <Button
              disabled={loading}
              className="btn-primary font-weight-bold"
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {activeStep === 0 && (
            <Button
              /*disabled={activeStep === 0}*/ className="btn-secondary font-weight-bold"
              onClick={handleCloseListOnMarketplace}
            >
              Cancel
            </Button>
            // <CancelButton stepper={true} />
          )}
          <Typography style={{ flex: 1 }} />
          <Button
            className="btn-primary font-weight-bold"
            onClick={
              activeStep === steps.length - 1
                ? bulk
                  ? () => bulkUpdateMarketplacesGrouping()
                  : () => updateMarketplaces()
                : handleNext
            }
            disabled={disabled || loading}
            // onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDisabled}
        onClose={handleCloseDisabled}
        classes={{ paper: 'shadow-lg rounded' }}
        onKeyDown={stopPropagationForTab}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['far', 'sign-hanging']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            List on Marketplace
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            In order to be listed on the marketplace, a property must
            have a city, state, # beds, # baths, sqft, year, a resale
            price, and at least 2 images.
          </p>
          <Grid container justifyContent="space-around" spacing={2}>
            {(sum(property.beds) === 0 ||
              sum(property.baths) === 0 ||
              sum(property.sqft) === 0 ||
              !property.year ||
              !property.city ||
              !property.state) && (
              <>
                <Grid item xs={12}>
                  <List
                    className="nav-line d-flex mt-3"
                    style={{ width: propertyInput.beds.length * 100 }}
                  >
                    {propertyInput.beds.length > 1 ? (
                      map(propertyInput.beds, (value, index) => (
                        <ListItem
                          button
                          disableRipple
                          selected={activeUnit === index}
                          onClick={() => {
                            setActiveUnit(index)
                          }}
                        >
                          Unit {index + 1}
                        </ListItem>
                      ))
                    ) : (
                      <Typography>Property</Typography>
                    )}
                  </List>
                </Grid>
                {map(propertyFields, (field) => {
                  if (field.show)
                    return (
                      <Grid
                        item
                        xs={field.xs}
                        sm={field.sm}
                        md={field.md}
                        lg={field.lg}
                        xl={field.xl}
                        //alignItems='center'
                      >
                        <TextField
                          name={field.name}
                          variant="outlined"
                          margin="dense"
                          value={field.value || ''}
                          onChange={(event) =>
                            field.onChange
                              ? field.onChange(event)
                              : onChangeProperty(event)
                          }
                          onBlur={field.onBlur ? field.onBlur : null}
                          placeholder={field.label}
                          label={field.label}
                          type={
                            field.type === 'percentage'
                              ? 'number'
                              : field.type
                          }
                          fullWidth={true}
                          disabled={field.disabled}
                          required={field.required}
                        />
                      </Grid>
                    )
                })}
              </>
            )}
            {map(analysisInputFields, (field) => {
              if (field.show)
                return (
                  <Grid
                    item
                    xs={field.xs}
                    sm={field.sm}
                    md={field.md}
                    lg={field.lg}
                    xl={field.xl}
                  >
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator={true}
                      prefix={'$'}
                      fullWidth={true}
                      isNumericString={true}
                      decimalScale={0}
                      key={field.name}
                      name={field.name}
                      value={Math.round(field.value) || ''}
                      disabled={field.disabled}
                      onValueChange={(values) => {
                        const { value } = values
                        const event = {
                          target: {
                            name: field.name,
                            value: value,
                            type: 'number',
                          },
                          preventDefault: () => {
                            return null
                          },
                        }
                        if (field.disabled) return null
                        else if (field.details)
                          onChangeProperty(
                            event,
                            field.definition ? 'userInput' : null,
                          )
                      }}
                      type={'text'}
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      variant="outlined"
                      margin="dense"
                      label={field.label}
                      fontSize={field.font}
                      required={field.required}
                    />
                  </Grid>
                )
            })}
            {property.images && property.images.length < 2 && (
              <Grid item xs={12}>
                <ImageUploader propertyId={property._id} />
              </Grid>
            )}
          </Grid>
        </div>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDisabled}>
            <span className="btn-wrapper--label">Close</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              property.images
                ? property.images.length < 2
                : false ||
                  sum(propertyInput.beds) === 0 ||
                  sum(propertyInput.baths) === 0 ||
                  sum(propertyInput.sqft) === 0 ||
                  !propertyInput.year ||
                  !propertyInput.state ||
                  !propertyInput.city ||
                  !propertyInput.analysisInput.resellPrice
            }
          >
            <span className="btn-wrapper--label">Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(OldListOnMarketplaceComponent)
