import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'

export const GET_ME = gql`
  {
    me {
      _id
      username
      profile {
        firstname
        lastname
      }
      preferences
      email
      phone
      setup
      bioDescription
      userType
      userImage {
        url
        url_small
      }
      defaultOrg
      activeOrg {
        kanbanBoard
        _id
        marketPlaces {
          name
          url
          meetingURL
          contactEmail
          googleAnalytics
          marketplaceVideoURL
          preApprovedURL
          status
          featuredProperties
          propertyCount
          submittedPropertyCount
        }
        sharedPortfolios {
          _id
          name
          strategy
          strategies
          description
          propertyCount
          defaultPortfolio
          sharePermission
          assumptions {
            ...assumptions
          }
          organization {
            _id
            name
          }
        }
        sharedMarketplaces {
          name
          url
          meetingURL
          contactEmail
          googleAnalytics
          marketplaceVideoURL
          preApprovedURL
          status
          featuredProperties
          propertyCount
          sharePermission
          shareDate
        }
        sharedProperties
        meetingUrl
        phone
        email
        website
        legalDescription
        CMALegalDescription
        delinquent
        name
        address
        city
        state
        zip
        stripeCustomerID
        subscriptions
        logo {
          url
          url_small
        }
        proformaLogos {
          url
          url_small
        }
        createdAt
        createdBy {
          _id
        }
        assumptions {
          ...assumptions
        }
        setup
        corporateDetails {
          inviteMax
          overflowFee
        }
        corpChildren {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          parentCorpDetails {
            active
            parentOrg
          }
        }
        corpChildrenCount
        parentCorpDetails {
          active
          parentOrg
        }
        parentCorp {
          _id
          logo {
            url
            url_small
          }
          subscriptions
          southernImpressions
        }
        memberProvidedSubscriptions
        filters {
          name
          currentFilters
        }
        propertyCount {
          totalCount
          countTimeoutDate
          monthlyCount
          bonusProperties
          activeProperties
        }
        MLSData {
          MLS
          STATE
          MLSGrid
          username
          password
          agentId
          loginURL
          propertyURL
          photoURL
          agentURL
          rentalURL
        }
        hfpid
        affiliateActive
        affiliateTaC
        isRealeflow
        realeflowURL
        isAffiliate {
          _id
          name
          orgId
          hfpid
          memberValue
          defaultStrategy
          companyName
          email
          phone
          createdAt
          commission
          active
          rehabTiers
          defaultMarketType
          marketTypes
          createAccount
          signIn
          launchpad
          sidebar
          training
          planDetails
          isRealeflow
          affiliateAdmins {
            _id
            profile {
              firstname
              lastname
            }
            username
            email
            lastLogin
            affiliateAdmin
          }
        }
        affiliateCommission
        markets
        propertyTypes
        plans
        zones
        lenders
        builders
        resellers
        entities
        buyerSellerProfiles
        rehabTiers
        customAcquisitionExpenseTypes
        customMonthlyExpenseTypes
        customPropertyColumns
        marketingExtraTags
        affiliate
        affiliateMarketplace
        member
        memberPreferences
        memberRehabTiers
        dynamicMemberRehabTiers
        marketplaceDisclaimerRead
        marketPlaceFields
        southernImpressions
        canListOnHousefoliosMarketplace
        assetManagement {
          order
          action
          daysUntilNextAction
          label
        }
        savedSearches
        recentSearches
        watchedDemos
        submittedProperties
        manageLots
        manageCommercial
        preferences
        newMarketplace
        buyBox
        reiSoftwareContactId
        skipTraceCredits
      }
      roles {
        organization {
          _id
          name
          preferences
        }
        role
      }
      customColumns
      lastViews {
        propertiesView
        marketView
      }
      subscriptionType
      subscriptionPlan
      subscriptionPrice
      limits
      watchedDemos
      restrictedMarketplaces
      housefoliosAdmin
      affiliateAdmin
      address
      address2
      city
      state
      zip
      DoB
      citizenship
      FICO
      firstTimeInvestor
      yearsInvesting
      realEstateInvestments
      estimatedNetWorth
      estimatedLiquidAssets
      creditReport
      scheduleOfRealEstate
      personalFinancialStatement
      defaults {
        marketingExtraTagOptions
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const VERSION = gql`
  {
    version {
      _id
      version
    }
  }
`
