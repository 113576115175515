/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-09 14:53:45
 * @ Description: Dialog to list a property on one or more marketplaces
 */

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@mui/material/Autocomplete'

import map from 'lodash/map'
import find from 'lodash/find'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import assign from 'lodash/assign'
import filter from 'lodash/filter'
import get from 'lodash/get'
import set from 'lodash/set'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'
import sum from 'lodash/sum'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import join from 'lodash/join'
import split from 'lodash/split'
import startsWith from 'lodash/startsWith'
import pullAt from 'lodash/pullAt'
import head from 'lodash/head'

import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  MenuItem,
  Step,
  StepConnector,
  Stepper,
  StepLabel,
  TextField,
  Typography,
  Chip,
  List,
  ListItem,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material'

import {
  ArrowForward,
  ContactSupportOutlined,
} from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import {
  GET_PROPERTY,
  GET_SIGNED_REQUEST,
} from '@/housefolios-components/PropertyContainer/queries'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { NumericFormat } from 'react-number-format'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { UPDATE_PROPERTIES } from '@/housefolios-components/Portfolio/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
// import { removeListener } from 'process'
import ImageUploader from '@/housefolios-components/PropertyContainer/CenterPage/ManageImages/ImageUploader'
import { STATES } from '@/constants/states'
import { groupBy, includes, uniq, unset } from 'lodash'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import RemoveOption from '@/housefolios-components/Dialogs/RemoveOption'
import AddOption from '@/housefolios-components/Dialogs/AddOption'
import PublicPropertyCard from '@/housefolios-components/PublicProperty/PublicPropertyCard'
import { useDropzone } from 'react-dropzone'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'

import axios from 'axios'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { getProformaLink } from '@/utils/proforma'
import {
  filterPreferedStrategies,
  getStrategyName,
  getStrategyOptions,
} from '@/utils/stratagies'
import { statesShort } from '@/utils/states'

//SPECIFIC COMMENT: If updating these fields make sure to update backend
const buyAndHoldAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.buyHold.arvTotalBuyHold',
    type: 'currency',
  },
  {
    label: 'IRR',
    target: 'acquisition.analytics.buyHold.IRR',
    type: 'percent',
  },
  {
    label: 'Yield-on-Cost',
    target: 'acquisition.analytics.buyHold.capRate',
    type: 'percent',
  },
  {
    label: 'Cash on Cash',
    target: 'acquisition.analytics.buyHold.cashOnCashReturn',
    type: 'percent',
  },
  {
    label: 'Net Monthly Cashflow',
    target: 'acquisition.analytics.buyHold.cashFlow',
    type: 'currency',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    target: 'acquisition.analytics.annualRent',
    type: 'currency',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    target: 'acquisition.analysis.propertyTaxes',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.buyHold.corTotal',
    type: 'currency',
  },
]
const fixAndFlipAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.fixFlip.arvTotalFixFlip',
    type: 'currency',
  },
  {
    label: 'Net Profit',
    target: 'acquisition.analytics.fixFlip.saleProceeds',
    type: 'currency',
  },
  {
    label: 'Net ROI',
    target: 'acquisition.analytics.fixFlip.netROIFixFlip',
    type: 'percent',
  },
  {
    label: 'All-in Cost to ARV',
    target: 'acquisition.analytics.fixFlip.percentageOfARV',
    type: 'percent',
  },
  {
    label: 'Taxes',
    target: 'acquisition.analysis.propertyTaxes',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.fixFlip.corTotalFixFlip',
    type: 'currency',
  },
]
const turnkeyAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.arvTotalTurnkey',
    type: 'currency',
  },
  {
    label: 'IRR',
    target: 'acquisition.analytics.turnkey.IRRTurnkey',
    type: 'percent',
  },
  {
    label: 'Cap Rate',
    target: 'acquisition.analytics.turnkey.capRateTurnkey',
    type: 'percent',
  },
  {
    label: 'Cash on Cash',
    target: 'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
    type: 'percent',
  },
  {
    label: 'Net Monthly Cashflow',
    target: 'acquisition.analytics.turnkey.cashFlowTurnkey',
    type: 'currency',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    target: 'acquisition.analytics.annualRent',
    type: 'currency',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    target: 'acquisition.analysis.propertyTaxes',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.turnkey.corTotalTurnkey',
    type: 'currency',
  },
]
const brrrrAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.BRRRR.arvTotalBRRRR',
    type: 'currency',
  },
  {
    label: 'IRR',
    target: 'acquisition.analytics.BRRRR.IRRBRRRR',
    type: 'percent',
  },
  {
    label: 'Yield-on-Cost',
    target: 'acquisition.analytics.BRRRR.capRateBRRRR',
    type: 'percent',
  },
  {
    label: 'Cash on Cash',
    target: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRR',
    type: 'percent',
  },
  {
    label: 'Net Monthly Cashflow',
    target: 'acquisition.analytics.BRRRR.cashFlowBRRRR',
    type: 'currency',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    target: 'acquisition.analytics.annualRent',
    type: 'currency',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    target: 'acquisition.analysis.propertyTaxes',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.BRRRR.corTotalBRRRR',
    type: 'currency',
  },
]
const shortTermRentalAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.STR.arvTotalSTR',
    type: 'currency',
  },
  {
    label: 'IRR',
    target: 'acquisition.analytics.STR.IRRSTR',
    type: 'percent',
  },
  {
    label: 'Yield-on-Cost',
    target: 'acquisition.analytics.STR.capRateSTR',
    type: 'percent',
  },
  {
    label: 'Cash on Cash',
    target: 'acquisition.analytics.STR.cashOnCashReturnSTR',
    type: 'percent',
  },
  {
    label: 'Net Monthly Cashflow',
    target: 'acquisition.analytics.STR.cashFlowSTR',
    type: 'currency',
    divisor: 12,
  },
  {
    label: 'Daily Rent',
    target: 'acquisition.analytics.STR.annualRentSTR',
    type: 'currency',
    divisor: 365,
    sum: true,
  },
  {
    label: 'Taxes',
    target: 'acquisition.analysis.propertyTaxes',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.STR.corTotalSTR',
    type: 'currency',
  },
]
const buildToRentAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.B2R.arvTotalB2R',
    type: 'currency',
  },
  {
    label: 'IRR',
    target: 'acquisition.analytics.B2R.IRRB2R',
    arrow: 'acquisition.analytics.B2R.IRRB2RArrow',
    type: 'percent',
  },
  {
    label: 'Yield-on-Cost',
    target: 'acquisition.analytics.B2R.capRateB2R',
    arrow: 'acquisition.analytics.B2R.capRateB2RArrow',
    type: 'percent',
  },
  {
    label: 'CoC ROI',
    target: 'acquisition.analytics.B2R.cashOnCashReturnB2R',
    arrow: 'acquisition.analytics.B2R.cashOnCashReturnB2RArrow',
    type: 'percent',
  },
  {
    label: 'Net Cashflow',
    target: 'acquisition.analytics.B2R.cashFlowB2R',
    arrow: 'acquisition.analytics.B2R.cashFlowB2RArrow',
    type: 'currency',
    divisor: 12,
  },
  {
    label: 'Monthly Rent',
    target: 'acquisition.analytics.annualRent',
    type: 'currency',
    divisor: 12,
    sum: true,
  },
  {
    label: 'Taxes',
    target: 'acquisition.analysis.propertyTaxes',
    type: 'currency',
  },
]
const wholesaleAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.wholesale.arvTotalWholesale',
    type: 'currency',
  },
  {
    label: 'Net Profit',
    target: 'acquisition.analytics.wholesale.saleProceedsWholesale',
    arrow: 'acquisition.analytics.B2R.saleProceedsWholesaleArrow',
    type: 'currency',
  },
  {
    label: 'Buyer Profit',
    target: 'acquisition.analytics.wholesale.buyerProfitTotal',
    arrow: 'acquisition.analytics.wholesale.buyerProfitArrow',
    type: 'currency',
  },
  {
    label: 'Closing Cost',
    target:
      'acquisition.analytics.wholesale.closingCostWholesaleDollar',
    type: 'currency',
  },
  {
    label: 'Assignment Fee',
    target: 'acquisition.analysis.assignmentFeeDollar',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.wholesale.rehabDollar',
    type: 'currency',
  },
]
const borAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.BOR.arvTotalBOR',
    type: 'currency',
  },
  {
    label: 'IRR',
    target: 'acquisition.analytics.BOR.IRRBOR',
    arrow: 'acquisition.analytics.BOR.IRRBORArrow',
    type: 'percent',
  },
  {
    label: 'Average Annual Return',
    target: 'acquisition.analytics.BOR.averageAnnualReturnBOR',
    arrow: 'acquisition.analytics.BOR.averageAnnualReturnBORArrow',
    type: 'percent',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.BOR.corTotalBOR',
    type: 'currency',
  },
]
const astroFlipAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.astroFlip.arvTotalAstroFlip',
    type: 'currency',
  },
  {
    label: 'Net Profit',
    target: 'acquisition.analytics.astroFlip.saleProceedsAstroFlip',
    arrow: 'acquisition.analytics.B2R.saleProceedsAstroFlipArrow',
    type: 'currency',
  },
  {
    label: 'Buyer Profit',
    target:
      'acquisition.analytics.astroFlip.buyerProfitAstroFlipTotal',
    arrow:
      'acquisition.analytics.astroFlip.buyerProfitAstorFlipArrow',
    type: 'currency',
  },
  {
    label: 'Closing Cost',
    target:
      'acquisition.analytics.astroFlip.closingCostWholesaleAstroFlipDollar',
    type: 'currency',
  },
  {
    label: 'Carrying Cost',
    target:
      'acquisition.analytics.astroFlip.carryCostAstroFlipDollar',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.astroFlip.rehabDollarAstroFlip',
    type: 'currency',
  },
]

const pepFixFlipAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.fixFlip.arvTotalFixFlip',
    type: 'currency',
  },
  {
    label: 'Estimated Profit',
    target: 'acquisition.analytics.fixFlip.pepEstimatedProfitFixFlip',
    type: 'currency',
  },
  {
    label: 'Extra Profit',
    target: 'acquisition.analytics.fixFlip.extraPepProfitFixFlip',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.fixFlip.corTotalFixFlip',
    type: 'currency',
  },
]

const pepWholesaleAnalysis = [
  {
    label: 'ARV',
    target: 'acquisition.analytics.wholesale.arvTotalWholesale',
    type: 'currency',
  },
  {
    label: 'Estimated Profit',
    target:
      'acquisition.analytics.wholesale.pepEstimatedProfitWholesale',
    type: 'currency',
  },
  {
    label: 'Extra Profit',
    target: 'acquisition.analytics.wholesale.extraPepProfitWholesale',
    type: 'currency',
  },
  {
    label: 'Rehab Est.',
    target: 'acquisition.analytics.wholesale.rehabDollar',
    type: 'currency',
  },
]

const expenseAssumptions = [
  {
    label: 'Prop. Insurance',
    target: 'assumptions.propertyInsurance',
  },
  {
    label: 'Prop mgmt fee',
    target: 'assumptions.propertyManagement',
  },
  { label: 'Maintenance', target: 'assumptions.maintenance' },
  {
    label: 'Vacancy',
    target: 'assumptions.vacancy[0]',
    type: 'percentage',
    divisor: 100,
  },
  { label: 'Utilities/mo', target: 'assumptions.utilities' },
  { label: 'Capex', target: 'assumptions.monthlyReserves' },
]
const financingAssumptions = [
  {
    label: 'Down payment',
    target: 'acquisition.analytics.buyHold.downPaymentTotal',
    type: 'currency',
  },
  { label: 'Interest rate', target: 'assumptions.rate' },
  { label: 'Loan term', target: 'assumptions.term' },
  { label: 'Points', target: 'assumptions.points' },
]
const otherAssumptions = [
  { label: 'Closing cost', target: 'assumptions.closingCost' },
  {
    label: 'Rental holding period (years)',
    target: 'assumptions.rentalHoldingPeriod',
  },
]

// function StepIcon(props) {
//   const { active, completed } = props;

//   const icons = {
//     1: <CloudUploadIcon />,
//     2: <ViewColumnIcon />,
//     3: <FolderIcon />,
//   };

//   return (
//     <div
//       className={clsx("d-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded", {
//         "d-80 bg-primary text-white shadow-primary-sm": active,
//         "d-50 bg-success text-white shadow-success-sm": completed,
//       })}
//     >
//       {completed ? <Check className="completed" /> : icons[String(props.icon)]}

//     </div>
//   );
// }

// StepIcon.propTypes = {
//   active: PropTypes.bool,
//   completed: PropTypes.bool,
//   icon: PropTypes.node,
// };

function getSteps(bulk) {
  return bulk ? ['Details'] : ['Details', 'Files']
}

function ListOnMarketplaceComponent(props) {
  const { actionsButton = false, property = {}, bulk = false } = props

  const [activeStep, setActiveStep] = useState(0)

  const [activeUnit, setActiveUnit] = useState(0)

  const steps = getSteps(bulk)

  const [propertyInput, setPropertyInput] = useState({
    city: property.city,
    state: property.state,
    zip: property.zip,
    beds: !isNil(property.beds) ? [...property.beds] : [0],
    baths: !isNil(property.baths) ? [...property.baths] : [0],
    sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
    year: property.year,
    analysisInput: {
      resellPrice: get(property, 'acquisition.analysis.resellPrice'),
      resellPriceB2R: get(
        property,
        'acquisition.analysis.resellPriceB2R',
      ),
      resellPriceBRRRR: get(
        property,
        'acquisition.analysis.resellPriceBRRRR',
      ),
      resellPriceFixFlip: get(
        property,
        'acquisition.analysis.resellPriceFixFlip',
      ),
      resellPriceTurnkey: get(
        property,
        'acquisition.analysis.resellPriceTurnkey',
      ),
      resellPriceSTR: get(
        property,
        'acquisition.analysis.resellPriceSTR',
      ),
      resellPriceWholesale: get(
        property,
        'acquisition.analysis.resellPriceWholesale',
      ),
    },
  })

  //Made options to not include Housefolios based on conditional
  // const options = props.session.me.activeOrg
  //   .canListOnHousefoliosMarketplace
  //   ? concat(
  //       [{ name: 'Housefolios', url: 'housefolios' }],
  //       props.session.me.activeOrg.marketPlaces,
  //       filter(
  //         props.session.me.activeOrg.sharedMarketplaces,
  //         (marketplace) =>
  //           startsWith(marketplace.sharePermission, 'canListDelist'),
  //       ),
  //     )
  //   : concat(
  //       props.session.me.activeOrg.marketPlaces,
  //       filter(
  //         props.session.me.activeOrg.sharedMarketplaces,
  //         (marketplace) =>
  //           startsWith(marketplace.sharePermission, 'canListDelist'),
  //       ),
  //     )
  const options =
    props.session.me.activeOrg?.isAffiliate?.memberValue === 'pep'
      ? concat(
          [{ name: 'PEP', url: 'pep' }],
          props.session.me.activeOrg.marketPlaces,
          filter(
            props.session.me.activeOrg.sharedMarketplaces,
            (marketplace) =>
              startsWith(
                marketplace.sharePermission,
                'canListDelist',
              ),
          ),
        )
      : concat(
          props.session.me.activeOrg.marketPlaces,
          filter(
            props.session.me.activeOrg.sharedMarketplaces,
            (marketplace) =>
              startsWith(
                marketplace.sharePermission,
                'canListDelist',
              ),
          ),
        )

  const extraOptions = props.session.me.activeOrg.marketingExtraTags
  const [status, setStatus] = useState(bulk ? null : property.status)
  const [strategy, setStrategy] = useState(
    bulk ? null : property.strategy,
  )
  const [proformaType, setProformaType] = useState(
    bulk
      ? null
      : property.strategy === 'Fix & Flip' ||
          property.strategy === 'Turnkey End Buyer' ||
          property.strategy === 'Short Term Rental' ||
          property.strategy === 'Wholesale' ||
          property.strategy === 'AstroFlip'
        ? property.strategy
        : 'Buy & Hold',
  )
  const [marketplaces, setMarketplaces] = useState(
    bulk
      ? []
      : !property.marketplaces ||
          isEmpty(property.marketplaces.marketplaceName)
        ? []
        : filter(property.marketplaces.marketplaceName, (url) =>
            find(options, (option) => option.url === url),
          ),
  )
  const [extras, setExtras] = useState(
    bulk
      ? []
      : !property.marketplaces ||
          isEmpty(property.marketplaces.extraTags)
        ? []
        : property.marketplaces.extraTags,
  )
  const [hide, setHide] = useState(bulk ? true : property.hideAddress)
  const [hideDays, setHideDays] = useState(
    bulk ? true : property.hideDaysOnMarketplace,
  )

  const [selectedPrimaryFields, setSelectedPrimaryFields] = useState(
    [],
  )
  const [selectedAnalysis, setSelectedAnalysis] = useState([])
  const [selectedExpenseAssumptions, setSelectedExpenseAssumptions] =
    useState([])
  const [
    selectedFinancingAssumptions,
    setSelectedFinancingAssumptions,
  ] = useState([])
  const [selectedOtherAssumptions, setSelectedOtherAssumptions] =
    useState([])

  const [marketplaceFiles, setMarketplaceFiles] = useState(
    cloneDeep(property.marketplaceFiles) || [],
  )
  const [videoWalkthrough, setVideoWalkthrough] = useState(
    property.videoWalkthrough || '',
  )

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    {
      general: {
        strategies:
          property && property.portfolio
            ? property.portfolio.strategies
            : [],
      },
    },
  )
  const strategies =
    property && !isEmpty(property.strategies)
      ? property.strategies
      : filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        )

  const analysisFields =
    strategy === 'Astroflip'
      ? astroFlipAnalysis
      : strategy === 'BOR'
        ? borAnalysis
        : strategy === 'Build to Rent'
          ? buildToRentAnalysis
          : strategy === 'Wholesale'
            ? props?.session?.me?.activeOrg?.member === 'pep'
              ? pepWholesaleAnalysis
              : wholesaleAnalysis
            : strategy === 'Short Term Rental'
              ? shortTermRentalAnalysis
              : strategy === 'BRRRR'
                ? brrrrAnalysis
                : strategy === 'Turnkey End Buyer'
                  ? turnkeyAnalysis
                  : strategy === 'Fix & Flip'
                    ? props?.session?.me?.activeOrg?.member === 'pep'
                      ? pepFixFlipAnalysis
                      : fixAndFlipAnalysis
                    : buyAndHoldAnalysis

  const expenseAssumptionFields = map(expenseAssumptions, (field) => {
    const strategyValue =
      strategy === 'BOR'
        ? 'BOR'
        : strategy === 'Build to Rent'
          ? 'B2R'
          : strategy === 'Wholesale'
            ? 'Wholesale'
            : strategy === 'Short Term Rental'
              ? 'STR'
              : strategy === 'BRRRR'
                ? 'BRRRR'
                : strategy === 'Turnkey End Buyer'
                  ? 'Turnkey'
                  : strategy === 'Fix & Flip'
                    ? 'FixFlip'
                    : ''
    const newTarget =
      field.target === 'assumptions.vacancy[0]'
        ? 'assumptions.vacancy' + strategyValue + '[0]'
        : field.target + strategyValue
    return {
      ...field,
      target: !isNil(get(property, newTarget))
        ? newTarget
        : field.target,
    }
  })

  const financingAssumptionFields = map(
    financingAssumptions,
    (field) => {
      const strategyValue =
        strategy === 'BOR'
          ? 'BOR'
          : strategy === 'Build to Rent'
            ? 'B2R'
            : strategy === 'Wholesale'
              ? 'Wholesale'
              : strategy === 'Short Term Rental'
                ? 'STR'
                : strategy === 'BRRRR'
                  ? 'BRRRR'
                  : strategy === 'Turnkey End Buyer'
                    ? 'Turnkey'
                    : strategy === 'Fix & Flip'
                      ? 'FixFlip'
                      : ''
      const newTarget = field.target + strategyValue
      return {
        ...field,
        target: !isNil(get(property, newTarget))
          ? newTarget
          : field.target,
      }
    },
  )

  const otherAssumptionFields = map(otherAssumptions, (field) => {
    const strategyValue =
      strategy === 'BOR'
        ? 'BOR'
        : strategy === 'Build to Rent'
          ? 'B2R'
          : strategy === 'Wholesale'
            ? 'Wholesale'
            : strategy === 'Short Term Rental'
              ? 'STR'
              : strategy === 'BRRRR'
                ? 'BRRRR'
                : strategy === 'Turnkey End Buyer'
                  ? 'Turnkey'
                  : strategy === 'Fix & Flip'
                    ? 'FixFlip'
                    : ''
    const newTarget = field.target + strategyValue
    return {
      ...field,
      target: !isNil(get(property, newTarget))
        ? newTarget
        : field.target,
    }
  })

  const primaryFields = analysisFields //concat(analysisFields, expenseAssumptionFields, financingAssumptionFields, otherAssumptionFields)

  useEffect(() => {
    const { marketPlaceFields } = props.session.me.activeOrg
    const options = props.session.me.activeOrg
      .canListOnHousefoliosMarketplace
      ? concat(
          [{ name: 'Housefolios', url: 'housefolios' }],
          props.session.me.activeOrg.marketPlaces,
          filter(
            props.session.me.activeOrg.sharedMarketplaces,
            (marketplace) =>
              startsWith(
                marketplace.sharePermission,
                'canListDelist',
              ),
          ),
        )
      : concat(
          props.session.me.activeOrg.marketPlaces,
          filter(
            props.session.me.activeOrg.sharedMarketplaces,
            (marketplace) =>
              startsWith(
                marketplace.sharePermission,
                'canListDelist',
              ),
          ),
        )
    setStatus(bulk ? null : property.status)
    setStrategy(bulk ? null : property.strategy)
    setMarketplaces(
      bulk
        ? []
        : !property.marketplaces ||
            isEmpty(property.marketplaces.marketplaceName)
          ? []
          : filter(property.marketplaces.marketplaceName, (url) =>
              find(options, (option) => option.url === url),
            ),
    )
    setExtras(
      bulk
        ? []
        : !property.marketplaces ||
            isEmpty(property.marketplaces.extraTags)
          ? []
          : property.marketplaces.extraTags,
    )
    if (marketPlaceFields[property.strategy])
      setSelectedPrimaryFields(
        bulk
          ? []
          : compact(
              map(
                marketPlaceFields[property.strategy]
                  .selectedPrimaryFields,
                (field) =>
                  find(
                    primaryFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
      )
  }, [
    bulk,
    props.session.me.activeOrg.canListOnHousefoliosMarketplace,
    props.session.me.activeOrg.marketPlaces,
    props.session.me.activeOrg.sharedMarketplaces,
  ]) //BUG COMMENT: Function used to be: [property, bulk, options]). We removed options as it caused the page to keep rerendering for canListOnHousefoliosMarketplace. Also removed property as it would constantly retrigger the useEffect May cause warning in Console.

  useEffect(() => {
    const { marketPlaceFields } = props.session.me.activeOrg
    if (marketPlaceFields[strategy])
      setSelectedPrimaryFields(
        bulk
          ? []
          : compact(
              map(
                marketPlaceFields[strategy].selectedPrimaryFields,
                (field) =>
                  find(
                    primaryFields,
                    (other) => other.label === field,
                  ),
              ),
            ),
      )
    else setSelectedPrimaryFields([])
  }, [strategy])

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      const { marketPlaceFields } = props.session.me.activeOrg
      if (marketPlaceFields[strategy]) {
        if (
          !isEmpty(property) &&
          !findResellPrice(property, strategy)
        ) {
          handleCloseListOnMarketplace()
          handleOpenDisabled()
          return
        }
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const [openListOnMarketplace, setOpenListOnMarketplace] =
    useState(false)
  const handleOpenListOnMarketplace = () => {
    setOpenListOnMarketplace(true)
  }
  const handleCloseListOnMarketplace = () => {
    setLoading(false)
    setOpenListOnMarketplace(false)
    setActiveStep(0)
  }

  const onChangeProperty = (event, source = null, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newPropertyInput = cloneDeep(propertyInput)
    let newValue = value
    // let newSource = null
    if (type === 'checkbox' && name !== 'owned')
      newValue = !get(newPropertyInput, name)
    if (type === 'number' && name !== 'zip') newValue = Number(value)
    if (includes(name, 'bed')) newValue = parseInt(newValue)
    if ((type === 'number' || type === 'dollar') && newValue < 0)
      newValue = 0
    set(newPropertyInput, name, newValue)
    setPropertyInput(newPropertyInput)
  }

  const [openDisabled, setOpenDisabled] = useState(false)
  const handleOpenDisabled = () => {
    setOpenDisabled(true)
    setPropertyInput({
      city: property.city,
      state: property.state,
      zip: property.zip,
      beds: !isNil(property.beds) ? [...property.beds] : [0],
      baths: !isNil(property.baths) ? [...property.baths] : [0],
      sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
      year: property.year,
      analysisInput: {
        resellPrice: get(
          property,
          'acquisition.analysis.resellPrice',
        ),
        resellPriceB2R: get(
          property,
          'acquisition.analysis.resellPriceB2R',
        ),
        resellPriceBRRRR: get(
          property,
          'acquisition.analysis.resellPriceBRRRR',
        ),
        resellPriceFixFlip: get(
          property,
          'acquisition.analysis.resellPriceFixFlip',
        ),
        resellPriceTurnkey: get(
          property,
          'acquisition.analysis.resellPriceTurnkey',
        ),
        resellPriceSTR: get(
          property,
          'acquisition.analysis.resellPriceSTR',
        ),
        resellPriceWholesale: get(
          property,
          'acquisition.analysis.resellPriceWholesale',
        ),
      },
    })
  }
  const handleCloseDisabled = () => {
    setOpenDisabled(false)
  }
  const hostname = import.meta.env.VITE_HOSTNAME

  //const [name, setName] = useState(portfolio.name)
  // const [description, setDescription] = useState(portfolio.description)

  const [loading, setLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateProperties] = useMutation(UPDATE_PROPERTIES)

  const accessableMarketplaces = concat(
    props.session.me.activeOrg.marketPlaces,
    props.session.me.activeOrg?.isAffiliate?.memberValue === 'pep'
      ? [{ name: 'PEP', url: 'pep' }]
      : [],
  )

  const onSubmit = () => {
    setLoading(true)
    updateProperty({
      variables: {
        id: property._id,
        propertyInput,
      },
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          {
            query: GET_PROPERTY,
            variables: { id: props.property._id },
          },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     orgIds: [props.session.me.activeOrg._id],
          //     approved: true,
          //   },
          // },
        ],
        map(accessableMarketplaces, (marketplace) => {
          return {
            query: GET_MARKETPLACES,
            variables: {
              marketplaces: [marketplace.url],
              approved: true,
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          }
        }),
        map(
          props.session.me.activeOrg.sharedMarketplaces,
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
      ),
    })
      .then((data) => {
        setLoading(false)
        handleCloseDisabled()
        handleOpenListOnMarketplace()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const findResellPrice = (property, marketplaceStrategy = null) => {
    const strategy = marketplaceStrategy
      ? marketplaceStrategy
      : property.marketplaceStrategy
    if (!strategy) return false
    if (strategy == 'Buy & Hold') {
      return property.acquisition.analysis.resellPrice
    } else if (strategy == 'Fix & Flip') {
      return property.acquisition.analysis.resellPriceFixFlip
    } else if (strategy == 'Turnkey End Buyer') {
      return property.acquisition.analysis.resellPriceTurnkey
    } else if (strategy == 'BRRRR') {
      return property.acquisition.analysis.resellPriceBRRRR
    } else if (strategy == 'Short Term Rental') {
      return property.acquisition.analysis.resellPriceSTR
    } else if (strategy == 'Build to Rent') {
      return property.acquisition.analysis.resellPriceB2R
    } else if (strategy == 'Wholesale') {
      return property.acquisition.analysis.resellPriceWholesale
    } else if (strategy == 'BOR') {
      return property.acquisition.analysis.resellPrice
    } else {
      return property.acquisition.analysis.resellPrice
    }
  }

  const findResellName = (property, marketplaceStrategy = null) => {
    const strategy = marketplaceStrategy
      ? marketplaceStrategy
      : property.marketplaceStrategy
    if (!strategy) return false
    if (strategy == 'Buy & Hold') {
      return 'resellPrice'
    } else if (strategy == 'Fix & Flip') {
      return 'resellPriceFixFlip'
    } else if (strategy == 'Turnkey End Buyer') {
      return 'resellPriceTurnkey'
    } else if (strategy == 'BRRRR') {
      return 'resellPriceBRRRR'
    } else if (strategy == 'Short Term Rental') {
      return 'resellPriceSTR'
    } else if (strategy == 'Build to Rent') {
      return 'resellPriceB2R'
    } else if (strategy == 'Wholesale') {
      return 'resellPriceWholesale'
    } else if (strategy == 'BOR') {
      return 'resellPrice'
    } else {
      return 'resellPrice'
    }
  }

  const bulkUpdateMarketplacesGrouping = (remove = false) => {
    setLoading(true)
    const validProperties = filter(
      props.properties,
      (property) =>
        property.images.length >= 2 &&
        findResellPrice(property, strategy) &&
        sum(property.beds) > 0 &&
        sum(property.baths) > 0 &&
        sum(property.sqft) > 0 &&
        property.year &&
        property.state &&
        property.city,
    )
    const groupedProperties = groupBy(validProperties, (property) =>
      property.marketplaces
        ? join(
            filter(
              property.marketplaces.approvedMarketplace,
              (url) => {
                //SPECIFIC COMMENT: The following conditonal means that you can aways delist from a marketplace if one of three conditons is met. 1: you are a housefolios admin delisting from housefolios. 2. You are on your on portfolio. 3. The property belongs to you.
                if (
                  (url === 'housefolios' &&
                    props.session.me.housefoliosAdmin) ||
                  (isNil(
                    get(property, 'portfolio.organization._id'),
                  ) &&
                    !props.propertySearch) ||
                  get(property, 'portfolio.organization._id') ===
                    props.session.me.activeOrg._id
                )
                  return false
                return !find(
                  accessableMarketplaces,
                  (marketplace) => marketplace.url === url,
                )
              },
            ),
            ',',
          )
        : '',
    )
    map(
      groupedProperties,
      (validProperties, noAccessApprovedMarketplace) => {
        const groupedProperties = groupBy(
          validProperties,
          (property) =>
            property.marketplaces
              ? join(
                  filter(
                    property.marketplaces.marketplaceName,
                    (url) => {
                      if (
                        (url === 'housefolios' &&
                          props.session.me.housefoliosAdmin) ||
                        (isNil(
                          get(property, 'portfolio.organization._id'),
                        ) &&
                          !props.propertySearch) ||
                        get(
                          property,
                          'portfolio.organization._id',
                        ) === props.session.me.activeOrg._id
                      )
                        return false
                      return !find(
                        accessableMarketplaces,
                        (marketplace) => marketplace.url === url,
                      )
                    },
                  ),
                  ',',
                )
              : '',
        )
        map(
          groupedProperties,
          (validProperties, noAccessMarketplaceName) => {
            if (remove) {
              const groupedProperties = groupBy(
                validProperties,
                (property) =>
                  property.marketplaces
                    ? join(property.marketplaces.extraTags, ',')
                    : '',
              )
              map(
                groupedProperties,
                (validProperties, formerExtraTags) => {
                  bulkUpdateMarketplaces(
                    validProperties,
                    remove,
                    compact(split(noAccessMarketplaceName, ',')),
                    compact(split(noAccessApprovedMarketplace, ',')),
                    compact(split(formerExtraTags, ',')),
                  )
                },
              )
            } else {
              bulkUpdateMarketplaces(
                validProperties,
                remove,
                compact(split(noAccessMarketplaceName, ',')),
                compact(split(noAccessApprovedMarketplace, ',')),
              )
            }
          },
        )
      },
    )

    if (hostname === 'app.housefolios.com')
      enqueueSnackbar(
        remove
          ? 'Properties Removed from all Marketplaces'
          : `${validProperties.length} Properties listed successfully`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        },
      )
    else
      enqueueSnackbar(
        remove
          ? 'Property Removed from all Marketplaces... if you were on live'
          : `${validProperties.length} Property listed successfully... if you were on live`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        },
      )

    if (validProperties.length < props.selected.length && !remove)
      enqueueSnackbar(
        `${
          props.selected.length - validProperties.length
        } Properties didn't get listed because more information is needed or it wasn't your property`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      )
    if (props.setSelected) props.setSelected([])
    handleCloseListOnMarketplace()
  }

  //TODO COMMENT: Bulk delist delists from all marketplaces. Fix it so that it can be selective
  const bulkUpdateMarketplaces = (
    validProperties,
    remove = false,
    noAccessMarketplaceName,
    noAccessApprovedMarketplace,
    formerExtraTags,
  ) => {
    setLoading(true)
    const marketplacesInput = {
      marketplaceName: remove
        ? concat(noAccessMarketplaceName, [])
        : uniq(concat(noAccessMarketplaceName, marketplaces)),
      approvedMarketplace: remove
        ? concat(noAccessApprovedMarketplace, [])
        : uniq(
            concat(
              noAccessApprovedMarketplace,
              filter(marketplaces, (url) =>
                find(
                  accessableMarketplaces,
                  (marketplace) => marketplace.url === url,
                ),
              ),
              filter(marketplaces, (url) =>
                find(
                  accessableMarketplaces,
                  (marketplace) => marketplace.url === url,
                ),
              ),
              filter(marketplaces, (url) =>
                find(
                  props.session.me.activeOrg.sharedMarketplaces,
                  (marketplace) =>
                    marketplace.url === url &&
                    marketplace.sharePermission ===
                      'canListDelistAutoApprove',
                ),
              ),
            ),
          ),
      expirationDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      extraTags: remove ? formerExtraTags : extras,
      proformaType: proformaType,
    }
    if (isEmpty(marketplaces) && !remove) {
      unset(marketplacesInput, 'marketplaceName')
      unset(marketplacesInput, 'approvedMarketplace')
    }
    if (isEmpty(proformaType)) {
      unset(marketplacesInput, 'proformaType')
    }
    updateProperties({
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     orgIds: [props.session.me.activeOrg._id],
          //     approved: true,
          //   },
          // },
          {
            query: GET_PORTFOLIOS,
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: [props.portfolioId],
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: map(
                props.portfolios,
                (portfolio) => portfolio._id,
              ),
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          },
        ],
        map(accessableMarketplaces, (marketplace) => {
          return {
            query: GET_MARKETPLACES,
            variables: {
              marketplaces: [marketplace.url],
              approved: true,
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          }
        }),
        map(
          props.session.me.activeOrg.sharedMarketplaces,
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
      ),
      variables: {
        ids: remove
          ? props.selected
          : map(validProperties, (property) => property._id),
        propertyInput: {
          status: status || undefined,
          marketplaceStrategy: strategy || undefined,
          hideAddress: hide,
          hideDaysOnMarketplace: hideDays,
          marketplaces: marketplacesInput,
          marketplaceSubmittedBy: props.session.me._id,
        },
      },
    })
      .then((data) => {
        // bulkPhpSend(
        //   validProperties,
        //   remove,
        //   noAccessMarketplaceName,
        //   noAccessApprovedMarketplace,
        //   formerExtraTags,
        // )
        if (!remove)
          updateOrganization({
            refetchQueries: [{ query: GET_ME }],
            variables: {
              id: props.session.me.activeOrg._id,
              organizationInput: {
                marketPlaceFields: {
                  ...props.session.me.activeOrg.marketPlaceFields,
                  [strategy]: {
                    selectedPrimaryFields: map(
                      selectedPrimaryFields,
                      (field) => field.label,
                    ),
                    selectedAnalysis: map(
                      selectedAnalysis,
                      (field) => field.label,
                    ),
                    selectedFinancingAssumptions: map(
                      selectedFinancingAssumptions,
                      (field) => field.label,
                    ),
                    selectedExpenseAssumptions: map(
                      selectedExpenseAssumptions,
                      (field) => field.label,
                    ),
                    selectedOtherAssumptions: map(
                      selectedOtherAssumptions,
                      (field) => field.label,
                    ),
                  },
                },
              },
            },
          })
      })
      .catch((error) => {
        console.log(error.message)
        enqueueSnackbar(`Failed to save`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        setLoading(false)
        handleCloseListOnMarketplace()
      })
  }

  const updateMarketplaces = (remove = false) => {
    setLoading(true)
    let noAccessMarketplaceName = []
    let noAccessApprovedMarketplace = []
    if (
      !(
        isNil(get(property, 'portfolio.organization._id')) &&
        !props.propertySearch
      ) &&
      get(property, 'portfolio.organization._id') !==
        props.session.me.activeOrg._id
    ) {
      noAccessMarketplaceName = property.marketplaces
        ? filter(
            property.marketplaces.marketplaces,
            (url) =>
              !find(
                accessableMarketplaces,
                (marketplace) => marketplace.url === url,
              ),
          )
        : []
      noAccessApprovedMarketplace = property.marketplaces
        ? filter(
            property.marketplaces.approvedMarketplace,
            (url) =>
              !find(
                accessableMarketplaces,
                (marketplace) => marketplace.url === url,
              ),
          )
        : []
    }
    updateProperty({
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          {
            query: GET_PROPERTY,
            variables: { id: props.property._id },
          },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     approved: true,
          //   },
          // },
          // {
          //   query: GET_MARKETPLACES,
          //   variables: {
          //     marketplaces: ['housefolios', 'myGrayLabel'],
          //     orgIds: [props.session.me.activeOrg._id],
          //     approved: true,
          //   },
          // },
        ],
        map(accessableMarketplaces, (marketplace) => {
          return {
            query: GET_MARKETPLACES,
            variables: {
              marketplaces: [marketplace.url],
              approved: true,
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          }
        }),
        map(
          props.session.me.activeOrg.sharedMarketplaces,
          (marketplace) => {
            return {
              query: GET_MARKETPLACES,
              variables: {
                marketplaces: [marketplace.url],
                approved: true,
                limit: GET_PROPERTIES_QUERY_LIMIT,
              },
            }
          },
        ),
      ),
      variables: {
        id: property._id,
        propertyInput: {
          status: status,
          marketplaceStrategy: strategy,
          hideAddress: hide,
          hideDaysOnMarketplace: hideDays,
          marketplaces: {
            marketplaceName: remove
              ? concat(noAccessMarketplaceName, [])
              : uniq(concat(noAccessMarketplaceName, marketplaces)),
            approvedMarketplace: remove
              ? concat(noAccessApprovedMarketplace, [])
              : uniq(
                  concat(
                    noAccessApprovedMarketplace,
                    filter(marketplaces, (url) =>
                      find(
                        accessableMarketplaces,
                        (marketplace) => marketplace.url === url,
                      ),
                    ),
                    filter(marketplaces, (url) =>
                      find(
                        props.session.me.activeOrg.sharedMarketplaces,
                        (marketplace) =>
                          marketplace.url === url &&
                          marketplace.sharePermission ===
                            'canListDelistAutoApprove',
                      ),
                    ),
                  ),
                ),
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: filter(extras, (extra) =>
              includes(extraOptions, extra),
            ),
            proformaType: proformaType,
          },
          marketplaceSubmittedBy: props.session.me._id,
          marketplaceFiles: marketplaceFiles,
          videoWalkthrough: videoWalkthrough,
        },
      },
    })
      .then((data) => {
        // phpSend(
        //   remove,
        //   noAccessMarketplaceName,
        //   noAccessApprovedMarketplace,
        // )
        if (!remove)
          updateOrganization({
            refetchQueries: [{ query: GET_ME }],
            variables: {
              id: props.session.me.activeOrg._id,
              organizationInput: {
                marketPlaceFields: {
                  ...props.session.me.activeOrg.marketPlaceFields,
                  [strategy]: {
                    selectedPrimaryFields: map(
                      selectedPrimaryFields,
                      (field) => field.label,
                    ),
                    selectedAnalysis: map(
                      selectedAnalysis,
                      (field) => field.label,
                    ),
                    selectedFinancingAssumptions: map(
                      selectedFinancingAssumptions,
                      (field) => field.label,
                    ),
                    selectedExpenseAssumptions: map(
                      selectedExpenseAssumptions,
                      (field) => field.label,
                    ),
                    selectedOtherAssumptions: map(
                      selectedOtherAssumptions,
                      (field) => field.label,
                    ),
                  },
                },
              },
            },
          })
        if (hostname === 'app.housefolios.com')
          enqueueSnackbar(
            remove
              ? 'Property Removed from all Marketplaces'
              : `Property listed successfully`,
            {
              variant: 'success',
              autoHideDuration: 3000,
            },
          )
        else
          enqueueSnackbar(
            remove
              ? 'Property Removed from all Marketplaces... if you were on live'
              : `Property listed successfully... if you were on live`,
            {
              variant: 'success',
              autoHideDuration: 3000,
            },
          )
        handleCloseListOnMarketplace()
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        setLoading(false)
        handleCloseListOnMarketplace()
      })
  }

  const bulkPhpSend = (
    validProperties,
    remove = false,
    noAccessMarketplaceName,
    noAccessApprovedMarketplace,
    formerExtraTags,
  ) => {
    if (remove && isEmpty(noAccessMarketplaceName)) {
      let data = {
        _id: props.selected,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(validProperties)
      data = map(data, (data) => {
        if (status) data.status = status
        if (strategy) data.marketplaceStrategy = strategy
        data.hideAddress = hide
        data.hideDaysOnMarketplace = hideDays
        let activeMP = []
        data.beds = sum(data.beds)
        data.baths = sum(data.baths)
        data.sqft = sum(data.sqft)
        if (get(data, 'acquisition.analysis')) {
          map(data.acquisition.analysis, (value, key) => {
            if (isArray(value)) {
              set(
                data,
                `acquisition.analysis.${key}`,
                sum(get(data, `acquisition.analysis.${key}`)),
              )
            }
          })
        }
        if (get(data, 'assumptions')) {
          map(data.assumptions, (value, key) => {
            if (isArray(value)) {
              set(
                data,
                `assumptions.${key}`,
                sum(get(data, `assumptions.${key}`)),
              )
            }
          })
        }
        if (get(data, 'acquisition.analytics')) {
          map(data.acquisition.analytics, (value, key) => {
            if (isArray(value)) {
              set(
                data,
                `acquisition.analytics.${key}`,
                sum(get(data, `acquisition.analytics.${key}`)),
              )
            } else if (isObject(value)) {
              map(value, (secondKey, value) => {
                if (isArray(value)) {
                  set(
                    data,
                    `acquisition.analytics.${key}.${secondKey}`,
                    sum(
                      get(
                        data,
                        `acquisition.analytics.${key}.${secondKey}`,
                      ),
                    ),
                  )
                }
              })
            }
          })
        }
        if (!get(data, 'portfolio.organization._id')) {
          set(
            data,
            'portfolio.organization._id',
            props.session.me.activeOrg._id,
          )
        }

        let dataProformaType =
          proformaType || get(data, 'marketplaces.proformaType')
        const proformaLink = getProformaLink(data._id, [
          dataProformaType,
        ])

        data.proformaURL = proformaLink

        activeMP.push({
          marketplaces: {
            marketplaceName: isEmpty(marketplaces)
              ? data.marketplaces
                ? data.marketplaces.marketplaceName
                : []
              : uniq(concat(noAccessMarketplaceName, marketplaces)),
            approvedMarketplace: isEmpty(marketplaces)
              ? data.marketplaces
                ? data.marketplaces.approvedMarketplace
                : []
              : uniq(
                  concat(
                    noAccessApprovedMarketplace,
                    filter(marketplaces, (url) =>
                      find(
                        accessableMarketplaces,
                        (marketplace) => marketplace.url === url,
                      ),
                    ),
                    filter(marketplaces, (url) =>
                      find(
                        props.session.me.activeOrg.sharedMarketplaces,
                        (marketplace) =>
                          marketplace.url === url &&
                          marketplace.sharePermission ===
                            'canListDelistAutoApprove',
                      ),
                    ),
                  ),
                ),
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: remove
              ? formerExtraTags
              : filter(extras, (extra) =>
                  includes(extraOptions, extra),
                ),
            proformaType: proformaType,
          },
        })

        data = assign(data, activeMP[0])

        if (remove || !strategy) {
          let changeRelist = { changeRelist: true }
          data = assign(data, changeRelist)
        } else {
          // map(selectedAnalysis, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedFinancingAssumptions, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedExpenseAssumptions, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedOtherAssumptions, field => {
          //   set(data, field.target, get(property, field.target))
          // })
          // map(selectedPrimaryFields, field => {
          //   set(data, field.target, get(property, field.target))
          // })

          let marketPlaceFields = {
            selectedPrimaryFields: map(
              selectedPrimaryFields,
              (field) => field.label,
            ),
            selectedAnalysis: map(
              selectedAnalysis,
              (field) => field.label,
            ),
            selectedFinancingAssumptions: map(
              selectedFinancingAssumptions,
              (field) => field.label,
            ),
            selectedExpenseAssumptions: map(
              selectedExpenseAssumptions,
              (field) => field.label,
            ),
            selectedOtherAssumptions: map(
              selectedOtherAssumptions,
              (field) => field.label,
            ),
          }
          data = assign(data, marketPlaceFields)
        }

        return data
      })

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const phpSend = (
    remove = false,
    noAccessMarketplaceName,
    noAccessApprovedMarketplace,
  ) => {
    if (remove && isEmpty(noAccessMarketplaceName)) {
      let data = {
        _id: props.property._id,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(props.property)
      data.status = status
      data.marketplaceStrategy = strategy
      data.hideAddress = hide
      data.hideDaysOnMarketplace = hideDays
      data.beds = sum(data.beds)
      data.baths = sum(data.baths)
      data.sqft = sum(data.sqft)
      if (get(data, 'acquisition.analysis')) {
        map(data.acquisition.analysis, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analysis.${key}`,
              sum(get(data, `acquisition.analysis.${key}`)),
            )
          }
        })
      }
      if (get(data, 'assumptions')) {
        map(data.assumptions, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `assumptions.${key}`,
              sum(get(data, `assumptions.${key}`)),
            )
          }
        })
      }
      if (get(data, 'acquisition.analytics')) {
        map(data.acquisition.analytics, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analytics.${key}`,
              sum(get(data, `acquisition.analytics.${key}`)),
            )
          } else if (isObject(value)) {
            map(value, (secondKey, value) => {
              if (isArray(value)) {
                set(
                  data,
                  `acquisition.analytics.${key}.${secondKey}`,
                  sum(
                    get(
                      data,
                      `acquisition.analytics.${key}.${secondKey}`,
                    ),
                  ),
                )
              }
            })
          }
        })
      }
      if (!get(data, 'portfolio.organization._id')) {
        set(
          data,
          'portfolio.organization._id',
          props.session.me.activeOrg._id,
        )
      }

      const proformaLink = getProformaLink(data._id, [proformaType])

      data.proformaURL = proformaLink

      let activeMP = []
      if (marketplaces) {
        activeMP.push({
          marketplaces: {
            marketplaceName: uniq(
              concat(noAccessMarketplaceName, marketplaces),
            ),
            approvedMarketplace: uniq(
              concat(
                noAccessApprovedMarketplace,
                filter(marketplaces, (url) =>
                  find(
                    accessableMarketplaces,
                    (marketplace) => marketplace.url === url,
                  ),
                ),
                filter(marketplaces, (url) =>
                  find(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) =>
                      marketplace.url === url &&
                      marketplace.sharePermission ===
                        'canListDelistAutoApprove',
                  ),
                ),
              ),
            ),
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: filter(extras, (extra) =>
              includes(extraOptions, extra),
            ),
            proformaType: proformaType,
          },
        })

        data = assign(data, activeMP[0])
      }

      if (remove || !strategy) {
        let changeRelist = { changeRelist: true }
        data = assign(data, changeRelist)
      } else {
        map(selectedAnalysis, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedFinancingAssumptions, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedExpenseAssumptions, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedOtherAssumptions, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })
        map(selectedPrimaryFields, (field) => {
          if (field.sum)
            set(data, field.target, [
              field.value * (field.divisor || 1),
            ])
          else
            set(
              data,
              field.target,
              field.value * (field.divisor || 1),
            )
        })

        let marketPlaceFields = {
          selectedPrimaryFields: map(
            selectedPrimaryFields,
            (field) => field.label,
          ),
          selectedAnalysis: map(
            selectedAnalysis,
            (field) => field.label,
          ),
          selectedFinancingAssumptions: map(
            selectedFinancingAssumptions,
            (field) => field.label,
          ),
          selectedExpenseAssumptions: map(
            selectedExpenseAssumptions,
            (field) => field.label,
          ),
          selectedOtherAssumptions: map(
            selectedOtherAssumptions,
            (field) => field.label,
          ),
        }
        data = assign(data, marketPlaceFields)
      }

      const str_json = JSON.stringify(data)

      //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const validProperties = filter(
    props.properties,
    (property) =>
      property.images.length >= 2 &&
      findResellPrice(property, strategy) &&
      sum(property.beds) > 0 &&
      sum(property.baths) > 0 &&
      sum(property.sqft) > 0 &&
      property.year &&
      property.state &&
      property.city,
  )
  const allowNullFields =
    bulk &&
    every(validProperties, (property) => {
      return (
        property.marketplaces &&
        !isEmpty(property.marketplaces.marketplaceName)
      )
    })

  const disabled = allowNullFields
    ? activeStep === 0
      ? selectedPrimaryFields.length < 1 && strategy
      : false
    : activeStep === 0
      ? !status ||
        !strategy ||
        isEmpty(marketplaces) ||
        selectedPrimaryFields.length < 1
      : false

  const propertyFields = [
    {
      type: 'text',
      name: 'city',
      label: 'City',
      value: propertyInput.city,
      xs: 12,
      md: 6,
      details: true,
      show: !property.city,
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      value: propertyInput.state,
      options: statesShort(),
      xs: 12,
      md: 6,
      details: true,
      show: !property.state,
    },
    {
      type: 'number',
      name: `beds.${activeUnit}`,
      label: 'Beds',
      value: propertyInput.beds[activeUnit],
      xs: 12,
      md: 6,
      details: true,
      show: sum(property.beds) === 0,
    },
    {
      type: 'number',
      name: `baths.${activeUnit}`,
      label: 'Baths',
      value: propertyInput.baths[activeUnit],
      xs: 12,
      md: 6,
      details: true,
      show: sum(property.baths) === 0,
    },
    {
      type: 'number',
      name: `sqft.${activeUnit}`,
      label: 'Sqft',
      value: propertyInput.sqft[activeUnit],
      xs: 12,
      md: 6,
      details: true,
      show: sum(property.sqft) === 0,
    },
    {
      type: 'number',
      name: 'year',
      label: 'Year',
      value: propertyInput.year,
      xs: 12,
      md: 6,
      details: true,
      show: !property.year,
    },
  ]

  const analysisInputFields = [
    {
      type: 'dollar',
      name: `analysisInput.${findResellName(
        propertyInput,
        strategy,
      )}`,
      label: 'Resale Price',
      value:
        get(
          propertyInput.analysisInput,
          findResellName(propertyInput, strategy),
        ) === null
          ? ''
          : get(
              propertyInput.analysisInput,
              findResellName(propertyInput, strategy),
            ),
      xs: 12,
      lg: 6,
      details: true,
      show: !get(
        property,
        `acquisition.analysis.${findResellName(
          propertyInput,
          strategy,
        )}`,
      ),
      tooltipField:
        'The price you will resell the property for once the rehab is completed.',
    },
  ]

  const getPropertyPreview = (property) => {
    const marketplaceAnalytics = compact(
      map(selectedPrimaryFields, (field) => {
        const analytic =
          find(
            primaryFields,
            (primary) => primary.label === field.label,
          ) || {}
        return {
          ...analytic,
          arrow: get(property, analytic.target + 'Arrow'),
        }
      }),
    )

    return {
      ...property,
      marketplaceStrategy: strategy,
      status,
      marketplaces: {
        expirationDate: new Date(),
        extraTags: extras,
      },
      marketplaceAnalytics,
      hideAddress: hide,
      hideDaysOnMarketplace: hideDays,
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'image/*, application/pdf',
    onDrop: async (acceptedFiles) => {
      setLoading(true)
      setFileLoading(true)
      let files = [...marketplaceFiles]
      const newFiles = await Promise.all(
        map(acceptedFiles, async (file) => {
          const {
            data: { getSignedRequest },
          } = await props.getSignedRequest({
            variables: {
              fileInput: {
                filename: file.name,
                filetype: file.type,
                collection: 'marketplace',
                documentId: props.property._id,
              },
            },
          })
          const { signedRequest, url } = getSignedRequest

          const options = {
            headers: {
              'Content-type': file.type,
            },
          }
          try {
            await axios.put(signedRequest, file, options)
            const { profile = {} } = props.session.me

            return {
              filename: file.name,
              filesize: file.size,
              filetype: file.type,
              url: url,
              createdAt: new Date(),
              createdBy: `${profile.firstname} ${profile.lastname}`,
            }
          } catch (error) {
            return error
          }
        }),
      )
      setMarketplaceFiles(concat(files, newFiles))
      setLoading(false)
      setFileLoading(false)
    },
  })

  return (
    <>
      {actionsButton && !props.edit && (
        <Button
          href={isEmpty(options) ? '/settings/marketplace' : null}
          target="_blank"
          fullWidth={true}
          size="small"
          className="btn-success"
          onClick={
            isEmpty(options)
              ? null
              : props.disabled ||
                  (!isEmpty(property) &&
                    !findResellPrice(property, strategy))
                ? handleOpenDisabled
                : handleOpenListOnMarketplace
          }
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'sign-hanging']} />
          </span>
          <span className="btn-wrapper--label">
            List on marketplace
          </span>
        </Button>
      )}
      {actionsButton && props.edit && (
        <>
          <Grid item xs={12}>
            <Button
              href={isEmpty(options) ? '/settings/marketplace' : null}
              target="_blank"
              fullWidth={true}
              size="small"
              className="btn-success"
              onClick={
                isEmpty(options)
                  ? null
                  : props.disabled ||
                      (!isEmpty(property) &&
                        !findResellPrice(property, strategy))
                    ? handleOpenDisabled
                    : handleOpenListOnMarketplace
              }
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'upload']} />
              </span>
              <span className="btn-wrapper--label">
                Update Listing
              </span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => updateMarketplaces(true)}
              disabled={loading}
              fullWidth={true}
              size="small"
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['far', 'circle-xmark']} />
              </span>
              <span className="btn-wrapper--label">
                Remove Listing
              </span>
            </Button>
          </Grid>
        </>
      )}
      {bulk && (
        <>
          {!isEmpty(props.properties) && (
            <MenuItem
              component={isEmpty(options) ? 'a' : null}
              href="/settings/marketplace"
              target="_blank"
              button
              onClick={
                isEmpty(options) ? null : handleOpenListOnMarketplace
              }
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'upload']} />
              </span>
              <span className="btn-wrapper--label">
                List Properties
              </span>
            </MenuItem>
          )}
          {!isEmpty(props.selected) && (
            <MenuItem
              button
              onClick={() => bulkUpdateMarketplacesGrouping(true)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['far', 'circle-xmark']} />
              </span>
              <span className="btn-wrapper--label">
                Delist Properties
              </span>
            </MenuItem>
          )}
        </>
      )}
      <Dialog
        open={openListOnMarketplace}
        onClose={handleCloseListOnMarketplace}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth={activeStep == 0 ? 'md' : 'sm'}
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        <ValidatorForm
          onSubmit={
            activeStep === steps.length - 1
              ? bulk
                ? () => bulkUpdateMarketplacesGrouping()
                : () => updateMarketplaces()
              : handleNext
          }
        >
          <DialogTitle>List on Marketplace</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            {/* <form onSubmit={() => updateMarketplaces()}> */}
            <BlockUi
              blocking={loading}
              loader={
                <PacmanLoader
                  color={'var(--primary)'}
                  loading={true}
                />
              }
              message={
                <div className="text-primary">
                  {fileLoading
                    ? 'Uploading Files'
                    : 'Updating Listing Status'}
                </div>
              }
            >
              {/* <Card className="card-box"> */}
              {/* <div className="card-header">
                  <div className="card-header--title">
                    <Typography variant='h6'>List on Marketplace</Typography>
                  </div>
                </div> */}
              {/* <div> */}
              <div className="bg-secondary mb-3">
                <Stepper
                  style={{ padding: 24 }}
                  className="stepper-horizontal-1"
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<StepConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <span>
                <div
                  style={{
                    maxHeight: window.innerHeight - 300,
                    overflow: 'scroll',
                  }}
                >
                  {activeStep === 0 && (
                    <Container>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Grid item xs={12}>
                            <TextField
                              select
                              variant="outlined"
                              margin="dense"
                              label="Status"
                              fullWidth={true}
                              value={status}
                              onChange={(event) =>
                                setStatus(event.target.value)
                              }
                              required={true}
                            >
                              <MenuItem
                                key={'Coming Soon'}
                                value={'Coming Soon'}
                              >
                                {'Coming Soon'}
                              </MenuItem>
                              <MenuItem
                                key={'Available'}
                                value={'Available'}
                              >
                                {'Available'}
                              </MenuItem>
                              <MenuItem
                                key={'New Construction'}
                                value={'New Construction'}
                              >
                                {'New Construction'}
                              </MenuItem>
                              <MenuItem
                                key={'Active'}
                                value={'Active'}
                              >
                                {'Active'}
                              </MenuItem>
                              <MenuItem
                                key={'Pending'}
                                value={'Pending'}
                              >
                                {'Pending'}
                              </MenuItem>
                              <MenuItem
                                key={'Contingent'}
                                value={'Contingent'}
                              >
                                {'Contingent'}
                              </MenuItem>
                              <MenuItem key={'Sold'} value={'Sold'}>
                                {'Sold'}
                              </MenuItem>
                              <MenuItem
                                key={'Under Contract'}
                                value={'Under Contract'}
                              >
                                {'Under Contract'}
                              </MenuItem>
                              <MenuItem
                                key={'Due Diligence'}
                                value={'Due Diligence'}
                              >
                                {'Due Diligence'}
                              </MenuItem>
                              <MenuItem
                                key={'Closed'}
                                value={'Closed'}
                              >
                                {'Closed'}
                              </MenuItem>
                              <MenuItem key={'Rehab'} value={'Rehab'}>
                                {'Rehab'}
                              </MenuItem>
                              <MenuItem
                                key={'Under Mgmt'}
                                value={'Under Mgmt'}
                              >
                                {'Under Mgmt'}
                              </MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              select
                              variant="outlined"
                              margin="dense"
                              label="Display Analysis"
                              fullWidth={true}
                              value={strategy}
                              onChange={(event) => {
                                const { value } = event.target
                                setStrategy(value)
                                if (
                                  !isEmpty(props.property) &&
                                  !findResellPrice(
                                    props.property,
                                    value,
                                  )
                                ) {
                                  handleCloseListOnMarketplace()
                                  handleOpenDisabled()
                                }
                              }}
                              required={true}
                            >
                              {map(strategies, (option) => (
                                <MenuItem key={option} value={option}>
                                  {getStrategyName(
                                    option,
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                  )}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              select
                              variant="outlined"
                              margin="dense"
                              label="Proforma Type"
                              fullWidth={true}
                              value={proformaType}
                              onChange={(event) =>
                                setProformaType(event.target.value)
                              }
                              required={true}
                            >
                              <MenuItem
                                key={'Fix & Flip'}
                                value={'Fix & Flip'}
                              >
                                {getStrategyName(
                                  'Fix & Flip',
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              </MenuItem>
                              <MenuItem
                                key={'Buy & Hold'}
                                value={'Buy & Hold'}
                              >
                                {getStrategyName(
                                  'Buy & Hold',
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              </MenuItem>
                              <MenuItem
                                key={'Turnkey End Buyer'}
                                value={'Turnkey End Buyer'}
                              >
                                {getStrategyName(
                                  'Turnkey End Buyer',
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              </MenuItem>
                              <MenuItem
                                key={'Short Term Rental'}
                                value={'Short Term Rental'}
                              >
                                {getStrategyName(
                                  'Short Term Rental',
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              </MenuItem>
                              <MenuItem
                                key={'Wholesale'}
                                value={'Wholesale'}
                              >
                                {getStrategyName(
                                  'Wholesale',
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              </MenuItem>
                              {props?.session?.me?.activeOrg
                                ?.member === 'astroflip' && (
                                <MenuItem
                                  key={'Astroflip'}
                                  value={'Astroflip'}
                                >
                                  {getStrategyName(
                                    'Astroflip',
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                  )}
                                </MenuItem>
                              )}
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              variant="outlined"
                              margin="dense"
                              style={{ width: '100%' }}
                            >
                              <InputLabel id="extraTags-multiple-chip-label">
                                Extra Tags
                              </InputLabel>
                              <Select
                                labelId="extraTags-multiple-chip-label"
                                name="marketingExtraTags"
                                multiple
                                fullWidth={true}
                                variant="outlined"
                                margin="dense"
                                label="Extra Tags"
                                value={filter(extras, (extra) =>
                                  includes(extraOptions, extra),
                                )}
                                onChange={(event) => {
                                  const { value } = event.target
                                  if (value.length <= 2)
                                    setExtras(value)
                                }}
                                renderValue={(value) => (
                                  <>
                                    {map(value, (v, index) => (
                                      <Chip
                                        color="primary"
                                        label={v}
                                        style={{ marginRight: 5 }}
                                      />
                                    ))}
                                  </>
                                )}
                              >
                                {map(extraOptions, (option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                  >
                                    <Checkbox
                                      checked={includes(
                                        extras,
                                        option,
                                      )}
                                    />
                                    <Grid
                                      container
                                      alignItems="center"
                                    >
                                      <Grid item>{option}</Grid>
                                      <Typography
                                        style={{ flex: 1 }}
                                      />
                                      <RemoveOption
                                        removeOption="marketingExtraTags"
                                        removeOptionLabel="Tag"
                                        removeOptionValue={option}
                                      />
                                    </Grid>
                                  </MenuItem>
                                ))}
                                <AddOption
                                  currentValue={filter(
                                    extras,
                                    (extra) =>
                                      includes(extraOptions, extra),
                                  )}
                                  addOption="marketingExtraTags"
                                  addOptionLabel="Tag"
                                  addOptionTarget="marketingExtraTags"
                                  onChangeProperty={(event) => {
                                    const { value } = event.target
                                    if (value.length <= 2)
                                      setExtras(value)
                                  }}
                                />
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <Autocomplete
                              multiple
                              disableCloseOnSelect
                              //id="fixed-tags-demo"
                              options={primaryFields}
                              getOptionLabel={(option) =>
                                option.label
                              }
                              filterSelectedOptions
                              isOptionEqualToValue={isEqual}
                              value={selectedPrimaryFields}
                              disabled={!strategy}
                              onChange={(event, newValue) => {
                                if (newValue.length <= 4)
                                  setSelectedPrimaryFields(newValue)
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    color="primary"
                                    label={option.label}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Primary Fields*"
                                  variant="outlined"
                                  margin="dense"
                                  placeholder="Primary Fields"
                                  fullWidth={true}
                                  helperText="These are the fields that are displayed on the card view and at the top of the property details page for each property. Please at least 1"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              disableCloseOnSelect
                              variant="outlined"
                              margin="dense"
                              label="List on Marketplace(s)"
                              options={options}
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) =>
                                value === option.url
                              }
                              filterSelectedOptions
                              fullWidth={true}
                              value={marketplaces}
                              onChange={(event, newValue) => {
                                setMarketplaces(
                                  map(newValue, (value) => {
                                    if (value.url) return value.url
                                    return value
                                  }),
                                )
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="List on Marketplace(s)*"
                                />
                              )}
                              renderTags={(value, getTagProps) => (
                                <>
                                  {map(value, (v, index) => (
                                    <Chip
                                      color="primary"
                                      label={
                                        find(
                                          options,
                                          (option) =>
                                            option.url === v,
                                        ).name
                                      }
                                      {...getTagProps({ index })}
                                    />
                                  ))}
                                </>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={hide}
                                    onClick={() => setHide(!hide)}
                                    id="Hide Address on external marketplace listing"
                                  />
                                }
                                label="Hide Address on external marketplace listing"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={hideDays}
                                    onClick={() =>
                                      setHideDays(!hideDays)
                                    }
                                    id="Hide Days on Marketplace on external marketplace listing"
                                  />
                                }
                                label="Hide Days On Marketplace on external marketplace listing"
                              />
                            </FormGroup>
                          </Grid>
                          {allowNullFields && (
                            <Grid item xs={12}>
                              <Typography>
                                Since all selected properties are
                                already listed on a marketplace,
                                required fields can be left blank if
                                you do not wish for its value to
                                change
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <PublicPropertyCard
                            property={
                              bulk
                                ? getPropertyPreview(
                                    head(props.properties),
                                  )
                                : getPropertyPreview(property)
                            }
                            marketplace="null"
                            subDomainParams=""
                            shared
                            fullWidth
                            preview
                          />
                        </Grid>
                      </Grid>
                    </Container>
                  )}
                  {activeStep === 1 && (
                    <Container>
                      <Grid item>Marketplace Video URL</Grid>
                      <Grid item className="my-4">
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="Video URL"
                          value={videoWalkthrough}
                          onChange={(event) =>
                            setVideoWalkthrough(event.target.value)
                          }
                          required={false}
                          validators={[
                            //'maxStringLength: 100',
                            'matchRegexp:^[^ ]+$',
                          ]}
                          errorMessages={[
                            //'You cannot exceed 100 characters',
                            'This field does not accept spaces',
                          ]}
                        />
                      </Grid>
                      <Grid item>Marketplace Files</Grid>
                      <Grid item style={{ marginTop: 5 }}>
                        <div className="dropzone">
                          <div
                            {...getRootProps({
                              className: 'dropzone-upload-wrapper',
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="dropzone-inner-wrapper">
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                              >
                                <Typography>Upload Files</Typography>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <div>
                        {marketplaceFiles.length <= 0 && (
                          <div className="text-info text-center font-size-sm">
                            Uploaded files will appear here!
                          </div>
                        )}
                        {marketplaceFiles.length > 0 && (
                          <div>
                            {/* <Alert severity="success" className="text-center mb-3">
                            You have uploaded <b>{files.length}</b> files!
                          </Alert> */}
                            <List
                              component="div"
                              className="font-size-sm"
                            >
                              {map(
                                marketplaceFiles,
                                (file, index) => (
                                  <ListItem
                                    className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
                                    key={file.filename}
                                  >
                                    {startsWith(file.url, 'data:') ? (
                                      <a
                                        onClick={() => {
                                          var iframe =
                                            "<iframe width='100%' height='100%' src='" +
                                            file.url +
                                            "'></iframe>"
                                          var x = window.open()
                                          x.document.open()
                                          x.document.write(iframe)
                                          x.document.close()
                                        }}
                                        rel="noopener noreferrer"
                                      >
                                        <span>{file.filename}</span>{' '}
                                      </a>
                                    ) : (
                                      <a
                                        href={file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span>{file.filename}</span>{' '}
                                      </a>
                                    )}

                                    {/* <span className="badge badge-pill bg-neutral-warning text-warning">
                                  {file.filesize} bytes
                                </span> */}
                                    <IconButton
                                      onClick={() => {
                                        setLoading(true)
                                        setFileLoading(true)
                                        let newFiles = [
                                          ...marketplaceFiles,
                                        ]
                                        pullAt(newFiles, index)
                                        setMarketplaceFiles(newFiles)
                                        setLoading(false)
                                        setFileLoading(false)
                                      }}
                                      size="large"
                                    >
                                      <FontAwesomeIcon
                                        icon={['far', 'circle-xmark']}
                                        className="font-size-md"
                                      />
                                    </IconButton>
                                  </ListItem>
                                ),
                              )}
                            </List>
                          </div>
                        )}
                      </div>
                    </Container>
                  )}
                </div>
              </span>
            </BlockUi>
            {/* </form> */}
          </DialogContent>
          <DialogActions>
            {/* {activeStep !== 0 && (
            <Button disabled={loading} className="btn-primary font-weight-bold" onClick={handleBack}>
              Back
            </Button>
          )} */}
            {/* {activeStep === 0 && ( */}
            <Button
              /*disabled={activeStep === 0}*/ className="btn-secondary font-weight-bold"
              onClick={handleCloseListOnMarketplace}
            >
              Cancel
            </Button>
            {/* )} */}
            <Typography style={{ flex: 1 }} />
            {activeStep !== 0 && (
              <Button
                className="btn-primary font-weight-bold"
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Button
              className="btn-primary font-weight-bold"
              type="submit"
              disabled={disabled || loading}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
      <Dialog
        open={openDisabled}
        onClose={handleCloseDisabled}
        classes={{ paper: 'shadow-lg rounded' }}
        onKeyDown={stopPropagationForTab}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['far', 'sign-hanging']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            List on Marketplace
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            In order to be listed on the marketplace, a property must
            have a city, state, # beds, # baths, sqft, year, a resale
            price, and at least 2 images.
          </p>
          <Grid container justifyContent="space-around" spacing={2}>
            {(sum(property.beds) === 0 ||
              sum(property.baths) === 0 ||
              sum(property.sqft) === 0 ||
              !property.year ||
              !property.city ||
              !property.state) && (
              <>
                <Grid item xs={12}>
                  <List
                    className="nav-line d-flex mt-3"
                    style={{ width: propertyInput.beds.length * 100 }}
                  >
                    {propertyInput.beds.length > 1 ? (
                      map(propertyInput.beds, (value, index) => (
                        <ListItem
                          button
                          disableRipple
                          selected={activeUnit === index}
                          onClick={() => {
                            setActiveUnit(index)
                          }}
                        >
                          Unit {index + 1}
                        </ListItem>
                      ))
                    ) : (
                      <Typography>Property</Typography>
                    )}
                  </List>
                </Grid>
                {map(propertyFields, (field) => {
                  if (field.show)
                    return (
                      <Grid
                        item
                        xs={field.xs}
                        sm={field.sm}
                        md={field.md}
                        lg={field.lg}
                        xl={field.xl}
                        //alignItems='center'
                      >
                        <TextField
                          name={field.name}
                          variant="outlined"
                          margin="dense"
                          value={field.value || ''}
                          onChange={(event) =>
                            field.onChange
                              ? field.onChange(event)
                              : onChangeProperty(event)
                          }
                          onBlur={field.onBlur ? field.onBlur : null}
                          placeholder={field.label}
                          label={field.label}
                          type={
                            field.type === 'percentage'
                              ? 'number'
                              : field.type
                          }
                          fullWidth={true}
                          disabled={field.disabled}
                          required={field.required}
                        />
                      </Grid>
                    )
                })}
              </>
            )}
            {map(analysisInputFields, (field) => {
              if (field.show)
                return (
                  <Grid
                    item
                    xs={field.xs}
                    sm={field.sm}
                    md={field.md}
                    lg={field.lg}
                    xl={field.xl}
                  >
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator={true}
                      prefix={'$'}
                      fullWidth={true}
                      isNumericString={true}
                      decimalScale={0}
                      key={field.name}
                      name={field.name}
                      value={Math.round(field.value) || ''}
                      disabled={field.disabled}
                      onValueChange={(values) => {
                        const { value } = values
                        const event = {
                          target: {
                            name: field.name,
                            value: value,
                            type: 'number',
                          },
                          preventDefault: () => {
                            return null
                          },
                        }
                        if (field.disabled) return null
                        else if (field.details)
                          onChangeProperty(
                            event,
                            field.definition ? 'userInput' : null,
                          )
                      }}
                      type={'text'}
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      variant="outlined"
                      margin="dense"
                      label={field.label}
                      fontSize={field.font}
                      required={field.required}
                    />
                  </Grid>
                )
            })}
            {property.images && property.images.length < 2 && (
              <Grid item xs={12}>
                <ImageUploader propertyId={property._id} />
              </Grid>
            )}
          </Grid>
        </div>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDisabled}>
            <span className="btn-wrapper--label">Close</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              property.images
                ? property.images.length < 2
                : false ||
                  sum(propertyInput.beds) === 0 ||
                  sum(propertyInput.baths) === 0 ||
                  sum(propertyInput.sqft) === 0 ||
                  !propertyInput.year ||
                  !propertyInput.state ||
                  !propertyInput.city ||
                  !propertyInput.analysisInput.resellPrice
            }
          >
            <span className="btn-wrapper--label">{'Save'}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default graphql(GET_SIGNED_REQUEST, {
  name: 'getSignedRequest',
})(withSession(ListOnMarketplaceComponent))
