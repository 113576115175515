import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import find from 'lodash/find'
import head from 'lodash/head'
import omitBy from 'lodash/omitBy'
import includes from 'lodash/includes'
import toLower from 'lodash/toLower'
import isString from 'lodash/isString'
import get from 'lodash/get'
import set from 'lodash/set'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import cloneDeep from 'lodash/cloneDeep'
import pullAt from 'lodash/pullAt'
import merge from 'lodash/merge'
import filter from 'lodash/filter'
import truncate from 'lodash/truncate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import moment from 'moment'

import AddMarketplace from '@/housefolios-components/Marketplace/AddMarketplace'
import BonusProperties from '@/housefolios-components/Properties/BonusProperties'
import CreatePortfolio from '@/housefolios-components/Portfolio/CreatePortfolio'
import DeletePortfolio from '@/housefolios-components/Portfolio/DeletePortfolio'
import DuplicatePortfolio from '@/housefolios-components/Portfolio/DuplicatePortfolio'
import EditPortfolio from '@/housefolios-components/Portfolio/EditPortfolio'
import EditAssumptions from '@/housefolios-components/AssumptionsSettings/EditAssumptions'
import EditMarketplace from '@/housefolios-components/Marketplace/EditMarketplace'
import SharePortfolio from '@/housefolios-components/Portfolio/SharePortfolio'
import DeleteProperties from '@/housefolios-components/Properties/DeleteProperties'

import {
  AppBar,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Collapse,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Grow,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableCell,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import {
  setSidebarToggleMobile,
  setSidebarToggle,
} from '../../reducers/ThemeOptions'

import map from 'lodash/map'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'

import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import FolderOpenTwoToneIcon from '@mui/icons-material/FolderOpenTwoTone'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import SendIcon from '@mui/icons-material/Send'
import LaunchIcon from '@mui/icons-material/Launch'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone'
import { useMutation } from '@apollo/client'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_TEAM } from '@/housefolios-components/Team/queries'
import { Share, Warehouse } from '@mui/icons-material'
import ShareMarketplace from '@/housefolios-components/Marketplace/ShareMarketplace'
import { GET_TRASH } from '@/housefolios-components/Properties/queries'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { Mutation, Query } from '@apollo/client/react/components'
import { enqueueSnackbar } from 'notistack'
import RemoveSharedPortfolio from '@/housefolios-components/Portfolio/RemoveSharedPortfolio'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import {
  RESET_CALCS,
  UPDATE_USER_BY_EMAIL,
} from '@/housefolios-components/ReportsComponents/mutations'
import { GET_AFFILIATES } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import Pagination from '@mui/material/Pagination'
import Add from '@mui/icons-material/Add'
import { Chat } from '@mui/icons-material'
import {
  ADD_AFFILIATE,
  UPDATE_AFFILIATE,
} from '@/housefolios-components/PartnerPortal/mutations'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { unset } from 'lodash'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import RemoveSharedMarketplace from '@/housefolios-components/Marketplace/RemoveSharedMarketplace'
import SkipTrace from '@/housefolios-components/Dialogs/SkipTrace'
import useSorting, {
  getSorting,
  stableSort,
} from '@/hooks/useSorting'
import useAffiliate from '@/hooks/useAffiliate'
import ContactDialog from '@/housefolios-components/Dialogs/ContactSupport'
// import TempChat from '@/housefolios-components/TempChat';

const DEFAULT_AFFILIATE_VALUES = {
  defaultStrategy: 'Buy & Hold',
  createAccount: {
    memberTrial: 3,
  },
  launchpad: {
    memberLayout: true,
  },
}

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  //  root: {
  //    display: 'flex',
  //    height: '100%'
  //  },
  //  appBar: {
  //    width: `calc(100% - ${drawerWidth}px)`,
  //    marginLeft: drawerWidth,
  //  },
  //  drawer: {
  //    width: drawerWidth,
  //    flexShrink: 0,
  //  },
  //   drawerPaper: {
  //     width: drawerWidth,
  //   },
  //   title: {
  //     color: 'white',
  //   },
  //   // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  //   content: {
  //     flexGrow: 1,
  //     backgroundColor: theme.palette.background.default,
  //     padding: theme.spacing(3),
  //   },
  //   accordionRoot: {
  //     width: '100%',
  //   },
  //   accordionHeading: {
  //     fontSize: theme.typography.pxToRem(20),
  //     flexBasis: '33.33%',
  //     flexShrink: 0,
  //     fontWeight: 550,
  //   },
  sortableHelper: {
    zIndex: 5000,
  },
}))

const SidebarMenu = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { setOpenFeedbackTour } = props
  const { activeOrg } = props.session.me
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)

  const [anchorElMenu2, setAnchorElMenu2] = useState(null)
  const [canDelete, setCanDelete] = useState(false)

  const [anchorElMenu1, setAnchorElMenu1] = useState(null)

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const [{ affiliate, urlAffiliate }] = useAffiliate({
    memberValue: props.session.me.activeOrg?.member,
  })

  function openPortfolioOptions(event, portfolio, canDelete) {
    setAnchorElMenu2(event.currentTarget)
    setSelectedPortfolio(portfolio)
    setCanDelete(canDelete)
    event.preventDefault()
    event.stopPropagation()
  }

  // function openSharedPortfolioOptions(event, portfolio) {
  //   setAnchorElMenu1(event.currentTarget);
  //   setSelectedPortfolio(portfolio);
  //   setCanDelete(false)
  //   event.preventDefault();
  // }
  function handleClosePortfolioOptions() {
    setAnchorElMenu1(null)
    setAnchorElMenu2(null)
    //setSelectedPortfolio(null);
  }

  const [openHousefoliosAdmin, setOpenHousefoliosAdmin] =
    useState(false)
  const [adminTab, setAdminTab] = useState('General')
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    //localStorage.setItem('reports-page', value)
    setPage(value)
  }

  const [{ order, orderBy }, { handleRequestSort }] = useSorting({
    defaultOrder: 'asc',
    defaultOrderBy: 'createdAt',
  })

  function handleOpenHousefoliosAdmin() {
    setOpenHousefoliosAdmin(true)
    setAdminTab('General')
    setPage(1)
  }

  function handleCloseHousefoliosAdmin() {
    setOpenHousefoliosAdmin(false)
    setAdminTab('General')
    setPage(1)
  }

  const listItems = [
    {
      name: 'General',
      icon: <SettingsTwoToneIcon />,
    },
    {
      name: 'Affiliate',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'folder-gear']}
          style={{ fontSize: 24 }}
        />
      ),
    },
  ]

  const [open, setOpen] = useState(true)
  function handleDrawerOpen() {
    setOpen(true)
  }
  function handleDrawerClose() {
    setOpen(false)
  }

  const [adminEmail, setAdminEmail] = useState('')

  const [updateUserByEmail] = useMutation(UPDATE_USER_BY_EMAIL)
  const [addAffiliate] = useMutation(ADD_AFFILIATE)
  const [updateAffiliate] = useMutation(UPDATE_AFFILIATE)
  const [resetCalcs] = useMutation(RESET_CALCS)

  const [anchorElAffiliateMenu, setAnchorElAffiliateMenu] =
    useState(null)
  const [affiliateInput, setAffiliateInput] = useState(
    cloneDeep(DEFAULT_AFFILIATE_VALUES),
  )
  const [openAffiliateEdit, setOpenAffiliateEdit] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const handleClickOpen = () => {
    setOpenDialog(true) // Open the dialog
  }

  const handleClose = () => {
    setOpenDialog(false) // Close the dialog
  }

  function handleOpenAffiliateOptions(event, affiliate) {
    setAnchorElAffiliateMenu(event.currentTarget)
    setAffiliateInput(affiliate)
    event.preventDefault()
    event.stopPropagation()
  }

  function handleCloseAffiliateOptions() {
    setAnchorElAffiliateMenu(null)
    setAffiliateInput(cloneDeep(DEFAULT_AFFILIATE_VALUES))
  }

  function handleOpenAffiliateEdit(event, affiliate = {}) {
    setOpenAffiliateEdit(true)
    // setAffiliateInput(affiliate);
    event.preventDefault()
    event.stopPropagation()
  }

  function handleCloseAffiliateEdit() {
    setOpenAffiliateEdit(false)
    setAffiliateInput(cloneDeep(DEFAULT_AFFILIATE_VALUES))
  }

  function onChangeAffiliate(event) {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAffiliateInput = cloneDeep(affiliateInput)
    set(newAffiliateInput, name, newValue)
    setAffiliateInput(newAffiliateInput)
  }

  const uploadFile = (target) => (files) => {
    let newAffiliateInput = cloneDeep(affiliateInput)
    let newFiles = []
    map(files, (file) => {
      newFiles.push(file)
    })
    set(newAffiliateInput, target, newFiles)
    setAffiliateInput(newAffiliateInput)
  }

  const deleteFile = (target) => (index) => {
    let newAffiliateInput = cloneDeep(affiliateInput)
    let newFiles = get(newAffiliateInput, target) || []
    pullAt(newFiles, index)
    set(newAffiliateInput, target, newFiles)
    setAffiliateInput(newAffiliateInput)
  }

  const renderInput = (field) =>
    field.type === 'select' ? (
      <Grid item xs={12} sm={6}>
        <TextValidator
          name={field.name}
          value={get(affiliateInput, field.name)}
          onChange={onChangeAffiliate}
          label={field.label}
          variant="outlined"
          fullWidth
          margin="dense"
          type={field.type}
          select
          validators={field.validators}
          errorMessages={field.errorMessages}
        >
          {map(field.options, (option) => (
            <MenuItem key={option.value} value={option.value}>
              <Grid container alignItems="center">
                <Grid item>{option.label}</Grid>
              </Grid>
            </MenuItem>
          ))}
        </TextValidator>
      </Grid>
    ) : field.type === 'file' ? (
      <>
        <Grid item xs={12} sm={6}>
          <Typography variant="subheader">{field.label}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUpload
            documentId={affiliateInput._id}
            files={get(affiliateInput, field.name) || []}
            uploadFile={uploadFile(field.name)}
            deleteFile={deleteFile(field.name)}
            filetypes={['image/*']}
            button
            buttonStyle={{ marginTop: 0, marginBottom: 0 }}
            //writeOnly={true}
            notMulti={true}
            collection="affiliateImages"
          />
        </Grid>
      </>
    ) : (
      <Grid item xs={12} sm={6}>
        <TextValidator
          name={field.name}
          value={get(affiliateInput, field.name)}
          onChange={onChangeAffiliate}
          label={field.label}
          variant="outlined"
          fullWidth
          margin="dense"
          helperText={field.helperText}
          type={field.type}
          validators={field.validators}
          errorMessages={field.errorMessages}
        />
      </Grid>
    )

  const [anchorElTrashMenu, setAnchorElTrashMenu] = useState(null)
  function openTrashOptions(event) {
    setAnchorElTrashMenu(event.currentTarget)
    event.preventDefault()
    event.stopPropagation()
  }

  function handleCloseTrashOptions() {
    setAnchorElTrashMenu(null)
  }

  const [anchorElMenu3, setAnchorElMenu3] = useState(null)
  const [selectedMarketplace, setSelectedMarketplace] = useState(null)

  function openMarketplaceOptions(event, marketplace) {
    setAnchorElMenu3(event.currentTarget)
    setSelectedMarketplace(marketplace)
    event.preventDefault()
    event.stopPropagation()
  }

  function handleCloseMarketplaceOptions() {
    setAnchorElMenu1(null)
    setAnchorElMenu2(null)
    setAnchorElMenu3(null)
  }

  //const { setSidebarToggleMobile, sidebarToggle } = props;
  const { setSidebarToggleMobile } = props

  const toggleSidebarMobile = () => setSidebarToggleMobile(false)

  const handlePortfolio = (portfolio) => {
    localStorage.setItem('portfolio', portfolio)
    toggleSidebarMobile()
  }

  const handleMarketplace = (marketplace) => {
    localStorage.setItem('marketplace', marketplace)
    toggleSidebarMobile()
  }

  const [portfoliosDropdownOpen, setPortfoliosDropdownOpen] =
    useState(false)
  const togglePortfoliosDropdown = (event) => {
    setPortfoliosDropdownOpen(!portfoliosDropdownOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const [marketplacesDropdownOpen, setMarketplacesDropdownOpen] =
    useState(false)
  const toggleMarketplacesDropdown = (event) => {
    setMarketplacesDropdownOpen(!marketplacesDropdownOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const [settingsDropdownOpen, setSettingsDropdownOpen] =
    useState(false)
  const toggleSettingsDropdown = (event) => {
    setSettingsDropdownOpen(!settingsDropdownOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const [supportDropdownOpen, setSupportDropdownOpen] =
    useState(false)
  const toggleSupportDropdown = (event) => {
    setSupportDropdownOpen(!supportDropdownOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const [partnerDropdownOpen, setPartnerDropdownOpen] =
    useState(false)
  const togglePartnerDropdown = (event) => {
    setPartnerDropdownOpen(!partnerDropdownOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  const [submittedDropdownOpen, setSubmittedDropdownOpen] =
    useState(false)
  const toggleSubmittedDropdown = (event) => {
    setSubmittedDropdownOpen(!submittedDropdownOpen)
    event.preventDefault()
    event.stopPropagation()
  }

  var today = new Date()
  var resetDate = !isEmpty(props.session.me.activeOrg.subscriptions)
    ? props.session.me.activeOrg.subscriptions[0].current_period_end
      ? new Date(
          props.session.me.activeOrg.subscriptions[0]
            .current_period_end * 1000,
        )
      : null
    : props.session.me.activeOrg.parentCorp &&
        !isEmpty(props.session.me.activeOrg.parentCorp.subscriptions)
      ? props.session.me.activeOrg.parentCorp.subscriptions[0]
          .current_period_end
        ? new Date(
            props.session.me.activeOrg.parentCorp.subscriptions[0]
              .current_period_end * 1000,
          )
        : null
      : !isEmpty(
            props?.session?.me?.activeOrg
              ?.memberProvidedSubscriptions,
          )
        ? props?.session?.me?.activeOrg
            ?.memberProvidedSubscriptions[0].current_period_end
          ? new Date(
              props?.session?.me?.activeOrg
                ?.memberProvidedSubscriptions[0].current_period_end *
                1000,
            )
          : null
        : null
  if (!resetDate) {
    var month = today.getMonth()
    resetDate = new Date()
    if (month === 11) {
      resetDate.setFullYear(resetDate.getFullYear() + 1, 0, 1)
    } else resetDate.setMonth(month + 1, 1)
  }
  if (
    (resetDate.getFullYear() > today.getFullYear() &&
      today.getMonth() !== 11) ||
    resetDate.getMonth() > today.getMonth() + 1 ||
    resetDate.getMonth() === today.getMonth() + 1 ||
    resetDate.getDate() > today.getDate()
  ) {
    if (resetDate.getDate() > today.getDate()) {
      resetDate.setFullYear(today.getFullYear(), today.getMonth())
      if (resetDate.getMonth() !== today.getMonth())
        resetDate.setDate(0)
    } else {
      resetDate.setFullYear(today.getFullYear(), today.getMonth() + 1)
      if (
        resetDate.getMonth() !== today.getMonth() + 1 &&
        resetDate.getMonth() !== 0
      )
        resetDate.setDate(0)
    }
  }
  const timeToReset = resetDate - today
  const daysToReset = Math.floor(timeToReset / 1000 / 60 / 60 / 24)

  const LIMIT = props.session.me.limits.propertyLimit
  const role = find(
    props.session.me.roles,
    (role) =>
      role.organization._id === props.session.me.activeOrg._id,
  )

  const [openShare, setOpenShare] = useState(false)
  const toggleShare = () => {
    setOpenShare(!openShare)
  }

  const [openMarkets, setOpenMarkets] = useState(false)
  const [selectedAffiliate, setSelectedAffiliate] = useState(
    head(activeOrg.affiliate),
  )
  const anchorRef = useRef(null)

  const toggleMarkets = () => {
    setOpenMarkets(!openMarkets)
  }

  const handleMarketsClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }
    setOpenMarkets(false)
  }

  return (
    <>
      <PerfectScrollbar>
        {/* {sidebarUserbox && <SidebarUserbox />} */}
        <div className="sidebar-navigation">
          {/* <div className="sidebar-header">
          <span>Navigation menu</span>
        </div> */}
          <ul>
            <li>
              <NavLink
                id="leftNavLaunchpad"
                className={({ isActive }) =>
                  isActive ? 'active' : ''
                }
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/launchpad"
              >
                <span className="sidebar-icon">
                  <FontAwesomeIcon
                    icon={['fad', 'rocket-launch']}
                    className="font-size-md"
                  />
                </span>
                Launchpad
              </NavLink>
            </li>
            {props?.session?.me?.activeOrg?.member !== 'RWN' && (
              <li>
                <NavLink
                  id="leftNavPropertySearch"
                  className={({ isActive }) =>
                    isActive ? 'active' : ''
                  }
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to="/property-search"
                >
                  <span className="sidebar-icon">
                    <SearchTwoToneIcon />
                  </span>
                  Property Search
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                id="leftNavMyProperties"
                className={({ isActive }) =>
                  isActive ? 'active' : ''
                }
                isActive={(match, location) => {
                  if (!match) {
                    return false
                  }
                  const storedPortfolio =
                    localStorage.getItem('portfolio')
                  return (
                    !storedPortfolio || storedPortfolio === 'null'
                  )
                }}
                onClick={() => handlePortfolio(null)}
                className="nav-link-simple"
                to="/my-properties"
              >
                <span className="sidebar-icon">
                  <HomeTwoToneIcon />
                </span>
                My Properties
              </NavLink>
            </li>
            {!isEmpty(
              props.session.me.activeOrg.submittedProperties,
            ) && (
              <li>
                <a
                  id="leftNavSettings"
                  href="#/"
                  onClick={toggleSubmittedDropdown}
                  className={clsx({ active: submittedDropdownOpen })}
                >
                  <span className="sidebar-icon">
                    <HomeTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">
                    Submitted Properties
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={submittedDropdownOpen}>
                  <ul>
                    {props.session.me.activeOrg.submittedProperties
                      .lender && (
                      <li>
                        <NavLink
                          id="leftNavSettingsPersonal"
                          onClick={() => handlePortfolio(null)}
                          to="/submitted-lender-properties"
                        >
                          Lender
                        </NavLink>
                      </li>
                    )}
                    {props.session.me.activeOrg.submittedProperties
                      .jv && (
                      <li>
                        <NavLink
                          id="leftNavSettingsPersonal"
                          onClick={() => handlePortfolio(null)}
                          to="/submitted-jv-properties"
                        >
                          JV Deals
                        </NavLink>
                      </li>
                    )}
                    {map(
                      filter(
                        props.session.me.activeOrg.marketPlaces,
                        (marketplace) =>
                          includes(
                            props.session.me.activeOrg
                              .submittedProperties.sharedMarketplaces,
                            marketplace.url,
                          ),
                      ),
                      (marketplace) => (
                        <li
                          key={marketplace.url}
                          className="list-group-item-action hover-show-hide-container"
                        >
                          <NavLink
                            name="leftNaveAddedMarketplace"
                            //id={"leftNavMarketplace" + marketplace.name}
                            onClick={() =>
                              handleMarketplace(marketplace.url)
                            }
                            to="/submitted-marketplace"
                            isActive={(match, location) => {
                              if (!match) {
                                return false
                              }
                              const storedMarketplace =
                                localStorage.getItem('marketplace')
                              return (
                                storedMarketplace === marketplace.url
                              )
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <Typography
                                  style={{ width: 180 }}
                                  noWrap
                                >
                                  {marketplace.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{ marginRight: -32, width: 8 }}
                              >
                                <Typography>
                                  {marketplace.submittedPropertyCount ||
                                    0}
                                </Typography>
                              </Grid>
                              {/* <Grid item className="hover-hide-wrapper" style={{ marginRight: -32, width: 8 }}>
                            <Typography>{marketplace.submittedPropertyCount}</Typography>
                          </Grid>
                          <Grid item className="hover-show-wrapper gridItem2" style={{ marginRight: -40 }}>
                            <Tooltip
                              title="Marketplace Actions"
                              classes={{ tooltip: 'tooltip-first' }}
                              arrow>
                              <IconButton
                                onClick={event => openMarketplaceOptions(event, marketplace)}
                                style={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                <MoreVertOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid> */}
                            </Grid>
                          </NavLink>
                        </li>
                      ),
                    )}
                    {/* {props.session.me.activeOrg.submittedProperties
                      .housefolios && (
                      <li>
                        <NavLink
                          id="leftNavMarketplacesHousefoliosMarketplace"
                          onClick={() => handleMarketplace(null)}
                          to="/submitted-marketplace"
                          isActive={(match, location) => {
                            if (!match) {
                              return false
                            }
                            const storedMarketplace =
                              localStorage.getItem('marketplace')
                            return storedMarketplace === 'null'
                          }}
                        >
                          {'Housefolios Marketplace'}
                        </NavLink>
                      </li>
                    )} */}
                    {props.session.me.activeOrg.submittedProperties
                      .pep && (
                      <li>
                        <NavLink
                          id="leftNavMarketplacesHousefoliosMarketplace"
                          onClick={() => handleMarketplace('pep')}
                          to="/submitted-marketplace"
                          isActive={(match, location) => {
                            if (!match) {
                              return false
                            }
                            const storedMarketplace =
                              localStorage.getItem('marketplace')
                            return storedMarketplace === 'null'
                          }}
                        >
                          {'Pep Marketplace'}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </Collapse>
              </li>
            )}
            {!isEmpty(
              props.session.me.activeOrg.sharedProperties,
            ) && (
              <li>
                <NavLink
                  id="leftNavLenderProperties"
                  className={({ isActive }) =>
                    isActive ? 'active' : ''
                  }
                  isActive={(match, location) => {
                    if (!match) {
                      return false
                    }
                    const storedPortfolio =
                      localStorage.getItem('portfolio')
                    return (
                      !storedPortfolio || storedPortfolio === 'null'
                    )
                  }}
                  onClick={() => handlePortfolio(null)}
                  className="nav-link-simple"
                  to="/shared-properties"
                >
                  <span className="sidebar-icon">
                    <HomeTwoToneIcon />
                  </span>
                  Shared Properties
                </NavLink>
              </li>
            )}
            {props.session.me.activeOrg.manageLots && (
              <li>
                <NavLink
                  id="leftNavMyLots"
                  className={({ isActive }) =>
                    isActive ? 'active' : ''
                  }
                  isActive={(match, location) => {
                    if (!match) {
                      return false
                    }
                    const storedPortfolio =
                      localStorage.getItem('portfolio')
                    return (
                      !storedPortfolio || storedPortfolio === 'null'
                    )
                  }}
                  onClick={() => handlePortfolio(null)}
                  className="nav-link-simple"
                  to="/my-lots"
                >
                  <span className="sidebar-icon">
                    <HomeTwoToneIcon />
                  </span>
                  My Lots
                </NavLink>
              </li>
            )}
            {props.session.me.activeOrg.manageCommercial && (
              <li>
                <NavLink
                  id="leftNavMyLots"
                  className={({ isActive }) =>
                    isActive ? 'active' : ''
                  }
                  isActive={(match, location) => {
                    if (!match) {
                      return false
                    }
                    const storedPortfolio =
                      localStorage.getItem('portfolio')
                    return (
                      !storedPortfolio || storedPortfolio === 'null'
                    )
                  }}
                  onClick={() => handlePortfolio(null)}
                  className="nav-link-simple"
                  to="/commercial"
                >
                  <span className="sidebar-icon">
                    <Warehouse />
                  </span>
                  Commercial
                </NavLink>
              </li>
            )}
            <li>
              <a
                id="leftNavSettings"
                data-tour="comps-step-4"
                href="#/"
                onClick={toggleSettingsDropdown}
                className={clsx({ active: settingsDropdownOpen })}
              >
                <span className="sidebar-icon">
                  <SettingsTwoToneIcon />
                </span>
                <span className="sidebar-item-label">Settings</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={settingsDropdownOpen}>
                <ul>
                  <li>
                    <NavLink
                      id="leftNavSettingsPersonal"
                      onClick={toggleSidebarMobile}
                      to="/settings/personal"
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={['far', 'user']}
                        size="1x"
                        fixedWidth
                      />
                      Personal
                    </NavLink>
                  </li>
                  {props?.session?.me?.activeOrg?.member !==
                    'RWN' && (
                    <li>
                      <NavLink
                        id="leftNavSettingsOrganization"
                        onClick={toggleSidebarMobile}
                        to="/settings/organization"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={['far', 'building']}
                          size="1x"
                          fixedWidth
                        />
                        Organization
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      id="leftNavSettingsAssumptions"
                      onClick={toggleSidebarMobile}
                      to="/settings/assumptions"
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={['fas', 'sliders']}
                        size="1x"
                        fixedWidth
                      />
                      Assumptions
                    </NavLink>
                  </li>
                  {props?.session?.me?.activeOrg?.member !==
                    'RWN' && (
                    <li>
                      <NavLink
                        id="leftNavSettingsTeam"
                        onClick={toggleSidebarMobile}
                        to="/settings/team"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={['far', 'user-group']}
                          size="1x"
                          fixedWidth
                        />
                        Team
                      </NavLink>
                    </li>
                  )}
                  {props?.session?.me?.activeOrg?.member !==
                    'RWN' && (
                    <li>
                      <NavLink
                        id="leftNavSettingsMarketplace"
                        onClick={toggleSidebarMobile}
                        to="/settings/marketplace"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={['far', 'sign-hanging']}
                          size="1x"
                          fixedWidth
                        />
                        Marketplace
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      id="leftNavSettingsBilling"
                      onClick={
                        props?.session?.me?.activeOrg?.isRealeflow
                          ? handleClickOpen
                          : toggleSidebarMobile
                      }
                      to={
                        props?.session?.me?.activeOrg?.isRealeflow
                          ? '#/'
                          : '/settings/billing'
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={['far', 'file-lines']}
                        size="1x"
                        fixedWidth
                      />
                      Billing
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <a
                id="leftNavSettings"
                href="#/"
                onClick={toggleSupportDropdown}
                className={clsx({ active: supportDropdownOpen })}
              >
                <span className="sidebar-icon">
                  <HelpTwoToneIcon />
                </span>
                <span className="sidebar-item-label">Support</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={supportDropdownOpen}>
                <ul>
                  <li>
                    <NavLink
                      id="leftNavSupportTutorial"
                      onClick={toggleSidebarMobile}
                      to="/training"
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={['far', 'user-graduate']}
                        size="1x"
                        fixedWidth
                      />
                      Training
                    </NavLink>
                  </li>
                  {!urlAffiliate && (
                    <li>
                      <NavLink
                        id="leftNavSupport"
                        className={({ isActive }) =>
                          isActive
                            ? 'active nav-link-simple'
                            : 'nav-link-simple'
                        }
                        data-tour="feedback-step-1"
                        to="/support-chat"
                      >
                        <Chat style={{ marginRight: 10 }} />
                        Live Chat
                      </NavLink>
                    </li>
                  )}
                  {!urlAffiliate && (
                    <li>
                      <a
                        id="leftNavFeedback"
                        href="#/"
                        onClick={() => setOpenFeedbackTour(true)}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={['fal', 'comment-dots']}
                          size="1x"
                          fixedWidth
                        />
                        <span className="sidebar-item-label">
                          Feedback
                        </span>
                      </a>
                    </li>
                  )}
                  {urlAffiliate && (
                    <>
                      <li>
                        <a
                          id="leftNavContactSupport"
                          href="#/"
                          onClick={handleClickOpen}
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: 10 }}
                            icon={['far', 'phone']}
                            size="1x"
                            fixedWidth
                          />
                          <span className="sidebar-item-label">
                            Contact Support
                          </span>
                        </a>
                      </li>
                    </>
                  )}
                  <ContactDialog
                    open={openDialog}
                    handleClose={handleClose}
                    activeOrg={props?.session?.me?.activeOrg?.member}
                  />
                </ul>
              </Collapse>
            </li>
            {/* <li>
            <NavLink
              id="leftNavSupport"
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={event => {event.preventDefault(); toggleSidebarMobile();}}
              className=" nav-link-simple"
              to="_blank"
            >
              <span className="sidebar-icon">
                <MessageTwoToneIcon />
              </span>
              Support
            </NavLink>
          </li> */}
          </ul>
          <Divider style={{ backgroundColor: '#46506c' }} />
          {props?.session?.me?.activeOrg?.member !== 'RWN' && (
            <ul>
              <li>
                <a
                  id="leftNavPortfolios"
                  href="#/"
                  onClick={togglePortfoliosDropdown}
                  className={clsx({ active: portfoliosDropdownOpen })}
                >
                  <span className="sidebar-icon">
                    <FolderOpenTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">
                    Portfolios
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Query
                  query={GET_PORTFOLIOS}
                  pollInterval={60 * 1000}
                >
                  {({ loading, error, refetch, data }) => {
                    if (loading) return ''
                    if (error) return `Error! ${error.message}`
                    const { portfolios } = data
                    return (
                      <Collapse in={portfoliosDropdownOpen}>
                        <ul>
                          {map(portfolios, (portfolio) => (
                            <li
                              key={portfolio._id}
                              className="list-group-item-action hover-show-hide-container"
                            >
                              <NavLink
                                onClick={() =>
                                  handlePortfolio(portfolio._id)
                                }
                                className={({ isActive }) =>
                                  isActive ? 'active' : ''
                                }
                                isActive={(match, location) => {
                                  if (!match) {
                                    return false
                                  }
                                  const storedPortfolio =
                                    localStorage.getItem('portfolio')
                                  return (
                                    storedPortfolio === portfolio._id
                                  )
                                }}
                                to="/my-properties"
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  <Grid item>
                                    <Typography
                                      style={{ width: 180 }}
                                      noWrap
                                    >
                                      {portfolio.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    className="hover-hide-wrapper"
                                    style={{
                                      marginRight: -32,
                                      width: 8,
                                    }}
                                  >
                                    <Typography>
                                      {portfolio.propertyCount || 0}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    className="hover-show-wrapper gridItem2"
                                    style={{ marginRight: -40 }}
                                  >
                                    <Tooltip
                                      title="Portfolio Actions"
                                      classes={{
                                        tooltip: 'tooltip-first',
                                      }}
                                      arrow
                                    >
                                      <IconButton
                                        onClick={(event) =>
                                          openPortfolioOptions(
                                            event,
                                            portfolio,
                                            portfolios.length > 1,
                                          )
                                        }
                                        style={{
                                          backgroundColor: 'white',
                                          width: 30,
                                          height: 30,
                                        }}
                                        size="large"
                                      >
                                        <MoreVertOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </NavLink>
                            </li>
                          ))}
                          <CreatePortfolio sidebarMenu={true} />
                          {!isEmpty(
                            props.session.me.activeOrg
                              .sharedPortfolios,
                          ) && (
                            <ListItem
                              button
                              disabled
                              style={{
                                color: 'white',
                                justifyContent: 'flex-start', // Align everything to the left
                                paddingLeft: 0, // Remove default padding
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Share
                                  style={{
                                    marginRight: 10,
                                    color: 'white',
                                  }}
                                />
                                Shared Portfolios
                              </div>
                            </ListItem>
                          )}
                          {map(
                            props.session.me.activeOrg
                              .sharedPortfolios,
                            (portfolio) => (
                              <li
                                key={portfolio._id}
                                className="list-group-item-action hover-show-hide-container"
                              >
                                <NavLink
                                  onClick={() =>
                                    handlePortfolio(portfolio._id)
                                  }
                                  className={({ isActive }) =>
                                    isActive ? 'active' : ''
                                  }
                                  isActive={(match, location) => {
                                    if (!match) {
                                      return false
                                    }
                                    const storedPortfolio =
                                      localStorage.getItem(
                                        'portfolio',
                                      )
                                    return (
                                      storedPortfolio ===
                                      portfolio._id
                                    )
                                  }}
                                  to="/my-properties"
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  >
                                    <Grid item>
                                      <Typography
                                        style={{ width: 180 }}
                                        noWrap
                                      >
                                        {portfolio.name}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      className="hover-hide-wrapper"
                                      style={{
                                        marginRight: -32,
                                        width: 8,
                                      }}
                                    >
                                      <Typography>
                                        {portfolio.propertyCount || 0}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      className="hover-show-wrapper"
                                      style={{ marginRight: -40 }}
                                    >
                                      <RemoveSharedPortfolio
                                        orgId={
                                          props.session.me.activeOrg
                                            ._id
                                        }
                                        portfolioId={portfolio._id}
                                        sidebarMenu
                                      />
                                    </Grid>
                                  </Grid>
                                </NavLink>
                              </li>
                            ),
                          )}
                        </ul>
                      </Collapse>
                    )
                  }}
                </Query>
              </li>
            </ul>
          )}
          <Divider style={{ backgroundColor: '#46506c' }} />
          <ul>
            <Query query={GET_TRASH}>
              {({ loading, error, refetch, data }) => {
                if (loading) return ''
                if (error) return `Error! ${error.message}`
                const { trashedProperties } = data
                return (
                  <li className="list-group-item-action hover-show-hide-container">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? 'active' : ''
                      }
                      isActive={(match, location) => {
                        return match
                      }}
                      to="/trash"
                    >
                      <span className="sidebar-icon">
                        <FontAwesomeIcon
                          size="sm"
                          icon={['far', 'trash']}
                        />
                      </span>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        className="sidebar-item-label"
                      >
                        <Grid item>
                          <span>Trash</span>
                        </Grid>
                        <Grid
                          item
                          className="hover-hide-wrapper"
                          style={{ marginRight: 10, width: 8 }}
                        >
                          <Typography>
                            {trashedProperties.length}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          className="hover-show-wrapper gridItem2" /*style={{ marginRight: -40 }}*/
                        >
                          <Tooltip
                            title="Trash Options"
                            classes={{ tooltip: 'tooltip-first' }}
                            arrow
                          >
                            <IconButton
                              onClick={openTrashOptions}
                              style={{
                                backgroundColor: 'white',
                                width: 30,
                                height: 30,
                              }}
                              size="large"
                            >
                              <MoreVertOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </NavLink>
                    <Menu
                      anchorEl={anchorElTrashMenu}
                      keepMounted
                      open={Boolean(anchorElTrashMenu)}
                      onClose={handleCloseTrashOptions}
                      classes={{ list: 'p-0' }}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div>
                        <List
                          component="div"
                          className="nav-list-square nav-neutral-primary"
                          onClick={handleCloseTrashOptions}
                        >
                          <DeleteProperties
                            sidebarMenu
                            selected={map(
                              trashedProperties,
                              (property) => property._id,
                            )}
                          />
                        </List>
                      </div>
                    </Menu>
                  </li>
                )
              }}
            </Query>
          </ul>
          <Divider style={{ backgroundColor: '#46506c' }} />
          <ul>
            <li>
              <a
                id="leftNavMarketplaces"
                href="#/"
                onClick={toggleMarketplacesDropdown}
                className={clsx({ active: marketplacesDropdownOpen })}
              >
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={['fad', 'sign-hanging']} />
                </span>
                <span className="sidebar-item-label">
                  Marketplaces
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={marketplacesDropdownOpen}>
                <ul>
                  {/* {!urlAffiliate && (
                    <li>
                      <NavLink
                        id="leftNavMarketplacesHousefoliosMarketplace"
                        onClick={() => handleMarketplace(null)}
                        to="/marketplace"
                        isActive={(match, location) => {
                          if (!match) {
                            return false
                          }
                          const storedMarketplace =
                            localStorage.getItem('marketplace')
                          return storedMarketplace === 'null'
                        }}
                      >
                        {props?.session?.me?.activeOrg?.member ===
                        'RWN'
                          ? 'All Properties'
                          : 'Housefolios Marketplace'}
                      </NavLink>
                    </li>
                  )} */}
                  {affiliate?.memberValue === 'pep' && (
                    <li>
                      <NavLink
                        id="leftNavMarketplacesPEPMarketplace"
                        onClick={() => handleMarketplace('pep')}
                        to="/marketplace"
                        isActive={(match, location) => {
                          if (!match) {
                            return false
                          }
                          const storedMarketplace =
                            localStorage.getItem('marketplace')
                          return storedMarketplace === 'PEP'
                        }}
                      >
                        {'PEP Marketplace'}
                      </NavLink>
                    </li>
                  )}
                  {map(
                    props.session.me.activeOrg.marketPlaces,
                    (marketplace) => (
                      <li
                        key={marketplace.url}
                        className="list-group-item-action hover-show-hide-container"
                      >
                        <NavLink
                          name="leftNaveAddedMarketplace"
                          //id={"leftNavMarketplace" + marketplace.name}
                          onClick={() =>
                            handleMarketplace(marketplace.url)
                          }
                          to="/marketplace"
                          isActive={(match, location) => {
                            if (!match) {
                              return false
                            }
                            const storedMarketplace =
                              localStorage.getItem('marketplace')
                            return (
                              storedMarketplace === marketplace.url
                            )
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <Typography
                                style={{ width: 180 }}
                                noWrap
                              >
                                {marketplace.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className="hover-hide-wrapper"
                              style={{ marginRight: -32, width: 8 }}
                            >
                              <Typography>
                                {marketplace.propertyCount || 0}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className="hover-show-wrapper gridItem2"
                              style={{ marginRight: -40 }}
                            >
                              <Tooltip
                                title="Marketplace Actions"
                                classes={{ tooltip: 'tooltip-first' }}
                                arrow
                              >
                                <IconButton
                                  onClick={(event) =>
                                    openMarketplaceOptions(
                                      event,
                                      marketplace,
                                    )
                                  }
                                  style={{
                                    backgroundColor: 'white',
                                    width: 30,
                                    height: 30,
                                  }}
                                  size="large"
                                >
                                  <MoreVertOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </NavLink>
                      </li>
                    ),
                  )}
                  {props?.session?.me?.activeOrg?.member !==
                    'RWN' && <AddMarketplace mainNav={true} />}
                  {!isEmpty(
                    props.session.me.activeOrg.sharedMarketplaces,
                  ) && (
                    <ListItem
                      button
                      disabled
                      style={{ color: 'white' }}
                    >
                      <Share
                        style={{ marginLeft: 10, marginRight: 10 }}
                      />
                      Shared Marketplaces
                    </ListItem>
                  )}
                  {map(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) => (
                      <li
                        key={marketplace.url}
                        className="list-group-item-action hover-show-hide-container"
                      >
                        <NavLink
                          name="leftNaveAddedMarketplace"
                          //id={"leftNavMarketplace" + marketplace.name}
                          onClick={() =>
                            handleMarketplace(marketplace.url)
                          }
                          to="/marketplace"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <Typography
                                style={{ width: 180 }}
                                noWrap
                              >
                                {marketplace.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className="hover-hide-wrapper"
                              style={{ marginRight: -32, width: 8 }}
                            >
                              <Typography>
                                {marketplace.propertyCount || 0}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className="hover-show-wrapper gridItem2"
                              style={{ marginRight: -40 }}
                            >
                              <RemoveSharedMarketplace
                                orgId={props.session.me.activeOrg._id}
                                marketplaceURL={marketplace.url}
                                sidebarMenu
                              />
                            </Grid>
                          </Grid>
                        </NavLink>
                      </li>
                    ),
                  )}
                </ul>
              </Collapse>
            </li>
          </ul>
          <Divider style={{ backgroundColor: '#46506c' }} />
          {props.session.me.housefoliosAdmin && (
            <ul>
              <li>
                <a
                  id="leftNavAdmin"
                  href="#/"
                  onClick={togglePartnerDropdown}
                  className={clsx({ active: partnerDropdownOpen })}
                >
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={['fal', 'toolbox']} />
                  </span>
                  <span className="sidebar-item-label">
                    Housefolios Admin
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={partnerDropdownOpen}>
                  <ul>
                    <li>
                      <a
                        id="leftNavMarketplaces"
                        href="#/"
                        onClick={handleOpenHousefoliosAdmin}
                      >
                        <span className="sidebar-icon">
                          <FontAwesomeIcon
                            size="sm"
                            icon={['far', 'folder-gear']}
                          />
                        </span>
                        <span className="sidebar-item-label">
                          Dashboard
                        </span>
                      </a>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'active' : ''
                        }
                        isActive={(match, location) => {
                          return match
                        }}
                        to="/reports"
                      >
                        <span className="sidebar-icon">
                          <FontAwesomeIcon
                            size="sm"
                            icon={['fal', 'file-chart-pie']}
                          />
                        </span>
                        <span className="sidebar-item-label">
                          Reports
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          )}
          {props.session.me.activeOrg.isAffiliate && (
            <ul>
              <li>
                <a
                  id="leftNavPartner"
                  href="#/"
                  onClick={togglePartnerDropdown}
                  className={clsx({ active: partnerDropdownOpen })}
                >
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={['far', 'people-roof']} />
                  </span>
                  <span className="sidebar-item-label">
                    {
                      props.session.me.activeOrg.isAffiliate
                        .companyName
                    }{' '}
                    Admin
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={partnerDropdownOpen}>
                  <ul>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'active' : ''
                        }
                        isActive={(match, location) => {
                          return match
                        }}
                        to="/partnerportal"
                      >
                        <span className="sidebar-icon">
                          <FontAwesomeIcon
                            size="sm"
                            icon={['far', 'folder-gear']}
                          />
                        </span>
                        <span className="sidebar-item-label">
                          Dashboard
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'active' : ''
                        }
                        isActive={(match, location) => {
                          return match
                        }}
                        to="/reports"
                      >
                        <span className="sidebar-icon">
                          <FontAwesomeIcon
                            size="sm"
                            icon={['fal', 'file-chart-pie']}
                          />
                        </span>
                        <span className="sidebar-item-label">
                          Reports
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'active' : ''
                        }
                        isActive={(match, location) => {
                          return match
                        }}
                        to="/clients"
                      >
                        <span className="sidebar-icon">
                          <HelpTwoToneIcon />
                        </span>
                        <span className="sidebar-item-label">
                          Client Support
                        </span>
                      </NavLink>
                    </li>
                    {props.session.me.activeOrg.isAffiliate
                      .isRealeflow && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? 'active' : ''
                          }
                          isActive={(match, location) => {
                            return match
                          }}
                          to="/featureRequests"
                        >
                          <span className="sidebar-icon">
                            <FontAwesomeIcon
                              size="sm"
                              icon={['fas', 'wand-magic-sparkles']}
                            />
                          </span>
                          <span className="sidebar-item-label">
                            Feature Request
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </Collapse>
              </li>
            </ul>
          )}
          <span className="sidebar-show-on-hover">
            <Divider style={{ backgroundColor: '#46506c' }} />
            {!props?.session?.me?.activeOrg?.isRealeflow && (
              <Query query={GET_PORTFOLIOS}>
                {({ loading, error, refetch, data }) => {
                  if (loading) return ''
                  if (error) return `Error! ${error.message}`
                  const { portfolios } = data
                  return (
                    <Query query={GET_TEAM}>
                      {({ loading, error, data }) => {
                        if (loading) return 'Loading...'
                        if (error) return `Error! ${error.message}`
                        const teamCount = data.teamMembers.length
                        const { limits } = props.session.me
                        const {
                          marketplaceLimit,
                          teamLimit,
                          portfolioLimit,
                        } = limits
                        return (
                          <div className="app-sidebar--userbox">
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              style={{ marginBottom: 15 }}
                            >
                              <Grid item className="gridItem2">
                                <Typography
                                  className="text-warning"
                                  style={{ fontSize: 14 }}
                                >
                                  Organization:
                                </Typography>
                              </Grid>
                              <Grid item className="gridItem2">
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    marginLeft: 5,
                                  }}
                                >
                                  {props.session.me.activeOrg.name}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              direction="column"
                              spacing={2}
                              alignItems="flex-start"
                            >
                              <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item className="gridItem2">
                                  <Typography>
                                    Bonus Properties
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ width: '100%' }}
                                  className="flex-fill"
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    className="progress-xs progress-animated-alt my-2 progress-bar-first"
                                    value={
                                      props.session.me.activeOrg
                                        .propertyCount.bonusProperties
                                        ? 100
                                        : 0
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  className="d-flex justify-content-between font-size-xs"
                                >
                                  <Typography style={{ flex: 1 }} />
                                  <Grid
                                    item
                                    className="opacity-5 gridItem2"
                                  >
                                    {props.session.me.activeOrg
                                      .propertyCount
                                      .bonusProperties || 0}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                              >
                                <Grid item className="gridItem2">
                                  <Typography>
                                    Monthly Limit
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ width: '100%' }}
                                  className="flex-fill"
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    className="progress-xs progress-animated-alt my-2 progress-bar-first"
                                    value={
                                      !isUndefined(LIMIT)
                                        ? props.session.me.activeOrg
                                            .propertyCount
                                            .monthlyCount > LIMIT
                                          ? 100
                                          : (props.session.me
                                              .activeOrg.propertyCount
                                              .monthlyCount /
                                              LIMIT) *
                                              99.5 +
                                            0.5
                                        : 0.5
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  className="d-flex justify-content-between font-size-xs"
                                >
                                  {resetDate && (
                                    <Grid
                                      item
                                      className="text-first gridItem2"
                                    >
                                      Limit resets in {daysToReset}{' '}
                                      days
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    className="opacity-5 gridItem2"
                                  >
                                    {!isUndefined(LIMIT)
                                      ? `${
                                          props.session.me.activeOrg
                                            .propertyCount
                                            .monthlyCount || 0
                                        } / ${Number(LIMIT)}`
                                      : 'Unlimited'}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {props?.session?.me?.activeOrg
                                ?.member !== 'RWN' && (
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                >
                                  <Grid item className="gridItem2">
                                    <Typography>
                                      Marketplace Limit
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ width: '100%' }}
                                    className="flex-fill"
                                  >
                                    <LinearProgress
                                      variant="determinate"
                                      className="progress-xs progress-animated-alt my-2 progress-bar-first"
                                      value={
                                        !isUndefined(marketplaceLimit)
                                          ? props.session.me.activeOrg
                                              .marketPlaces.length >
                                            marketplaceLimit
                                            ? 100
                                            : (props.session.me
                                                .activeOrg
                                                .marketPlaces.length /
                                                marketplaceLimit) *
                                                99.5 +
                                              0.5
                                          : 0.5
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    className="d-flex justify-content-between font-size-xs"
                                  >
                                    <Typography style={{ flex: 1 }} />
                                    <Grid
                                      item
                                      className="opacity-5 gridItem2"
                                    >
                                      {!isUndefined(LIMIT)
                                        ? `${
                                            props.session.me.activeOrg
                                              .marketPlaces.length ||
                                            0
                                          } / ${Number(
                                            marketplaceLimit,
                                          )}`
                                        : 'Unlimited'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                              {props?.session?.me?.activeOrg
                                ?.member !== 'RWN' && (
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                >
                                  <Grid item className="gridItem2">
                                    <Typography>
                                      Team Limit
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ width: '100%' }}
                                    className="flex-fill"
                                  >
                                    <LinearProgress
                                      variant="determinate"
                                      className="progress-xs progress-animated-alt my-2 progress-bar-first"
                                      value={
                                        !isUndefined(teamLimit)
                                          ? teamCount > teamLimit
                                            ? 100
                                            : (teamCount /
                                                teamLimit) *
                                                99.5 +
                                              0.5
                                          : 0.5
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    className="d-flex justify-content-between font-size-xs"
                                  >
                                    <Typography style={{ flex: 1 }} />
                                    <Grid
                                      item
                                      className="opacity-5 gridItem2"
                                    >
                                      {!isUndefined(teamLimit)
                                        ? `${teamCount || 0} / ${
                                            teamLimit || 1
                                          }`
                                        : 'Unlimited'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                              {props?.session?.me?.activeOrg
                                ?.member !== 'RWN' && (
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                >
                                  <Grid item className="gridItem2">
                                    <Typography>
                                      Portfolio Limit
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ width: '100%' }}
                                    className="flex-fill"
                                  >
                                    <LinearProgress
                                      variant="determinate"
                                      className="progress-xs progress-animated-alt my-2 progress-bar-first"
                                      value={
                                        !isUndefined(portfolioLimit)
                                          ? portfolios.length >
                                            portfolioLimit
                                            ? 100
                                            : (portfolios.length /
                                                portfolioLimit) *
                                                99.5 +
                                              0.5
                                          : 0.5
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    className="d-flex justify-content-between font-size-xs"
                                  >
                                    <Typography style={{ flex: 1 }} />
                                    <Grid
                                      item
                                      className="opacity-5 gridItem2"
                                    >
                                      {!isUndefined(portfolioLimit)
                                        ? `${
                                            portfolios.length || 0
                                          } / ${portfolioLimit || 1}`
                                        : 'Unlimited'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                              <Grid
                                item
                                style={{
                                  marginTop: 20,
                                  width: '100%',
                                }}
                                xs={12}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    style={{ width: '100%' }}
                                  >
                                    {/* <Button
                                // component={NavLink}
                                // to="/"
                                size="small"
                                className="btn-userbox"
                                fullWidth={true}
                              >
                                Get More Properties
                              </Button> */}
                                    <BonusProperties
                                      session={props.session}
                                      sidebarMenu={true}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            )}
          </span>
        </div>
      </PerfectScrollbar>

      {/* Edit portfolio Menu      
    <Menu
      anchorEl={anchorElMenu1}
      open={Boolean(anchorElMenu1)}
      onClose={handleCloseEditPortfolio}
      classes={{ list: 'p-0' }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div>
        <List
          component="div"
          className="nav-list-square nav-neutral-primary"
        >
        </List>
      </div>
    </Menu> */}

      {/* Portfolio MoreVert */}
      {selectedPortfolio && (
        <>
          <Menu
            anchorEl={anchorElMenu1}
            open={Boolean(anchorElMenu1)}
            onClose={handleClosePortfolioOptions}
            classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div>
              <List
                component="div"
                className="nav-list-square nav-neutral-primary"
              ></List>
            </div>
          </Menu>
          <Menu
            anchorEl={anchorElMenu2}
            keepMounted
            open={Boolean(anchorElMenu2)}
            onClose={handleClosePortfolioOptions}
            classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div>
              <List
                component="div"
                className="nav-list-square nav-neutral-primary"
                onClick={handleClosePortfolioOptions}
              >
                <EditPortfolio
                  sidebarMenu={true}
                  portfolio={selectedPortfolio}
                />
                <EditAssumptions
                  sidebarMenu={true}
                  documentId={selectedPortfolio._id}
                  documentName={selectedPortfolio.name}
                  defaultStrategy={selectedPortfolio.strategy}
                  documentType="portfolio"
                  member={props?.session?.me?.activeOrg?.member}
                />
                <DuplicatePortfolio
                  sidebarMenu={true}
                  portfolio={selectedPortfolio}
                />
                <SharePortfolio
                  sidebarMenu={true}
                  portfolio={selectedPortfolio}
                />
                {/* <SkipTrace sidebarMenu={true} portfolioId={selectedPortfolio._id} /> */}
                {/* <ExportPortfolio sidebarMenu={true} selectedPortfolioId={selectedPortfolio._id} closeMenu={handleClosePortfolioOptions} /> */}
                <Divider />
                {canDelete && (
                  <DeletePortfolio
                    sidebarMenu={true}
                    portfolio={selectedPortfolio}
                    handleClose={handleClosePortfolioOptions}
                  />
                )}
              </List>
            </div>
          </Menu>
        </>
      )}
      {selectedMarketplace && (
        <Menu
          anchorEl={anchorElMenu3}
          keepMounted
          open={Boolean(anchorElMenu3)}
          onClose={handleCloseMarketplaceOptions}
          classes={{ list: 'p-0' }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            <List
              component="div"
              className="nav-list-square nav-neutral-primary"
              onClick={handleCloseMarketplaceOptions}
            >
              <ShareMarketplace
                sidebarMenu={true}
                marketplace={selectedMarketplace}
                activeOrg={activeOrg}
              />
              {selectedMarketplace.status === 'active' && (
                //? (
                <MenuItem
                  component="a"
                  href={'https://' + selectedMarketplace.url}
                  target="_blank"
                >
                  <LaunchIcon
                    style={{ marginRight: 5, color: '#757575' }}
                  />
                  Open
                </MenuItem>
                // ) : (
                //   <Tooltip title='Your Marketplace is being setup, check again later.'>
                //     <MenuItem
                //       className="btn-first rounded-sm text-uppercase font-size-xs font-weight-bold mr-4 py-0 shadow-none hover-scale-sm w-auto d-40">
                //       Open
                //     </MenuItem>
                //   </Tooltip>
              )}
              {!isEmpty(activeOrg.affiliate) &&
                selectedMarketplace.status === 'active' && (
                  <MenuItem onClick={toggleShare}>
                    <SendIcon
                      style={{ marginRight: 5, color: '#757575' }}
                    />
                    Affiliate Share
                  </MenuItem>
                )}
              <EditMarketplace
                listItem
                marketPlace={selectedMarketplace}
              />
            </List>
          </div>
        </Menu>
      )}

      <Dialog
        open={Boolean(selectedMarketplace) && openShare}
        onClose={toggleShare}
      >
        <DialogTitle>Share Marketplace With Affiliate</DialogTitle>
        <DialogContent>
          When you share your marketplace with an organization below,
          the properties listed on{' '}
          {selectedMarketplace ? selectedMarketplace.name : ''} will
          also be shown on that organization's marketplace.
        </DialogContent>
        <DialogActions>
          <Mutation
            mutation={UPDATE_ORGANIZATION}
            refetchQueries={[
              {
                query: GET_ME,
              },
            ]}
          >
            {(updateOrganization, { data, loading, error }) => {
              let newMarkets = omitBy(
                props.session.me.activeOrg.affiliateMarketplace,
                (value) =>
                  value ===
                  (selectedMarketplace
                    ? selectedMarketplace.url
                    : ''),
              )
              newMarkets[selectedAffiliate] = selectedMarketplace
                ? selectedMarketplace.url
                : ''
              return (
                <span>
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                    aria-label="split button"
                  >
                    <Button
                      onClick={() =>
                        updateOrganization({
                          variables: {
                            id: activeOrg._id,
                            organizationInput: {
                              affiliateMarketplace: newMarkets,
                            },
                          },
                        }).then(({ data }) => {
                          setSelectedMarketplace({})
                          setOpenShare(false)
                        })
                      }
                      style={{
                        marginVertical: 5,
                      }}
                    >
                      Share with {selectedAffiliate}
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      aria-controls={
                        openMarkets ? 'split-button-menu' : undefined
                      }
                      aria-expanded={openMarkets ? 'true' : undefined}
                      aria-label="select portfolio"
                      aria-haspopup="menu"
                      onClick={toggleMarkets}
                      style={{ marginVertical: 5 }}
                    >
                      <ArrowDropDown />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    open={openMarkets}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 1500 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={handleMarketsClose}
                          >
                            <MenuList id="split-button-menu">
                              {map(
                                activeOrg.affiliate,
                                (affiliate) => (
                                  <MenuItem
                                    key={affiliate._id}
                                    selected={
                                      affiliate === selectedAffiliate
                                    }
                                    onClick={(event) => {
                                      setSelectedAffiliate(affiliate)
                                      setOpenMarkets(false)
                                    }}
                                  >
                                    {affiliate}
                                  </MenuItem>
                                ),
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </span>
              )
            }}
          </Mutation>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openHousefoliosAdmin}
        onClose={handleCloseHousefoliosAdmin}
        fullScreen
      >
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                style={{ color: 'white' }}
                className={clsx(
                  classes.menuButton,
                  open && classes.hide,
                )}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                style={{ color: 'white' }}
                noWrap
              >
                Housefolios Admin
              </Typography>
              <Typography style={{ flex: 1 }} />
              <Button
                variant="outlined"
                size="small"
                style={{ color: 'white', borderColor: 'white' }}
                onClick={handleCloseHousefoliosAdmin}
              >
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {map(listItems, (section) => (
                <ListItem
                  button
                  selected={adminTab === section.name}
                  onClick={() => {
                    setAdminTab(section.name)
                    handleDrawerClose(false)
                  }}
                >
                  <ListItemIcon style={{ paddingLeft: 20 }}>
                    {section.icon}
                  </ListItemIcon>
                  <ListItemText>{section.name}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {adminTab === 'General' && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ paddingTop: 25 }}>
                    General
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: 10, paddingBottom: 25 }}
                >
                  <Typography variant="subtitle1">
                    A list of general actions for Housefolios.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Housefolios Admin
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Give a user access to Housefolios Admin tools.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <TextField
                      name="email"
                      variant="outlined"
                      label="Email"
                      value={adminEmail}
                      onChange={(event) =>
                        setAdminEmail(event.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateUserByEmail({
                          variables: {
                            email: adminEmail,
                            userInput: {
                              housefoliosAdmin: true,
                            },
                          },
                        })
                          .then(() => {
                            enqueueSnackbar(`Success!`, {
                              variant: 'success',
                              autoHideDuration: 3000,
                            })
                          })
                          .catch((error) => {
                            enqueueSnackbar(
                              `Failed: ${error.message}`,
                              {
                                variant: 'error',
                                autoHideDuration: 3000,
                              },
                            )
                          })
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <Typography variant="h6">Reset Calcs</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    This will recalculate ALL calculations. Don't do
                    this unless instructed to.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#f83145',
                      color: 'white',
                    }}
                    onClick={() => {
                      resetCalcs()
                        .then(() => {
                          enqueueSnackbar(`Started Recalculation!`, {
                            variant: 'success',
                            autoHideDuration: 3000,
                          })
                        })
                        .catch((error) => {
                          enqueueSnackbar(
                            `Failed: ${error.message}`,
                            {
                              variant: 'error',
                              autoHideDuration: 3000,
                            },
                          )
                        })
                    }}
                  >
                    Recalculate
                  </Button>
                </Grid>
              </Grid>
            )}
            {adminTab === 'Affiliate' && (
              <Query query={GET_AFFILIATES}>
                {({ loading, error, data }) => {
                  if (loading) return 'Loading'
                  if (error) return `Error! ${error.message}`
                  const { affiliates } = data

                  const count = ceil(affiliates.length / 20)

                  const visuallyHidden = {
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }

                  const tableHeaders = [
                    { title: 'Name', name: 'name' },
                    { title: 'Affiliate ID', name: 'hfpid' },
                    { title: 'Company Name', name: 'companyName' },
                    { title: 'Affiliate Tag', name: 'memberValue' },
                    { title: 'Date Created', name: 'createdAt' },
                    { title: 'Status', name: 'active' },
                    { title: 'Actions', name: '' },
                  ]

                  const affiliateSections = [
                    {
                      label: 'Affiliate Info',
                      fields: [
                        {
                          name: 'name',
                          label: 'Name',
                          type: 'text',
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'hfpid',
                          label: 'Affiliate ID',
                          type: 'text',
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'companyName',
                          label: 'Company Name',
                          type: 'text',
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'email',
                          label: 'Email',
                          type: 'text',
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'phone',
                          label: 'Phone',
                          type: 'text',
                          validators: ['matchRegexp:^[0-9()-]+$'],
                          errorMessages: ['Not a valid phone number'],
                        },
                        {
                          name: 'commission',
                          label: 'Commission %',
                          type: 'number',
                        },
                        {
                          name: 'memberValue',
                          label: 'Affiliate Tag',
                          type: 'text',
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'orgId',
                          label: 'Org Id',
                          type: 'text',
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'defaultStrategy',
                          label: 'Default Strategy',
                          type: 'select',
                          options: map(
                            merge(
                              cloneDeep(DEFAULT_PREFERENCES),
                              props.session.me.activeOrg
                                .memberPreferences,
                              props?.session?.me?.activeOrg
                                ?.preferences,
                            ).general['strategies'],
                            (preference) => ({
                              value: preference.value,
                              label: preference.value,
                            }),
                          ),
                          validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                      ],
                    },
                    {
                      label: 'Create Account',
                      fields: [
                        {
                          name: 'createAccount.headerImage',
                          label: 'Create Account Image',
                          type: 'file',
                        },
                        {
                          name: 'createAccount.memberTrial',
                          label: 'Trial Days',
                          type: 'number',
                        },
                      ],
                    },
                    {
                      label: 'Sign In Page',
                      fields: [
                        {
                          name: 'signIn.headerImage',
                          label: 'Sign In Image',
                          type: 'file',
                        },
                      ],
                    },
                    {
                      label: 'Launchpad Page',
                      fields: [
                        {
                          name: 'launchpad.memberLayout',
                          label: 'Default Layout',
                          type: 'select',
                          options: [
                            {
                              value: true,
                              label: 'Yes',
                            },
                            {
                              value: false,
                              label: 'No',
                            },
                          ],
                        },
                        {
                          name: 'launchpad.memberWelcomeVideo',
                          label: 'Welcome Video URL',
                          type: 'text',
                        },
                      ],
                    },
                    {
                      label: 'Left Nav',
                      fields: [
                        {
                          name: 'sidebar.sidebarMemberFullImage',
                          label: 'Upload Full Image',
                          type: 'file',
                        },
                        {
                          name: 'sidebar.sidebarMemberSmallImage',
                          label: 'Upload Small Image',
                          type: 'file',
                        },
                      ],
                    },
                    {
                      label: 'Plan Details',
                      fields: [
                        {
                          name: 'planDetails.price',
                          label: 'Price',
                          type: 'number',
                          // validators: ['required'],
                          errorMessages: ['This field is required'],
                          helperText: `This value is equivelent to ${currencyFormat.format(
                            get(affiliateInput, 'planDetails.price') /
                              100 || 0,
                          )}`,
                        },
                        {
                          name: 'planDetails.paymentInterval',
                          label: 'Payment Interval',
                          type: 'select',
                          options: [
                            {
                              value: 'monthly',
                              label: 'Monthly',
                            },
                            {
                              value: 'yearly',
                              label: 'Yearly',
                            },
                          ],
                          // validators: ['required'],
                          errorMessages: ['This field is required'],
                        },
                        {
                          name: 'planDetails.portfolioLimit',
                          label: 'Portfolio Limit',
                          type: 'number',
                        },
                        {
                          name: 'planDetails.marketplaceLimit',
                          label: 'Marketplace Limit',
                          type: 'number',
                        },
                        {
                          name: 'planDetails.teamLimit',
                          label: 'Team Limit',
                          type: 'number',
                        },
                        {
                          name: 'planDetails.propertyCount',
                          label: 'Property Count',
                          type: 'number',
                        },
                      ],
                    },
                  ]

                  return (
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={1}
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            style={{ paddingTop: 25 }}
                          >
                            Affiliates
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={handleOpenAffiliateEdit}
                            style={{
                              backgroundColor: '#28b854',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                            className="btn-success d-40 w-auto mr-3"
                          >
                            <Add
                              style={{
                                fontWeight: 600,
                                marginRight: 5,
                              }}
                            />
                            <Typography style={{ fontWeight: 600 }}>
                              Add Affiliate
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: 10, paddingBottom: 25 }}
                      >
                        <Typography variant="subtitle1">
                          A list of actions for Housefolios
                          Affiliates.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Housefolios Affiliates
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Add/Edit/Remove Affiliates.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className="table-responsive">
                          <Table className="table text-nowrap mb-0">
                            <thead className="thead-light">
                              <tr className="text-center">
                                {map(tableHeaders, (column) => (
                                  <TableCell
                                    key={column.title}
                                    //style={{ whiteSpace: 'nowrap' }}
                                    align={column.align}
                                    sortDirection={
                                      orderBy === column.name
                                        ? order
                                        : false
                                    }
                                  >
                                    {column.name ? (
                                      <TableSortLabel
                                        active={
                                          orderBy === column.name
                                        }
                                        direction={order}
                                        onClick={(event) =>
                                          handleRequestSort(
                                            event,
                                            column.name,
                                          )
                                        }
                                      >
                                        {column.title}
                                        {orderBy === column.name ? (
                                          <span
                                            style={visuallyHidden}
                                          >
                                            {order === 'desc'
                                              ? 'sorted descending'
                                              : 'sorted ascending'}
                                          </span>
                                        ) : null}
                                      </TableSortLabel>
                                    ) : (
                                      column.title
                                    )}
                                  </TableCell>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {map(
                                slice(
                                  stableSort(
                                    affiliates,
                                    getSorting(order, orderBy),
                                  ),
                                  (page - 1) * 20,
                                  page * 20,
                                ),
                                (aff) => {
                                  return (
                                    <tr>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div>{aff.name || '-'}</div>
                                        </div>
                                      </td>
                                      <td>
                                        <div>{aff.hfpid || '-'}</div>
                                      </td>
                                      <td>
                                        <div>
                                          {aff.companyName || '-'}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {aff.memberValue || '-'}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {moment(
                                            aff.createdAt,
                                          ).format('MMM Do YYYY')}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {aff.active
                                            ? 'active'
                                            : 'disabled'}
                                        </div>
                                      </td>
                                      <td>
                                        <IconButton
                                          onClick={(event) =>
                                            handleOpenAffiliateOptions(
                                              event,
                                              aff,
                                            )
                                          }
                                          style={{
                                            backgroundColor: 'white',
                                            width: 30,
                                            height: 30,
                                          }}
                                          size="large"
                                        >
                                          <MoreVertOutlinedIcon />
                                        </IconButton>

                                        <Menu
                                          anchorEl={
                                            anchorElAffiliateMenu
                                          }
                                          keepMounted
                                          open={
                                            aff._id ===
                                              affiliateInput._id &&
                                            Boolean(
                                              anchorElAffiliateMenu,
                                            )
                                          }
                                          onClose={
                                            handleCloseAffiliateOptions
                                          }
                                          classes={{ list: 'p-0' }}
                                          getContentAnchorEl={null}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                        >
                                          <div>
                                            <List
                                              component="div"
                                              className="nav-list-square nav-neutral-primary"
                                            >
                                              <MenuItem
                                                button
                                                onClick={
                                                  handleOpenAffiliateEdit
                                                }
                                              >
                                                Edit Affiliate
                                              </MenuItem>
                                              {affiliateInput.active && (
                                                <MenuItem
                                                  button
                                                  onClick={() => {
                                                    updateAffiliate({
                                                      variables: {
                                                        affiliateId:
                                                          affiliateInput._id,
                                                        affiliateInput:
                                                          {
                                                            active: false,
                                                          },
                                                      },
                                                      refetchQueries:
                                                        [
                                                          {
                                                            query:
                                                              GET_AFFILIATES,
                                                          },
                                                        ],
                                                    })
                                                      .then(() => {
                                                        enqueueSnackbar(
                                                          `Success! Affiliate disabled`,
                                                          {
                                                            variant:
                                                              'success',
                                                            autoHideDuration: 3000,
                                                          },
                                                        )
                                                      })
                                                      .catch(
                                                        (error) => {
                                                          enqueueSnackbar(
                                                            `Failed to disable affiliate`,
                                                            {
                                                              variant:
                                                                'error',
                                                              autoHideDuration: 3000,
                                                            },
                                                          )
                                                        },
                                                      )
                                                      .finally(() => {
                                                        handleCloseAffiliateOptions()
                                                      })
                                                  }}
                                                >
                                                  Disable Affiliate
                                                </MenuItem>
                                              )}
                                              {!affiliateInput.active && (
                                                <MenuItem
                                                  button
                                                  onClick={() => {
                                                    updateAffiliate({
                                                      variables: {
                                                        affiliateId:
                                                          affiliateInput._id,
                                                        affiliateInput:
                                                          {
                                                            active: true,
                                                          },
                                                      },
                                                      refetchQueries:
                                                        [
                                                          {
                                                            query:
                                                              GET_AFFILIATES,
                                                          },
                                                        ],
                                                    })
                                                      .then(() => {
                                                        enqueueSnackbar(
                                                          `Success! Affiliate activated`,
                                                          {
                                                            variant:
                                                              'success',
                                                            autoHideDuration: 3000,
                                                          },
                                                        )
                                                      })
                                                      .catch(
                                                        (error) => {
                                                          enqueueSnackbar(
                                                            `Failed to activate affiliate`,
                                                            {
                                                              variant:
                                                                'error',
                                                              autoHideDuration: 3000,
                                                            },
                                                          )
                                                        },
                                                      )
                                                      .finally(() => {
                                                        handleCloseAffiliateOptions()
                                                      })
                                                  }}
                                                >
                                                  Activate Affiliate
                                                </MenuItem>
                                              )}
                                            </List>
                                          </div>
                                        </Menu>
                                      </td>
                                    </tr>
                                  )
                                },
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div className="divider" />
                        <div className="divider" />
                        <div className="p-3 d-flex justify-content-center">
                          <Pagination
                            className="pagination-first"
                            size="small"
                            count={count}
                            page={page}
                            onChange={handleChange}
                          />
                        </div>
                      </Grid>

                      <Dialog
                        open={openAffiliateEdit}
                        onClose={handleCloseAffiliateEdit}
                      >
                        <ValidatorForm
                          onSubmit={() => {
                            if (affiliateInput._id) {
                              let finalAffiliateInput = {
                                ...affiliateInput,
                              }
                              unset(finalAffiliateInput, '_id')
                              unset(finalAffiliateInput, '__typename')
                              updateAffiliate({
                                variables: {
                                  affiliateId: affiliateInput._id,
                                  affiliateInput: finalAffiliateInput,
                                },
                                refetchQueries: [
                                  {
                                    query: GET_AFFILIATES,
                                  },
                                ],
                              })
                                .then(() => {
                                  enqueueSnackbar(
                                    `Success! Affiliate updated`,
                                    {
                                      variant: 'success',
                                      autoHideDuration: 3000,
                                    },
                                  )
                                  handleCloseAffiliateEdit()
                                  handleCloseAffiliateOptions()
                                })
                                .catch((error) => {
                                  enqueueSnackbar(
                                    `Failed to update affiliate`,
                                    {
                                      variant: 'error',
                                      autoHideDuration: 3000,
                                    },
                                  )
                                })
                            } else {
                              addAffiliate({
                                variables: {
                                  affiliateInput: {
                                    ...affiliateInput,
                                    active: true,
                                  },
                                },
                                refetchQueries: [
                                  {
                                    query: GET_AFFILIATES,
                                  },
                                ],
                              })
                                .then(() => {
                                  enqueueSnackbar(
                                    `Success! Affiliate added`,
                                    {
                                      variant: 'success',
                                      autoHideDuration: 3000,
                                    },
                                  )
                                  handleCloseAffiliateEdit()
                                })
                                .catch((error) => {
                                  enqueueSnackbar(
                                    `Failed to add affiliate`,
                                    {
                                      variant: 'error',
                                      autoHideDuration: 3000,
                                    },
                                  )
                                })
                            }
                          }}
                        >
                          <DialogTitle>
                            {affiliateInput._id ? 'Edit' : 'Add'}{' '}
                            Affiliate
                          </DialogTitle>
                          <DialogContent>
                            <Grid container spacing={1}>
                              {map(affiliateSections, (section) => (
                                <>
                                  <Grid item>
                                    <Typography>
                                      {section.label}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    {map(section.fields, (field) =>
                                      renderInput(field),
                                    )}
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleCloseAffiliateEdit}
                            >
                              Close
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </ValidatorForm>
                      </Dialog>
                    </Grid>
                  )
                }}
              </Query>
            )}
          </main>
        </div>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarUserbox: state.DeprecatedThemeOptions.sidebarUserbox,
  sidebarToggle: state.DeprecatedThemeOptions.sidebarToggle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
})

export default withSession(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMenu),
)
